import { $GQL } from '@GQL/index';

export async function getMAutStateRevenueAndBuyerCount(
	params: {
		date_range: [string, string];
		filters: {
			source_id?: string;
			product?: string[];
			vertical_id: string;
		};
		mode: 'velocity' | 'range';
	},
	refresh?: boolean
) {
	const query = `
        query GetMAutStateRevenueAndBuyerCount($params: JSONObject!) {
            getMAutStateRevenueAndBuyerCount(params: $params) {
				rows
				metrics
			}
        }
    `;

	const no_cache = refresh ? ['getMAutStateRevenueAndBuyerCount'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.getMAutStateRevenueAndBuyerCount;
}
