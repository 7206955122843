<template>
	<div id="state-report-wrapper" class="view-content">
		<div class="page-heading">
			<h1>Buyer Count & Revenue By State</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div>
			<query-form v-model:query="query" :loading="loading" @apply="getData">
				<template #display>
					<div v-if="query.mode === 'range'" class="display-value">
						<div class="label">Purchase Dates:</div>
						<div class="value">
							{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>
					<div v-else class="display-value">
						<div class="label">Purchase Date</div>
						<div class="value">
							{{ formatDate(query.date_range[0].toISOString(), 'ddd, MMM DD, YYYY') }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Report Mode:</div>
						<div class="value">
							{{ displaySelected(query.mode, mode_options) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Vertical:</div>
						<div class="value">
							{{ displaySelected(query.filters.vertical_id, $root.appStore.verticalOptions) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Lead Type:</div>
						<div class="value">
							{{ displaySelected(query.filters.product, product_type_options) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Source ID:</div>
						<div class="value">{{ query.filters.source_id || 'All' }}</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						@click="getData(true)"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
					/>
				</template>

				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Date Range:</label>
									<div class="controls">
										<div class="field">
											<date-range-picker :max-range="21" v-model="form.query.date_range" />
										</div>
									</div>
								</div>
							</div>

							<div class="control-group">
								<div class="inner">
									<label class="control-label">Mode:</label>
									<div class="controls">
										<div class="field">
											<p-dropdown
												id="mode"
												v-model="form.query.mode"
												option-label="label"
												option-value="value"
												:options="mode_options"
												placeholder="Select Report Mode"
												class="state-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
								<gutter size="10px" />
								<p>When selecting Velocity, the start date in the range will be used as "Selected Day"</p>
							</div>
						</column>
						<gutter size="1em" />
						<column>
							<div class="control-group collapse">
								<div class="inner">
									<label class="control-label">Filter By Vertical:</label>
									<div class="controls">
										<div class="field">
											<p-dropdown
												v-model="form.query.filters.vertical_id"
												name="vertical_id"
												:options="$root.appStore.verticalOptions"
												option-label="label"
												option-value="value"
												class="w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>
							<gutter size="10px" />

							<div class="control-group">
								<div class="inner">
									<label class="control-label">Filter By Product:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												id="product-type"
												v-model="form.query.filters.product"
												option-label="label"
												option-value="value"
												:options="product_type_options"
												placeholder="Select product type"
												class="state-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="control-group">
								<div class="inner">
									<label class="control-label">Source ID:</label>
									<div class="controls">
										<div class="field">
											<p-input-text class="state-select w-full md:w-20rem" v-model="form.query.filters.source_id" />
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
		</div>
		<div class="page-content">
			<div class="card padded">
				<div class="card-heading unpadded" style="gap: 20px">
					<div><strong>Metrics by State</strong></div>
					<div>
						<p-select-button
							v-model="metric_type"
							:options="metric_type_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
				<gutter size="20px" />
				<p-data-table
					ref="statemetrics"
					@row-click="showDetails"
					:value="state_metrics"
					scrollable
					:scrollHeight="'70vh'"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No Data</div>
					</template>
					<p-column field="state" header="State">
						<template #body="row">
							{{ row.data.state }}
						</template>
					</p-column>
					<p-column header="" v-if="query.mode === 'velocity'">
						<template #body="row">
							<template v-if="row.data.show_details">
								{{ capitalize(metric_type) }}
								<div class="sub-value">Delta</div>
								<div class="details" v-if="metric_type === 'revenue'">
									RPL
									<div class="sub-value">Delta</div>
								</div>
							</template>
						</template>
					</p-column>
					<p-column v-for="day in days" :header="day" :key="day">
						<template #body="row">
							<template v-if="metric_type === 'revenue'">
								<span>
									{{ currency(get(row, `data.days.${day}.revenue`, 0), 0) }}
								</span>
								<div
									v-if="row.data.show_details"
									:class="[
										'sub-value',
										trend(
											calculateDelta(
												get(velocity_baseline, `${row.data.state}.revenue`, 0),
												get(row, `data.days.${day}.revenue`, 0)
											)
										),
									]"
								>
									{{
										percentage(
											calculateDelta(
												get(velocity_baseline, `${row.data.state}.revenue`, 0),
												get(row, `data.days.${day}.revenue`, 0)
											),
											2
										)
									}}
								</div>
								<div v-if="row.data.show_details" class="details">
									{{ currency(get(row, `data.days.${day}.rpl`, 0)) }}
									<div
										:class="[
											'sub-value',
											trend(
												calculateDelta(
													get(velocity_baseline, `${row.data.state}.rpl`, 0),
													get(row, `data.days.${day}.rpl`, 0)
												)
											),
										]"
									>
										{{
											percentage(
												calculateDelta(
													get(velocity_baseline, `${row.data.state}.rpl`, 0),
													get(row, `data.days.${day}.rpl`, 0)
												),
												2
											)
										}}
									</div>
								</div>
							</template>
							<template v-else-if="metric_type === 'leads'">
								<span>
									{{ get(row, `data.days.${day}.lead_count`, 0) }}
								</span>
								<div
									v-if="row.data.show_details"
									:class="[
										'sub-value',
										trend(
											calculateDelta(
												get(velocity_baseline, `${row.data.state}.lead_count`, 0),
												get(row, `data.days.${day}.lead_count`, 0)
											)
										),
									]"
								>
									{{
										percentage(
											calculateDelta(
												get(velocity_baseline, `${row.data.state}.lead_count`, 0),
												get(row, `data.days.${day}.lead_count`, 0)
											),
											2
										)
									}}
								</div>
							</template>
							<template v-else-if="metric_type === 'buyers'">
								{{ get(row, `data.days.${day}.buyer_count`, 0) }}
								<div
									v-if="row.data.show_details"
									:class="[
										'sub-value',
										trend(
											calculateDelta(
												get(velocity_baseline, `${row.data.state}.buyer_count`, 0),
												get(row, `data.days.${day}.buyer_count`, 0)
											)
										),
									]"
								>
									{{
										percentage(
											calculateDelta(
												get(velocity_baseline, `${row.data.state}.buyer_count`, 0),
												get(row, `data.days.${day}.buyer_count`, 0)
											),
											2
										)
									}}
								</div>
							</template>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import pColumnGroup from 'primevue/columngroup';
import { formatDate, currency, displaySelected, capitalize, percentage } from '@/lib/Filters';
import { getMAutStateRevenueAndBuyerCount } from '@GQL';
import dayjs from 'dayjs';
import { vertical_options, product_type_options } from '@/lib/Options';
export default {
	name: 'MautStateReport',
	components: {
		pColumnGroup,
		dateRangePicker,
		queryForm,
		verticalIcon,
	},
	data() {
		return {
			product_type_options,
			mode_options: [
				{
					value: 'range',
					label: 'Date Range',
				},
				{
					value: 'velocity',
					label: 'Velocity',
				},
			],
			query: {
				date_range: [dayjs().subtract(5, 'days').toDate(), dayjs().toDate()],
				filters: {
					source_id: '',
					product: [],
					vertical_id: 'health_insurance',
				},
				mode: 'velocity',
			},
			metric_type: 'revenue',
			metric_type_options: [
				{ value: 'revenue', label: 'Revenue' },
				{ value: 'leads', label: 'Leads' },
				{ value: 'buyers', label: 'Buyers' },
			],
			velocity_baseline: {},
			loading: false,
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Velocity Metrics by State' }],
			days: [],
			state_metrics: [],
		};
	},
	methods: {
		percentage,
		capitalize,
		vertical_options() {
			return vertical_options;
		},
		get,
		currency,
		displaySelected,
		formatDate,
		calculateDelta(baseline, value) {
			return baseline / value - 1;
		},
		trend(value) {
			if (Math.abs(value) > 0.05) {
				if (value > 0) return 'trending-up';
				if (value < 0) return 'trending-down';
			}
			return '';
		},
		async handleRowCollapse(event) {
			event.originalEvent.stopPropagation();
		},
		showDetails(event) {
			if (this.query.mode === 'velocity') {
				event.data.show_details = !event.data.show_details;
			}
		},
		async getData(refresh = false) {
			this.loading = true;
			try {
				const start_date = this.query.mode === 'velocity' ? dayjs() : dayjs(this.query.date_range[0]).startOf('day');
				const date_range = [start_date.toISOString(), dayjs(this.query.date_range[1]).endOf('day').toISOString()];
				const { metrics, rows } = await getMAutStateRevenueAndBuyerCount(
					{
						date_range,
						filters: this.query.filters,
						mode: this.query.mode,
					},
					refresh
				);

				this.days = get(metrics, 'headers', []);
				const selected_date_values = {};
				this.state_metrics = rows.map((row) => {
					// if velocity calculate deltas here
					if (this.query.mode === 'velocity') {
						// we need to set up the baseline for comparisons
						set(selected_date_values, row.state, get(row.days, 'Selected Date', {}));
						return { ...row, show_details: false };
					} else {
						return row;
					}
				});

				if (this.query.mode === 'velocity') {
					this.velocity_baseline = cloneDeep(selected_date_values);
				}
			} finally {
				this.loading = false;
			}
		},
	},
	computed: {
		// totals() {
		// 	const totals = {};
		// 	// Get totals for count and revenue
		// 	this.state_metrics.forEach((state) => {
		// 		Object.keys(state.days).forEach((day) => {
		// 			if (!totals[day]) {
		// 				totals[day] = {
		// 					revenue: 0,
		// 					buyer_count: 0,
		// 				};
		// 			}
		// 			totals[day].revenue += state.days[day].revenue;
		// 			totals[day].buyer_count += state.days[day].buyer_count;
		// 		});
		// 	});
		// 	return totals;
		// },
	},
	async mounted() {
		await this.getData();
	},
};
</script>

<style scoped lang="less">
.details {
	margin-top: 1em;
}
</style>
