<template>
	<p-dialog
		v-model:visible="show_modal"
		class="follow-up-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			root: { class: 'w-12 sm:w-12 md:w-6' },
		}"
	>
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<alt-icon type="sticky-note-2" size="24px" style="color: var(--color-b)" />
				<strong>Resolve Follow Up</strong>
			</div>
		</template>
		<div class="flex-flex-column">
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Disposition:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="disposition"
								name="disposition"
								:options="dispositionOptions"
								option-label="label"
								option-value="value"
							/>

							<div v-if="v$.disposition.$error" class="validation-error">
								{{ v$.disposition.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="disposition === 'Voice-To-Voice' || disposition === 'Working'" class="control-group">
				<div class="inner">
					<label class="control-label req">Due Date:</label>
					<div class="controls">
						<div class="field required">
							<p-calendar v-model="due_at" date-format="D, M d, yy" :manual-input="false" />
						</div>
					</div>
				</div>
			</div>

			<EmailNoteComponent
				ref="email_note_component"
				:data="{ disposition, account_id }"
				template_types="new_accounts"
			/>
		</div>

		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Submit" @click.prevent="submit" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import pTextArea from 'primevue/textarea';
import { resolveFollowUp, sendGmail } from '@GQL';
import { useSessionStore } from '@/stores/session';
import { disposition_options } from '@/lib/Options';
import pCalendar from 'primevue/calendar';
import { formatDate } from '@/lib/Filters';
import EmailNoteComponent from '@/views/FollowUps/Record/EmailNoteComponent.vue';

export default {
	name: 'ResolveFollowUpModal',
	props: {
		account_id: {
			type: String,
			required: true,
		},
		follow_up_id: {
			type: String,
			required: true,
		},
	},
	components: {
		pTextArea,
		pCalendar,
		EmailNoteComponent,
	},
	emits: ['refresh'],
	setup() {
		return {
			v$: useVuelidate(),
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			show_modal: false,
			disposition: '',
			due_at: new Date(),
			loading: false,
			disposition_options,
		};
	},
	computed: {
		dispositionOptions() {
			return this.disposition_options.filter((row) => {
				if (row.type) {
					return ['existing', 'reactivation'].indexOf(row.type) === -1;
				} else {
					return true;
				}
			});
		},
	},
	methods: {
		formatDate,
		openModal() {
			this.reset();
			this.show_modal = true;
		},
		reset() {
			this.disposition = '';
			this.due_at = new Date();
		},
		async submit() {
			const data = await this.$refs.email_note_component.getData();
			try {
				const is_valid = await this.v$.$validate();
				if (is_valid && data) {
					this.loading = true;
					const params = {
						id: this.follow_up_id,
						account_id: this.account_id,
						account_manager_id: this.sessionStore.user.id,
						disposition: this.disposition,
						note_data: data,
					};
					if (this.disposition === 'Voice-To-Voice' || this.disposition === 'Working') {
						params.due_at = this.due_at;
					}
					try {
						const result = await resolveFollowUp(params);
						this.$emit('refresh', { follow_up: result, disposition: this.disposition });

						let toast_summary = 'Follow up was resolved';

						// now if there's html and subject, make the Gmail request
						if ('body' in data) {
							// we gotta send the Gmail
							try {
								const gmail_res = await sendGmail({
									account_id: this.account_id,
									sender_user_id: this.sessionStore.user.id,
									body: data.body,
									subject: data.subject,
									from_email: this.sessionStore.user.email,
									from_name: this.sessionStore.user.first_name + ' ' + this.sessionStore.user.last_name,
									to_email: data.to_email,
									to_name: data.to_name,
								});
								toast_summary = 'Follow Up was resolved & GMail sent';
							} catch (err) {
								console.error(err);
							}
						}
						// close the modal & reset
						this.$toast.add({
							severity: 'success',
							summary: toast_summary,
							detail: result
								? `The next follow up is due on ${formatDate(new Date(result.due_at), 'M/D/YYYY')}`
								: 'No follow up scheduled',
							life: 10000,
						});

						this.show_modal = false;
						this.$refs.email_note_component.reset(); // do it at the end...
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to resolve Follow Up',
							detail: err.message.slice(0, 50),
							life: 6000,
						});
					}
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Required fields may be missing',
						life: 5000,
					});
				}
			} finally {
				this.loading = false;
			}
		},
	},
	validations() {
		return {
			disposition: {
				required: helpers.withMessage('Select a valid disposition', required),
			},
		};
	},
};
</script>
