import { $GQL } from '@GQL/index';
import type { SendEmailReq, SendEmailRes } from '@nextgenleads/email-driver';

export async function sendTemplatedEmail(params: SendEmailReq): Promise<SendEmailRes> {
	// Accounts can only send templated emails for lead delivery or for receipts

	const mutation = `
        mutation SendTemplatedEmail($params: SendEmailReq!) {
            sendTemplatedEmail(params: $params) {
                status
                error
            }
        }
    `;
	const result = await $GQL.request(mutation, { params });
	return result.sendTemplatedEmail;
}
