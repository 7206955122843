import { $GQL } from '@GQL/index';

export async function checkExistingAgentLicenseNumber(agent_license_number: string): Promise<Boolean> {
	const query = `
        query checkExistingAgentLicenseNumber($agent_license_number: String! ) {
            agentLicenseNumberExists(agent_license_number: $agent_license_number)
        }
    `;

	const result = await $GQL.request(query, { agent_license_number });
	return result.agentLicenseNumberExists;
}
