<template>
	<div>
		<p>
			<a class="font-normal" style="font-size: var(--front-size-sm)" @click.prevent="show_form = true"
				>Add New Setting</a
			>
		</p>
		<div class="card padded" v-if="show_form">
			<p>Add Setting for {{ source_code }}</p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Daypart:</label>
					<div class="controls">
						<div class="field m">
							<p-dropdown
								v-model="setting.daypart"
								:options="daypart_options"
								option-label="label"
								option-value="value"
								placeholder="Day Part"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Type:</label>
					<div class="controls">
						<div class="field m">
							<p-dropdown
								v-model="setting.type"
								:options="type_options"
								option-label="label"
								option-value="value"
								placeholder="Type"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Run Day:</label>
					<div class="controls">
						<div class="field m">
							<p-dropdown
								v-model="setting.run_day"
								:options="run_day_options"
								option-label="label"
								option-value="value"
								placeholder="Run Day"
							/>
						</div>
					</div>
				</div>
			</div>
			<row>
				<column>
					<p><strong>Historical Period</strong></p>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Start:</label>
							<div class="controls">
								<div class="field m">
									<p-input-number
										v-model="setting.historical_period.start"
										showButtons
										suffix=" day(s)"
										:min="-21"
										:max="-1"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">End:</label>
							<div class="controls">
								<div class="field m">
									<p-input-number
										v-model="setting.historical_period.end"
										showButtons
										suffix=" day(s)"
										:min="-21"
										:max="-1"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Weight:</label>
							<div class="controls">
								<div class="field s">
									<p-input-percentage v-model="setting.historical_period.weight" />
								</div>
							</div>
						</div>
					</div>
				</column>
				<gutter size="20px" />
				<column>
					<p><strong>Recent Period</strong></p>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Start:</label>
							<div class="controls">
								<div class="field m">
									<p-input-number
										v-model="setting.recent_period.start"
										showButtons
										suffix=" day(s)"
										:min="-7"
										:max="0"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">End:</label>
							<div class="controls">
								<div class="field m">
									<p-input-number v-model="setting.recent_period.end" showButtons suffix=" day(s)" :min="-7" :max="0" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Weight:</label>
							<div class="controls">
								<div class="field s">
									<p-input-percentage v-model="setting.historical_period.weight" :min="0" :max="1" />
								</div>
							</div>
						</div>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<row>
				<column>
					<p><strong>Target Performance</strong></p>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">ROI Min:</label>
							<div class="controls">
								<div class="field s">
									<p-input-percentage v-model="setting.target_performance.roi_min" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">ROI Max:</label>
							<div class="controls">
								<div class="field s">
									<p-input-percentage v-model="setting.target_performance.roi_max" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Avg Pos Min:</label>
							<div class="controls">
								<div class="field s">
									<p-input-number v-model="setting.target_performance.avg_pos_min" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Avg Pos Max:</label>
							<div class="controls">
								<div class="field s">
									<p-input-number v-model="setting.target_performance.avg_pos_max" />
								</div>
							</div>
						</div>
					</div>
				</column>
				<gutter size="20px" />
				<column>
					<p><strong>Bid Settings</strong></p>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Standard Bid Change:</label>
							<div class="controls">
								<div class="field s">
									<p-input-percentage v-model="setting.bid_settings.standard_bid_change" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Max Bid Change</label>
							<div class="controls">
								<div class="field s">
									<p-input-percentage v-model="setting.bid_settings.max_mid_change" />
								</div>
							</div>
						</div>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<div class="flex justify-content-between">
				<p-button severity="secondary" label="Cancel" text @click.prevent="show_form = false" />
				<p-button label="Save" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
const daypart_options = [
	{ label: 'Weekday', value: 'weekday' },
	{ label: 'Weekend', value: 'weekend' },
	{ label: 'Weeknight', value: 'weeknight' },
];

const type_options = [
	{ label: 'Channels', value: 'channels' },
	{ label: 'Ad Groups', value: 'ad_groups' },
];

const run_day_options = [
	{ label: 'Monday', value: 'monday' },
	{ label: 'Tuesday', value: 'tuesday' },
	{ label: 'Wednesday', value: 'wednesday' },
	{ label: 'Thursday', value: 'thursday' },
	{ label: 'Friday', value: 'friday' },
	{ label: 'Saturday', value: 'saturday' },
	{ label: 'Sunday', value: 'sunday' },
];

export default {
	name: 'AutomaticOptimizationSettingForm',
	props: {
		source_code: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			daypart_options,
			type_options,
			run_day_options,
			show_form: false,
			setting: {
				daypart: 'weekday',
				type: 'channels',
				run_day: 'monday',
				historical_period: {
					start: '',
					end: '',
					weight: 0.4,
				},
				recent_period: {
					start: '',
					end: '',
					weight: 0.6,
				},
				target_performance: {
					roi_min: 0.5,
					roi_max: 0.8,
					avg_pos_min: 1.5,
					avg_pos_max: 2.3,
				},
				bid_settings: {
					standard_bid_change: 0.15,
					max_mid_change: 0.2,
				},
			},
		};
	},
};
</script>

<style scoped lang="less">
:deep(.p-inputnumber-button) {
	background-color: var(--color-b-light);
	border-color: var(--color-b-light);
	&:hover {
		background-color: var(--color-b-dark);
		border-color: var(--color-b-dark);
	}
}
</style>
