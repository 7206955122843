import { $GQL } from '@GQL/index';

export async function getMAutCampaignAnalyzer(
	params: {
		start_date: string;
		end_date: string;
		filters: {
			search_term?: string;
			vertical_id?: string;
			business_unit?: string;
			type?: string;
		};
		group_by: string[]; // selectable
	},
	refresh?: boolean
) {
	const query = `
        query GetMAutCampaignAnalyzer($params: JSONObject!) {
            getMAutCampaignAnalyzer(params: $params) {
				rows
				metrics
			}
        }
    `;

	const no_cache = refresh ? ['getMAutCampaignAnalyzer'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.getMAutCampaignAnalyzer;
}
