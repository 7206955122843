import { $GQL } from '@GQL/index';
import { type QueryParams } from '@GQL/queryParams';
import { type Purchase } from '@nextgenleads/broker-driver';

export async function getPurchaseHistory(params: QueryParams): Promise<Purchase[]> {
	const query = `
        query GetPurchasesList($params: GetListParams) {
            purchaseList(params: $params) {
				rows {
					id
					account {
						id
						name
					}
					campaign {
						id
						name
						duration
						flags {
							bulk_returns {
								enabled
							}
						}
					}
					vertical_id
					auction_log_id
					call_duration
					call_log_id
					mpid
					product
					bid_type
					tags
					price
					original_price
					transaction_id
					status
					status_message
					status_changed_by
					created_at
					returned_at
					return_processed_at
					return_reason
					return_notes
					ignore
					modified_at
				}
            }
        }
    `;
	const result = await $GQL.request(query, { params });
	return result.purchaseList;
}
