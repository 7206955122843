<template>
	<div id="universal-search">
		<!-- Toolbar Search Input -->
		<div v-if="!mq.phone" class="search-input">
			<div class="p-input-icon-left">
				<div class="icon-wrapper">
					<icon type="magnify" size="24px" />
				</div>
				<p-input-text
					v-model="search_query"
					class="rounded p-inputtext-sm"
					placeholder="Search Everything [CTRL+F]"
					@keyup.enter="handleSearchModal"
					@focus="outer_search_focus = true"
					@blur="outer_search_focus = false"
				/>
				<div v-if="search_query && outer_search_focus" class="return-icon-right">
					<icon type="keyboard-return" size="16px" />
				</div>
			</div>
		</div>
		<div v-else class="search-btn">
			<a @click.prevent="handleSearchModal">
				<alt-icon type="search" size="24px" />
			</a>
		</div>

		<!-- Search Modal -->
		<p-dialog
			id="search-modal"
			v-model:visible="search_modal_open"
			:modal="true"
			:dismissable-mask="true"
			@after-hide="resetSearch"
		>
			<template #header>
				<div class="search-form">
					<div class="search-header">
						<div class="top-row flex align-items-center">
							<div class="search-input">
								<div class="p-input-icon-left">
									<div class="icon-wrapper">
										<icon type="magnify" size="24px" />
									</div>
									<p-input-text
										v-model="search_query"
										class="rounded p-inputtext-sm"
										placeholder="Enter Search Term"
										autofocus
										@keyup.enter="handleInnerSearch($event)"
										@focus="inner_search_focus = true"
										@blur="inner_search_focus = false"
										@keyup.down="updateSelected($event)"
										@keyup.up="updateSelected($event)"
									/>
									<div v-if="search_query && inner_search_focus && !has_selection" class="return-icon-right">
										<icon type="keyboard-return" size="16px" aria-describedby=": false,return-key-icon" />
									</div>
								</div>
							</div>
							<div v-if="!mq.phone" class="clear-search">
								<p-button size="small" text @click="resetSearch(true)">Clear</p-button>
							</div>
						</div>
						<div class="bottom-row flex align-items-center gap-20">
							<div class="control-group">
								<div class="inner">
									<div class="controls gap-20">
										<div class="field fit">
											<div class="flex align-items-center">
												<p-checkbox input-id="search_leads" v-model="search_leads" binary />
												<label for="search_leads" style="margin-top: 3px">
													Search Leads <span class="dim">(ctrl+enter)</span>
												</label>
											</div>
										</div>
										<div class="field label"></div>
										<div v-if="search_leads" class="field">
											<div class="lead-date-picker">
												<template v-if="mq.phone">
													<p-button icon="pi pi-calendar" rounded v-tooltip.top="dateRange" @click="toggleDatePicker" />
												</template>
												<template v-else>
													<date-range-picker v-model="date_range" />
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<!-- Search Results -->
			<div v-if="has_searched" class="results">
				<row gap="80px">
					<column>
						<h2>Account Results</h2>
						<div v-if="!searching_accounts && account_results.length === 0" class="no-results">No accounts found</div>
						<div class="search-results-column account-results">
							<div v-for="account in account_results" class="account-result" :key="account.account_id">
								<div class="account-name ellipsis">
									<icon
										v-if="account.is_parent"
										type="account-child-circle"
										size="24px"
										v-tooltip.top="'Parent Account'"
									/>
									{{ account.account_name }}
								</div>
								<div class="account-id flex">
									<strong>{{ account.account_id }}</strong>
									<icon
										v-if="account.parent_account_id"
										type="account-supervisor"
										size="18px"
										v-tooltip.top="`Child of ${account.parent_account_id}`"
										style="margin-left: 5px"
									/>
								</div>
								<div class="account-owner">
									<icon type="account-supervisor-circle" size="18px" /> {{ account.account_owner }}
								</div>
								<div class="primary-user">
									<div class="full-name ellipsis">{{ account.primary_user }}</div>
									<div class="email ellipsis"><icon type="email" size="18px" /> {{ account.email }}</div>
									<div class="phone"><icon type="phone" size="18px" /> {{ phoneFormat(account.phone) }}</div>
								</div>
								<div class="edit-link">
									<template v-if="account.is_parent">
										<router-link
											:to="{
												name: 'ParentAccountsDetail:AccountsDashboard',
												params: { parent_account_id: account.account_id, mpid: account.mpid },
											}"
											@click="handleLinkClick"
											>View Details <icon type="open-in-new" size="16px"
										/></router-link>
									</template>
									<template v-else>
										<router-link
											:to="{
												name: 'AccountsDetail:AccountActivity',
												params: { account_id: account.account_id, mpid: account.mpid },
											}"
											@click="handleLinkClick"
											>View Details <icon type="open-in-new" size="16px"
										/></router-link>
									</template>
								</div>
							</div>
							<div>
								<a
									href="javascript:void(0)"
									v-if="account_results.length && !accounts_end"
									class="more-results"
									@click="loadMoreResults('accounts')"
								>
									Load More Results <icon type="magnify-plus-outline" size="24px" />
								</a>
							</div>
						</div>
						<div v-if="searching_accounts" class="searching-spinner">
							<p-progress-spinner style="height: 30px; width: 30px" /> Searching accounts...
						</div>
					</column>
					<column>
						<h2>User Results</h2>
						<div v-if="!searching_users && user_results.length === 0" class="no-results">No users found</div>
						<div class="search-results-column user-results">
							<div v-for="user in user_results" class="user-result">
								<div class="full-name ellipsis">
									<icon type="account-circle" size="24px" />
									{{ user.full_name }}
								</div>
								<div class="account-id ellipsis">
									<template v-if="user.account_id"
										><strong>{{ user.account_name }}</strong> ({{ user.account_id }})
									</template>
									<template v-else> <strong>Admin User</strong> </template>
								</div>
								<div class="contact-details">
									<div class="email ellipsis"><icon type="email" size="18px" /> {{ user.email }}</div>
									<div class="phone"><icon type="phone" size="18px" /> {{ phoneFormat(user.phone) }}</div>
								</div>
								<div class="edit-link">
									<template v-if="!user.account_id">
										<router-link
											:to="{
												name: 'EditAdminUser',
												params: { user_id: user.user_id },
											}"
											@click="handleLinkClick"
											>Edit User <icon type="open-in-new" size="16px"
										/></router-link>
									</template>
									<template v-else-if="user.is_parent_user">
										<router-link
											:to="{
												name: 'ParentAccountsDetail:EditAccountUser',
												params: { user_id: user.user_id, parent_account_id: user.account_id, mpid: user.mpid },
											}"
											@click="handleLinkClick"
											>Edit User <icon type="open-in-new" size="16px"
										/></router-link>
									</template>
									<template v-else>
										<router-link
											:to="{
												name: 'AccountsDetail:EditAccountUser',
												params: { user_id: user.user_id, account_id: user.account_id, mpid: user.mpid },
											}"
											@click="handleLinkClick"
											>Edit User <icon type="open-in-new" size="16px"
										/></router-link>
									</template>
								</div>
							</div>
							<div>
								<a
									href="javascript:void(0)"
									v-if="user_results.length && !users_end"
									class="more-results"
									@click="loadMoreResults('users')"
								>
									Load More Results <icon type="magnify-plus-outline" size="24px" />
								</a>
							</div>
						</div>
						<div v-if="searching_users" class="searching-spinner">
							<p-progress-spinner style="height: 30px; width: 30px" /> Searching users...
						</div>
					</column>
					<template v-if="search_leads">
						<column>
							<h2>Lead results</h2>
							<div v-if="!searching_leads && lead_results.length === 0" class="no-results">No leads found</div>
							<div class="search-results-column lead-results">
								<div v-for="lead in lead_results" :class="['lead-result', { dnc: lead.dnc }]" :key="lead.lead_id">
									<div class="flex align-items-start">
										<div class="icon">
											<vertical-icon :vertical-id="lead.vertical_id" hide-tooltip size="30px" />
										</div>
										<div class="details">
											<div class="full-name ellipsis">
												{{ lead.full_name }}
												<div v-if="lead.dnc" class="dnc-tag">DNC</div>
											</div>
											<div class="lead-id">{{ lead.lead_id }}</div>
											<div class="source ellipsis"><strong>Source:</strong> {{ lead.source_id }}</div>
											<div class="phone"><icon type="phone" size="18px" /> {{ phoneFormat(lead.phone) }}</div>
											<div class="email ellipsis"><icon type="email" size="18px" /> {{ lead.email }}</div>
											<div class="created-at">{{ formatDate(lead.created_at, 'ddd, MMM D, YYYY [at] h:mmA') }}</div>
											<div class="edit-link">
												<router-link
													:to="{
														name: 'LeadDetail',
														params: { lead_id: lead.lead_id },
													}"
													@click="handleLinkClick"
													>View Details <icon type="open-in-new" size="16px"
												/></router-link>
											</div>
										</div>
										<div class="purchases">
											<div v-for="purchase in lead.purchases" class="purchase" :key="purchase.account_id">
												<div class="account-name ellipsis">{{ purchase.account_name }}</div>
												<div class="account-id">{{ purchase.account_id }}</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<a
										href="javascript:void(0)"
										v-if="lead_results.length && !leads_end"
										class="more-results"
										@click="loadMoreResults('leads')"
									>
										Load More Results <icon type="magnify-plus-outline" size="24px" />
									</a>
								</div>
							</div>
							<div v-if="searching_leads" class="searching-spinner">
								<p-progress-spinner style="height: 30px; width: 30px" /> Searching leads...
							</div>
						</column>
					</template>
				</row>
			</div>
		</p-dialog>
		<p-overlay-panel ref="date_picker" style="min-width: 400px; transform: translate(5px, 5px)">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Lead Date Range:</label>
					<div class="controls">
						<div class="field">
							<date-range-picker v-model="date_range" />
						</div>
					</div>
				</div>
			</div>
		</p-overlay-panel>
	</div>
</template>

<script lang="ts">
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import pTag from 'primevue/tag';
import pOverlayPanel from 'primevue/overlaypanel';
import pProgressSpinner from 'primevue/progressspinner';
import { formatDate, phoneFormat, sourceHash, timeAgo } from '@/lib/Filters';
import { searchAccounts, searchLeads, searchUsers } from '@GQL';
import { useMagicKeys } from '@vueuse/core';
import dayjs from 'dayjs';

export default {
	name: 'UniversalSearch',
	components: {
		dateRangePicker,
		pOverlayPanel,
		pProgressSpinner,
		pTag,
		verticalIcon,
	},
	inject: ['mq'],
	setup() {
		const keys = useMagicKeys();
		const ctrl_f = keys['Ctrl+F'];
		return { ctrl_f };
	},
	data() {
		return {
			date_range: [dayjs().startOf('day').subtract(30, 'days').toDate(), dayjs().endOf('day').toDate()],
			has_selection: false,
			has_searched: false,
			categoryIndex: -1,
			resultIndex: -1,
			outer_search_focus: false,
			inner_search_focus: false,
			search_modal_open: false,
			search_query: '',
			previous_search_query: '',
			searching_accounts: false,
			account_results: [],
			account_page: 0,
			accounts_end: false,
			searching_users: false,
			user_results: [],
			user_page: 0,
			users_end: false,
			search_leads: false,
			searching_leads: false,
			lead_results: [],
			lead_page: 0,
			leads_end: false,
			searching_campaigns: false,
			campaign_results: [],
			campaign_page: 0,
			campaigns_end: false,
			searching_navigation: false,
			navigation_results: [],
			searching_settings: false,
			setting_results: [],
		};
	},
	computed: {
		dateRange() {
			return `${dayjs(this.date_range[0]).format('MMM D, YYYY')} - ${dayjs(this.date_range[1]).format('MMM D, YYYY')}`;
		},
		hasResults() {
			return (
				this.account_results.length ||
				this.user_results.length ||
				this.lead_results.length ||
				this.campaign_results.length
			);
		},
	},
	methods: {
		formatDate,
		phoneFormat,
		sourceHash,
		timeAgo,
		expandShown(array_name) {
			this.search_results[array_name].shown_count = this.search_results[array_name].results.length;
		},
		linkHelper(result) {
			if (this.$root.sessionStore.isAdminUser && result.mpid) {
				// links need to have mpid set
				return `/marketplaces/manage/${result.mpid}${result.link}`;
			} else {
				return result.link;
			}
		},
		toggleDatePicker(event) {
			this.$refs.date_picker.toggle(event);
		},
		newSearch(event) {
			if (this.previous_search_query !== this.search_query) {
				this.resetSearch();
				this.previous_search_query = this.search_query;
			}

			// Always search accounts
			this.searching_accounts = true;
			searchAccounts(this.search_query, 0)
				.then((account_results) => {
					this.account_results = account_results;
					if (account_results.length < 10) {
						this.accounts_end = true;
					}
				})
				.catch((err) => {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to search accounts',
						life: 5000,
					});
				})
				.finally(() => {
					this.searching_accounts = false;
				});

			// Always search users
			this.searching_users = true;
			searchUsers(this.search_query, 0)
				.then((user_results) => {
					this.user_results = user_results;
					if (user_results.length < 10) {
						this.users_end = true;
					}
				})
				.catch((err) => {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to search users',
						life: 5000,
					});
				})
				.finally(() => {
					this.searching_users = false;
				});

			// Search leads
			if (event.ctrlKey || this.search_leads) {
				this.search_leads = true;
				this.searching_leads = true;
				searchLeads(this.search_query, this.date_range, 0)
					.then((lead_results) => {
						this.lead_results = lead_results;
						if (lead_results.length < 10) {
							this.leads_end = true;
						}
					})
					.catch((err) => {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to search leads',
							life: 5000,
						});
					})
					.finally(() => {
						this.searching_leads = false;
					});
			}

			this.has_searched = true;
		},
		loadMoreResults(collection) {
			switch (collection) {
				case 'accounts':
					this.account_page = this.account_page + 1;
					searchAccounts(this.search_query, this.account_page * 10)
						.then((account_results) => {
							this.account_results.push(...account_results);
							if (account_results.length < 10) {
								this.accounts_end = true;
							}
						})
						.catch((err) => {
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to search accounts',
								life: 5000,
							});
						});
					break;
				case 'leads':
					this.lead_page = this.lead_page + 1;
					searchLeads(this.search_query, this.date_range, this.lead_page * 10)
						.then((lead_results) => {
							this.lead_results.push(...lead_results);
							if (lead_results.length < 10) {
								this.leads_end = true;
							}
						})
						.catch((err) => {
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to search leads',
								life: 5000,
							});
						});
					break;
				case 'users':
					this.user_page = this.user_page + 1;
					searchUsers(this.search_query, this.user_page * 10)
						.then((user_results) => {
							this.user_results.push(...user_results);
							if (user_results.length < 10) {
								this.users_end = true;
							}
						})
						.catch((err) => {
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to search users',
								life: 5000,
							});
						});
					break;
			}
		},
		handleSearchModal(event) {
			this.search_modal_open = !this.search_modal_open;
			// If opening, do a search, otherwise close modal
			if (this.search_modal_open) {
				this.handleInnerSearch(event);
			}
		},
		async handleInnerSearch(event) {
			if (this.search_query !== '') {
				await this.newSearch(event);
			}
		},
		handleLinkClick(event) {
			// If not holding down the cmd key when clicked, close the search modal
			if (!event.metaKey) {
				this.search_modal_open = false;
			}
		},
		updateSelected() {
			this.has_selection = true;
		},
		resetSearch(clear_query) {
			if (clear_query) {
				this.search_query = '';
			}

			this.account_results = [];
			this.account_page = 0;
			this.accounts_end = false;
			this.user_results = [];
			this.user_page = 0;
			this.users_end = false;
			this.lead_results = [];
			this.lead_page = 0;
			this.leads_end = false;
			this.campaign_results = [];
			this.campaign_page = 0;
			this.campaigns_end = false;
			this.search_leads = false;
			this.has_searched = false;
		},
	},
	watch: {
		ctrl_f(new_val) {
			if (new_val) {
				this.handleSearchModal();
			}
		},
		search_leads(new_val, old_val) {
			if (new_val !== old_val) {
				this.searching_leads = true;
				searchLeads(this.search_query, this.date_range, 0)
					.then((lead_results) => {
						this.lead_results = lead_results;
						if (lead_results.length < 10) {
							this.leads_end = true;
						}
					})
					.catch((err) => {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to search leads',
							life: 5000,
						});
					})
					.finally(() => {
						this.searching_leads = false;
					});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.search-input {
	position: relative;
	min-width: 300px;

	.icon-wrapper {
		position: absolute;
	}

	.return-icon-right {
		background: var(--gray-15);
		border-radius: 4px;
		font-size: var(--font-size-sm);
		padding: 0.25rem;
		position: absolute;
		right: 0.75rem;
		top: 50%;
		transform: translateY(-50%);
	}

	input {
		padding: 6px 10px 8px 40px;
		width: 100%;
	}
}

.search-btn {
	a {
		color: var(--black);
		cursor: pointer;
		font-weight: bold;
		padding: 0.25rem;
	}
}
</style>

<style lang="less">
.marketplace #universal-search input::placeholder {
	color: white;
	opacity: 0.5;
}

#search-modal.p-dialog {
	color: red;
	border: 0;
	box-shadow: 0;
	max-width: 95vw;
	padding: 0;
	width: auto;

	h2 {
		color: var(--color-a);
		font-size: 0.875rem;
		margin-bottom: 0.5em;
		text-transform: uppercase;
	}

	.searching-spinner {
		align-items: center;
		color: var(--gray-35);
		display: flex;
		font-size: 0.875rem;
		gap: 10px;
		margin-bottom: 2rem;
	}

	.no-results {
		color: var(--gray-35);
		font-size: 0.875rem;
		margin-top: 0.5rem;
	}

	.search-header {
		font-size: 1.125rem;
		font-weight: bold;

		.top-row {
			margin-bottom: 1em;
		}

		.lead-date-picker {
			gap: 0;
			min-width: 320px;
		}
	}

	.p-dialog-header {
		align-items: flex-start;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.clear-search {
		margin: 0 0.5rem;
	}

	.search-input,
	.p-input-icon-left,
	.p-inputtext {
		width: 100%;
	}

	.p-dialog-content {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		line-height: 1.5em;
		overflow-y: auto;
		padding: 1.5rem;

		.search-results-column {
			min-width: 300px;
		}

		.account-result,
		.user-result {
			border-radius: 5px;
			font-size: 0.75rem;
			line-height: 1.65em;
			margin: 0 -10px 10px;
			min-width: 300px;
			max-width: 450px;
			padding: 10px;

			&:hover {
				background-color: var(--gray-05);
			}

			> .account-name,
			> .full-name {
				align-items: center;
				display: flex;
				font-size: 1.125rem;
				font-weight: bold;
				gap: 5px;
			}

			.account-id {
				color: var(--gray-45);
				font-size: 0.875rem;
			}

			.primary-user {
				border-left: 2px solid var(--gray-15);
				color: var(--gray-40);
				margin-top: 0.5rem;
				padding-left: 0.5rem;

				.full-name {
					font-weight: bold;
				}
			}

			.contact-details {
				color: var(--gray-40);
			}

			.phone,
			.email,
			.account-owner {
				align-items: center;
				display: flex;
				gap: 5px;
			}

			.edit-link {
				margin-top: 0.5rem;
			}
		}

		.lead-result {
			border-radius: 5px;
			color: var(--gray-40);
			font-size: 0.75rem;
			line-height: 1.65em;
			margin: 0 -10px 10px;
			padding: 10px;

			&:hover {
				background-color: var(--gray-05);
			}

			.details {
				margin-left: 1rem;
				min-width: 200px;
				max-width: 350px;
			}

			.full-name {
				color: var(--gray-80);
				font-size: 1.125rem;
				font-weight: bold;
			}

			&.dnc .full-name {
				color: var(--red);
			}

			.dnc-tag {
				background-color: var(--red);
				border-radius: 3px;
				color: white;
				display: inline-block;
				font-size: 0.5rem;
				line-height: 1;
				padding: 3px;
				vertical-align: middle;
			}

			.lead-id {
				font-weight: bold;
			}

			.purchases {
				margin: 35px 0 0 50px;
				max-width: 350px;
				min-width: 200px;

				.purchase {
					display: list-item;
					margin-bottom: 1rem;
					margin-left: 1rem;

					.account-name {
						font-size: 0.875rem;
						font-weight: bold;
					}

					.account-id {
						color: var(--gray-30);
						font-size: 0.75rem;
					}
				}
			}
		}

		.more-results {
			color: var(--color-b);
			font-size: 0.875rem;
			font-weight: bold;
		}
	}
}
</style>
