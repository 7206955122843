// --------------------------------------------------
// FILTER: colorCell
// Returns a color based on the value of a cell in a table
// highest range is green, lowest range is red
// --------------------------------------------------

export function colorCell(value: number, min: number, max: number, inverse?: boolean): string {
	const range = max - min;
	const values = [
		'color-cell bg-red-400 text-black-alpha-90',
		'color-cell bg-red-300 text-black-alpha-90',
		'color-cell bg-red-200 text-black-alpha-80',
		'color-cell bg-red-100 text-black-alpha-80',
		'color-cell text-black-alpha-80',
		'color-cell bg-green-100 text-black-alpha-80',
		'color-cell bg-green-200 text-black-alpha-80',
		'color-cell bg-green-300 text-black-alpha-90',
		'color-cell bg-green-400 text-black-alpha-90',
	];

	if (inverse) {
		values.reverse();
	}

	const index = Math.round((value - min) / Math.floor(range / (values.length - 1)));
	return values[index];
}
