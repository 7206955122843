import { $GQL } from '@GQL/index';

export async function getSimilarAccounts(phone: string, no_cache?: string[]) {
	const query = `
        query AccountsSimilar($phone: String) {
            accountsSimilar(phone: $phone) {
                id
                name
			    account_manager_id
                created_at
              }
        }
    `;

	const result = await $GQL.request(query, {
		phone,
		no_cache,
	});

	return result.accountsSimilar;
}
