<template>
	<div class="attribute-section filter" :class="{ active: medicare_enrollment }">
		<div class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field fit">
						<p-checkbox v-model="medicare_enrollment" input-id="medicare_enrollment" binary />
					</div>
					<gutter size="10px" />
					<div class="field">
						<label class="large-label" for="medicare_enrollment">
							<div>Medicare Enrollment</div>
							<div class="sub-value">
								Exclude leads that have indicated they
								<strong style="color: var(--red)">ARE NOT</strong>
								enrolled in Parts A & B
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MedicareEnrollmentAttribute',
	emits: ['update'],
	props: {
		mode: {
			type: String,
			default: 'basic',
		},
	},
	data() {
		return {
			medicare_enrollment: false,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
					invert_basic: false,
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const enrollment_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.has_medicare_parts_a_b';
			});
			if (enrollment_match) {
				this.medicare_enrollment = true;
			}
		},
		saveAttribute() {
			// This is a bit confusing, but we are matching to false because we want to target those who are not enrolled
			if (this.medicare_enrollment) {
				return {
					target: {
						path: 'lead.data.has_medicare_parts_a_b',
					},
					strategy: 'equals',
					comparator: {
						value: false,
					},
				};
			}
			return null;
		},
	},
};
</script>
