<template>
	<div class="qualifier premium-listing">
		<div class="title">
			<div class="name">Premium Listing</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Use different criteria to determine if premium listing campaigns shoud be allowed to compete in this auction.
		</div>
		<div class="step-settings">
			<div class="control-group">
				<div class="inner">
					<div class="field-row tight">
						<div class="field flex align-items-center">
							<p-radio-button input-id="invert" v-model="criteria" value="default" />
							<label class="inline" for="invert"> Use default logic to determine PL campaign qualification </label>
						</div>
					</div>
					<div class="field-row tight">
						<div class="field flex align-items-center">
							<p-radio-button input-id="invert" v-model="criteria" value="explicit_allow" />
							<label class="inline" for="invert">
								Only allow premium listing campaigns to compete in this auction
							</label>
						</div>
					</div>
					<div class="field-row tight">
						<div class="field flex align-items-center">
							<p-radio-button input-id="invert" v-model="criteria" value="explicit_reject" />
							<label class="inline" for="invert"> Disqualify all premium listing campaigns from this auction </label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'PremiumListingQualifier',
	emits: ['remove', 'update:modelValue'],
	computed: {
		criteria: {
			get() {
				if (this.modelValue.explicit) {
					if (this.modelValue.invert) {
						return 'explicit_allow';
					}
					return 'explicit_reject';
				}
				return 'default';
			},
			set(new_value) {
				if (new_value === 'explicit_allow') {
					this.$emit('update:modelValue', {
						explicit: true,
						invert: true,
					});
				} else if (new_value === 'explicit_reject') {
					this.$emit('update:modelValue', {
						explicit: true,
						invert: false,
					});
				} else {
					this.$emit('update:modelValue', {
						explicit: false,
						invert: false,
					});
				}
			},
		},
	},
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					explicit: false,
					invert: false,
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>

<style lang="less" scoped>
label.inline {
	line-height: 1.25em;
	margin-left: 10px;

	.sub-value {
		font-weight: normal;
	}
}
</style>
