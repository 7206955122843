<template>
	<div id="vertical-performance-wrapper">
		<row>
			<div class="page-heading">
				<h1>Vertical Performance</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
		</row>
		<row class="tab-row">
			<p-tab-menu v-model:activeIndex="active_tab" :model="tabs">
				<template #item="{ label, item }">
					<template v-if="item.route">
						<div v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)" class="account-tab">
							<router-link :to="`${item.route}`">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
							</router-link>
						</div>
					</template>
					<template v-else>
						<div
							v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)"
							class="account-tab"
							@click.stop
						>
							<div class="tab-submenu-wrapper" @click="toggleSubMenu($event, item.ref)">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
								<div class="tab-submenu-toggle">
									<alt-icon type="expand_more" />
								</div>
							</div>
							<p-menu :ref="item.ref" class="account-submenu" :model="item.items" :popup="true">
								<template #item="{ item }">
									<template v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)">
										<router-link :to="`${item.route}`">
											<div class="tab-label">
												{{ item.label }}
											</div>
										</router-link>
									</template>
								</template>
							</p-menu>
						</div>
					</template>
				</template>
			</p-tab-menu>
		</row>
		<row class="maut-content">
			<div class="top-fade" />
			<router-view>
				<template #default="{ Component, route }">
					<transition name="drop-fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</template>
			</router-view>
		</row>
	</div>
</template>

<script lang="ts">
import pTabMenu from 'primevue/tabmenu';
import pMenu from 'primevue/menu';

export default {
	name: 'VerticalPerformance',
	components: {
		pMenu,
		pTabMenu,
	},
	data() {
		return {
			active_tab: 0,
			active: 0,
			tabs: [
				{
					label: 'Biz Units',
					icon: 'cases',
					ref: 'bizunits',
					items: [
						{
							label: 'Health',
							icon: 'health_and_safety',
							route: '/maut/vertical-performance/biz-units/health',
							ref: 'buhealth',
						},
						{
							label: 'Medicare',
							icon: 'medical_services',
							route: '/maut/vertical-performance/biz-units/medicare',
							ref: 'bumedicare',
						},
						{
							label: 'Auto',
							icon: 'directions_car',
							route: '/maut/vertical-performance/biz-units/auto',
							ref: 'buauto',
						},
					],
				},
				{
					label: 'Owned & Operated',
					icon: 'home_work',
					ref: 'owned-operated',
					items: [
						{
							label: 'Health',
							icon: 'health_and_safety',
							route: '/maut/vertical-performance/OO/health',
							ref: 'oohealth',
						},
						{
							label: 'Medicare',
							icon: 'medical_services',
							route: '/maut/vertical-performance/OO/medicare',
							ref: 'oomedicare',
						},
						{
							label: 'Auto',
							icon: 'directions_car',
							route: '/maut/vertical-performance/OO/auto',
							ref: 'ooauto',
						},
					],
				},
			],
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Vertical Performance' }],
		};
	},
	beforeMount() {
		const paths = this.$router.currentRoute.value.fullPath.split('/').filter((p) => {
			if (p) return p;
		});

		if (paths.length === 2) {
			this.$router.push('/maut/vertical-performance/biz-units/health');
		}
	},
	methods: {
		toggleSubMenu(event, sub_menu_ref) {
			this.$refs[sub_menu_ref].toggle(event);
		},
		transitionType(route) {
			return route.matched[route.matched.length - 1].meta.transition || 'drop-fade';
		},
	},
};
</script>

<style scoped lang="less">
#vertical-performance-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;

	.row {
		flex: 0 0 auto;
	}

	.maut-content {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		overflow-y: hidden;

		.view-content {
			min-height: auto;
		}
	}

	.top-fade {
		background: linear-gradient(
			180deg,
			rgb(255 255 255 / 100%) 0%,
			rgb(255 255 255 / 100%) 30%,
			rgb(255 255 255 / 0%) 100%
		);
		height: 40px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
}

.page-heading {
	margin-bottom: -20px;
	padding: 40px;
}

.maut-content {
	background: linear-gradient(
		180deg,
		rgb(255 255 255 / 100%) 0%,
		rgb(255 255 255 / 0%) 15%,
		rgb(255 255 255 / 0%) 100%
	);

	:deep(h1) {
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}

	:deep(.page-heading) {
		margin-bottom: 1em;
	}

	:deep(.p-breadcrumb) {
		display: none;
	}
}

.account-submenu {
	width: 240px;

	a {
		align-items: center;
		color: var(--gray-50);
		display: flex;
		height: 40px;
		line-height: 40px;
		padding: 0 1.5em;

		&:hover {
			text-decoration: none;
		}
	}
}
</style>
