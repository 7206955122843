import { $GQL } from '@GQL/index';

export async function getConversionAPIKeys(account_id: string) {
	const query = `
        query GetConversionAPIKeys($account_id: ID!) {
            conversionApiKeys(account_id: $account_id){
                id
                name
                key
                mpid
                owner_id
                role_id
                status
            }
        }
    `;

	return (await $GQL.request(query, { account_id })).conversionApiKeys;
}
