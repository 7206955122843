<template>
	<div class="build-step check-duplicates">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Check Fraud</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Enabled varying checks to test whether an incoming lead has the potential to be fraudulent.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<p-fieldset legend="Resubmission Check">
				<p>
					Test whether a lead with the same phone number has been submitted multiple times within a set number of days.
					If so, either reject the lead or mark it as DNC.
				</p>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field fit">
								<p-input-switch input-id="resubmission_check" v-model="modelValue.resubmission_check.enabled" />
							</div>
							<div class="field">
								<label for="resubmission_check">Enable Resubmission Check</label>
							</div>
						</div>
					</div>
				</div>
				<template v-if="modelValue.resubmission_check.enabled">
					<div class="control-group">
						<div class="inner">
							<div class="controls gap-20">
								<div class="field">
									<label class="control-label">Action:</label>
									<p-dropdown
										v-model="modelValue.resubmission_check.action"
										:options="action_options"
										option-label="label"
										option-value="value"
									/>
								</div>
								<div class="field xs">
									<label class="control-label">Lookback:</label>
									<div class="p-inputgroup">
										<p-input-number v-model="modelValue.resubmission_check.lookback" class="input-align-right" />
										<div class="p-inputgroup-addon">days</div>
									</div>
								</div>
								<div class="field xs">
									<label class="control-label">Max Count:</label>
									<p-input-number v-model="modelValue.resubmission_check.count" class="input-align-right" />
								</div>
							</div>
						</div>
					</div>
				</template>
			</p-fieldset>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';
export default {
	name: 'CheckFraud',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					resubmission_check: {
						enabled: false,
						action: 'flag',
						lookback: 14,
						count: 5,
					},
				};
			},
		},
	},
	data() {
		return {
			action_options: [
				{ label: 'Flag', value: 'flag' },
				{ label: 'Reject', value: 'reject' },
			],
		};
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
