<template>
	<div id="account-form" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Migrate From V3</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card v-if="step === 1" class="account-form-content">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">V3 Company ID:</label>
						<div class="controls">
							<div class="field">
								<div class="field">
									<p-input-text id="company_id" v-model="company_id" placeholder="ID from V3" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<label class="control-label">Parent Account ID:</label>
						<div class="controls">
							<div class="field">
								<div class="field">
									<p-input-text
										id="company_id"
										v-model="parent_account_id"
										placeholder="Optional Parent Account ID in V4"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<p>Select the MPID for this new account:</p>
				<div class="flex flex-column">
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Marketplace:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="mpid"
										:options="mpid_options"
										:option-label="(option) => `${option.label} - ${option.value}`"
										option-value="value"
										placeholder="Select MPID"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template #footer>
				<div class="flex align-items-center justify-content-end">
					<p-button :loading="loading" label="Start Migration" @click="migrate" />
				</div>
			</template>
		</p-card>
		<p-card v-if="step === 2" class="account-form-content">
			<template #title> Dry Run </template>
			<template #content>
				<p-message :closable="false"
					>This is the response from the "dry run", <strong>no data has fully been inserted yet</strong>.</p-message
				>

				<p-message
					:closable="false"
					severity="warn"
					v-if="get(dry_run_response, 'data.v4_account.parent_account_id', false)"
				>
					This account will be created as a child account of
					<strong>{{ get(dry_run_response, 'data.v4_account.parent_account_id', '') }}</strong> which was
					<strong>{{
						get(dry_run_response, 'v4_id_map.' + get(dry_run_response, 'data.v4_account.parent_account_id', ''), '')
					}}</strong>
					in V3
				</p-message>

				<div class="new-account-info p-2">
					<ul class="migration-stats" style="columns: 3; font-size: var(--font-size-sm); color: var(--gray-50)">
						<li class="pb-1">
							Accounts migrated:
							<strong style="color: var(--black)">{{ dry_run_response.accounts_migrated }}</strong>
						</li>
						<li class="pb-1">
							Users migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': dry_run_response.users_migrated < dry_run_response.users_found,
								}"
								>{{ dry_run_response.users_migrated }} / {{ dry_run_response.users_found }}</strong
							>
						</li>
						<li class="pb-1">
							Campaigns migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': dry_run_response.campaigns_migrated < dry_run_response.campaigns_found,
								}"
								>{{ dry_run_response.campaigns_migrated }} / {{ dry_run_response.campaigns_found }}</strong
							>
						</li>
						<li class="pb-1">
							Shippers migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': dry_run_response.shippers_migrated < dry_run_response.shippers_found,
								}"
								>{{ dry_run_response.shippers_migrated }} / {{ dry_run_response.shippers_found }}</strong
							>
						</li>
						<li class="pb-1">
							Payment Methods migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': dry_run_response.payment_methods_migrated < dry_run_response.payment_methods_found,
								}"
								>{{ dry_run_response.payment_methods_migrated }} / {{ dry_run_response.payment_methods_found }}</strong
							>
						</li>
						<li class="pb-1">
							PLs migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': dry_run_response.premium_listings_migrated < dry_run_response.premium_listings_found,
								}"
								>{{ dry_run_response.premium_listings_migrated }} /
								{{ dry_run_response.premium_listings_found }}</strong
							>
						</li>
					</ul>
				</div>

				<template v-if="dry_run_response.errors.length > 0">
					<gutter size="20px" />
					<h3 class="text-red-400">Errors</h3>
					<ul class="list-disc pl-3 ml-3">
						<li v-for="error in dry_run_response.errors">{{ error }}</li>
					</ul>
					<gutter size="20px" />
				</template>

				<h3>To Do List (Preview)</h3>
				<p-message v-if="dry_run_response.todos.length === 0" :closable="false"
					>There are no TO DO items for this account migration</p-message
				>
				<p v-else>These will be the list of pending items that may require manual updates</p>
				<div class="todo-container">
					<div v-for="todo in dry_run_response.todos" class="todo">
						<label class="font-normal mb-2"
							>Type: <strong>{{ todo.type }}</strong></label
						>
						<p class="sub-value">{{ todo.message }}</p>
						<a :href="prepareLink(todo)" target="_blank"
							><p-button class="p-button-sm" icon="pi pi-link" rounded label="V3 Link"
						/></a>
					</div>
				</div>
			</template>

			<template #footer>
				<div class="flex align-items-center justify-content-end">
					<p-button
						:disabled="dry_run_response.v4_account_id === ''"
						label="Complete Migration"
						:loading="loading"
						@click="migrate(false)"
					/>
				</div>
			</template>
		</p-card>
		<p-card v-if="step === 3" class="account-form-content">
			<template #title>Migration Result</template>
			<template #content>
				<p>You will be emailed a copy of these results</p>
				<div class="new-account-info p-2">
					<p class="text-lg">
						New Account ID:
						<router-link
							target="_blank"
							:to="
								get(this.final_response, 'data.v4_account.is_parent', false)
									? `/marketplaces/manage/${final_response.mpid}/parent-accounts/${final_response.v4_account_id}/reports/parent-account-overview`
									: `/marketplaces/manage/${final_response.mpid}/accounts/manage/${final_response.v4_account_id}/reports/account-activity`
							"
						>
							<strong
								>{{ get(this.final_response, 'data.v4_account.is_parent', false) ? 'Parent Account: ' : ''
								}}{{ final_response.v4_account_id }}</strong
							>
						</router-link>
					</p>
					<ul class="migration-stats" style="columns: 3; font-size: var(--font-size-sm); color: var(--gray-50)">
						<li class="pb-1">
							Accounts migrated:
							<strong style="color: var(--black)">{{ final_response.accounts_migrated }}</strong>
						</li>
						<li class="pb-1">
							Users migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': final_response.users_migrated < final_response.users_found,
								}"
								>{{ final_response.users_migrated }} / {{ final_response.users_found }}</strong
							>
						</li>
						<li class="pb-1">
							Campaigns migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': final_response.campaigns_migrated < final_response.campaigns_found,
								}"
								>{{ final_response.campaigns_migrated }} / {{ final_response.campaigns_found }}</strong
							>
						</li>
						<li class="pb-1">
							Shippers migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': final_response.shippers_migrated < final_response.shippers_found,
								}"
								>{{ final_response.shippers_migrated }} / {{ final_response.shippers_found }}</strong
							>
						</li>
						<li class="pb-1">
							Payment Methods migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': final_response.payment_methods_migrated < final_response.payment_methods_found,
								}"
								>{{ final_response.payment_methods_migrated }} / {{ final_response.payment_methods_found }}</strong
							>
						</li>
						<li class="pb-1">
							Premium Listings migrated:
							<strong
								style="color: var(--black)"
								:class="{
									'text-red': final_response.premium_listings_migrated < final_response.premium_listings_found,
								}"
								>{{ final_response.premium_listings_migrated }} / {{ final_response.premium_listings_found }}</strong
							>
						</li>
					</ul>
				</div>

				<template v-if="final_response.errors.length > 0">
					<gutter size="20px" />
					<h3 class="text-red-400">Errors</h3>
					<ul class="list-disc pl-3 ml-3">
						<li v-for="error in final_response.errors">{{ error }}</li>
					</ul>
				</template>

				<gutter size="25px" />
				<template v-if="final_response.todos.length > 0">
					<h3>To Do List</h3>
					<div class="todo-container">
						<div v-for="(todo, index) in final_response.todos" class="todo" :key="index">
							<div class="flex align-items-start justify-content-between gap-2">
								<div class="info">
									<label
										class="font-normal mb-2"
										:class="{
											'line-through': completed_todos.includes(index),
										}"
										>Type: <strong>{{ todo.type }}</strong></label
									>
									<p
										class="sub-value"
										:class="{
											'line-through': completed_todos.includes(index),
										}"
									>
										{{ todo.message }}
									</p>
									<div class="flex justify-content-start align-items-center gap-2">
										<a :href="prepareLink(todo)" target="_blank"
											><p-button class="p-button-sm" icon="pi pi-link" rounded label="V3 Link"
										/></a>
										<a v-if="'v4_id' in todo" :href="prepareLink(todo, true)" target="_blank"
											><p-button class="p-button-sm" icon="pi pi-arrow-up-right" rounded label="V4 Link"
										/></a>
									</div>
								</div>
								<p-button
									v-if="!completed_todos.includes(index)"
									v-tooltip.top="'Mark Complete'"
									severity="secondary"
									class="p-button-sm"
									icon="pi pi-check"
									text
									@click="markCompleted(index)"
								/>
								<p-button
									v-else
									v-tooltip.top="'Mark Incomplete'"
									severity="secondary"
									class="p-button-sm"
									icon="pi pi-undo"
									text
									@click="markCompleted(index)"
								/>
							</div>
						</div>
					</div>
				</template>
			</template>
		</p-card>
	</div>
</template>
<script lang="ts">
import { migrateAccountData, getMarketplacesAsOptions } from '@GQL';
import { get } from 'lodash-es';
import Loader from '@/App.vue';

export default {
	name: 'MigrateAccountPage',
	components: {
		Loader,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			breadcrumb_items: [{ label: 'Migrate V3 Account' }],
			company_id: '',
			mpid: '',
			parent_account_id: null,
			mpid_options: [],
			step: 1,
			dry_run_response: {},
			final_response: {},
			loading: false,
			base_v4_url: '',
			base_v3_url: '',
			completed_todos: [],
		};
	},
	async mounted() {
		this.mpid_options = await getMarketplacesAsOptions();
		this.base_v4_url = `${window.location.origin}`;
		if (window.location.origin.indexOf('admin.nextgenleads.app') > -1) {
			// we are in production
			this.base_v3_url = `https://admin.nextgenleads.com`;
		} else {
			this.base_v3_url = `https://admin.stage.nextgenleads.com`;
		}
	},
	beforeRouteLeave(to, from, next) {
		if ('v4' in this.final_response) {
			this.$confirm.require({
				group: 'default',
				message: `Are you sure you want to leave this page? You may have unfinished to dos.`,
				header: 'Leaving Migration',
				acceptClass: 'p-button-danger',
				acceptLabel: 'Leave',
				rejectLabel: 'Stay',
				accept: () => {
					next();
				},
				reject: () => {
					next(false);
				},
			});
		} else {
			next();
		}
	},
	methods: {
		get,
		prepareLink(todo: { type: string; v3_id: string; v4_id?: string }, v4 = false) {
			// based on the type we create a different link
			try {
				if (
					todo.type.indexOf('campaign') > -1 ||
					todo.type.indexOf('channel_targeting') > -1 ||
					todo.type === 'premium_listing_type_mismatch'
				) {
					if (v4) {
						if ('v4_id' in todo) {
							if (get(this.final_response, 'data.v4_account.is_parent', false)) {
								return `${this.base_v4_url}/marketplaces/manage/${this.final_response.mpid}/parent-accounts/${this.final_response.v4_account_id}/campaign-templates/${todo.v4_id}`;
							} else {
								return `${this.base_v4_url}/marketplaces/manage/${this.final_response.mpid}/accounts/manage/${this.final_response.v4_account_id}/campaigns/${todo.v4_id}`;
							}
						}
					} else {
						return `${this.base_v3_url}/buyers/${this.dry_run_response.company_id}/campaigns/${todo.v3_id}/edit`;
					}
				} else if (todo.type.indexOf('shipper') > -1) {
					if (v4) {
						if ('v4_id' in todo) {
							return `${this.base_v4_url}/marketplaces/manage/${this.final_response.mpid}/accounts/manage/${this.final_response.v4_account_id}/shippers/${todo.v4_id}`;
						}
					} else {
						return `${this.base_v3_url}/buyers/${this.dry_run_response.company_id}/shippers/${todo.v3_id}/edit`;
					}
				} else {
					return '_no_link';
				}
			} catch (err) {
				return '_no_link';
			}
		},
		async migrate(dry = true) {
			this.loading = true;
			// this runs with dry = true
			try {
				const params = {
					company_id: this.company_id,
					mpid: this.mpid,
					dry_run: dry,
				};
				if (this.parent_account_id && this.parent_account_id !== '') {
					params.parent_account_id = this.parent_account_id;
				}
				const response = await migrateAccountData(params);
				if (dry) {
					this.dry_run_response = { ...response };
					this.step = 2;
				} else {
					this.final_response = { ...response };
					this.step = 3;
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error when trying to migrate account',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		markCompleted(index) {
			if (this.completed_todos.includes(index)) {
				this.completed_todos = this.completed_todos.filter((todo) => todo !== index);
			} else {
				this.completed_todos.push(index);
			}
		},
	},
};
</script>

<style lang="less" scoped>
.account-form-content {
	max-width: 1000px;
}

h3 {
	color: var(--color-b);
}

.todo-container {
	padding: 0 1rem;
	border: 1px dashed var(--gray-30);
}

.todo {
	margin: 15px 0;
	padding-bottom: 15px;
	// border: 1px solid var(--gray-30);
	border-bottom: 1px dashed var(--gray-30);
	&:last-of-type {
		border-bottom: none;
	}
}

:deep(.account-stats) {
	columns: 3;
}
</style>
