<template>
	<div class="settings-form">
		<h2>Account Settings</h2>
		<gutter size="20px" />
		<p-message severity="warn" :closable="false" v-if="!account.verified">
			This account is <strong>not</strong> verified.
			<span v-if="!$root.sessionStore.hasValidAccountPhone"
				>The daily spend will be limited to $150 per vertical and lead type.</span
			>
			<template v-if="$root.sessionStore.isAccountUser">
				Contact your Account Manager to request your account be verified.</template
			>
		</p-message>
		<div v-if="!account.verified && $root.sessionStore.isAdminUser">
			<p-button severity="warning" icon="pi pi-check" label="Verify Account" @click="verifyAccount" />
		</div>

		<gutter size="20px" />

		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Company Name:</label>
				<div class="controls">
					<div class="field full">
						<p-input-text v-model="account.name" />
						<div v-if="v$.account.name.$error" class="validation-error">
							{{ v$.account.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="$root.sessionStore.isAdminUser" class="control-group">
			<div class="inner">
				<label class="control-label">Display Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="account.display_name" placeholder="Used in results of LTA auctions" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="$root.appStore.mpid === 'GRF'" class="control-group">
			<div class="inner">
				<label class="control-label">Agent License #:</label>
				<div class="controls">
					<div class="field full">
						<p-input-text v-model="account.signup_data.agent_license_number" />
						<div v-if="v$.account.signup_data.agent_license_number.$error" class="validation-error">
							{{ v$.account.signup_data.agent_license_number.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div  v-if="$root.appStore.mpid === 'GRF'" class="control-group">
			<div class="inner">
				<label class="control-label">Agent License #:</label>
				<div class="controls">
					<div class="field full">
						<p-input-text
							id="agent-license"
							v-model="account.signup_data.agent_license_number"
							class="p-inputtext"
							placeholder="Agent License #"
						/>
						<div v-if="v$.account.signup_data.agent_license_number.$error" class="validation-error">
							{{ v$.account.signup_data.agent_license_number.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<template v-if="$root.sessionStore.isAdminUser">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="is-parent" v-model="account.is_parent" />
						</div>
						<div class="field">
							<label for="is-parent">This account is a parent</label>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Group IDs:</label>
					<div class="controls">
						<div class="field">
							<p-chips add-on-blur v-model="account.group_ids" placeholder="Group IDs must match exactly" />
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="$root.sessionStore.isAdminUser || $root.sessionStore.hasPermission('Account.parent_account_id')">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Parent Account ID:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="account.parent_account_id" />
							<div v-if="v$.account.parent_account_id.$error" class="validation-error">
								{{ v$.account.parent_account_id.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<p-fieldset legend="Contact Info">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label req">Phone:</label>
							<p-input-mask mask="(999) 999-9999" unmask :auto-clear="false" v-model="account.contact.phone" />
							<div v-if="v$.account.contact.phone.$error" class="validation-error">
								{{ v$.account.contact.phone.$errors[0].$message }}
							</div>
						</div>
						<div class="field">
							<label class="control-label req">Email:</label>
							<p-input-text v-model="account.contact.email" />
							<div v-if="v$.account.contact.email.$error" class="validation-error">
								{{ v$.account.contact.email.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">State:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="account.contact.state"
								:options="stateOptions"
								optionLabel="label"
								optionValue="value"
								placeholder="Select State"
								filter
							/>
							<div v-if="v$.account.contact.state.$error" class="validation-error">
								{{ v$.account.contact.state.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<template v-if="$root.sessionStore.isAdminUser">
				<gutter size="10px" />
				<hr />
				<gutter size="10px" />
				<div class="control-group">
					<div class="inner">
						<h3>Additional Contacts</h3>
						<p>These contacts will be used to correctly associate phone activity between AMs and buyers</p>
						<row class="justify-content-end">
							<div>
								<p v-if="unsaved_contact_changes" class="alert">Unsaved Changes</p>
							</div>
							<gutter size="20px" />
							<p-button icon="pi pi-plus" label="Add" @click="openContactModal()" />
						</row>
						<gutter size="10px" />
						<p-data-table ref="dt" scrollable :value="account.contact.associated_contacts">
							<template #empty>
								<div class="dim">No Additional Contacts found</div>
							</template>
							<p-column field="name" header="Name">
								<template #body="row"> {{ row.data.first_name }} {{ row.data.last_name }} </template>
							</p-column>
							<p-column field="first_name" header="Phone">
								<template #body="row">
									{{ phoneFormat(row.data.phone) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button v-tooltip.top="'Edit'" aria-label="Edit" @click="openContactModal(row.data)">
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button v-tooltip.top="'Delete'" @click="deleteContact(row.data)">
												<template #icon>
													<icon type="delete" size="20px" />
												</template>
											</p-button>
										</div>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>

					<p-dialog
						v-model:visible="show_contact_modal"
						class="action-modal"
						:modal="true"
						:dismissable-mask="true"
						:style="{ width: '25vw' }"
					>
						<template #header>
							<div class="flex gap-2 align-content-center align-items-center">
								<alt-icon type="sticky-note-2" size="24px" style="color: var(--color-b)" />
								<strong>Contact</strong>
							</div>
						</template>
						<div class="flex-flex-column">
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">First Name:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="additional_contact.first_name" />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Last Name:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="additional_contact.last_name" />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Phone:</label>
									<div class="controls">
										<div class="field">
											<p-input-mask v-model="additional_contact.phone" mask="(999) 999-9999" unmask />
										</div>
									</div>
								</div>
							</div>
						</div>

						<template #footer>
							<div class="flex justify-content-end">
								<p-button text label="Cancel" @click="cancelContact" />
								<p-button icon="pi pi-check" label="Save" @click="saveContact" />
							</div>
						</template>
					</p-dialog>
				</div>
			</template>
		</p-fieldset>
		<gutter size="20px" />
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Timezone:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="account.settings.timezone"
							placeholder="Select Timezone"
							:options="timezoneOptions"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.account.settings.timezone.$error" class="validation-error">
							{{ v$.account.settings.timezone.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-if="$root.sessionStore.isAdminUser">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Tags:</label>
					<div class="controls">
						<div class="field">
							<p-chips add-on-blur v-model="account.tags" separator="," />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Status:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="account.status"
								:options="account_status_options"
								option-label="label"
								option-value="value"
								placeholder="Select status"
							/>
							<div v-if="v$.account.status.$error" class="validation-error">
								{{ v$.account.status.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="inner" v-if="account.status === 'away'">
				<label class="control-label" for="status">Date Of Return:</label>
				<div class="controls">
					<div class="field">
						<p-calendar v-model="date_of_return" date-format="D, M d, yy" :manual-input="false" />
					</div>
				</div>
			</div>

			<p-fieldset legend="Notifications">
				<p>Enter email addresses that should be BCC'ed in the following template notifications</p>

				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field-row gap-20 align-items-start">
								<div class="field">
									<label class="control-label" for="granted">Notification BCC Emails:</label>
									<InputList id="granted" v-model="account.settings.notifications_bcc" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<label class="control-label">Email Notifications:</label>
						<p>
							Note that even if these emails are not selected, all the account users will receive these notifications
						</p>
						<div class="controls">
							<div class="field">
								<p-multi-select
									v-model="account.settings.notifications_bcc_opt_in"
									:options="email_notifications"
									option-label="label"
									option-value="value"
									placeholder="Select emails"
								/>
								<div v-if="v$.account.status.$error" class="validation-error">
									{{ v$.account.status.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>

			<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="DNC CSV Tool">
				<p>Uploading a CSV will create DNC records for this account only.</p>
				<DNCCSVUpload :account_id="accountId" />
			</p-fieldset>
		</template>
		<div class="actions flex">
			<p-button label="Save" icon="pi pi-check" @click="updateAccount()" :loading="loading" />
		</div>

		<p-confirm-dialog />
	</div>
</template>

<script>
import '@/lib/Utils/isDst';
import { cloneDeep, sortBy } from 'lodash-es';
import pChips from 'primevue/chips';
import pCalendar from 'primevue/calendar';
import STATES from '@/lib/Data/states.json';
import TIMEZONES from '@/lib/Data/timezones.json';
import { account_status_options } from '@/lib/Options';
import { getAccountSettings, updateAccount } from '@GQL';
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import DNCCSVUpload from '@/views/DNCs/Record/CSVUploadModal.vue';
import pConfirmDialog from 'primevue/confirmdialog';
import InputList from '@/components/forms/InputList.vue';
import { phoneFormat } from '@/lib/Filters';
import debounceAsync from 'debounce-async';
import { checkExistingAgentLicenseNumber } from '@GQL';

export default {
	name: 'AccountSettings',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	components: {
		pChips,
		pCalendar,
		DNCCSVUpload,
		pConfirmDialog,
		InputList,
		phoneFormat,
	},
	data() {
		return {
			account: {
				name: null,
				mpid: null,
				display_name: '',
				is_parent: false,
				parent_account_id: '',
				group_ids: [],
				contact: {
					state: null,
					phone: '',
					email: '',
					associated_contacts: [],
				},
				settings: {
					timezone: null,
				},
				signup_data: {
					agent_license_number: '',
				},
				support: {},
				tags: [],
				status: 'active',
				verified: false,
			},
			email_notifications: [
				{ label: 'Live Transfer Failure', value: '01J86APAYS6M4QFR0MKNJ84SPY' }, // these are email templates found in different areas
				{ label: 'Low Balance', value: '01J5REQH48GV8C3KGNXG5KM56H' },
				{ label: 'Refund Deposits Receipts', value: '01HH07N6V852R46WSQ591XMBSW' },
				{ label: 'Deposit Receipts', value: '01HH07FWV2900AHYNC6AZR72RG' },
			],
			date_of_return: new Date(),
			account_status_options,
			show_contact_modal: false,
			unsaved_contact_changes: false,
			temp_contact: {},
			additional_contact: {
				phone: '',
				first_name: '',
				last_name: '',
			},
			loading: false,
		};
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
		timezoneOptions() {
			const is_dst = new Date().isDst();

			const timezones = TIMEZONES.map((tz) => {
				const offset = is_dst ? tz.offset_dst : tz.offset;
				return {
					label: `${tz.label} (${offset})`,
					value: tz.id,
					offset,
				};
			});

			return sortBy(timezones, (tz) => {
				return parseInt(tz.offset);
			});
		},
		stateOptions() {
			return STATES;
		},
	},
	validations() {
		return {
			account: {
				name: {
					required: helpers.withMessage('An account name is required', required),
				},
				contact: {
					phone: {
						required: helpers.withMessage('A contact phone is required', required),
						phone: helpers.withMessage('Please enter a valid phone number', (value) => {
							const phone_pattern = /^\d{10}$/;
							return phone_pattern.test(value);
						}),
					},
					email: {
						required: helpers.withMessage('A contact email is required', required),
						email: helpers.withMessage('Please enter a valid email address', email),
					},
					state: {
						required: helpers.withMessage('A state is required', required),
					},
				},
				settings: {
					timezone: {
						required: helpers.withMessage('A timezone is required', required),
					},
				},
				signup_data: {
					agent_license_number: {
						...(this.$root.appStore.mpid === 'GRF' && {
							uniqueAgentLicenseNumber: helpers.withMessage(
								'A user with that license number already exists.',
								helpers.withAsync((value) => {
									if (!value) return true; // Skip validation if empty
									return debounceAsync(async () => {
										return !(await checkExistingAgentLicenseNumber(value));
									}, 500)();
								})
							),
						}),
					},
				},
				status: {
					required: helpers.withMessage('The account status is required', required),
				},
				parent_account_id: {
					marketplace: helpers.withMessage('The Parent Account must be from the same Marketplace', (value) => {
						if (value !== null && value.length > 0) {
							// first three characters must be the same as account.id
							const account_mpid = this.accountId.slice(0, 3);
							const parent_mpid = value.slice(0, 3);
							return account_mpid === parent_mpid;
						}
						return true;
					}),
				},
			},
		};
	},
	async beforeMount() {
		const result = await getAccountSettings(this.accountId);
		delete result.account.settings.budgets; // removing extra data returned
		this.account = result.account;
		if (!this.account.signup_data) {
			this.account.signup_data = {};
		}
		if (!this.account.signup_data.agent_license_number) {
			this.account.signup_data.agent_license_number = '';
		}
		if (this.account.status === 'away') {
			this.date_of_return = new Date(this.account.support.date_of_return);
		}
		if (this.account.support.matching_accounts) {
			this.account.support.matching_accounts.forEach((matching_account) => {
				matching_account.created_at = new Date(matching_account.created_at);
			});
		}
		this.unsaved_contact_changes = false;
	},
	methods: {
		phoneFormat,
		deleteContact(contact) {
			const index = this.account.contact.associated_contacts.findIndex((item) => {
				return item.phone === contact.phone && item.last_name === contact.last_name;
			});
			this.account.contact.associated_contacts.splice(index, 1);
			this.$toast.add({
				severity: 'info',
				summary: 'The Contact has been removed',
				detail:
					'In order to save the change to the account make sure to Click the Save button at the bottom of this page.',
				life: 8000,
			});
			this.unsaved_contact_changes = true;
		},
		uniqueAgentLicenseNumber(agent_license_number) {
			return debounceAsync(async () => {
				const agent_license_number_exists = await checkExistingAgentLicenseNumber(agent_license_number);
				return !agent_license_number_exists;
			}, 500)();
		},
		saveContact() {
			// update or push the contact to the array
			// close modal
			if (this.account.contact.associated_contacts) {
				this.account.contact.associated_contacts.push({ ...this.additional_contact });
			} else {
				this.account.contact.associated_contacts = [{ ...this.additional_contact }];
			}
			this.show_contact_modal = false;
			this.$toast.add({
				severity: 'info',
				summary: 'The Contact was added',
				detail:
					'In order to save the change to the account make sure to Click the Save button at the bottom of this page.',
				life: 8000,
			});

			this.unsaved_contact_changes = true;
		},
		cancelContact() {
			// reset to the array
			if (this.temp_contact && 'phone' in this.temp_contact) {
				this.account.contact.associated_contacts.push({ ...this.temp_contact });
			}
			this.show_contact_modal = false;
		},
		openContactModal(contact) {
			if (contact) {
				// and pop it off the array
				this.additional_contact = { ...contact };
				const index = this.account.contact.associated_contacts.findIndex((item) => {
					return item.phone === contact.phone && item.last_name === contact.last_name;
				});
				this.temp_contact = this.account.contact.associated_contacts.splice(index, 1)[0];
			} else {
				this.additional_contact = {
					phone: '',
					first_name: '',
					last_name: '',
				};
			}
			this.show_contact_modal = true;
		},
		verifyAccount() {
			this.$confirm.require({
				header: 'Verify Account',
				message: `Ideally you've confirmed the email address and payment methods of this account and have decided to remove all restrictions on this account`,
				accept: async () => {
					// set the verified_by field and update account
					this.account.verified = true;
					this.account.verified_by = this.$root.sessionStore.user.id;
					this.account.verified_at = new Date();
					this.account.settings.budgets = []; // CLEAR OUT THE ACCOUNT BUDGETS
					await this.updateAccount();
				},
			});
		},
		async updateAccount() {
			this.loading = true;
			const new_account = cloneDeep(this.account);

			// Remove the parent_account_id
			if (new_account.parent_account_id === '') {
				new_account.parent_account_id = null;
			} else {
				// MAKE SURE THAT
			}

			const is_valid = await this.v$.$validate();

			if (is_valid) {
				if (new_account.status === 'away') {
					new_account.support.date_of_return = this.date_of_return;
				} else {
					delete new_account.support.date_of_return;
				}
				const account_result = await updateAccount(this.accountId, new_account);
				if (account_result.updateAccount.id) {
					// confirm saved
					this.unsaved_contact_changes = false;
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved account settings',
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 3000,
					});
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'A required field must be missing or invalid',
					life: 10000,
				});
			}
			this.loading = false;
		},
	},
};
</script>

<style lang="less">
.alert {
	color: red;
	font-size: 1.25em !important;
	font-style: italic;
	text-align: center;
}
</style>
