<template>
	<div class="settings-form">
		<h2>Auto-Copy Campaign Templates</h2>
		<gutter size="20px" />
		<p>Select templates to automatically be created for New Sign Ups and any newly created children accounts.</p>
		<p-data-table :loading="loading" scrollable :value="campaigns">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<p-column header="Auto Copy">
				<template #body="campaign">
					<p-input-switch v-model="campaign.data.autocopy" />
				</template>
			</p-column>
			<p-column field="name" header="Campaign Info" sortable sortField="name">
				<template #body="campaign">
					<div class="name mb-2 font-bold" style="font-size: var(--font-size-base)">
						{{ campaign.data.name }}
					</div>
					<div class="tags flex flex-row justify-content-start gap-2">
						<p-tag
							v-if="campaign.data.vertical_id_targeting"
							v-tooltip.top="capitalize(campaign.data.vertical_id_targeting)"
							rounded
							:class="{
								health: campaign.data.vertical_id_targeting === 'health_insurance',
								medicare: campaign.data.vertical_id_targeting === 'medicare',
							}"
							:value="upperCase(campaign.data.vertical_id_targeting)"
						/>
						<p-tag
							v-if="campaign.data.promotion && campaign.data.promotion.description"
							v-tooltip.top="campaign.data.promotion.description"
							rounded
							class="promo"
							:value="'PROMO'"
						/>
						<p-tag
							rounded
							:class="kebabCase(campaign.data.product_targeting)"
							:value="upperCase(campaign.data.product_targeting)"
						/>
						<p-tag rounded :class="campaign.data.bid_type" :value="upperCase(campaign.data.bid_type)" />
						<p-tag v-if="campaign.data.mode === 'advanced'" rounded :class="campaign.data.mode" value="ADVANCED" />
						<p-tag
							v-if="get(campaign.data.flags, 'lock.enabled', false)"
							icon="pi pi-lock"
							class="locked"
							value="LOCKED"
						/>
					</div>
					<div class="mt-1">
						{{ capitalize(campaign.data.location_targeting_mode) }} Targeting:
						{{ getLocationTargeting(campaign) }}
					</div>
					<div v-if="campaign.data.location_targeting_mode === 'state'" class="sub-value">
						{{ campaign.data.state_targeting.length }} State(s) Targeted
					</div>
					<div v-if="campaign.data.location_targeting_mode === 'zip'" class="sub-value">
						{{ campaign.data.zip_targeting.length }} ZIP Code(s) Targeted
					</div>
				</template>
			</p-column>
			<p-column field="bid" header="Bid" class="align-center" sortable sortField="default_bid">
				<template #body="campaign">
					{{ currency(campaign.data.default_bid) }}
				</template>
			</p-column>
		</p-data-table>
		<gutter size="20px" />
		<div class="actions">
			<div class="flex justify-content-start">
				<p-button label="Save Changes" @click="saveChanges" :loading="save_loading" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { getCampaigns, getAccountSettings, updateAccount } from '@GQL';
import log from '@/lib/Log';
import type { FilterParams } from '@nextgenleads/db';
import dayjs from 'dayjs';
import { currency, capitalize } from '@/lib/Filters';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import pTag from 'primevue/tag';
import { kebabCase, upperCase, get } from 'lodash-es';

export default {
	name: 'CampaignTemplates',
	components: {
		verticalIcon,
		pTag,
	},
	data() {
		return {
			campaigns: [],
			loading: false,
			save_loading: false,
		};
	},
	mounted() {
		this.getCampaigns(true);
	},
	methods: {
		currency,
		capitalize,
		kebabCase,
		upperCase,
		get,
		updateAutocopyCampaigns(campaign_id) {
			if (this.autocopy_campaigns.includes(campaign_id)) {
				this.autocopy_campaigns = this.autocopy_campaigns.filter((id) => id !== campaign_id);
			} else {
				this.autocopy_campaigns.push(campaign_id);
			}
		},
		getLocationTargeting(campaign) {
			if (campaign.data.location_targeting_mode === 'state') {
				let states = campaign.data.state_targeting.slice(0, 5).join(', ');
				if (campaign.data.state_targeting.length > 5) {
					states += ', ...';
				}
				return states;
			} else {
				let zips = campaign.data.zip_targeting.slice(0, 5).join(', ');
				if (campaign.data.zip_targeting.length > 5) {
					zips += ', ...';
				}
				return zips;
			}
		},
		async getCampaigns(refresh?: boolean) {
			this.loading = true;
			// transformfilters and call getCampaigns
			const filters: FilterParams = [
				{
					op: 'eq',
					value: this.account_id,
					field: 'account_id',
				},
			];

			const dated_params = [
				{
					field: 'created_at',
					op: 'gt',
					value: dayjs().toDate(),
				},
				{
					field: 'created_at',
					op: 'lt',
					value: dayjs().toDate(),
				},
			];

			try {
				const results = await getCampaigns([filters], [dated_params], refresh);
				const settings_result = await getAccountSettings(this.account_id, true);
				let autocopy_campaigns = get(settings_result, 'account.settings.autocopy_campaigns', []);

				results.forEach((campaign) => {
					if (autocopy_campaigns.includes(campaign.id)) {
						campaign['autocopy'] = true;
					} else {
						campaign['autocopy'] = false;
					}
				});

				this.campaigns = results;
			} catch (err) {
				log.error('GetCampaign', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Campaigns',
					err: err,
				});
			} finally {
				this.loading = false;
			}
		},
		async saveChanges() {
			this.save_loading = true;
			const autocopy_campaigns = this.campaigns.filter((campaign) => campaign.autocopy).map((campaign) => campaign.id);
			try {
				await updateAccount(this.account_id, {
					settings: {
						autocopy_campaigns: autocopy_campaigns,
					},
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully saved changes',
				});
			} catch (err) {
				log.error('SaveChanges', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save changes',
				});
			} finally {
				this.save_loading = false;
			}
		},
	},
	computed: {
		account_id() {
			if (this.$route.params.account_id) {
				return this.$route.params.account_id;
			} else if (this.$route.params.parent_account_id) {
				return this.$route.params.parent_account_id;
			} else {
				return this.sessionStore.account.id;
			}
		},
		autocopy_campaigns() {
			return this.campaigns.filter((campaign) => campaign.autocopy).map((campaign) => campaign.id);
		},
	},
};
</script>

<style lang="less" scoped>
.p-tag {
	font-size: 0.625rem;
	padding: 0.5em 1em;

	&.health {
		background: #d90000;
	}

	&.medicare {
		background: #0056b3;
	}

	&.exclusive {
		background: var(--teal);
	}

	&.data {
		background: #5cadc4;
	}

	&.call {
		background: var(--purple-400);
	}

	&.pingpost {
		background: var(--orange-500);
	}

	&.night {
		background: var(--purple-600);
	}

	&.weekend {
		background: var(--red-600);
	}

	&.live-transfer {
		background: #82bd41;
	}

	&.choice {
		background: var(--gray-50);
	}

	&.premium,
	&.promo {
		background: var(--yellow-600);
	}

	&.throttled {
		background: var(--yellow);
		cursor: default;
	}

	&.advanced {
		background: var(--orange);
	}

	&.locked {
		background: var(--red);
	}
}
</style>
