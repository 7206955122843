<template>
	<div class="sub-value">Values that do not appear here were not collected.</div>
	<div class="vehicles">
		<div class="data-section-heading">Vehicles</div>
		<div v-if="lead.data.vehicles.length === 0" class="vehicle">
			<div class="collapsed">
				<div class="item-heading dim">No Vehicles</div>
			</div>
		</div>
		<div v-for="vehicle in lead.data.vehicles" class="vehicle" :key="vehicle.id">
			<div v-if="!expanded_vehicles.includes(vehicle.id)" class="collapsed">
				<div class="item-heading flex align-items-center" @click="toggleItem(expanded_vehicles, vehicle.id)">
					<div class="item-label">
						{{ `${vehicle.year} ${vehicle.make} ${vehicle.model}` || 'Unknown' }}
					</div>
					<div class="item-identifier">
						{{ vehicle.vin || 'NA' }}
					</div>
					<div class="arrow">
						<icon type="chevron-right" size="24px" />
					</div>
				</div>
			</div>
			<div v-else class="expanded">
				<div class="item-heading flex align-items-center" @click="toggleItem(expanded_vehicles, vehicle.id)">
					<div class="item-label">
						{{ `${vehicle.year} ${vehicle.make} ${vehicle.model}` || 'Unknown' }}
					</div>
					<div class="item-identifier">
						{{ vehicle.vin || 'NA' }}
					</div>
					<div class="arrow">
						<icon type="chevron-down" size="24px" />
					</div>
				</div>
				<ul class="table-list">
					<li>
						<div class="label">Year</div>
						<div class="value">
							{{ vehicle.year || 'Unknown' }}
						</div>
					</li>
					<li>
						<div class="label">Make</div>
						<div class="value">
							{{ vehicle.make || 'Unknown' }}
						</div>
					</li>
					<li>
						<div class="label">Model</div>
						<div class="value">
							{{ vehicle.model || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.trim)">
						<div class="label">Trim</div>
						<div class="value">
							{{ vehicle.trim || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.vin)">
						<div class="label">Vin Number (Last 5)</div>
						<div class="value">
							{{ vehicle.vin || 'NA' }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.parking)">
						<div class="label">Parking Location</div>
						<div class="value">
							{{ vehicle.parking || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.alarm)">
						<div class="label">Has Alarm</div>
						<div class="value">
							{{ vehicle.alarm ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.primary_purpose)">
						<div class="label">Primary Purpose</div>
						<div class="value">
							{{ vehicle.primary_purpose || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.primary_driver)">
						<div class="label">Primary Driver</div>
						<div class="value">
							{{ driverName(vehicle.primary_driver) }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.daily_mileage)">
						<div class="label">Daily Mileage</div>
						<div class="value">{{ numberFormat(vehicle.daily_mileage) || '??' }} miles</div>
					</li>
					<li v-if="!isNil(vehicle.annual_mileage)">
						<div class="label">Annual Mileage</div>
						<div class="value">{{ numberFormat(vehicle.annual_mileage) || '??' }} miles</div>
					</li>
					<li v-if="!isNil(vehicle.commute_days_per_week)">
						<div class="label">Commute Days / Week</div>
						<div class="value">{{ vehicle.commute_days_per_week || '??' }} miles</div>
					</li>
					<li v-if="!isNil(vehicle.current_mileage)">
						<div class="label">Current Mileage</div>
						<div class="value">{{ numberFormat(vehicle.current_mileage) || '??' }} miles</div>
					</li>
					<li v-if="!isNil(vehicle.auto_wrranty)">
						<div class="label">Has Warranty</div>
						<div class="value">
							{{ vehicle.auto_wrranty ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(vehicle.ownership)">
						<div class="label">Ownership Type</div>
						<div class="value">{{ vehicle.ownership || 'NA' }}</div>
					</li>
					<li v-if="!isNil(vehicle.collision_deductible)">
						<div class="label">Collision Ded.</div>
						<div class="value">{{ currency(vehicle.collision_deductible) }}</div>
					</li>
					<li v-if="!isNil(vehicle.collision_deductible)">
						<div class="label">Comprehensive Ded.</div>
						<div class="value">{{ currency(vehicle.comprehensive_deductible) }}</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="drivers">
		<div class="data-section-heading">Drivers</div>
		<div v-if="lead.data.drivers.length === 0" class="driver">
			<div class="collapsed">
				<div class="item-heading dim">No Drivers</div>
			</div>
		</div>
		<div v-for="driver in lead.data.drivers" class="driver" :key="driver.id">
			<div v-if="!expanded_drivers.includes(driver.id)" class="collapsed">
				<div class="item-heading flex align-items-center" @click="toggleItem(expanded_drivers, driver.id)">
					<div class="item-label">
						{{ `${driver.first_name} ${driver.last_name}` }}
					</div>
					<div class="item-identifier">
						{{ driver.relationship }}
					</div>
					<div class="arrow">
						<icon type="chevron-right" size="24px" />
					</div>
				</div>
			</div>
			<div v-else class="expanded">
				<div class="item-heading flex align-items-center" @click="toggleItem(expanded_drivers, driver.id)">
					<div class="item-label">
						{{ `${driver.first_name} ${driver.last_name}` }}
					</div>
					<div class="item-identifier">
						{{ driver.relationship }}
					</div>
					<div class="arrow">
						<icon type="chevron-down" size="24px" />
					</div>
				</div>
				<ul class="table-list">
					<li v-if="!isNil(driver.relationship)">
						<div class="label">Relationship</div>
						<div class="value">
							{{ driver.relationship }}
						</div>
					</li>
					<li>
						<div class="label">Full Name</div>
						<div class="value">
							{{ `${driver.first_name} ${driver.last_name}` }}
						</div>
					</li>
					<li v-if="!isNil(driver.gender)">
						<div class="label">Gender</div>
						<div class="value">
							{{ title(driver.gender) }}
						</div>
					</li>
					<li v-if="!isNil(driver.marital_status)">
						<div class="label">Marital Status</div>
						<div class="value">
							{{ driver.marital_status }}
						</div>
					</li>
					<li v-if="!isNil(driver.dob)">
						<div class="label">Date of Birth</div>
						<div class="value">
							{{ formatDate(driver.dob, 'MMMM d, YYYY') }}
						</div>
					</li>
					<li v-if="!isNil(driver.dob)">
						<div class="label">Age</div>
						<div class="value">
							{{ dobToAge(driver.dob) }}
						</div>
					</li>
					<li v-if="!isNil(driver.licensed_age)">
						<div class="label">Age Licensed</div>
						<div class="value">
							{{ driver.licensed_age || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(driver.license_status)">
						<div class="label">License Status</div>
						<div class="value">
							{{ driver.license_status || 'Unknown' }}
						</div>
					</li>
					<li v-if="driver.license_status === 'Suspended'">
						<div class="label">Suspended Reason</div>
						<div class="value">
							{{ driver.suspended_reason || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(driver.license_state)">
						<div class="label">License State</div>
						<div class="value">
							{{ driver.license_state || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(driver.education_level)">
						<div class="label">Education</div>
						<div class="value">
							{{ driver.education_level || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(driver.credit_rating_score) || !isNil(driver.credit_rating_string)">
						<div class="label">Credit Rating</div>
						<div class="value">
							{{ driver.credit_rating_score || driver.credit_rating_string || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(driver.bankruptcy)">
						<div class="label">Bankruptcy</div>
						<div class="value">
							{{ driver.bankruptcy ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(driver.occupation)">
						<div class="label">Occupation</div>
						<div class="value">
							{{ driver.occupation || 'Unknown' }}
						</div>
					</li>
					<li v-if="!isNil(driver.good_student)">
						<div class="label">Good Student</div>
						<div class="value">
							{{ driver.good_student ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(driver.military)">
						<div class="label">Military</div>
						<div class="value">
							{{ driver.military ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(driver.aaa_member)">
						<div class="label">AAA Member</div>
						<div class="value">
							{{ driver.aaa_member ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(driver.sr_22)">
						<div class="label">Requires SR22</div>
						<div class="value">
							{{ driver.sr_22 ? 'True' : 'False' }}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="incidents">
		<div class="data-section-heading">Incidents</div>
		<div v-if="lead.data.incidents.length === 0" class="incident">
			<div class="collapsed">
				<div class="item-heading dim">No Incidents</div>
			</div>
		</div>
		<div v-for="incident in lead.data.incidents" class="incident" :key="incident.id">
			<div v-if="!expanded_incidents.includes(incident.id)" class="collapsed">
				<div class="item-heading flex align-items-center" @click="toggleItem(expanded_incidents, incident.id)">
					<div class="item-label">
						{{ incident.type }}
						<div class="sub-value">{{ formatDate(incident.date, 'MMM d, YYYY') }}</div>
					</div>
					<div class="item-identifier">
						{{ driverName(incident.driver) }}
					</div>
					<div class="arrow">
						<icon type="chevron-right" size="24px" />
					</div>
				</div>
			</div>
			<div v-else class="expanded">
				<div class="item-heading flex align-items-center" @click="toggleItem(expanded_incidents, incident.id)">
					<div class="item-label">
						{{ incident.type }}
						<div class="sub-value">{{ formatDate(incident.date, 'MMM d, YYYY') }}</div>
					</div>
					<div class="item-identifier">
						{{ driverName(incident.driver) }}
					</div>
					<div class="arrow">
						<icon type="chevron-down" size="24px" />
					</div>
				</div>
				<ul class="table-list">
					<li>
						<div class="label">Type</div>
						<div class="value">
							{{ incident.type }}
						</div>
					</li>
					<li>
						<div class="label">Description</div>
						<div class="value">
							{{ incident.description || 'None' }}
						</div>
					</li>
					<li>
						<div class="label">Date</div>
						<div class="value">
							{{ formatDate(incident.incident_date, 'MMMM d, YYYY') }}
						</div>
					</li>
					<li>
						<div class="label">Driver</div>
						<div class="value">
							{{ driverName(incident.driver) }}
						</div>
					</li>
					<li v-if="!isNil(incident.what_damaged)">
						<div class="label">Damage To</div>
						<div class="value">
							{{ incident.what_damaged }}
						</div>
					</li>
					<li v-if="!isNil(incident.accident_at_fault)">
						<div class="label">Accident At Fault</div>
						<div class="value">
							{{ incident.accident_at_fault ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(incident.claim_at_fault)">
						<div class="label">Claim At Fault</div>
						<div class="value">
							{{ incident.claim_at_fault ? 'True' : 'False' }}
						</div>
					</li>
					<li v-if="!isNil(incident.amount_paid)">
						<div class="label">Amount Paid</div>
						<div class="value">
							{{ currency(incident.amount_paid) }}
						</div>
					</li>
					<li v-if="!isNil(incident.liability_medical_amount_paid)">
						<div class="label">Liability Paid</div>
						<div class="value">
							{{ currency(incident.liability_medical_amount_paid) }}
						</div>
					</li>
					<li v-if="incident.type === 'DUI' && !isNil(incident.dui_state)">
						<div class="label">DUI State</div>
						<div class="value">
							{{ currency(incident.dui_state) }}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="data-section-heading">Current Insurance</div>
	<ul class="table-list bordered">
		<li>
			<div class="label">Currently Insured</div>
			<div class="value">
				{{ lead.data.currently_insured ? 'True' : 'False' }}
			</div>
		</li>
		<li>
			<div class="label">Policy Expires Soon</div>
			<div class="value">
				{{ lead.data.policy_expires_soon ? 'True' : 'False' }}
			</div>
		</li>
		<li>
			<div class="label">Policy Expires In</div>
			<div class="value">{{ lead.data.current_policy_expires || '??' }} days</div>
		</li>
		<li>
			<div class="label">Policy Needed Soon</div>
			<div class="value">
				{{ lead.data.policy_needed_soon ? 'True' : 'False' }}
			</div>
		</li>
		<li>
			<div class="label">Current Insurance</div>
			<div class="value">
				{{ lead.data.current_company }}
			</div>
		</li>
		<li>
			<div class="label">Insured for</div>
			<div class="value">{{ lead.data.current_customer_length || '??' }} months</div>
		</li>
		<li>
			<div class="label">Continuous Coverage</div>
			<div class="value">{{ lead.data.continuous_coverage_length || '??' }} months</div>
		</li>
		<li>
			<div class="label">Bodily Injury / Person</div>
			<div class="value">{{ currency(lead.data.bi_per_person) }}</div>
		</li>
		<li>
			<div class="label">Bodily Injury / Incident</div>
			<div class="value">{{ currency(lead.data.bi_per_incident) }}</div>
		</li>
		<li>
			<div class="label">Reason for Shopping</div>
			<div class="value">{{ lead.data.reason_for_shopping || 'Unknown' }}</div>
		</li>
	</ul>

	<div class="data-section-heading">Home Ownership</div>
	<ul class="table-list bordered">
		<li>
			<div class="label">Home Owner</div>
			<div class="value">
				{{ lead.data.home_owner ? 'True' : 'False' }}
			</div>
		</li>
		<template v-if="lead.data.home_owner">
			<li>
				<div class="label">Months at Residence</div>
				<div class="value">{{ lead.data.months_at_residence || 'NA' }}</div>
			</li>
			<li>
				<div class="label">Year Home Was Built</div>
				<div class="value">{{ lead.data.home_built_year || 'NA' }}</div>
			</li>
			<li>
				<div class="label">Home Size</div>
				<div class="value">
					{{ lead.data.home_square_footage ? `${lead.data.home_square_footage} ft&sup2;` : 'NA' }}
				</div>
			</li>
		</template>
	</ul>
</template>

<script>
import { isNil, pull } from 'lodash-es';
import { currency, dobToAge, formatDate, numberFormat, title } from '@/lib/Filters';

export default {
	name: 'AutoInsuranceDetails',
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			expanded_vehicles: [],
			expanded_drivers: [],
			expanded_incidents: [],
		};
	},
	methods: {
		currency,
		dobToAge,
		formatDate,
		isNil,
		numberFormat,
		title,
		driverName(id) {
			const found_driver = this.lead.data.drivers.find((driver) => {
				return driver.id === id;
			});

			if (found_driver) {
				return `${found_driver.first_name} ${found_driver.last_name}`;
			}
			return 'Unknown';
		},
		toggleItem(item_arr, value) {
			if (!item_arr.includes(value)) {
				item_arr.push(value);
			} else {
				pull(item_arr, value);
			}
		},
	},
};
</script>

<style lang="less" scoped>
.data-section-heading {
	font-size: 0.875rem;
	font-weight: bold;
	margin: 20px 0 5px 2px;
	text-transform: uppercase;
}

.vehicle,
.driver,
.incident {
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	margin-top: 10px;
	overflow: hidden;

	.table-list {
		border-top: 1px solid var(--gray-15);

		li {
			padding: 0.5em 1em;
		}
	}
}

.item-heading {
	background-color: var(--gray-05);
	cursor: default;
	font-weight: bold;
	gap: 10px;
	line-height: 50px;
	padding: 0 1em;
	width: 100%;

	.item-label {
		flex: 1 0;
	}

	.item-identifier {
		flex: 0 0 auto;
	}

	.arrow {
		line-height: 20px;
	}
}

.table-list {
	&.bordered {
		border: 1px solid var(--gray-20);
		border-radius: 5px;
	}

	li {
		padding: 0.5em 1em;
	}
}

.red {
	color: var(--red);
}

.green {
	color: var(--green);
}
</style>
