import { $GQL } from '@GQL/index';

export async function verifyAuth(token: string) {
	const mut = `
        mutation VerifyAuth($token: String!) {
            verifyAuth(token: $token)
        }
  `;

	const result = await $GQL.request(mut, { token });
	return result.verifyAuth;
}
