import { $GQL } from '@GQL/index';

export async function checkExistingUserEmail(email: string, id?: String): Promise<Boolean> {
	const query = `
		query CheckExistingUserEmail($email: String!, $id: ID) {
			userEmailExists(email: $email, id: $id)
		}
	`;

	const result = await $GQL.request(query, { email, id, no_cache: ['users'] });
	return result.userEmailExists;
}
