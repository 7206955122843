<template>
	<div id="campaign-template-list" class="view-content">
		<CopyTemplateToChildAccountModal ref="copy_template_modal" />

		<div class="page-heading">
			<h1>Campaign Templates</h1>
		</div>
		<div>
			<query-form v-model:query="query" :loading="loading" @apply="getCampaignTemplates" @abort="clearQuery">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.filters.created_at, 'ddd, MMM D, YYYY') }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="getCampaignTemplates(true)"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="font-bold pb-2" style="font-size: var(--font-size-base)">Date Range:</div>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field xl">
											<date-range v-model="form.query.filters.created_at" show-time />
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<div class="campaign-list-toolbar flex">
				<div class="flex-group fit">
					<p-button label="Create New Template" @click="$router.push({ path: `${$route.path}/new` })">
						<template #icon>
							<div class="icon-wrapper button-icon">
								<icon type="plus" size="24px" />
							</div>
						</template>
					</p-button>
				</div>
				<gutter size="20px" />
				<div class="flex-group flex align-items-center">
					<div>
						<p-dropdown v-model="group_value" :options="group_options" option-label="label" option-value="value" />
					</div>
				</div>
				<div class="flex-group flex-fill"></div>
				<div class="flex-group fit flex align-items-center">
					<show-archived v-model="show_archived" />
				</div>
			</div>
			<gutter size="20px" />

			<template v-if="Object.keys(groupedCampaignTemplates).length === 0 && loading === false">
				<div class="create-new-campaign">
					<row gap="50px">
						<column>
							<h3>Create Your First Campaign Template</h3>
							<p class="larger">You don't have any campaign templates!</p>
							<p>
								Buying leads is easy with NextGen Leads. Just create a new campaign and add funds to your account to
								start buying data leads, calls, and live transfers that are targeted to your precise specifications. You
								can set budgets, adjust bids by various parameters, and turn on and off your campaigns whenever you
								want.
							</p>
							<div class="action">
								<p-button
									icon="pi pi-plus"
									label="Create Your First Campaign Template Now"
									size="large"
									@click="$router.push({ path: `${$route.path}/new` })"
								/>
							</div>
						</column>
						<column class="fit">
							<img src="/assets/img/illustrations/new-campaign.svg" alt="People around a large target" />
						</column>
					</row>
				</div>
			</template>

			<div
				v-for="(campaign_group, campaign_group_key) in groupedCampaignTemplates"
				:key="campaign_group_key"
				class="campaign-group"
			>
				<h2>{{ campaign_group_key }}</h2>
				<div class="campaign-list">
					<p v-if="campaign_group.length === 0" class="text-center p-3 mt-4 mb-4 border-1 border-500 line-height-3">
						No {{ campaign_group_key }}, create a campaign to see performance.
					</p>
				</div>
				<div class="card padded">
					<p-data-table ref="dt" :loading="loading" scrollable :value="campaign_group">
						<template #loading>
							<line-loader :show="loading" />
						</template>
						<template #empty>
							<div class="dim">No results</div>
						</template>
						<p-column field="bid" header="Status" class="column-align-center">
							<template #body="campaign">
								{{ campaign.data.status === 'paused' ? 'Active' : title(campaign.data.status) }}
							</template>
						</p-column>
						<p-column
							class="align-center"
							field="vertical_id_targeting"
							header="Vertical"
							sortable
							sortField="vertical"
						>
							<template #body="campaign">
								<vertical-icon :vertical-id="campaign.data.vertical_id_targeting" size="40px" style="margin: auto" />
							</template>
						</p-column>
						<p-column field="name" header="Template Info" sortable sortField="name">
							<template #body="campaign">
								<div class="name mb-2 font-bold" style="font-size: var(--font-size-base)">
									<router-link v-if="!isLocked(campaign.data)" :to="`${$route.path}/${campaign.data.id}`">{{
										campaign.data.name
									}}</router-link>
									<template v-else>{{ campaign.data.name }}</template>
								</div>
								<div class="tags flex flex-row justify-content-start gap-2">
									<p-tag
										rounded
										:class="kebabCase(campaign.data.product_targeting)"
										:value="upperCase(campaign.data.product_targeting)"
									/>
									<p-tag rounded :class="campaign.data.bid_type" :value="upperCase(campaign.data.bid_type)" />
									<p-tag
										v-if="campaign.data.mode === 'advanced'"
										rounded
										:class="campaign.data.mode"
										value="ADVANCED"
									/>
								</div>
							</template>
						</p-column>
						<p-column field="bid" header="Bid" class="align-center" sortable sortField="default_bid">
							<template #body="campaign">
								{{ currency(campaign.data.default_bid) }}
							</template>
						</p-column>
						<p-column header="Tools" class="align-center">
							<template #body="campaign">
								<div class="toolset">
									<div v-if="!isLocked(campaign.data)" class="tool">
										<p-button
											v-tooltip.top="'Edit Campaign Template'"
											@click="
												$router.push({
													path: `${$route.path}/${campaign.data.id}`,
												})
											"
										>
											<template #icon>
												<icon type="square-edit-outline" size="24px" />
											</template>
										</p-button>
									</div>
									<div class="tool">
										<p-button
											v-tooltip.top="'Duplicate Campaign Template'"
											@click="
												$router.push({
													path: `${$route.path}/new`,
													query: {
														duplicate: campaign.data.id,
													},
												})
											"
										>
											<template #icon>
												<icon type="content-copy" size="24px" />
											</template>
										</p-button>
									</div>
									<div class="tool">
										<p-button
											v-if="campaign.data.status !== 'archived'"
											v-tooltip.top="'Archive Campaign Template'"
											aria-label="Archive"
											@click="handleStatusUpdate({ value: 'archived' }, campaign.data.id)"
										>
											<template #icon>
												<icon type="archive" size="20px" />
											</template>
										</p-button>
										<p-button
											v-else
											v-tooltip.top="'Un-Archive Campaign Template'"
											aria-label="Edit"
											@click="handleStatusUpdate({ value: 'paused' }, campaign.data.id)"
										>
											<template #icon>
												<icon type="archive-off" size="20px" />
											</template>
										</p-button>
									</div>
									<div class="tool">
										<p-button
											v-tooltip.top="'Copy Template to Children Accounts'"
											@click="$refs.copy_template_modal.openModal(campaign.data.id, campaign.data.name)"
										>
											<template #icon>
												<icon type="expand-all-outline" size="24px" />
											</template>
										</p-button>
									</div>
								</div>
							</template>
						</p-column>
					</p-data-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep, find, get, groupBy, kebabCase, map, startCase, upperCase } from 'lodash-es';
import { endOfDay, startOfDay } from 'date-fns';
import { formatDate, title, displaySelected, currency, verticalName, phoneFormat } from '@/lib/Filters';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import pColumn from 'primevue/column';
import pTag from 'primevue/tag';
import pSelectButton from 'primevue/selectbutton';
import { getCampaigns, updateCampaign } from '@GQL';
import log from '@/lib/Log';
import { useSessionStore } from '@/stores/session';
import CopyTemplateToChildAccountModal from '../Modals/CopyTemplateToChildAccount.vue';

export default {
	name: 'CampaignsList',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
		pColumn,
		pTag,
		pSelectButton,
		showArchived,
		CopyTemplateToChildAccountModal,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
				},
			},
			local_filter_status: ['active', 'paused'],
			status_options: [
				{ label: 'Off', value: 'paused' },
				{ label: 'On', value: 'active' },
			],
			group_value: 'vertical_id_targeting',
			group_options: [
				{ label: 'Group by Vertical', value: 'vertical_id_targeting' },
				{ label: 'Group by Lead Type', value: 'product_targeting' },
				{ label: 'Group by Bid Type', value: 'bid_type' },
			],
			show_archived: false,
			campaigns: [],
		};
	},
	computed: {
		account_id() {
			if (this.$route.params.account_id) {
				return this.$route.params.account_id;
			} else if (this.$route.params.parent_account_id) {
				return this.$route.params.parent_account_id;
			} else {
				return this.sessionStore.account.id;
			}
		},
		route_name_prefix() {
			const split = this.$route.name.split('_');
			if (split.length > 1) {
				return split[0] + '_';
			}
			return '';
		},
		health_campaigns() {
			return this.campaigns.filter((campaign) => {
				return (
					campaign.vertical_id_targeting === 'health_insurance' &&
					this.local_filter_status.indexOf(campaign.status) > -1
				);
			});
		},
		medicare_campaigns() {
			return this.campaigns.filter((campaign) => {
				return campaign.vertical_id_targeting === 'medicare' && this.local_filter_status.indexOf(campaign.status) > -1;
			});
		},
		groupedCampaignTemplates() {
			let campaigns = cloneDeep(this.campaigns);
			if (!this.show_archived) {
				campaigns = campaigns.filter((campaign) => {
					return campaign.status !== 'archived';
				});
			}

			const campaign_groups = groupBy(campaigns, (campaign) => {
				return startCase(campaign[this.group_value]) + ' Campaign Templates';
			});

			return campaign_groups;
		},
	},
	async mounted() {
		this.getCampaignTemplates(true);
	},
	methods: {
		title,
		currency,
		formatDate,
		displaySelected,
		verticalName,
		kebabCase,
		startCase,
		upperCase,
		phoneFormat,
		map,
		isLocked(campaign) {
			return !this.$root.sessionStore.isAdminUser && get(campaign.flags, 'lock.enabled', false);
		},
		async archiveCampaign(id) {
			this.loading = true;
			const found = find(this.campaigns, { id });
			try {
				await updateCampaign(id, { status: 'archive' });
				found.status = 'archive';
				this.$toast.add({
					severity: 'success',
					summary: 'Campaign has been archived',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update status',
				});
			} finally {
				this.loading = false;
				await this.getCampaignTemplates(true);
			}
		},
		async unarchive(id) {
			this.loading = true;
			const found = find(this.campaigns, { id });
			try {
				await updateCampaign(id, { status: 'paused' });
				found.status = 'paused';
				this.$toast.add({
					severity: 'success',
					summary: 'Campaign has been un-archived',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update status',
				});
			} finally {
				this.loading = false;
			}
		},
		// async deleteCampaign(id) {
		// 	try {
		// 		const deleted_campaign = await deleteCampaign(id);
		// 		this.$toast.add({
		// 			severity: 'success',
		// 			summary: `Campaign Template named "${deleted_campaign.name}" was deleted`,
		// 			life: 5000,
		// 		});
		// 	} catch (err) {
		// 		this.$toast.add({
		// 			severity: 'error',
		// 			summary: 'Unable to delete the campaign',
		// 		});
		// 	} finally {
		// 		await this.getCampaignTemplates(true);
		// 	}
		// },
		async handleStatusUpdate({ value }: { value: string }, id: string) {
			this.loading = true;
			const found = find(this.campaigns, { id });
			try {
				await updateCampaign(id, { status: value });
				found.status = value;
				this.$toast.add({
					severity: 'success',
					summary: 'Status Updated',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update status',
				});
			} finally {
				this.loading = false;
			}
		},
		clearQuery() {
			this.loading = false;
		},
		async getCampaignTemplates(refresh?: boolean) {
			this.loading = true;
			// transformfilters and call getCampaigns
			const filters = [
				{
					op: 'eq',
					value: this.account_id,
					field: 'account_id',
				},
			];

			const dated_params = [
				{
					field: 'created_at',
					op: 'gt',
					value: this.query.filters.created_at[0],
				},
				{
					field: 'created_at',
					op: 'lt',
					value: this.query.filters.created_at[1],
				},
			];

			try {
				const results = await getCampaigns([filters], [dated_params], refresh);
				log.trace('Results', results);

				this.campaigns = results;
			} catch (err) {
				log.error('GetCampaign', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Campaigns',
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.create-new-campaign {
	background-color: var(--gray-10);
	border-radius: 10px;
	padding: 30px;

	img {
		opacity: 0.65;
		width: 500px;
	}

	h3 {
		font-size: 2rem;
		font-weight: bold;
	}

	p {
		font-size: 1rem;
	}
}

h2 {
	font-size: 1.25rem;
	margin-bottom: 0.5em;
}

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.flex-group {
	.caption {
		font-size: 0.75rem;
		text-transform: uppercase;
	}
}

.campaign-group {
	margin-bottom: 2em;
}

:deep(.p-selectbutton.campaign-status-switch .p-button) {
	font-size: 0.625rem;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
}

.locked-icon {
	align-items: center;
	cursor: no-drop;
	display: inline-flex;
	height: 30px;
	justify-content: center;
	margin-left: 5px;
	width: 20px;
}

.created-sub,
.purchased-sub {
	color: var(--gray-50);
	font-style: italic;
	margin-top: 0.5rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.p-tag {
	font-size: 0.625rem;
	padding: 0.5em 1em;

	&.exclusive {
		background: var(--teal);
	}

	&.data {
		background: #5cadc4;
	}

	&.call {
		background: var(--purple-400);
	}

	&.night {
		background: var(--purple-600);
	}

	&.weekend {
		background: var(--red-600);
	}

	&.live-transfer {
		background: #82bd41;
	}

	&.choice {
		background: var(--gray-50);
	}

	&.premium {
		background: var(--yellow-600);
	}

	&.advanced {
		background: var(--orange);
	}
}
</style>
