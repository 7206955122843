<template>
	<p-card>
		<template #title>
			<div class="flex align-items-center">
				<icon type="clipboard-account-outline" size="24px" class="mr-2" />
				<span class="name">Lead Details</span>
			</div>
		</template>
		<template #content>
			<h3 class="heading">Contact Information</h3>
			<template
				v-if="!$root.sessionStore.isAdminUser && purchase && ['return_accepted', 'canceled'].includes(purchase.status)"
			>
				<ul class="table-list">
					<li>
						<div class="label">First Name</div>
						<div class="value">UNAVAILABLE</div>
					</li>
					<li>
						<div class="label">Last Name</div>
						<div class="value">UNAVAILABLE</div>
					</li>
					<li>
						<div class="label">Phone</div>
						<div class="value">
							{{ phoneFormat('******' + lead.phone.substring(6, 10)) }}
						</div>
					</li>
					<li>
						<div class="label">Email</div>
						<div class="value">UNAVAILABLE</div>
					</li>
					<li>
						<div class="label">Spoken Language</div>
						<div class="value">
							{{ capitalize(lead.spoken_language) || 'N/A' }}
						</div>
					</li>
					<li>
						<div class="label">Address</div>
						<div class="value">UNAVAILABLE</div>
					</li>
					<li>
						<div class="label">City</div>
						<div class="value">UNAVAILABLE</div>
					</li>
					<li>
						<div class="label">State</div>
						<div class="value">
							{{ lead.state }}
						</div>
					</li>
					<li>
						<div class="label">Zipcode</div>
						<div class="value">UNAVAILABLE</div>
					</li>
				</ul>
				<h3 class="heading">Geographical Information</h3>
				<ul class="table-list">
					<li v-if="lead.county">
						<div class="label">County</div>
						<div class="value">UNAVAILABLE</div>
					</li>
					<li>
						<div class="label">City</div>
						<div class="value">UNAVAILABLE</div>
					</li>
					<li>
						<div class="label">State</div>
						<div class="value">
							{{ lead.state }}
						</div>
					</li>
					<li>
						<div class="label">Zipcode</div>
						<div class="value">UNAVAILABLE</div>
					</li>
				</ul>
				<h3 class="heading">Vertical Information</h3>
				<p>UNAVAILABLE</p>
			</template>
			<template v-else>
				<ul class="table-list">
					<li>
						<div class="label">First Name</div>
						<div class="value">
							{{ lead.first_name }}
						</div>
					</li>
					<li>
						<div class="label">Last Name</div>
						<div class="value">
							{{ lead.last_name }}
						</div>
					</li>
					<li>
						<div class="label">Phone</div>
						<div class="value">
							{{ phoneFormat(lead.phone) }}
						</div>
					</li>
					<li>
						<div class="label">Email</div>
						<div class="value">
							{{ lead.email }}
						</div>
					</li>
					<li>
						<div class="label">Spoken Language</div>
						<div class="value">
							{{ capitalize(lead.spoken_language) || 'N/A' }}
						</div>
					</li>
					<li>
						<div class="label">Address</div>
						<div class="value">{{ lead.street_1 }} {{ lead.street_2 }}</div>
					</li>
					<li>
						<div class="label">City</div>
						<div class="value">
							{{ lead.city }}
						</div>
					</li>
					<li>
						<div class="label">State</div>
						<div class="value">
							{{ lead.state }}
						</div>
					</li>
					<li>
						<div class="label">Zipcode</div>
						<div class="value">
							{{ lead.zip }}
						</div>
					</li>
				</ul>
				<h3 class="heading">Geographical Information</h3>
				<ul class="table-list">
					<li v-if="lead.county">
						<div class="label">County</div>
						<div class="value">
							{{ lead.county }}
						</div>
					</li>
					<li>
						<div class="label">City</div>
						<div class="value">
							{{ lead.city }}
						</div>
					</li>
					<li>
						<div class="label">State</div>
						<div class="value">
							{{ lead.state }}
						</div>
					</li>
					<li>
						<div class="label">Zipcode</div>
						<div class="value">
							{{ lead.zip }}
						</div>
					</li>
				</ul>
				<h3 class="heading">Vertical Information</h3>
				<component :is="verticalData" :lead="lead" />
				<template v-if="is_lt_lead">
					<h3 class="heading">LT Information</h3>
					<ul class="table-list">
						<template v-if="lead.vertical_id === 'health_insurance'">
							<li>
								<div class="label">Affordability</div>
								<div class="value">
									{{ lead.data.can_afford_insurance ? 'Yes' : 'No' }}
								</div>
							</li>
							<li>
								<div class="label">Has Medical Conditions</div>
								<div class="value">
									{{ lead.data.has_medical_condition ? 'Yes' : 'No' }}
								</div>
							</li>
						</template>
						<template v-if="lead.vertical_id === 'medicare'">
							<li>
								<div class="label">Has Medicare Parts A/B</div>
								<div class="value">
									{{ lead.data.has_medicare_parts_a_b ? 'Yes' : 'No' }}
								</div>
							</li>
							<div class="sub-value">*Some states prevent collecting this info</div>
						</template>
					</ul>
				</template>
			</template>
		</template>
	</p-card>
</template>

<script lang="ts">
import { capitalize, phoneFormat } from '@/lib/Filters';
import autoInsuranceDetails from '../Verticals/AutoInsuranceDetails.vue';
import healthInsuranceDetails from '../Verticals/HealthInsuranceDetails.vue';
import medicareDetails from '../Verticals/MedicareDetails.vue';

export default {
	name: 'DetailsPanel',
	components: {
		autoInsuranceDetails,
		healthInsuranceDetails,
		medicareDetails,
	},
	props: {
		lead: {
			type: Object,
		},
		purchase: {
			type: Object,
		},
	},
	computed: {
		verticalData() {
			if (this.lead.vertical_id === 'auto_insurance') {
				return autoInsuranceDetails;
			}
			if (this.lead.vertical_id === 'medicare') {
				return medicareDetails;
			}
			return healthInsuranceDetails;
		},
		is_lt_lead() {
			return this.lead.id.startsWith('T');
		},
	},
	methods: {
		capitalize,
		phoneFormat,
	},
};
</script>
