<template>
	<div id="sources-list" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.source_throttle_config_id ? 'Edit' : 'Create' }} Source Throttle Config</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div>
			<line-loader :show="loading" />
			<div class="content">
				<p-card class="source-card">
					<template #content>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<label class="control-label req" for="source_throttle_config">Source ID:</label>
										<div class="p-inputgroup">
											<div v-if="existing_record" class="p-inputgroup-addon">
												<icon type="lock" />
											</div>
											<p-input-text
												id="source_throttle_config"
												v-model="source_throttle_config.source_id"
												:disabled="!$route.meta.new"
											/>
										</div>
										<div v-if="v$.source_throttle_config.source_id.$error">
											<p
												v-for="error in v$.source_throttle_config.source_id.$errors"
												:key="error.$uid"
												class="validation-error"
											>
												{{ error.$message }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label req" for="">Vertical:</label>
								<div class="controls">
									<div class="field">
										<p-dropdown
											id="vertical_id"
											v-model="source_throttle_config.vertical_id"
											:options="vertical_options"
											placeholder="Select Vertical"
											option-label="label"
											option-value="value"
										/>
										<div v-if="v$.source_throttle_config.vertical_id.$error">
											<p
												v-for="error in v$.source_throttle_config.vertical_id.$errors"
												:key="error.$uid"
												class="validation-error"
											>
												{{ error.$message }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label req" for="product">Product:</label>
								<div class="controls">
									<div class="field">
										<p-dropdown
											id="product"
											v-model="source_throttle_config.product"
											:options="product_type_options"
											placeholder="Select Product"
											option-label="label"
											option-value="value"
										/>
										<div v-if="v$.source_throttle_config.product.$error">
											<p
												v-for="error in v$.source_throttle_config.product.$errors"
												:key="error.$uid"
												class="validation-error"
											>
												{{ error.$message }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<p-fieldset legend="Throttling">
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field fit">
											<p-input-switch input-id="enabled" v-model="source_throttle_config.enabled" />
										</div>
										<div class="field">
											<label class="control-label clickable" for="enabled"><strong>Enable Throttle</strong></label>
										</div>
									</div>
								</div>
							</div>
							<div v-if="source_throttle_config.enabled" class="control-group">
								<div class="inner">
									<div class="controls gap-10">
										<div class="field fit">Allow up to</div>
										<div class="field fit xxs">
											<p-input-number
												v-model="source_throttle_config.limit"
												name="throttling_purchase_input"
												class="input-align-center"
												placeholder="5"
											/>
										</div>
										<div class="field fit">requests every</div>
										<div class="field fit xxs">
											<p-input-number
												v-model="source_throttle_config.interval"
												name="throttling_time_input"
												class="input-align-center"
												placeholder="300"
											/>
										</div>
										<div class="field fit">seconds.</div>
									</div>
								</div>
							</div>
						</p-fieldset>

						<p-fieldset legend="Ping/Post Limits">
							<div class="flex align-items-center justify-content-between">
								<div class="control-group inline">
									<div class="inner">
										<div class="controls">
											<div class="field fit">
												<p-input-switch input-id="enable_max_pings" v-model="source_throttle_config.enable_max_pings" />
											</div>
											<div class="field">
												<label class="control-label clickable" for="enable_max_pings"
													><strong>Enable Max Pings</strong></label
												>
											</div>
										</div>
									</div>
								</div>
								<div v-if="source_throttle_config.enable_max_pings" class="control-group">
									<div class="inner">
										<div class="controls gap-10">
											<div class="field fit">Allow up to</div>
											<div class="field fit xxs">
												<p-input-number
													v-model="source_throttle_config.max_pings"
													name="throttling_purchase_input"
													class="input-align-center"
													placeholder="5"
												/>
											</div>
											<div class="field fit">pings per day</div>
										</div>
									</div>
								</div>
							</div>
							<div class="divider"></div>
							<div class="flex align-items-center justify-content-between">
								<div class="control-group inline">
									<div class="inner">
										<div class="controls">
											<div class="field fit">
												<p-input-switch input-id="enable_max_posts" v-model="source_throttle_config.enable_max_posts" />
											</div>
											<div class="field">
												<label class="control-label clickable" for="enable_max_posts"
													><strong>Enable Max Posts</strong></label
												>
											</div>
										</div>
									</div>
								</div>
								<div v-if="source_throttle_config.enable_max_posts" class="control-group">
									<div class="inner">
										<div class="controls gap-10">
											<div class="field fit">Allow up to</div>
											<div class="field fit xxs">
												<p-input-number
													v-model="source_throttle_config.max_posts"
													name="throttling_purchase_input"
													class="input-align-center"
													placeholder="5"
												/>
											</div>
											<div class="field fit">posts per day</div>
										</div>
									</div>
								</div>
							</div>
						</p-fieldset>
					</template>
					<template #footer>
						<div class="flex justify-content-between align-items-center">
							<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
							<p-button icon="pi pi-check" label="Save Config" @click.prevent="save" />
						</div>
					</template>
				</p-card>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { vertical_options, product_type_options } from '@/lib/Options';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { getSourceThrottleConfigById, insertSourceThrottleConfig, updateSourceThrottleConfig } from '@GQL';
import pTextArea from 'primevue/textarea';
import pChips from 'primevue/chips';
import { camelCase, cloneDeep, merge, omit } from 'lodash-es';
import optionPicklist from '@/components/forms/OptionPicklist.vue';

const default_source = {
	source_id: '', // MAKE SURE THAT THIS IS NOT EDITABLE ON EDIT, ONLY ON INSERT
	vertical_id: '',
	product: '',
	enabled: false,
	limit: null,
	interval: null,
	enable_max_pings: false,
	max_pings: 0,
	enable_max_posts: false,
	max_posts: 0,
};

export default {
	name: 'SourceThrottleConfigEditPage',
	components: {
		pTextArea,
		pChips,
		optionPicklist,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			breadcrumb_items: [
				{ label: 'Source Throttle Configs', route: '/source-throttle-configs' },
				{
					label: this.$route.params.source_throttle_config_id
						? 'Edit Source Throttle Config'
						: 'Create Source Throttle Config',
				},
			],
			existing_record: false,
			product_type_options,
			vertical_options,
			source_throttle_config: cloneDeep(default_source),
			network_options: [],
			user_options: [],
			loading: false,
		};
	},
	validations() {
		return {
			source_throttle_config: {
				source_id: {
					required,
					custom: helpers.withMessage('Value must be camel case', camelCase),
				},
				product: { required },
				vertical_id: { required },
				interval: { required },
				limit: { required },
				enabled: { required },
			},
		};
	},
	async mounted() {
		try {
			this.loading = true;
			// if id exist get it
			if (this.$route.params.source_throttle_config_id) {
				this.source_throttle_config = await getSourceThrottleConfigById(this.$route.params.source_throttle_config_id);
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async save() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				const new_source = cloneDeep(this.source_throttle_config);
				try {
					let result;
					if (this.$route.meta.new) {
						result = await insertSourceThrottleConfig(new_source);
					} else {
						result = await updateSourceThrottleConfig(this.source_throttle_config.id, omit(new_source, 'source_id'));
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully ${this.$route.meta.new ? 'inserted new' : 'updated'} source`,
							detail: `${new_source.source_id}`,
							life: 3000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} source`,
							detail: `${new_source.source_id}`,
							life: 3000,
						});
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} source`,
						detail: `${new_source.source_id}`,
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
label {
	font-size: var(--font-size-sm);
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.sub-value {
	color: var(--gray-50);
	display: inline-block;
	font-size: var(--font-size-sm);
}

.source-card {
	max-width: 800px;
}

.control-group.inline {
	margin: 0;
	margin-top: 10px;
}

.divider {
	border-top: 1px solid var(--gray-10);
	height: 0;
	margin: 1em 0;
	width: 100%;
}
</style>
