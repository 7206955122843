import { $GQL } from '@GQL/index';
import type { User } from '@nextgenleads/auth-driver';

export interface UsersParams {
	mpid: string;
	account_id?: string;
	status?: string;
	team?: string;
	marketplace_for_roles?: string;
	roles?: string[];
}

export async function getUsersList(params: UsersParams, no_cache?: string[], segments?: string[]): Promise<User[]> {
	const query = `
        query Users($params: UsersParams) {
            users(params: $params) {
              id
              first_name
              last_name
              email
              title
              role_id
              status
            }
        }
    `;
	const result = await $GQL.request(query, { params, no_cache, segments });
	return result.users;
}
