import { $GQL } from '@GQL/index';

export async function purchasePhoneNumber(phone: string, sub_account_id: string): Promise<{ phone_number: string }> {
	const mutation = `
        mutation PurchasePhoneNumber($phone: String!, $sub_account_id: String!) {
            purchasePhoneNumber(phone: $phone, sub_account_id: $sub_account_id)
        }
    `;
	const result = await $GQL.request(mutation, { phone, sub_account_id });
	return result.purchasePhoneNumber;
}
