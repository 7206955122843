<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Data Lead Velocity Report</h1>
			<breadcrumb :model="breadcrumbs" />
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getDataLeadVelocity" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}</div>
				</div>
				<div class="display-value">
					<div class="label">Verticals:</div>
					<div class="value">{{ displaySelected(query.vertical_id, $root.appStore.verticalOptions) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Business Units:</div>
					<div class="value">{{ displaySelected(query.business_unit, business_unit_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Products:</div>
					<div class="value">{{ displaySelected(query.product, product_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Source ID:</div>
					<div class="value">{{ query.source_id || 'All' }}</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getDataLeadVelocity(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column max-width="400px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker v-model="form.query.date_range" show-time :date-options="date_quick_options" />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Vertical ID:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.vertical_id"
											:options="$root.appStore.verticalOptions"
											option-label="label"
											option-value="value"
											placeholder="Select verticals"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="250px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Source ID:</label>
								<div class="controls">
									<div class="field">
										<p-input-text v-model="form.query.source_id" />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Business Unit:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.business_unit"
											:options="business_unit_options"
											option-label="label"
											option-value="value"
											placeholder="Select business units"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="250px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Product:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.product"
											:options="product_options"
											option-label="label"
											option-value="value"
											placeholder="Select products"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column></column>
				</row>
			</template>
		</query-form>
		<div class="charts">
			<row class="align-items-stretch flex-charts">
				<column>
					<div class="card padded">
						<div class="card-heading unpadded"><icon type="chart-bar" size="24px" /><strong>Lead Volume</strong></div>
						<p-chart ref="leads_chart" type="bar" :data="leadsChartData" :height="250" :options="chartOptions()" />
					</div>
				</column>
				<gutter size="20px" />
				<column>
					<div class="card padded">
						<div class="card-heading unpadded"><icon type="chart-bar" size="24px" /><strong>Revenue</strong></div>
						<p-chart
							ref="revenue_chart"
							type="bar"
							:data="revenueChartData"
							:height="250"
							:options="chartOptions(true)"
						/>
					</div>
				</column>
				<gutter size="20px" />
				<column>
					<div class="card padded">
						<div class="card-heading unpadded">
							<icon type="chart-bar" size="24px" /><strong>Revenue / Lead</strong>
						</div>
						<p-chart ref="rpl_chart" type="bar" :data="rplChartData" :height="250" :options="chartOptions(true)" />
					</div>
				</column>
			</row>
		</div>
		<gutter size="20px" />
		<div class="metrics-table">
			<div class="card padded">
				<p-data-table :value="metrics" ref="metrics_table" data-key="metric">
					<template #empty>
						<div class="dim">Unable to get data</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<p-column header="Metric" field="metric">
						<template #body="row">
							<strong>{{ row.data.metric }}</strong>
						</template>
					</p-column>
					<p-column header="Last Quarter" class="column-align-right">
						<template #body="row">
							{{
								row.data.metric === 'Leads'
									? numberFormat(row.data.last_quarter.amount)
									: currency(row.data.last_quarter.amount)
							}}
							<div :class="['sub-value', trend(row.data.last_quarter.delta)]">
								{{ percentage(row.data.last_quarter.delta, 2) }}
							</div>
						</template>
					</p-column>
					<p-column header="Last Month" class="column-align-right">
						<template #body="row">
							{{
								row.data.metric === 'Leads'
									? numberFormat(row.data.last_month.amount)
									: currency(row.data.last_month.amount)
							}}
							<div :class="['sub-value', trend(row.data.last_month.delta)]">
								{{ percentage(row.data.last_month.delta, 2) }}
							</div>
						</template>
					</p-column>
					<p-column header="Last Week" class="column-align-right">
						<template #body="row">
							{{
								row.data.metric === 'Leads'
									? numberFormat(row.data.last_week.amount)
									: currency(row.data.last_week.amount)
							}}
							<div :class="['sub-value', trend(row.data.last_week.delta)]">
								{{ percentage(row.data.last_week.delta, 2) }}
							</div>
						</template>
					</p-column>
					<p-column header="Yesterday" class="column-align-right">
						<template #body="row">
							{{
								row.data.metric === 'Leads'
									? numberFormat(row.data.yesterday.amount)
									: currency(row.data.yesterday.amount)
							}}
							<div :class="['sub-value', trend(row.data.yesterday.delta)]">
								{{ percentage(row.data.yesterday.delta, 2) }}
							</div>
						</template>
					</p-column>
					<p-column header="Today" class="column-align-right">
						<template #body="row">
							{{ row.data.metric === 'Leads' ? numberFormat(row.data.today.amount) : currency(row.data.today.amount) }}
							<div class="sub-value">--</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div class="card-heading unpadded" style="gap: 20px">
				<div><strong>Metrics by Source</strong></div>
				<div>
					<p-select-button
						v-model="metric_type"
						:options="metric_type_options"
						option-label="label"
						option-value="value"
					/>
				</div>
			</div>
			<p-data-table
				:value="report_data.sources"
				ref="sources_table"
				data-key="source_id"
				v-model:expandedRows="expanded_source_rows"
				class="velocity-table"
				:sort-field="`${metric_type}_today`"
				:sort-order="-1"
				@row-click="showSourceDetails"
				@row-expand="handleRowExpand"
				@row-collapse="handleRowCollapse"
			>
				<template #empty>
					<div class="dim">No Data</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column expander style="width: 1rem"></p-column>
				<p-column header="Source ID" sortable field="source_id">
					<template #body="row">
						<strong>{{ row.data.source_id }}</strong>
					</template>
				</p-column>
				<p-column header="" class="column-align-right">
					<template #body="row">
						<template v-if="row.data.show_details">
							{{ capitalize(metric_type) }}
							<div class="sub-value">Delta</div>
							<div class="details">
								RPL
								<div class="sub-value">Delta</div>
							</div>
						</template>
					</template>
				</p-column>
				<p-column header="Last Quarter" class="column-align-right" sortable :field="`${metric_type}_last_quarter`">
					<template #body="row">
						<span :class="{ nil: row.data[`${metric_type}_last_quarter`] === 0 }">
							{{
								metric_type === 'leads'
									? numberFormat(row.data[`${metric_type}_last_quarter`])
									: currency(row.data[`${metric_type}_last_quarter`])
							}}
						</span>
						<div
							v-if="row.data.show_details"
							:class="['sub-value', trend(row.data[`${metric_type}_last_quarter_delta`])]"
						>
							{{ percentage(row.data[`${metric_type}_last_quarter_delta`], 2) }}
						</div>
						<div v-if="row.data.show_details" class="details">
							{{ currency(row.data.rpl_last_quarter) }}
							<div :class="['sub-value', trend(row.data.rpl_last_quarter_delta)]">
								{{ percentage(row.data.rpl_last_quarter_delta, 2) }}
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Last Month" class="column-align-right" sortable :field="`${metric_type}_last_month`">
					<template #body="row">
						<span :class="{ nil: row.data[`${metric_type}_last_month`] === 0 }">
							{{
								metric_type === 'leads'
									? numberFormat(row.data[`${metric_type}_last_month`])
									: currency(row.data[`${metric_type}_last_month`])
							}}
						</span>
						<div
							v-if="row.data.show_details"
							:class="['sub-value', trend(row.data[`${metric_type}_last_month_delta`])]"
						>
							{{ percentage(row.data[`${metric_type}_last_month_delta`], 2) }}
						</div>
						<div v-if="row.data.show_details" class="details">
							{{ currency(row.data.rpl_last_month) }}
							<div :class="['sub-value', trend(row.data.rpl_last_month_delta)]">
								{{ percentage(row.data.rpl_last_month_delta, 2) }}
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Last Week" class="column-align-right" sortable :field="`${metric_type}_last_week`">
					<template #body="row">
						<span :class="{ nil: row.data[`${metric_type}_last_week`] === 0 }">
							{{
								metric_type === 'leads'
									? numberFormat(row.data[`${metric_type}_last_week`])
									: currency(row.data[`${metric_type}_last_week`])
							}}
						</span>
						<div v-if="row.data.show_details" :class="['sub-value', trend(row.data[`${metric_type}_last_week_delta`])]">
							{{ percentage(row.data[`${metric_type}_last_week_delta`], 2) }}
						</div>
						<div v-if="row.data.show_details" class="details">
							{{ currency(row.data.rpl_last_week) }}
							<div :class="['sub-value', trend(row.data.rpl_last_week_delta)]">
								{{ percentage(row.data.rpl_last_week_delta, 2) }}
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Yesterday" class="column-align-right" sortable :field="`${metric_type}_yesterday`">
					<template #body="row">
						<span :class="{ nil: row.data[`${metric_type}_yesterday`] === 0 }">
							{{
								metric_type === 'leads'
									? numberFormat(row.data[`${metric_type}_yesterday`])
									: currency(row.data[`${metric_type}_yesterday`])
							}}
						</span>
						<div v-if="row.data.show_details" :class="['sub-value', trend(row.data[`${metric_type}_yesterday_delta`])]">
							{{ percentage(row.data[`${metric_type}_yesterday_delta`], 2) }}
						</div>
						<div v-if="row.data.show_details" class="details">
							{{ currency(row.data.rpl_yesterday) }}
							<div :class="['sub-value', trend(row.data.rpl_yesterday_delta)]">
								{{ percentage(row.data.rpl_yesterday_delta, 2) }}
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Today" class="column-align-right" sortable :field="`${metric_type}_today`">
					<template #body="row">
						<span :class="{ nil: row.data[`${metric_type}_today`] === 0 }">
							{{
								metric_type === 'leads'
									? numberFormat(row.data[`${metric_type}_today`])
									: currency(row.data[`${metric_type}_today`])
							}}
						</span>
						<div v-if="row.data.show_details" class="sub-value">--</div>
						<div v-if="row.data.show_details" class="details">
							{{ currency(row.data.rpl_today) }}
							<div v-if="row.data.show_details" class="sub-value">--</div>
						</div>
					</template>
				</p-column>
				<template #expansion="expanded_row">
					<p-data-table :value="expanded_row.data.sub_id_data" data-key="sub_id" @row-click="showSubIdDetails">
						<template #empty>
							<div class="dim">No Data</div>
						</template>
						<template #loading>
							<line-loader :show="sub_ids_loading" />
						</template>
						<p-column header="Sub ID" sortable field="sub_id">
							<template #body="row">
								<strong>{{ row.data.sub_id }}</strong>
							</template>
						</p-column>
						<p-column header="" class="column-align-right">
							<template #body="row">
								<template v-if="row.data.show_details">
									{{ capitalize(metric_type) }}
									<div class="sub-value">Delta</div>
									<div class="details">
										RPL
										<div class="sub-value">Delta</div>
									</div>
								</template>
							</template>
						</p-column>
						<p-column header="Last Quarter" class="column-align-right" sortable :field="`${metric_type}_last_quarter`">
							<template #body="row">
								<span :class="{ nil: row.data[`${metric_type}_last_quarter`] === 0 }">
									{{
										metric_type === 'leads'
											? numberFormat(row.data[`${metric_type}_last_quarter`])
											: currency(row.data[`${metric_type}_last_quarter`])
									}}
								</span>
								<div
									v-if="row.data.show_details"
									:class="['sub-value', trend(row.data[`${metric_type}_last_quarter_delta`])]"
								>
									{{ percentage(row.data[`${metric_type}_last_quarter_delta`], 2) }}
								</div>
								<div v-if="row.data.show_details" class="details">
									{{ currency(row.data.rpl_last_quarter) }}
									<div :class="['sub-value', trend(row.data.rpl_last_quarter_delta)]">
										{{ percentage(row.data.rpl_last_quarter_delta, 2) }}
									</div>
								</div>
							</template>
						</p-column>
						<p-column header="Last Month" class="column-align-right" sortable :field="`${metric_type}_last_month`">
							<template #body="row">
								<span :class="{ nil: row.data[`${metric_type}_last_month`] === 0 }">
									{{
										metric_type === 'leads'
											? numberFormat(row.data[`${metric_type}_last_month`])
											: currency(row.data[`${metric_type}_last_month`])
									}}
								</span>
								<div
									v-if="row.data.show_details"
									:class="['sub-value', trend(row.data[`${metric_type}_last_month_delta`])]"
								>
									{{ percentage(row.data[`${metric_type}_last_month_delta`], 2) }}
								</div>
								<div v-if="row.data.show_details" class="details">
									{{ currency(row.data.rpl_last_month) }}
									<div :class="['sub-value', trend(row.data.rpl_last_month_delta)]">
										{{ percentage(row.data.rpl_last_month_delta, 2) }}
									</div>
								</div>
							</template>
						</p-column>
						<p-column header="Last Week" class="column-align-right" sortable :field="`${metric_type}_last_week`">
							<template #body="row">
								<span :class="{ nil: row.data[`${metric_type}_last_week`] === 0 }">
									{{
										metric_type === 'leads'
											? numberFormat(row.data[`${metric_type}_last_week`])
											: currency(row.data[`${metric_type}_last_week`])
									}}
								</span>
								<div
									v-if="row.data.show_details"
									:class="['sub-value', trend(row.data[`${metric_type}_last_week_delta`])]"
								>
									{{ percentage(row.data[`${metric_type}_last_week_delta`], 2) }}
								</div>
								<div v-if="row.data.show_details" class="details">
									{{ currency(row.data.rpl_last_week) }}
									<div :class="['sub-value', trend(row.data.rpl_last_week_delta)]">
										{{ percentage(row.data.rpl_last_week_delta, 2) }}
									</div>
								</div>
							</template>
						</p-column>
						<p-column header="Yesterday" class="column-align-right" sortable :field="`${metric_type}_yesterday`">
							<template #body="row">
								<span :class="{ nil: row.data[`${metric_type}_yesterday`] === 0 }">
									{{
										metric_type === 'leads'
											? numberFormat(row.data[`${metric_type}_yesterday`])
											: currency(row.data[`${metric_type}_yesterday`])
									}}
								</span>
								<div
									v-if="row.data.show_details"
									:class="['sub-value', trend(row.data[`${metric_type}_yesterday_delta`])]"
								>
									{{ percentage(row.data[`${metric_type}_yesterday_delta`], 2) }}
								</div>
								<div v-if="row.data.show_details" class="details">
									{{ currency(row.data.rpl_yesterday) }}
									<div :class="['sub-value', trend(row.data.rpl_yesterday_delta)]">
										{{ percentage(row.data.rpl_yesterday_delta, 2) }}
									</div>
								</div>
							</template>
						</p-column>
						<p-column header="Today" class="column-align-right" sortable :field="`${metric_type}_today`">
							<template #body="row">
								<span :class="{ nil: row.data[`${metric_type}_yesterday`] === 0 }">
									{{
										metric_type === 'leads'
											? numberFormat(row.data[`${metric_type}_today`])
											: currency(row.data[`${metric_type}_today`])
									}}
								</span>
								<div v-if="row.data.show_details" class="sub-value">--</div>
								<div v-if="row.data.show_details" class="details">
									{{ currency(row.data.rpl_today) }}
									<div class="sub-value">--</div>
								</div>
							</template>
						</p-column>
					</p-data-table>
				</template>
			</p-data-table>
		</div>
	</div>
</template>

<script>
import { get, merge } from 'lodash-es';
import dayjs from 'dayjs';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import pAutoComplete from 'primevue/autocomplete';
import pChart from 'primevue/chart';
import pColumnGroup from 'primevue/columngroup';
import { capitalize, currency, displaySelected, formatDate, numberFormat, percentage } from '@/lib/Filters';
import { reportSourceDataLeadVelocityBySubId, reportDataLeadVelocity } from '@GQL';

export default {
	name: 'DataLeadVelocity',
	components: {
		dateRangePicker,
		queryForm,
		quickFilter,
		pAutoComplete,
		pChart,
		pColumnGroup,
	},
	data() {
		// Round current time to the nearest 5 minutes
		const minute = Math.floor(dayjs().minute() / 5) * 5;

		return {
			breadcrumbs: [
				{
					label: 'Data Lead Velocity Report',
				},
			],
			loading: false,
			sub_ids_loading: false,
			query: {
				date_range: [dayjs().startOf('day').toDate(), dayjs().minute(minute).second(0).toDate()],
				vertical_id: [],
				product: [],
				business_unit: [],
				source_id: '',
			},
			report_data: {
				leads: {},
				revenue: {},
				rpl: {},
			},
			metric_type: 'revenue',
			expanded_source_rows: {},
			source_id_suggestions: [],
			date_quick_options: [
				{
					icon: 'calendar-today',
					label: 'Today',
					value: [dayjs().startOf('day').toDate(), dayjs().minute(minute).second(0).toDate()],
				},
				{
					icon: 'calendar-today',
					label: 'Yesterday',
					value: [dayjs().subtract(1, 'day').startOf('day').toDate(), dayjs().subtract(1, 'day').endOf('day').toDate()],
				},
				{
					icon: 'calendar-today',
					label: 'This Week',
					value: [dayjs().startOf('isoWeek').toDate(), dayjs().minute(minute).second(0).toDate()],
				},
				{
					icon: 'calendar-today',
					label: 'Last Week',
					value: [
						dayjs().subtract(1, 'week').startOf('isoWeek').toDate(),
						dayjs().subtract(1, 'week').endOf('isoWeek').toDate(),
					],
				},
				{
					icon: 'calendar-today',
					label: 'This Month',
					value: [dayjs().startOf('month').toDate(), dayjs().minute(minute).second(0).toDate()],
				},
				{
					icon: 'calendar-today',
					label: 'Last Month',
					value: [
						dayjs().subtract(1, 'month').startOf('month').toDate(),
						dayjs().subtract(1, 'month').endOf('month').toDate(),
					],
				},
				{
					icon: 'restart-alt',
					label: 'Reset',
					value: 'RESET',
				},
			],
			business_unit_options: [
				{
					label: 'Owned and Operated',
					value: 'OO',
				},
				{
					label: 'Partner Network',
					value: 'PN',
				},
			],
			product_options: [
				{
					label: 'Data Leads',
					value: 'data',
				},
				{
					label: 'Calls',
					value: 'call',
				},
				{
					label: 'Live Transfers',
					value: 'live_transfer',
				},
				{
					label: 'Premium Listings',
					value: 'ad',
				},
			],
			metric_type_options: [
				{
					label: 'Revenue',
					value: 'revenue',
				},
				{
					label: 'Leads',
					value: 'leads',
				},
			],
		};
	},
	computed: {
		metrics() {
			const { revenue, leads, rpl } = this.report_data;
			return [
				{
					metric: 'Leads',
					last_quarter: {
						amount: leads.leads_last_quarter,
						delta: leads.leads_today / leads.leads_last_quarter - 1,
					},
					last_month: {
						amount: leads.leads_last_month,
						delta: leads.leads_today / leads.leads_last_month - 1,
					},
					last_week: {
						amount: leads.leads_last_week,
						delta: leads.leads_today / leads.leads_last_week - 1,
					},
					yesterday: {
						amount: leads.leads_yesterday,
						delta: leads.leads_today / leads.leads_yesterday - 1,
					},
					today: {
						amount: leads.leads_today,
						delta: 0,
					},
				},
				{
					metric: 'Revenue',
					last_quarter: {
						amount: revenue.revenue_last_quarter,
						delta: revenue.revenue_today / revenue.revenue_last_quarter - 1,
					},
					last_month: {
						amount: revenue.revenue_last_month,
						delta: revenue.revenue_today / revenue.revenue_last_month - 1,
					},
					last_week: {
						amount: revenue.revenue_last_week,
						delta: revenue.revenue_today / revenue.revenue_last_week - 1,
					},
					yesterday: {
						amount: revenue.revenue_yesterday,
						delta: revenue.revenue_today / revenue.revenue_yesterday - 1,
					},
					today: {
						amount: revenue.revenue_today,
						delta: 0,
					},
				},
				{
					metric: 'Revenue / Lead',
					last_quarter: {
						amount: rpl.rpl_last_quarter,
						delta: rpl.rpl_today / rpl.rpl_last_quarter - 1,
					},
					last_month: {
						amount: rpl.rpl_last_month,
						delta: rpl.rpl_today / rpl.rpl_last_month - 1,
					},
					last_week: {
						amount: rpl.rpl_last_week,
						delta: rpl.rpl_today / rpl.rpl_last_week - 1,
					},
					yesterday: {
						amount: rpl.rpl_yesterday,
						delta: rpl.rpl_today / rpl.rpl_yesterday - 1,
					},
					today: {
						amount: rpl.rpl_today,
						delta: 0,
					},
				},
			];
		},
		leadsChartData() {
			const documentStyle = getComputedStyle(document.documentElement);

			return {
				labels: ['Last Quarter', 'Last Month', 'Last Week', 'Yesterday', 'Today'],
				datasets: [
					{
						type: 'bar',
						label: 'Leads',
						backgroundColor: documentStyle.getPropertyValue('--green'),
						opacity: 0.5,
						data: [
							get(this.report_data, 'leads.leads_last_quarter', 0),
							get(this.report_data, 'leads.leads_last_month', 0),
							get(this.report_data, 'leads.leads_last_week', 0),
							get(this.report_data, 'leads.leads_yesterday', 0),
							get(this.report_data, 'leads.leads_today', 0),
						],
						options: {
							plugins: {
								legend: false,
							},
						},
					},
				],
			};
		},
		revenueChartData() {
			const documentStyle = getComputedStyle(document.documentElement);

			return {
				labels: ['Last Quarter', 'Last Month', 'Last Week', 'Yesterday', 'Today'],
				datasets: [
					{
						type: 'bar',
						label: 'Revenue',
						backgroundColor: documentStyle.getPropertyValue('--green'),
						opacity: 0.5,
						data: [
							get(this.report_data, 'revenue.revenue_last_quarter', 0),
							get(this.report_data, 'revenue.revenue_last_month', 0),
							get(this.report_data, 'revenue.revenue_last_week', 0),
							get(this.report_data, 'revenue.revenue_yesterday', 0),
							get(this.report_data, 'revenue.revenue_today', 0),
						],
						options: {
							plugins: {
								legend: false,
							},
						},
					},
				],
			};
		},
		rplChartData() {
			const documentStyle = getComputedStyle(document.documentElement);

			return {
				labels: ['Last Quarter', 'Last Month', 'Last Week', 'Yesterday', 'Today'],
				datasets: [
					{
						type: 'bar',
						label: 'RPL',
						backgroundColor: documentStyle.getPropertyValue('--green'),
						opacity: 0.5,
						data: [
							get(this.report_data, 'rpl.rpl_last_quarter', 0),
							get(this.report_data, 'rpl.rpl_last_month', 0),
							get(this.report_data, 'rpl.rpl_last_week', 0),
							get(this.report_data, 'rpl.rpl_yesterday', 0),
							get(this.report_data, 'rpl.rpl_today', 0),
						],
						options: {
							plugins: {
								legend: false,
							},
						},
					},
				],
			};
		},
		queryVerticals() {
			return this.query.vertical_ids;
		},
		queryProducts() {
			return this.query.products;
		},
	},
	watch: {
		queryVerticals(new_value, old_value) {
			if (new_value.length !== old_value.length) {
				this.getDataLeadVelocity();
			}
		},
		queryProducts(new_value, old_value) {
			if (new_value.length !== old_value.length) {
				this.getDataLeadVelocity();
			}
		},
	},
	methods: {
		capitalize,
		currency,
		displaySelected,
		formatDate,
		numberFormat,
		percentage,
		clearQuery() {
			this.loading = false;
		},
		async getDataLeadVelocity(refresh) {
			if (refresh) {
				const minute = Math.floor(dayjs().minute() / 5) * 5;
				this.query.date_range[1] = dayjs(this.query.date_range[1]).minute(minute).second(0).toDate();
			}

			this.loading = true;
			const velocity_results = await reportDataLeadVelocity(this.query);
			this.report_data = velocity_results;
			this.loading = false;
			this.expanded_source_rows = [];
		},
		showSourceDetails(event) {
			event.data.show_details = !event.data.show_details;
		},
		async handleRowExpand(event) {
			event.originalEvent.stopPropagation();
			const source_id = event.data.source_id;
			this.sub_ids_loading = true;
			const source_id_data = await reportSourceDataLeadVelocityBySubId(source_id, this.query);
			this.sub_ids_loading = false;
			event.data.sub_id_data = source_id_data;
		},
		async handleRowCollapse(event) {
			event.originalEvent.stopPropagation();
		},
		showSubIdDetails(event) {
			event.data.show_details = !event.data.show_details;
		},
		trend(value) {
			if (Math.abs(value) > 0.05) {
				if (value > 0) return 'trending-up';
				if (value < 0) return 'trending-down';
			}
			return '';
		},
		chartOptions(is_currency = false) {
			const options = {
				plugins: {
					legend: {
						display: false,
					},
				},
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			};

			if (is_currency) {
				merge(options, {
					plugins: {
						tooltip: {
							callbacks: {
								label(context) {
									return `${context.dataset.label}: ${currency(context.dataset.data[context.dataIndex])}`;
								},
							},
						},
					},
					scales: {
						y: {
							ticks: {
								callback(value, index) {
									return currency(value);
								},
							},
						},
					},
				});
			}

			return options;
		},
	},
	mounted() {
		this.getDataLeadVelocity();
	},
};
</script>

<style scoped lang="less">
:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.flex-charts {
	flex-wrap: nowrap;

	@tablet-down ({
		flex-wrap: wrap;
	});
}

.quick-filters {
	margin-bottom: 20px;
}

.details {
	margin-top: 1em;
}

.card-heading strong {
	font-size: 1.125rem;
}

:deep(.nil) {
	color: #f88a7d;
	font-weight: bold;
}
</style>
