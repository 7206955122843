import { $GQL } from '@GQL/index';

export async function getMAutRevenue(
	params: {
		date_range: [string, string];
		pagination: { page_size: number; page: number };
		order: { field: string; desc: boolean };
	},
	refresh?: boolean
) {
	const query = `
        query GetMAutRevenue($params: JSONObject!) {
            getMAutRevenue(params: $params) {
				rows
				row_count
			}
        }
    `;

	const no_cache = refresh ? ['getMAutRevenue'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.getMAutRevenue;
}
