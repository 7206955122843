<template>
	<query-form ref="query_form" v-model:query="query" @apply="updateQuery" @abort="">
		<template #display>
			<div class="display-value">
				<div class="label">Date Range:</div>
				<div class="value">
					{{ formatDate(query.range, 'ddd, MMM DD, YYYY') }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Group By:</div>
				<div class="value">
					{{ simpleDisplayHelper(query.group_by) }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Source Id Match:</div>
				<div class="value">
					{{ query.filters.source_id || 'All' }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Vertical:</div>
				<div class="value">
					{{ displaySelected(query.filters.vertical_id, vertical_options) || 'All' }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Campaign Type:</div>
				<div class="value">
					{{ query.filters.campaign_type.length > 0 ? query.filters.campaign_type.join(', ') : 'Any' }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Sign Up Offer:</div>
				<div class="value">
					{{ simpleDisplayHelper(query.filters.signup_offer, offers_options) }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Account Manager:</div>
				<div class="value">
					{{ simpleDisplayHelper(query.filters.account_manager, userNamesForDisplay) }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Remove Outliers:</div>
				<div class="value">
					{{ query.filters.remove_outliers ? 'Yes' : 'No' }}
				</div>
			</div>
		</template>
		<template #utils>
			<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" @click="emitRefresh" />
		</template>
		<template #form="form">
			<row>
				<column max-width="400px">
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Date Range:</label>
							<div class="controls">
								<div class="field">
									<date-range-picker :max-range="62" v-model="form.query.range" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Group By:</label>
							<div class="controls">
								<div class="field">
									<p-multi-select
										v-model="form.query.group_by"
										:options="group_by_options"
										option-label="label"
										option-value="value"
										showClear
										placeholder="Group By"
									/>
								</div>
							</div>
						</div>
					</div>
				</column>
				<gutter size="20px" />
				<column max-width="400px">
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Source ID Match:</label>
							<div class="controls">
								<div class="field">
									<p-input-text v-model="form.query.filters.source_id" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Primary Vertical:</label>
							<div class="controls">
								<div class="field">
									<p-multi-select
										v-model="form.query.filters.vertical_id"
										:options="vertical_options"
										option-label="label"
										option-value="value"
										showClear
										placeholder="Vertical"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Campaign Type:</label>
							<div class="controls">
								<div class="field">
									<p-multi-select
										v-model="form.query.filters.campaign_type"
										:options="campaign_type_options"
										showClear
										placeholder="Type"
									/>
								</div>
							</div>
						</div>
					</div>
				</column>
				<gutter size="20px" />
				<column>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Sign Up Offer:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="form.query.filters.signup_offer"
										:options="offers_options"
										option-label="label"
										option-value="value"
										showClear
										placeholder="Sign Up Offer"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Account Manager:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="form.query.filters.account_manager"
										display="chip"
										filter
										:options="account_manager_options"
										option-group-label="label"
										option-group-children="items"
										option-label="label"
										option-value="value"
										placeholder="Account Manager"
									/>
									<!--									<p-dropdown-->
									<!--										v-model="form.query.filters.account_manager"-->
									<!--										:options="account_manager_options"-->
									<!--										option-label="label"-->
									<!--										option-value="value"-->
									<!--										showClear-->
									<!--										placeholder="Account Manager"-->
									<!--									/>-->
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<div class="p-checkbox-item flex align-items-center">
												<p-checkbox
													v-model="form.query.filters.remove_outliers"
													input-id="remove_out"
													name="category"
													binary
												/>
												<label for="remove_out">Remove Outliers</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</column>
			</row>
		</template>
	</query-form>
</template>

<script lang="ts">
import { currency, formatDate, displaySelected, title } from '@/lib/Filters';
import queryForm from '@/components/widgets/QueryForm.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import { vertical_options } from '@/lib/Options';
import dayjs from 'dayjs';
import { getOffersAsOptions, getAccountManagersAsOptions } from '@GQL';
const match_type_options = ['LIKE', 'EXACT'];
const campaign_type_options = ['Internal Media Buying', 'Biz Dev', 'FMO', 'Organic', 'Other'];
const group_by_options = [
	{ value: 'date', label: 'Date' },
	{ value: 'week', label: 'Week' },
	{ value: 'month', label: 'Month' },
	{ value: 'source_id', label: 'Source Id' },
	{ value: 'campaign_type', label: 'Campaign Type' },
	{ value: 'offer_name', label: 'Sign Up Offer' },
	{ value: 'vertical_id', label: 'Vertical' },
	{ value: 'account_manager', label: 'Account Manager' },
];

export default {
	name: 'CustomerAcquisitionAnalyzerform',
	components: {
		dateRangePicker,
		queryForm,
		tableTools,
	},
	emits: ['update:query'],
	data() {
		return {
			vertical_options,
			match_type_options,
			campaign_type_options,
			group_by_options,
			offers_options: [],
			account_manager_options: [],
			query: {
				range: [dayjs().startOf('isoWeek').toDate(), dayjs().endOf('day').toDate()],
				filters: {
					vertical_id: [],
					source_id: '',
					campaign_type: [],
					signup_offer: '',
					remove_outliers: false,
					account_manager: '',
				},
				group_by: [],
			},
		};
	},
	computed: {
		userNamesForDisplay() {
			const users = [];
			this.account_manager_options.forEach((arr) => {
				return users.push(...arr.items);
			});
			return users;
		},
	},
	methods: {
		currency,
		formatDate,
		displaySelected,
		emitRefresh() {
			this.$emit('refresh', this.query);
		},
		simpleDisplayHelper(values: string[] | string, display_options = []) {
			if (values === null) {
				return 'All';
			}

			if (display_options.length > 0 && typeof values === 'string') {
				if (values.length === 0) {
					return 'All';
				}
				// do a search
				const find_index = display_options.findIndex((item) => {
					return item.value === values;
				});
				if (find_index > -1) {
					return display_options[find_index].label;
				}
			} else {
				if (values.length === 0) {
					return 'All';
				}
				return values
					.map((item) => {
						return title(item);
					})
					.join(', ');
			}
		},
		updateQuery() {
			this.$emit('update:query', this.query);
		},
		group_by() {
			return this.query.group_by;
		},
		toggleForm() {
			this.$refs.query_form.toggleDrawer();
		},
	},
	async mounted() {
		this.toggleForm();
		this.offers_options = await getOffersAsOptions();
		this.account_manager_options = await getAccountManagersAsOptions();
	},
};
</script>

<style scoped lang="less"></style>
