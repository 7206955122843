<template>
	<div id="lead" class="view-content">
		<div class="title-section">
			<div class="page-heading">
				<h1>Lead Details</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
			<div v-if="loading" class="spinner-container w-full text-center">
				<p-progress-spinner style="display: block; margin: 0 auto" />
			</div>
			<Toolbar
				v-if="!loading && loaded"
				:lead="lead"
				:purchase="purchase"
				:conversion="conversion"
				@updatePurchase="purchase.status = 'return_pending'"
			/>
		</div>
		<p-message v-if="!loading && !validPurchase" :closable="false">
			The lead you are searching for does not exist.
		</p-message>
		<row v-else class="lead-info-container">
			<p-message v-if="!loading && loaded && !lead" severity="info" :closable="false"
				>Could not find details for {{ $route.params.lead_id }}</p-message
			>
			<template v-if="!loading && loaded">
				<column>
					<p-message
						v-if="purchase && purchase.status === 'return_pending'"
						:closable="false"
						severity="warn"
						:pt="{
							root: 'mt-0 mb-4',
						}"
						>This purchase is pending a return</p-message
					>
					<p-message
						v-if="purchase && purchase.status === 'return_accepted'"
						severity="info"
						:closable="false"
						:pt="{
							root: 'mt-0 mb-4',
						}"
						>This lead has been returned</p-message
					>
					<p-message
						v-if="purchase && purchase.status === 'canceled'"
						severity="info"
						:closable="false"
						:pt="{
							root: 'mt-0 mb-4',
						}"
						>This purchase has been canceled</p-message
					>
					<p-message
						v-if="purchase && purchase.status === 'return_rejected'"
						severity="error"
						:closable="false"
						:pt="{
							root: 'mt-0 mb-4',
						}"
						>The return for this lead has been rejected</p-message
					>
					<Overview :lead="lead" :purchase="purchase" :revenue="revenue" />
					<gutter size="2rem" />
					<LeadDetails :lead="lead" :purchase="purchase" />
					<!-- Show meta info here for admin and marketplace managers -->
					<template v-if="!$root.sessionStore.isAccountUser">
						<gutter size="2rem" />
						<MetaDetails :lead="lead" :purchase="purchase" />
					</template>
				</column>
				<gutter size="20px" />
				<column>
					<p-card v-if="purchase">
						<template #title>
							<div class="flex align-items-center">
								<icon type="shopping-outline" size="24px" class="mr-2" />
								<span class="name">Purchase Information</span>
							</div>
						</template>
						<template #content>
							<h3 class="heading">Purchase Details</h3>
							<ul class="table-list">
								<li>
									<div class="label">Date</div>
									<div class="value">
										{{ formatDate(purchase.created_at, 'ddd, MMMM D, YYYY [at] h:mm:ssA') || '' }}<br />
										<div class="sub-value">{{ timeAgo(purchase.created_at) }}</div>
									</div>
								</li>
								<li>
									<div class="label">Price</div>
									<div class="value">
										{{ currency(purchase.price) }}
									</div>
								</li>
								<li v-if="pl">
									<div class="label">Premium Listing</div>
									<div class="value">
										<template v-if="pl.status === 'complete'">
											{{ currency(pl.price) }}
											<icon
												style="display: inline-table"
												type="information-outline"
												v-tooltip.top="
													`Includes $5.00 Premium Listing fee (non-refundable) if returned, you will receive $${purchase.price}`
												"
												size="1em"
											/>
										</template>
										<template v-else-if="pl.status === 'pending'"> {{ currency(pl.price) }} (Pending) </template>
										<template v-else> $0.00 (Failed) </template>
									</div>
								</li>
								<li>
									<div class="label">Product</div>
									<div class="value">
										{{ capitalize(purchase.product) }}
									</div>
								</li>
								<li>
									<div class="label">Campaign</div>
									<div class="value">
										<router-link :to="`/campaigns/${purchase.campaign.id}`">
											{{ purchase.campaign.name }}
										</router-link>
									</div>
								</li>
							</ul>
						</template>
					</p-card>
					<gutter v-if="purchase" size="20px" />
					<SourceDetails :lead="lead" />
					<!-- Show meta info here for account users -->
					<template v-if="$root.sessionStore.isAccountUser">
						<gutter size="2rem" />
						<MetaDetails :lead="lead" />
					</template>
					<gutter size="2rem" />
					<PurchaseHistoryPanel v-if="!$root.sessionStore.isAccountUser" :lead-id="$route.params.lead_id" />
					<gutter size="2rem" />
					<template v-if="$root.sessionStore.isAdminUser">
						<AuctionPanel :lead-id="$route.params.lead_id" />
						<gutter size="2rem" />
					</template>
					<ShippingPanel v-if="!$root.sessionStore.isAccountUser" :lead-id="$route.params.lead_id" />
				</column>
			</template>
		</row>
	</div>
</template>

<script lang="ts">
import Overview from './panels/Overview.vue';
import Toolbar from './LeadToolbar.vue';
import LeadDetails from './panels/Details.vue';
import SourceDetails from './panels/Source.vue';
import MetaDetails from './panels/Meta.vue';
import pMessage from 'primevue/message';
import pProgressSpinner from 'primevue/progressspinner';
import { currency, phoneFormat, formatDate, timeAgo, capitalize } from '@/lib/Filters';
import type { Conversion } from '@nextgenleads/marketplace-driver';
import { getLeadDetail, getConversion, getPurchaseHistory, getDescendantAccounts } from '@GQL';
import { cloneDeep, sumBy } from 'lodash-es';
import log from '@/lib/Log';
import PurchaseHistoryPanel from '@/views/Leads/panels/PurchaseHistory.vue';
import AuctionPanel from '@/views/Leads/panels/Auction.vue';
import ShippingPanel from './panels/Shipping.vue';
export default {
	name: 'DataLeadsReport',
	components: {
		AuctionPanel,
		PurchaseHistoryPanel,
		Toolbar,
		Overview,
		SourceDetails,
		LeadDetails,
		MetaDetails,
		ShippingPanel,
		pProgressSpinner,
		pMessage,
	},
	data() {
		return {
			breadcrumb_items: [],
			loading: true,
			loaded: false,
			lead: null,
			purchase: null,
			pl: null,
			purchases: [],
			conversion: null,
		};
	},
	computed: {
		accountId() {
			if ('account_id' in this.$route.params) {
				return this.$route.params.account_id;
			} else {
				return this.$root.sessionStore.account.id;
			}
		},
		revenue() {
			return sumBy(this.purchases, 'price');
		},
		validPurchase() {
			return this.purchase && ['complete', 'return_rejected', 'return_pending'].includes(this.purchase.status);
		},
	},
	async mounted() {
		this.breadcrumb_items = this.$root.sessionStore.isMarketplaceUser
			? [{ label: 'Leads', route: '/reports/leads' }, { label: this.$route.params.lead_id }]
			: [{ label: 'Purchases', route: '/purchases' }, { label: this.$route.params.lead_id }];

		await this.getPurchaseInfo();
	},
	methods: {
		capitalize,
		currency,
		phoneFormat,
		formatDate,
		timeAgo,
		async getLeadData() {
			this.loading = true;
			try {
				const result = await getLeadDetail(this.$route.params.lead_id);
				this.lead = cloneDeep(result.lead);
				this.purchases = result.purchases;
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get lead',
					detail: err.message,
					life: 6000,
				});
			} finally {
				this.loading = false;
				this.loaded = true;
			}
		},
		async getPurchaseInfo() {
			const filters = [`lead_id = '${this.$route.params.lead_id}'`];
			if (this.$root.sessionStore.isAccountUser) {
				let account_filter = `account_id = '${this.accountId}'`;
				if (this.$root.sessionStore.isParentAccount) {
					// Get all descendants of the account
					const parent_account_id = this.$route.params.parent_account_id || this.accountId;
					this.descendant_ids = (await getDescendantAccounts(parent_account_id, ['descendantAccountsList'])).map(
						(account) => {
							return account.id;
						}
					);
					account_filter = `account_id IN (${this.descendant_ids.map((id) => `'${id}'`).join(',')})`;
					// account_filter += ` OR parent_account_id = '${this.accountId}'`;
				}
				filters.push(`${account_filter}`);
			} else {
				filters.push(`mpid = '${this.$root.appStore.mpid}'`);
			}

			try {
				const { rows } = await getPurchaseHistory({
					filters: [filters],
				});
				// Separate purchase information from Premium Listing portion
				this.purchase = rows.find((row) => row.product !== 'ad');
				// If there is a Premium Listing, display that there is $5 that is not refundable
				const pl = rows.find((row) => row.product === 'ad');
				if (pl) {
					this.pl = pl;
				}
				if (this.purchase) {
					try {
						await this.getLeadData();
						const result: Conversion = await getConversion(this.purchase.id);
						if (result) {
							this.conversion = result;
						}
					} catch (conversion_err) {
						log.trace(conversion_err);
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to get Conversion Info',
							detail: conversion_err,
							life: 3000,
						});
					}
				}
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Something went wrong',
					detail: 'Unable to get Purchase History',
					life: 3000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.lead-info-container {
	max-width: 1200px;
}

.title-section {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 1rem;
	max-width: 1200px;

	h2 {
		margin: 0;
	}

	.tablet-down({
		flex-direction: column;
		align-content: flex-start;
		align-items: flex-start;

		h2 {
			margin-bottom: 0.5em;
		}
	});
}

:deep(.name) {
	font-size: var(--font-size-h4);
}

:deep(h3.heading) {
	color: var(--color-b);
	font-size: 0.875rem;
	font-weight: bold;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}
</style>
