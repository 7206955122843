<template>
	<div id="automatic-optimization-wrapper" class="view-content">
		<div class="page-heading">
			<h1>Automatic Optimization</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<p-button label="New Automatic Optimizer" @click="$router.push({ path: `${route}/new` })" />
			<gutter size="20px" />
			<div class="card padded">
				<p-data-table
					ref="auto_optimizers"
					:loading="loading"
					scrollable
					:value="auto_optimizers"
					v-model:expandedRows="expanded_rows"
					data-key="source_code"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column expander style="width: 1rem"></p-column>
					<p-column field="source_code" header="Source Code" sortField="source_code">
						<template #body="row">
							{{ row.data.source_code }}
						</template>
					</p-column>
					<p-column field="traffic_source" header="Traffic Source" sortField="traffic_source">
						<template #body="row">
							{{ row.data.traffic_source }}
						</template>
					</p-column>
					<template #expansion="expanded_row">
						<p-data-table
							scrollable
							class="optimizer-settings-table"
							:value="expanded_row.data.settings"
							:pt="{
								footer: { class: 'bg-white pl-3' },
							}"
						>
							<template #loading>
								<line-loader :show="true" />
							</template>
							<template #empty>
								<div class="dim">No results</div>
							</template>
							<p-column header="Daypart" field="daypart" class="align-center">
								<template #body="row">
									{{ title(row.data.daypart) }}
								</template>
							</p-column>
							<p-column header="Type" field="type" class="align-center">
								<template #body="row">
									{{ title(row.data.type) }}
								</template>
							</p-column>
							<p-column header="Run Day" field="run_day" class="align-center">
								<template #body="row">
									{{ title(row.data.run_day) }}
								</template>
							</p-column>
							<p-column header="Historical Period" field="historical_period" class="align-center">
								<template #body="row">
									{{ row.data.historical_period.start }} d.a. - {{ row.data.historical_period.end }} d.a. ({{
										row.data.historical_period.weight * 100
									}}%)
								</template>
							</p-column>
							<p-column header="Recent Period" field="recent_period" class="align-center">
								<template #body="row">
									{{ row.data.recent_period.start }} d.a. - {{ row.data.recent_period.end }} d.a. ({{
										row.data.recent_period.weight * 100
									}}%)
								</template>
							</p-column>
							<p-column header="Target ROI" field="target_performance.roi_min" class="align-center">
								<template #body="row">
									{{ 100 * row.data.target_performance.roi_min }}% - {{ 100 * row.data.target_performance.roi_max }}%
								</template>
							</p-column>
							<p-column header="Target Avg. Pos." field="target_performance.avg_pos_min" class="align-center">
								<template #body="row">
									{{ row.data.target_performance.avg_pos_min }} - {{ row.data.target_performance.avg_pos_max }}
								</template>
							</p-column>
							<p-column header="Bid change" field="bid_settings" class="align-center">
								<template #body="row">
									{{ 100 * row.data.bid_settings.standard_bid_change }}% ({{
										100 * row.data.bid_settings.max_mid_change
									}}% max)
								</template>
							</p-column>
							<template #footer>
								<OptimizerSetting :source_code="expanded_row.data.source_code" />
							</template>
						</p-data-table>
					</template>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { title, currency } from '@/lib/Filters';
import OptimizerSetting from './Setting.vue';
export default {
	name: 'AutomaticOptimization',
	components: {
		OptimizerSetting,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Automatic Optimization' }],
			auto_optimizers: [
				{
					source_code: 'NG_HE_TEST',
					traffic_source: 'Ads',
					settings: [
						{
							daypart: 'weekday',
							type: 'channels',
							run_day: 'monday',
							historical_period: {
								start: 6,
								end: 3,
								weight: 0.4,
							},
							recent_period: {
								start: 3,
								end: 4,
								weight: 0.6,
							},
							target_performance: {
								roi_min: 0.5,
								roi_max: 0.8,
								avg_pos_min: 1.5,
								avg_pos_max: 2.3,
							},
							bid_settings: {
								standard_bid_change: 0.15,
								max_mid_change: 0.2,
							},
						},
						{
							daypart: 'weekday',
							type: 'channels',
							run_day: 'monday',
							historical_period: {
								start: '',
								end: '',
								weight: 0.4,
							},
							recent_period: {
								start: '',
								end: '',
								weight: 0.6,
							},
							target_performance: {
								roi_min: 0.5,
								roi_max: 0.8,
								avg_pos_min: 1.5,
								avg_pos_max: 2.3,
							},
							bid_settings: {
								standard_bid_change: 0.15,
								max_mid_change: 0.2,
							},
						},
					],
				},
				{
					source_code: 'NG_HE_MA',
					traffic_source: 'Ads',
					settings: [
						{
							daypart: 'weekday',
							type: 'channels',
							run_day: 'monday',
							historical_period: {
								start: '',
								end: '',
								weight: 0.4,
							},
							recent_period: {
								start: '',
								end: '',
								weight: 0.6,
							},
							target_performance: {
								roi_min: 0.5,
								roi_max: 0.8,
								avg_pos_min: 1.5,
								avg_pos_max: 2.3,
							},
							bid_settings: {
								standard_bid_change: 0.15,
								max_mid_change: 0.2,
							},
						},
					],
				},
			],
			expanded_rows: {},
			loading: false,
		};
	},
	methods: {
		title,
		currency,
	},
	computed: {
		route() {
			return this.$route.path.endsWith('/') ? this.$route.path.slice(0, -1) : this.$route.path;
		},
	},
};
</script>

<style scoped lang="less"></style>
