<template>
	<div id="account-performance-report" class="view-content">
		<div class="page-heading">
			<h1>Marketplace Overview</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div v-if="vendor_performance.length > 0" class="vendor-performance">
			<div class="flex align-items-center justify-content-between">
				<div class="flex align-items-center">
					<div class="quick-filters vertical flex align-items-center">
						<div class="label">Vertical:</div>
						<gutter size="10px" />
						<template v-for="vertical in $root.appStore.verticalOptions" :key="vertical.value">
							<quick-filter
								v-model="query.filters.vertical_id"
								:value="vertical.value"
								allow-multiple
								@toggle="getReportData"
							>
								{{ vertical.label }}
							</quick-filter>
						</template>
					</div>
					<gutter size="40px" />
					<div class="quick-filters product flex align-items-center">
						<div class="label">Product:</div>
						<gutter size="10px" />
						<quick-filter v-model="filters.products" value="data" @toggle="getReportData()" allow-multiple
							>Data Leads</quick-filter
						>
						<quick-filter v-model="filters.products" value="call" @toggle="getReportData()" allow-multiple
							>Calls</quick-filter
						>
						<quick-filter v-model="filters.products" value="live_transfer" @toggle="getReportData()" allow-multiple
							>Live Transfers</quick-filter
						>
						<quick-filter v-model="filters.products" value="ad" @toggle="getReportData()" allow-multiple
							>Ads</quick-filter
						>
					</div>
				</div>
				<div class="flex align-items-center">
					<strong>Compare:</strong>
					<quick-filter v-model="compare_vendor" value="dod">Day</quick-filter>
					<quick-filter v-model="compare_vendor" value="wow">Week</quick-filter>
					<quick-filter v-model="compare_vendor" value="mom">Month</quick-filter>
				</div>
			</div>
			<gutter size="10px" />
			<template v-for="vendor in vendor_performance" :key="vendor.id">
				<div class="vendor card padded">
					<h3>{{ vendor.name }}</h3>
					<row>
						<column>
							<metric
								label="Lead Volume"
								:value="currentMetric(vendor, 'leads')"
								:previous-value="compareMetric(vendor, 'leads')"
								is-number
							/>
						</column>
						<gutter size="20px" />
						<column>
							<metric
								label="Ping Volume"
								:value="currentMetric(vendor, 'pings')"
								:previous-value="compareMetric(vendor, 'pings')"
								is-number
							/>
						</column>
						<gutter size="20px" />
						<column>
							<metric
								label="Lead Purchases"
								:value="currentMetric(vendor, 'purchases')"
								:previous-value="compareMetric(vendor, 'purchases')"
								is-number
							/>
						</column>
						<gutter size="20px" />
						<column>
							<metric
								label="Total Spend"
								:value="currentMetric(vendor, 'spend')"
								:previous-value="compareMetric(vendor, 'spend')"
								is-currency
							/>
						</column>
						<gutter size="20px" />
						<column>
							<metric
								label="CPL"
								:value="currentMetric(vendor, 'cpl')"
								:previous-value="compareMetric(vendor, 'cpl')"
								is-currency
							/>
						</column>
					</row>
					<gutter size="20px" />
					<div class="top-buyers">
						<row>
							<column>
								<h3>Top Buyers {{ capitalize(currentPeriod) }}</h3>
								<p-data-table :value="currentMetric(vendor, 'top_buyers')" :loading="loading">
									<template #loading>
										<line-loader :show="loading" />
									</template>
									<template #empty>
										<div class="dim">No buyers</div>
									</template>
									<p-column header="Account Name">
										<template #body="row">
											<router-link :to="accountLink(row.data.account_id)">{{ row.data.account_name }}</router-link>
											<div class="sub-value">{{ row.data.account_id }}</div>
										</template>
									</p-column>
									<p-column header="Purchases" class="column-align-right">
										<template #body="row">
											{{ numberFormat(row.data.purchases || 0) }}
											<div :class="['sub-value', trendClass(row.data.purchases || 0, row.data.compare_purchases || 0)]">
												<icon style="margin-top: -4px" type="calendar-arrow-left" />
												{{ numberFormat(row.data.compare_purchases || 0) }}
											</div>
										</template>
									</p-column>
									<p-column header="Spend" class="column-align-right">
										<template #body="row">
											{{ currency(row.data.spend || 0) }}
											<div :class="['sub-value', trendClass(row.data.spend, row.data.compare_spend || 0)]">
												<icon style="margin-top: -4px" type="calendar-arrow-left" />
												{{ currency(row.data.compare_spend || 0) }}
											</div>
										</template>
									</p-column>
									<p-column header="CPL" class="column-align-right">
										<template #body="row">
											{{ cpl(row.data.spend, row.data.purchases) }}
											<div
												:class="[
													'sub-value',
													trendClass(
														row.data.spend / row.data.purchases,
														row.data.compare_spend / row.data.compare_purchases
													),
												]"
											>
												<icon style="margin-top: -4px" type="calendar-arrow-left" />
												{{ cpl(row.data.compare_spend, row.data.compare_purchases) }}
											</div>
										</template>
									</p-column>
								</p-data-table>
							</column>
							<gutter size="20px" />
							<column>
								<h3>Top Buyers {{ capitalize(comparePeriod) }}</h3>
								<p-data-table :value="compareMetric(vendor, 'top_buyers')" :loading="loading">
									<template #loading>
										<line-loader :show="loading" />
									</template>
									<template #empty>
										<div class="dim">No buyers</div>
									</template>
									<p-column header="Account Name">
										<template #body="row">
											<router-link :to="accountLink(row.data.account_id)">{{ row.data.account_name }}</router-link>
											<div class="sub-value">{{ row.data.account_id }}</div>
										</template>
									</p-column>
									<p-column header="Purchases" class="column-align-right">
										<template #body="row">
											{{ numberFormat(row.data.purchases || 0) }}
											<div :class="['sub-value', trendClass(row.data.purchases || 0, row.data.compare_purchases || 0)]">
												<icon style="margin-top: -4px" type="calendar-arrow-left" />
												{{ numberFormat(row.data.compare_purchases || 0) }}
											</div>
										</template>
									</p-column>
									<p-column header="Spend" class="column-align-right">
										<template #body="row">
											{{ currency(row.data.spend || 0) }}
											<div :class="['sub-value', trendClass(row.data.spend, row.data.compare_spend || 0)]">
												<icon style="margin-top: -4px" type="calendar-arrow-left" />
												{{ currency(row.data.compare_spend || 0) }}
											</div>
										</template>
									</p-column>
									<p-column header="CPL" class="column-align-right">
										<template #body="row">
											{{ cpl(row.data.spend, row.data.purchases) }}
											<div
												:class="[
													'sub-value',
													trendClass(
														row.data.spend / row.data.purchases,
														row.data.compare_spend / row.data.compare_purchases
													),
												]"
											>
												<icon style="margin-top: -4px" type="calendar-arrow-left" />
												{{ cpl(row.data.compare_spend, row.data.compare_purchases) }}
											</div>
										</template>
									</p-column>
								</p-data-table>
							</column>
						</row>
					</div>
				</div>
			</template>
		</div>
		<p-message v-else severity="error" :closable="false">No leads have been generated today.</p-message>
	</div>
</template>

<script>
import { round } from 'lodash-es';
import { capitalize, currency, numberFormat } from '@/lib/Filters';
import trendClass from '@/lib/Utils/trendClass';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import lineLoader from '@/components/elements/LineLoader.vue';
import { reportMarketplaceOverview } from '@GQL';

export default {
	name: 'MarketplaceOverview',
	components: {
		lineLoader,
		quickFilter,
	},
	data() {
		return {
			breadcrumb_items: [],
			loading: false,
			filters: {
				verticals: [],
				products: [],
			},
			compare_vendor: 'dod',
			vendor_performance: [
				{
					id: 'nextgenleads',
					name: 'NextGen Leads',
					logo_url: '',
					status: 'active',
					today: {
						leads: 0,
						pings: 0,
						purchases: 0,
						spend: 0,
						cpl: 0,
						top_buyers: [],
					},
					yesterday: {
						leads: 0,
						pings: 0,
						purchases: 0,
						spend: 0,
						cpl: 0,
						top_buyers: [],
					},
					this_week: {
						leads: 0,
						pings: 0,
						purchases: 0,
						spend: 0,
						cpl: 0,
						top_buyers: [],
					},
					last_week: {
						leads: 0,
						pings: 0,
						purchases: 0,
						spend: 0,
						cpl: 0,
						top_buyers: [],
					},
					this_month: {
						leads: 0,
						pings: 0,
						purchases: 0,
						spend: 0,
						cpl: 0,
						top_buyers: [],
					},
					last_month: {
						leads: 0,
						pings: 0,
						purchases: 0,
						spend: 0,
						cpl: 0,
						top_buyers: [],
					},
				},
			],
		};
	},
	computed: {
		currentPeriod() {
			if (this.compare_vendor === 'dod') {
				return 'today';
			} else if (this.compare_vendor === 'wow') {
				return 'this_week';
			}
			return 'this_month';
		},
		comparePeriod() {
			if (this.compare_vendor === 'dod') {
				return 'yesterday';
			} else if (this.compare_vendor === 'wow') {
				return 'last_week';
			}
			return 'last_month';
		},
	},
	methods: {
		capitalize,
		currency,
		numberFormat,
		trendClass,
		currentMetric(vendor, metric) {
			if (this.compare_vendor === 'dod') {
				return vendor.today[metric];
			} else if (this.compare_vendor === 'wow') {
				return vendor.this_week[metric];
			}
			return vendor.this_month[metric];
		},
		compareMetric(vendor, metric) {
			if (this.compare_vendor === 'dod') {
				return vendor.yesterday[metric];
			} else if (this.compare_vendor === 'wow') {
				return vendor.last_week[metric];
			}
			return vendor.last_month[metric];
		},
		cpl(current, compare) {
			if (compare > 0) {
				return currency(current / compare);
			}
			return currency(0);
		},
		async getReportData(no_cache) {
			this.loading = true;
			this.vendor_performance = await reportMarketplaceOverview(
				{
					mpid: this.$route.params.mpid || this.$root.appStore.mpid,
					vertical_id: this.filters.verticals,
					product: this.filters.products,
				},
				no_cache
			);
			this.loading = false;
		},
		accountLink(account_id) {
			if (this.$root.appStore.mpid === '$NG') {
				return `/marketplaces/manage/${this.$route.params.mpid}/accounts/manage/${account_id}/reports/account-activity`;
			}
			return `/accounts/manage/${account_id}/reports/account-overview`;
		},
	},
	async mounted() {
		await this.getReportData();
		if (this.$route.params.mpid) {
			this.breadcrumb_items.push(
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/reports/marketplace-overview`,
				}
			);
		} else {
			this.breadcrumb_items.push({
				label: 'Marketplace Overview',
			});
		}
	},
};
</script>

<style scoped lang="less">
.vendor-performance {
	margin-top: 2em;
}

.vendor {
	margin-bottom: 20px;
}

h2 {
	font-size: 1rem;
	margin-bottom: 0;
	text-transform: uppercase;
}

h3 {
	font-size: 1.25rem;
	font-weight: bold;
}
</style>
