import { $GQL } from '@GQL/index';

export async function updateLeadCampaignCost(id: string, cost: number) {
	const mutation = `
        mutation UpdateLeadCampaignCost($id: ID!, $cost: Float!) {
            updateLeadCampaignCost(id: $id, cost: $cost)
        }
    `;

	const result = await $GQL.request(mutation, { id, cost });
	return result.updateLeadCampaignCost;
}
