<template>
	<query-form ref="query_form" v-model:query="query" @apply="updateQuery" @abort="">
		<template #display>
			<div class="display-value">
				<div class="label">Date Range:</div>
				<div class="value">
					{{ formatDate(query.range, 'ddd, MMM DD, YYYY') }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Group By:</div>
				<div class="value">
					{{ simpleDisplayHelper(query.group_by) }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Source ID Match:</div>
				<div class="value">
					{{ query.filters.source_id || 'Any' }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Day of the Week:</div>
				<div class="value">
					{{ query.filters.day_of_the_week || 'All' }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Vertical:</div>
				<div class="value">
					{{ displaySelected(query.filters.vertical_id, vertical_options) || 'All' }}
				</div>
			</div>
			<div class="display-value">
				<div class="label">Business Unit:</div>
				<div class="value">
					{{ query.filters.business_unit.length > 0 ? query.filters.business_unit.join(', ') : 'Any' }}
				</div>
			</div>
		</template>
		<template #utils>
			<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" @click="emitRefresh" />
		</template>
		<template #form="form">
			<row>
				<column max-width="450px">
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Date Range:</label>
							<div class="controls">
								<div class="field">
									<date-range-picker :max-range="62" v-model="form.query.range" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Group By:</label>
							<div class="controls">
								<div class="field">
									<p-multi-select
										v-model="form.query.group_by"
										:options="group_by_options"
										option-label="label"
										option-value="value"
										showClear
										placeholder="Group By"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Source ID Match:</label>
							<div class="controls">
								<div class="field">
									<p-input-text v-model="form.query.filters.source_id" placeholder="Source ID" />
								</div>
							</div>
						</div>
					</div>
				</column>
				<gutter size="20px" />
				<column>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Day of the Week:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="form.query.filters.day_of_the_week"
										:options="day_of_the_week_options"
										placeholder="Day of the Week"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Vertical:</label>
							<div class="controls">
								<div class="field">
									<p-multi-select
										v-model="form.query.filters.vertical_id"
										:options="vertical_options"
										option-label="label"
										option-value="value"
										showClear
										placeholder="Vertical"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Biz Unit:</label>
							<div class="controls">
								<div class="field">
									<p-multi-select
										v-model="form.query.filters.business_unit"
										:options="business_unit_options"
										showClear
										placeholder="Biz Unit"
									/>
								</div>
							</div>
						</div>
					</div>
				</column>
			</row>
		</template>
	</query-form>
</template>

<script lang="ts">
import { currency, formatDate, displaySelected, title } from '@/lib/Filters';
import queryForm from '@/components/widgets/QueryForm.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import { vertical_options } from '@/lib/Options';
import dayjs from 'dayjs';

const match_type_options = ['LIKE', 'EXACT'];
const business_unit_options = ['OO', 'PN', 'CP'];
const day_of_the_week_options = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const group_by_options = [
	{ value: 'date', label: 'Date' },
	{ value: 'week', label: 'Week' },
	{ value: 'month', label: 'Month' },
	{ value: 'source_id', label: 'Source ID' },
	{ value: 'vertical_id', label: 'Vertical' },
	{ value: 'business_unit', label: 'Business Unit' },
];

export default {
	name: 'CampaignAnalyzerForm',
	components: {
		dateRangePicker,
		queryForm,
		tableTools,
	},
	emits: ['update:query'],
	data() {
		return {
			vertical_options,
			match_type_options,
			day_of_the_week_options,
			business_unit_options,
			group_by_options,
			query: {
				range: [dayjs().startOf('isoWeek').toDate(), dayjs().endOf('day').toDate()],
				filters: {
					source_id: '',
					day_of_the_week: '',
					vertical_id: [],
					business_unit: [],
				},
				group_by: [],
			},
		};
	},
	methods: {
		currency,
		formatDate,
		displaySelected,
		emitRefresh() {
			this.$emit('refresh', this.query);
		},
		simpleDisplayHelper(values: string[]) {
			if (values.length === 0) {
				return 'All';
			}
			return values
				.map((item) => {
					return title(item);
				})
				.join(', ');
		},
		updateQuery() {
			this.$emit('update:query', this.query);
		},
		group_by() {
			return this.query.group_by;
		},
	},
	mounted() {},
};
</script>

<style scoped lang="less"></style>
