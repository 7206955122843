<template>
	<div class="shipper-entry">
		<a class="inline-flex align-items-center" href="javascript:void(0)" @click="handleShipmentModal">
			<template v-if="isUSHALMS">
				<span class="account-name">USHA LMS</span>
			</template>
			<template v-else>
				<span class="account-name">{{ shipment.account.name }}</span>
			</template>
			<alt-icon type="open-in-new" size="18px" />
		</a>
		<template v-if="shipment.shipper">
			<div class="sub-value">{{ shipment.shipper?.name }} - {{ shipment.shipper.type }}</div>
		</template>
		<template v-else-if="hasIntegration">
			<div class="sub-value">
				{{ shipment.integration.post_settings.script }}<template v-if="!isUSHALMS"> - Ping/Post</template>
			</div>
		</template>
		<template v-else>
			<div class="sub-value">Email</div>
		</template>
	</div>
	<template v-if="!hasIntegration">
		<p-dialog
			:style="{ width: '50vw' }"
			v-model:visible="shipment_modal_open"
			class="shipment-modal"
			:modal="true"
			:dismissable-mask="true"
		>
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="truck-outline" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">Shipment</span>
				</div>
			</template>
			<h2 v-if="shipment && shipment.shipper">
				{{ shipment.shipper.name || 'Email Shipment' }}
			</h2>
			<ul class="table-list">
				<li v-if="shipment && shipment.shipper">
					<div class="label">Shipper Type</div>
					<div class="value">
						{{ shipment.shipper.type }}
					</div>
				</li>
				<li>
					<div class="label">Status</div>
					<div class="value">
						{{ title(shipment.status) }}
					</div>
				</li>
				<li>
					<div class="label">Account Name</div>
					<div class="value">
						{{ shipment.account.name }}
					</div>
				</li>
				<li>
					<div class="label">Campaign Name</div>
					<div class="value">
						{{ shipment.campaign.name }}
					</div>
				</li>
			</ul>
			<div v-if="shipment.shipper_id.trim() !== 'EMAIL_SHIPPER'" class="p-2">
				<div style="font-size: var(--font-size-sm)" class="font-bold mt-2 mb-2">Request</div>
				<highlightjs autodetect :code="shipment.request" />
			</div>
			<div class="p-2">
				<div style="font-size: var(--font-size-sm)" class="font-bold mt-2 mb-2">Response</div>
				<highlightjs autodetect :code="shipment.response" />
			</div>
		</p-dialog>
	</template>
	<template v-else>
		<p-dialog
			:style="{ width: '50vw' }"
			v-model:visible="shipment_modal_open"
			class="shipment-modal"
			:modal="true"
			:dismissable-mask="true"
		>
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="truck-outline" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">Shipment</span>
				</div>
			</template>
			<ul class="table-list">
				<li>
					<div class="label">Shipper Type</div>
					<div class="value">
						<template v-if="isUSHALMS">USHA LMS</template>
						<template v-else>Ping/Post</template>
					</div>
				</li>
				<li>
					<div class="label">Result</div>
					<div class="value">
						{{ title(shipment.result) }}
					</div>
				</li>
				<li v-if="!isUSHALMS">
					<div class="label">Account Name</div>
					<div class="value">
						{{ shipment.account.name }}
					</div>
				</li>
				<!-- <li>
					<div class="label">Campaign Name</div>
					<div class="value">
						{{ shipment.campaign.name }}
					</div>
				</li> -->
			</ul>
			<div class="p-2">
				<div style="font-size: var(--font-size-sm)" class="font-bold mt-2 mb-2">Request</div>
				<highlightjs autodetect :code="request_content" />
			</div>
			<div class="p-2">
				<div style="font-size: var(--font-size-sm)" class="font-bold mt-2 mb-2">Response</div>
				<highlightjs autodetect :code="shipment.response" />
			</div>
		</p-dialog>
	</template>
</template>

<script lang="ts">
import pDialog from 'primevue/dialog';
import { title } from '@/lib/Filters';
import { has, get } from 'lodash-es';

export default {
	name: 'ShipmentModal',
	components: {
		pDialog,
	},
	props: {
		shipment: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			shipment_modal_open: false,
			request_content: JSON.stringify(this.shipment.request, null, 2),
			response_content: JSON.stringify(this.shipment.response, null, 2),
			editor_options: {
				mode: 'json',
				theme: 'monokai',
			},
		};
	},
	methods: {
		title,
		handleShipmentModal() {
			this.shipment_modal_open = !this.shipment_modal_open;
		},
	},
	computed: {
		hasIntegration() {
			return has(this.shipment, 'integration');
		},
		isUSHALMS() {
			return get(this.shipment, 'integration.post_settings.script', '') === 'USHALMS';
		},
	},
};
</script>

<style lang="less">
.shipper-entry {
	margin-top: -1px;
	.account-name {
		margin-right: 5px;
	}

	.sub-value {
		line-height: 1.35em;
		margin-top: 5px;
	}
}

.shipment-modal {
	max-width: 800px;
}

h2 {
	font-size: 1.25rem;
}
</style>
