export function formatCallDuration(duration: number) {
	if (duration) {
		const rounded_duration = Math.round(duration);
		// from seconds to minutes or hours
		const minutes = Math.floor(rounded_duration / 60);
		const seconds = rounded_duration % 60;

		return `${minutes ? minutes + 'm ' : ''}${seconds}s`;
	}
	return '0s';
}
