<template>
	<p-data-table :value="data" :loading="loading">
		<template #loading>
			<line-loader :show="loading" />
		</template>
		<template #empty>
			<div class="dim">No Results</div>
		</template>
		<p-column
			v-for="col of columns"
			:key="col.field"
			:field="col.field"
			:header="col.header"
			sortable
			:sort-field="col.field"
		>
			<template #body="row">
				<div v-if="col.field === 'revenue'">
					{{ currency(row.data[col.field], 0) }}
				</div>
				<div v-else-if="col.field === 'count'">
					{{ numberFormat(row.data[col.field]) }}
				</div>
				<div v-else-if="col.field === 'rpc'">
					{{ currency(row.data[col.field], 2) }}
				</div>
				<div v-else-if="col.field === 'revenue_percentage'">
					{{ percentage(row.data[col.field], 1) }}
				</div>
				<div v-else>
					{{ row.data[col.field] }}
				</div>
			</template>
		</p-column>
	</p-data-table>
</template>

<script lang="ts">
import { currency, numberFormat, title, percentage } from '@/lib/Filters';

export default {
	name: 'RevenueAnalyzerRevenue',
	data() {
		return {
			base_columns: [
				{ field: 'revenue', header: 'Revenue' },
				{ field: 'count', header: 'Count' },
				{ field: 'rpc', header: 'Rev/Count' },
				{ field: 'revenue_percentage', header: 'Rev %' },
			],
		};
	},
	props: {
		data: {
			type: Array,
		},
		loading: {
			type: Boolean,
		},
		grouping: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	computed: {
		columns() {
			const calculated_columns = this.grouping.map((group) => {
				return { field: group, header: title(group) };
			});
			return [...calculated_columns, ...this.base_columns];
		},
	},
	methods: {
		title,
		currency,
		numberFormat,
		percentage,
	},
};
</script>

<style scoped lang="less"></style>
