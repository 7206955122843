<template>
	<div id="customer-acquisition-analyzer-wrapper" class="view-content">
		<div class="page-heading">
			<h1>Customer Acquisition Analyzer</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<QueryForm ref="query" @update:query="handleUpdateQuery" />
			<div class="card padded">
				<p-data-table :value="summary">
					<p-column field="stat" header="Stat">
						<template #body="row">
							<strong>
								{{ row.data.stat }}
							</strong>
						</template>
					</p-column>
					<p-column field="ltv" header="LTV">
						<template #body="row">
							{{ currency(row.data.ltv, 0) }}
						</template>
					</p-column>
					<p-column field="cost" header="Cost">
						<template #body="row">
							{{ currency(row.data.cost, 0) }}
						</template>
					</p-column>
					<p-column field="profit" header="Profit">
						<template #body="row">
							{{ currency(row.data.profit, 0) }}
						</template>
					</p-column>
					<p-column field="roi" header="ROI">
						<template #body="row">
							{{ percentage(row.data.roi, 0) }}
						</template>
					</p-column>
					<p-column field="signup_count" header="Signups">
						<template #body="row">
							{{ numberFormat(row.data.signup_count) }}
						</template>
					</p-column>
					<p-column field="funded_accounts" header="Funded">
						<template #body="row">
							{{ numberFormat(row.data.funded_accounts) }}
						</template>
					</p-column>
					<p-column field="funded_percentage" header="Funded %">
						<template #body="row">
							{{ percentage(row.data.funded_percentage, 0) }}
						</template>
					</p-column>
					<p-column field="ltv_per_customer" header="LTV / Account">
						<template #body="row">
							{{ currency(row.data.ltv_per_customer, 0) }}
						</template>
					</p-column>
					<p-column field="cpa" header="CPA">
						<template #body="row">
							{{ currency(row.data.cpa, 0) }}
						</template>
					</p-column>
				</p-data-table>
				<gutter size="20px" />
				<p-data-table :value="data" :loading="loading">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No Results</div>
					</template>
					<p-column
						v-for="col in query.group_by"
						:key="col"
						:field="col"
						sortable
						:sort-field="col"
						:header="formatGroupBy(col)"
					>
						<template #body="row">
							<span v-if="col === 'vertical_id'">
								{{ title(row.data.vertical_id) }}
							</span>
							<span v-else>
								{{ get(row.data, col, 'XX') }}
							</span>
						</template>
					</p-column>
					<p-column field="ltv" header="LTV" sortable sort-field="ltv">
						<template #body="row">
							{{ currency(row.data.ltv, 0) }}
						</template>
					</p-column>
					<p-column field="cost" header="Cost" sortable sort-field="cost">
						<template #body="row">
							{{ currency(row.data.cost, 0) }}
						</template>
					</p-column>
					<p-column field="profit" header="Profit" sortable sort-field="profit">
						<template #body="row">
							{{ currency(row.data.profit, 0) }}
						</template>
					</p-column>
					<p-column field="roi" header="ROI" sortable sort-field="roi">
						<template #body="row">
							{{ row.data.roi !== -1 ? percentage(row.data.roi, 0) : '' }}
						</template>
					</p-column>
					<p-column field="signup_count" header="Signups" sortable sort-field="signup_count">
						<template #body="row">
							<div :class="colorCell(row.data.signup_count, minmax.signup_count.min, minmax.signup_count.max)">
								{{ numberFormat(row.data.signup_count) }}
							</div>
						</template>
					</p-column>
					<p-column field="funded_accounts" header="Funded" sortable sort-field="funded_accounts">
						<template #body="row">
							<div :class="colorCell(row.data.funded_accounts, minmax.funded_accounts.min, minmax.funded_accounts.max)">
								{{ numberFormat(row.data.funded_accounts) }}
							</div>
						</template>
					</p-column>
					<p-column field="funded_percentage" header="Funded %" sortable sort-field="funded_percentage">
						<template #body="row">
							<div
								:class="
									colorCell(row.data.funded_percentage, minmax.funded_percentage.min, minmax.funded_percentage.max)
								"
							>
								{{ percentage(row.data.funded_percentage, 0) }}
							</div>
						</template>
					</p-column>
					<p-column field="ltv_per_customer" header="LTV / Account" sortable sort-field="ltv_per_customer">
						<template #body="row">
							<div
								:class="colorCell(row.data.ltv_per_customer, minmax.ltv_per_customer.min, minmax.ltv_per_customer.max)"
							>
								{{ currency(row.data.ltv_per_customer, 0) }}
							</div>
						</template>
					</p-column>
					<p-column field="cpa" header="CPA" sortable sort-field="cpa">
						<template #body="row">
							<div :class="colorCell(row.data.cpa, minmax.cpa.min, minmax.cpa.max)">
								{{ currency(row.data.cpa, 0) }}
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, title, numberFormat, percentage, colorCell } from '@/lib/Filters';
import QueryForm from './Form.vue';
import get from 'lodash/get';
import { getMAutCustomerAcquisitionAnalyzer } from '@GQL';

const group_by_options = [
	{ value: 'date', label: 'Date' },
	{ value: 'week', label: 'Week' },
	{ value: 'month', label: 'Month' },
	{ value: 'source_id', label: 'Source Id' },
	{ value: 'campaign_type', label: 'Campaign Type' },
	{ value: 'offer_name', label: 'Sign Up Offer' },
	{ value: 'vertical_id', label: 'Vertical' },
	{ value: 'account_manager', label: 'Account Manager' },
];

interface Query {
	range: Date[];
	filters: {
		source_code: string;
		vertical_id: string[];
		campaign_type: string;
		signup_offer: string;
		remove_outliers: boolean;
		account_manager: string;
	};
	group_by: string[];
}

export default {
	name: 'CustomerAcquisitionAnalyzer',
	components: {
		QueryForm,
	},
	data() {
		return {
			loading: false,
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Customer Acquisition Analyzer' }],
			group_by_options,
			data: [],
			minmax: {
				signup_count: { min: 0, max: 60 },
				funded_accounts: { min: 0, max: 30 },
				funded_percentage: { min: 0, max: 0.8 },
				ltv_per_customer: { min: 100, max: 400 },
				cpa: { min: 0, max: 100 },
			},
			summary: [],
			query: {
				group_by: [],
			} as Query,
		};
	},
	methods: {
		get,
		title,
		colorCell,
		currency,
		formatDate,
		numberFormat,
		percentage,
		formatGroupBy(group_by) {
			// find the matching value
			const index = this.group_by_options.indexOf((item) => {
				return item.value === group_by;
			});

			if (index > -1) {
				return this.group_by_options[index].label;
			} else {
				return this.title(group_by);
			}
		},
		async handleUpdateQuery(query: Partial<Query>, refresh = false) {
			try {
				this.validateQuery(query);
				this.query = query;
				await this.getData();
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error',
					detail: err.message,
					life: 8000,
				});
			}
		},
		validateQuery(query) {
			if (query.group_by.length === 0) {
				this.$nextTick(() => {
					this.$refs.query.toggleForm();
				});
				throw new Error('Missing Group By');
			}
		},
		async getData() {
			this.loading = true;
			try {
				const response = await getMAutCustomerAcquisitionAnalyzer({
					...this.query,
				});
				this.minmax = get(response, 'metrics.minmax', {});
				this.data = response.rows;
				this.summary = get(response, 'metrics.summary', []);
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		// await this.getData();
	},
};
</script>

<style scoped lang="less">
:deep(.p-datatable .p-datatable-tbody > tr > td:has(.color-cell)) {
	padding: 0;
	.color-cell {
		padding: 0.75rem 1rem;
	}
}
:deep(.p-datatable .p-datatable-tbody > tr > td:has(.color-row)) {
	padding: 0;
	.color-row {
		padding: 0.75rem 1rem;
	}
}
</style>
