<template>
	<p-dialog v-model:visible="show_modal" modal style="max-width: 400px">
		<template #header>
			<strong>Select Sub-Account</strong>
		</template>
		<p>
			You are purchasing <strong>{{ phoneFormat(phone) }}</strong
			>. Please select a sub-account below to add the phone number to...
		</p>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Sub-Account:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="selected_sub_account"
							filter
							:options="sub_account_options"
							option-label="label"
							option-value="value"
							placeholder="Select a Sub-Account"
						/>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<p-button
				label="Select Sub-Account"
				:disabled="purchase_loader"
				:loading="purchase_loader"
				@click="purchasePhone"
			/>
		</template>
	</p-dialog>
</template>

<script>
import pDialog from 'primevue/dialog';
import { getSubAccountsAsOptions, purchasePhoneNumber } from '@GQL';
import { phoneFormat } from '@/lib/Filters';
import { sortBy } from 'lodash-es';

export default {
	name: 'SelectSubAccountModal',
	components: {
		pDialog,
	},
	data() {
		return {
			phone: null,
			show_modal: false,
			sub_account_options: [],
			selected_sub_account: null,
		};
	},
	methods: {
		phoneFormat,
		async open(phone) {
			const sub_accounts = await getSubAccountsAsOptions([[`provider_id = 'twilio'`]]);
			this.sub_account_options = sortBy(sub_accounts, 'label');
			if (phone) {
				this.phone = phone;
				this.show_modal = true;
			}
		},
		async purchasePhone() {
			this.purchase_loader = true;
			try {
				const result = await purchasePhoneNumber(this.phone, this.selected_sub_account);
				if (result.id) {
					this.$router.push(`/phone-numbers/${result.id}`);
					this.$toast.add({
						severity: 'success',
						summary: 'Phone number successfully purchased',
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to complete phone purchase',
						detail: 'Unknown error',
						life: 8000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to complete phone purchase',
					detail: 'Phone number may no longer be available',
					life: 8000,
				});
			} finally {
				this.purchase_loader = false;
				this.show_modal = false;
			}
		},
	},
};
</script>

<style lang="less" scoped></style>
