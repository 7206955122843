import { $GQL } from '@GQL/index';

export async function checkExistingUserPhone(phone: string, id?: String): Promise<Boolean> {
	const query = `
		query CheckExistingUserPhone($phone: String!, $id: ID) {
			userPhoneExists(phone: $phone, id: $id)
		}
	`;

	const result = await $GQL.request(query, { phone, id, no_cache: ['users'] });
	return result.userPhoneExists;
}
