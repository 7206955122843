import { $GQL } from '@GQL';

// Hardly a need to filter, just use quick filters if anything
export async function initVendorSettings(mpid: string, refresh?: boolean) {
	const query = `
        query InitVendorSettings($mpid: String) {
            vendors {
                id
                name
				verticals {
					auto_insurance {
						data
						call
						live_transfer
						ad
					}
					health_insurance {
						data
						call
						live_transfer
						ad
					}
					medicare {
						data
						call
						live_transfer
						ad
					}
				}
				settings {
					bid_margin
					max_return_rate
					allow_cancellations
				}
				status
            }
			settings(paths: ["vendors"], mpid: $mpid)
        }
    `;

	const no_cache = refresh ? ['vendorSettings'] : undefined;

	const request = await $GQL.request(query, {
		mpid,
		no_cache,
	});

	return request;
}
