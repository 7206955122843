<template>
	<div>
		<h2>AMW Dialer IDs</h2>
		<p-dialog v-model:visible="show_modal" modal :dismissable-mask="true" style="width: 400px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
					<strong>Edit AMW Dialer ID</strong>
				</div>
			</template>
			<div class="content">
				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req" for="role-id">Dialer User ID:</label>
									<p-input-text
										v-model="new_dialer_id"
										class="p-inputtext"
										placeholder="Zoom User ID or Dialpad User ID"
									/>
									<div v-if="v$.new_dialer_id.$error" class="validation-error">
										{{ v$.new_dialer_id.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req">Email Address:</label>
									<p-dropdown
										v-model="new_email_address"
										:options="email_address_options"
										option-group-label="label"
										option-group-children="items"
										option-label="label"
										option-value="value"
										placeholder="Account Manager"
									/>
									<div v-if="v$.new_email_address.$error" class="validation-error">
										{{ v$.new_email_address.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<template #footer class="flex justify-content-end">
				<p-button text label="Cancel" @click="cancelChange" />
				<p-button label="Add Change" @click="saveChange" />
			</template>
		</p-dialog>
		<gutter size="20px" />

		<div>
			<p-button label="Add Dialer ID" @click="openModal" />
			<gutter v-if="dirty" size="20px" />
			<div v-if="dirty" class="flex justify-content-between">
				<div>
					<h3 :style="{ color: 'red', 'font-style': 'italic' }">Changes pending, Save to implement</h3>
				</div>
				<div class="flex justify-content-end">
					<p-button label="Reset" text @click="reset" />
					<p-button :disable="hasPendingChange" label="Save" @click="save" />
				</div>
			</div>
		</div>

		<gutter size="20px" />

		<p-data-table ref="dt" :value="amw_dialer_ids" key="key" :loading="loading">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>

			<p-column header="Dialer User ID">
				<template #body="row">
					{{ row.data.key }}
				</template>
			</p-column>
			<p-column header="Email Address">
				<template #body="row">
					{{ row.data.value }}
				</template>
			</p-column>
			<p-column header="Tools">
				<template #body="row">
					<p-button v-tooltip.top="'Edit'" text @click="editValue(row.data)">
						<template #icon>
							<icon type="pencil-box-outline" size="24px" />
						</template>
					</p-button>
					<p-button v-tooltip.top="'Delete'" text @click="removeValue(row.data)">
						<template #icon>
							<icon type="trash-can-outline" size="24px" />
						</template>
					</p-button>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>
<script>
import { getSettings, updateSetting, getUserEmailsAsOptions } from '@GQL';
import { cloneDeep, findIndex } from 'lodash-es';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
export default {
	name: 'AMWDialerIDs',
	emits: ['refresh'],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			show_modal: false,
			loading: false,
			original_map: null,
			amw_dialer_ids: [],
			new_dialer_id: '',
			new_email_address: '',
			email_address_options: [],
			dirty: false,
		};
	},
	computed: {
		hasPendingChange() {
			return this.new_email_address !== '' || this.new_dialer_id !== '';
		},
	},
	async mounted() {
		await this.getSetting();
		const account_manager_params = {
			mpid: '$NG',
			team: 'sales',
			status: 'active',
		};
		this.email_address_options = await getUserEmailsAsOptions(account_manager_params);
	},
	methods: {
		cancelChange() {
			this.new_dialer_id = '';
			this.new_email_address = '';
			this.v$.$reset();
			this.show_modal = false;
		},
		async saveChange() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				this.amw_dialer_ids = [{ key: this.new_dialer_id, value: this.new_email_address }, ...this.amw_dialer_ids];
				this.dirty = true;
				this.cancelChange();
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Cannot save change',
					detail: 'One of the required fields is missing',
					life: 7000,
				});
			}
		},
		async save() {
			try {
				this.loading = true;
				// check that nothhing is pending in the new
				const new_map = {};
				this.amw_dialer_ids.forEach((row) => {
					new_map[row.key] = row.value;
				});
				const settings_result = await updateSetting(
					'amw_dialer_ids',
					{
						data: new_map,
					},
					'$NG',
					true
				);
				this.dirty = false;
				if (settings_result.id === 'amw_dialer_ids') {
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved AMW Dialer ID settings',
						life: 5000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 7000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getSetting() {
			this.loading = true;
			try {
				// only ever global
				const settings_result = await getSettings(['amw_dialer_ids'], '$NG', true);
				this.original_map = cloneDeep(settings_result.settings.amw_dialer_ids);
				this.amw_dialer_ids = Object.keys(this.original_map).map((key) => {
					return {
						key: key,
						value: this.original_map[key],
					};
				});
			} finally {
				this.loading = false;
			}
		},
		reset() {
			this.amw_dialer_ids = Object.keys(this.original_map).map((key) => {
				return {
					key: key,
					value: this.original_map[key],
				};
			});
			this.dirty = false;
			this.cancelChange();
		},
		editValue(row) {
			this.dirty = true;
			this.new_dialer_id = row.key;
			this.new_email_address = row.value;
			this.removeValue(row);
			this.openModal();
		},
		removeValue(row) {
			this.dirty = true;
			this.loading = true;
			const index = findIndex(this.amw_dialer_ids, { key: row.key });
			let copy = cloneDeep(this.amw_dialer_ids);
			copy.splice(index, 1);
			this.amw_dialer_ids = [...copy];
			this.loading = false;
		},
		openModal() {
			this.show_modal = true;
		},
	},
	validations() {
		return {
			new_email_address: {
				required: helpers.withMessage('Email is required.', required),
			},
			new_dialer_id: {
				required: helpers.withMessage('Dialer ID is required.', required),
				length: helpers.withMessage('Zoom ID must be 22 characters or Dialpad ID must be 16 characters', (value) => {
					return value.length === 22 || value.length === 16;
				}),
			},
		};
	},
};
</script>
