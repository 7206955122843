import { get, sortBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { initMarketplaceApp } from '@GQL';
import { useSessionStore } from '@/stores/session';
import { useMarketplaceStore } from '@/stores/marketplace';
import { type ToastMessageOptions } from 'primevue/toast';
import app from '@/App.vue';
import domainMap from '@/lib/Data/subdomains.json';

export const useAppStore = defineStore('app', {
	state() {
		return {
			mpid:
				import.meta.env.VITE_MPID ||
				domainMap[window.location.hostname.split('.')[0].toLowerCase()] ||
				window.location.hostname.split('.')[0].toUpperCase(),
			marketplace: {
				id: '$NG',
				name: 'NextGen Leads',
				settings: {
					logo_url: '/assets/img/logos/ngl-logo.svg',
					favicon_url: '/favicon.png',
					login_bkg_url: '/assets/img/backgrounds/bg-facets.webp',
					theme: 'default',
				},
			},
			settings: {},
			options: {
				verticals: [
					{ label: 'Auto Insurance', value: 'auto_insurance' },
					{ label: 'Health Insurance', value: 'health_insurance' },
					{ label: 'Medicare', value: 'medicare' },
				],
				products: [
					{ label: 'Data Leads', value: 'data' },
					{ label: 'Calls', value: 'call' },
					{ label: 'Live Transfers', value: 'live_transfer' },
					{ label: 'Ads', value: 'ad' },
				],
			},
			show_notifications_drawer: false,
			flash_message: {
				severity: 'info',
				summary: '',
				detail: '',
				life: 3000,
			},
			show_contact_on_signup: true,
		};
	},
	getters: {
		appType(state) {
			const sessionStore = useSessionStore();
			return state.mpid === '$NG' ? 'admin' : sessionStore.role.type;
		},
		isAdminApp(state) {
			return state.mpid === '$NG';
		},
		verticalOptions() {
			const marketplaceStore = useMarketplaceStore();
			const verticals = marketplaceStore.settings.verticals || this.settings.verticals;

			const options = [];
			if (get(verticals, 'auto_insurance.enabled', false)) {
				options.push({ label: 'Auto Insurance', value: 'auto_insurance' });
			}
			if (get(verticals, 'health_insurance.enabled', false)) {
				options.push({ label: 'Health Insurance', value: 'health_insurance' });
			}
			if (get(verticals, 'medicare.enabled', false)) {
				options.push({ label: 'Medicare', value: 'medicare' });
			}
			return options;
		},
	},
	actions: {
		// Initialize the app
		async initialize() {
			const init_result = await initMarketplaceApp(this.mpid);

			if (!this.isAdminApp) {
				document.body.classList.add('marketplace');
				Object.assign(this.marketplace, init_result.marketplace);
				document.querySelector("link[rel~='icon']").href = this.marketplace.settings.favicon_url;
			} else {
				document.body.classList.add('admin');
			}
			Object.assign(this.settings, init_result.settings);
			this.options.marketplaces = sortBy(init_result.marketplace_options, 'label');
			this.options.vendors = sortBy(init_result.vendor_options, 'label');
		},

		verticalSettings() {
			return this.settings.verticals;
		},

		setFlashMessage(params: ToastMessageOptions) {
			Object.assign(this.flash_message, params);
		},

		showFlashMessage() {
			if (this.flash_message.summary) {
				app.toast.add(this.flash_message);
			}
			this.resetFlashMessage();
		},

		resetFlashMessage() {
			this.flash_message = {
				severity: 'info',
				summary: '',
				detail: '',
				life: 3000,
			};
		},
	},
});
