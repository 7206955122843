<template>
	<div class="page-heading">
		<h1>
			{{
				purchases.length > 0 ? `Campaign Purchase Report: ${purchases[0].campaign_name}` : `Campaign Purchase Report`
			}}
		</h1>
		<breadcrumb :items="breadcrumb_items" />
	</div>
	<div id="campaign-purchase-list">
		<query-form v-model:query="query" :loading="loading" @apply="getCampaignPurchaseReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Vertical:</div>
					<div class="value">
						{{ displaySelected(query.filters.vertical_id, appStore.verticalOptions) }}
					</div>
				</div>
				<div v-if="$root.sessionStore.isAdminUser" class="display-value">
					<div class="label">Source Search:</div>
					<div class="value">{{ query.filters.source_search || 'Any' }}</div>
				</div>
				<div class="display-value">
					<div class="label">State:</div>
					<div class="value">
						{{ displaySelected(query.filters.state, state_options) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getCampaignPurchaseReport(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range-picker v-model="form.query.filters.created_at" show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Filter By Vertical:</label>
								<div class="controls">
									<div class="field">
										<div
											v-for="vertical of appStore.verticalOptions"
											:key="vertical.value"
											class="p-checkbox-item flex align-items-center"
										>
											<p-checkbox
												v-model="query.filters.vertical_id"
												:input-id="vertical.value"
												name="category"
												:value="vertical.value"
											/>
											<label :for="vertical.value">{{ vertical.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column v-if="$root.sessionStore.isAdminUser">
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Query By Source ID:</label>
								<div class="controls">
									<div class="field">
										<p-input-text v-model="query.filters.source_search" class="search-query" type="text" />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.state"
											filter
											filter-placeholder="Filter States"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select States"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="metrics">
			<row>
				<div class="column">
					<metric label="Data Lead Purchases" :value="row_count" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Cost Per Lead" :value="revenuePerLead" is-currency inverse-metric />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Cost" :value="revenue" is-currency inverse-metric />
				</div>
			</row>
		</div>
		<gutter size="20px" />
		<div class="flex align-items-center justify-content-between">
			<div>&nbsp;</div>
			<table-tools v-model:show="show_table_tools">
				<p-button text v-tooltip.top="'Re-Ship Purchases'" @click="reshipPurchases()">
					<template #icon>
						<div class="icon-wrapper">
							<icon type="send-variant-outline" size="20px" />
						</div>
					</template>
				</p-button>
			</table-tools>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				ref="dt"
				:loading="loading"
				:rows="100"
				scrollable
				:value="purchases"
				:key="refreshKey"
				data-key="purchase_id"
				v-model:selection="selected_purchases"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column v-if="show_table_tools" selection-mode="multiple" class="column-align-center"></p-column>
				<p-column header="Purchased On">
					<template #body="lead">
						<div class="capture-date">
							{{ formatDate(new Date(lead.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
						</div>
						<div class="sub-value">{{ timeAgo(lead.data.created_at) }}</div>
					</template>
				</p-column>
				<p-column header="Lead Info">
					<template #body="lead">
						<router-link
							:to="
								$root.sessionStore.isParentAccount
									? `/accounts/manage/${lead.data.account_id}/leads/${lead.data.lead_id}`
									: `/leads/${lead.data.lead_id}`
							"
						>
							<strong>{{ lead.data.first_name }} {{ lead.data.last_name }}</strong>
						</router-link>
						<div class="sub-value">{{ lead.data.lead_id }}: {{ phoneFormat(lead.data.phone) }}</div>
					</template>
				</p-column>
				<p-column header="Source">
					<template #body="lead">
						<template v-if="$root.sessionStore.isAdminUser">
							{{ lead.data.vendor_name }}:
							{{ lead.data.product === 'call' ? lead.data.data_source_id || lead.data.source_id : lead.data.source_id }}
							<div class="sub-value">{{ lead.data.sub_id }}</div>
						</template>
						<template v-else>
							{{ lead.data.vendor_name }}
							<div class="sub-value">
								{{
									lead.data.product === 'call'
										? lead.data.data_source_hash || lead.data.source_hash
										: lead.data.source_hash
								}}-{{ lead.data.sub_id_hash }}
							</div>
						</template>
					</template>
				</p-column>
				<p-column header="State" class="column-align-center">
					<template #body="lead">
						{{ lead.data.state }}
					</template>
				</p-column>
				<p-column header="Vertical" class="column-align-center">
					<template #body="lead">
						<vertical-icon :vertical-id="lead.data.vertical_id" size="30px" />
					</template>
				</p-column>
				<p-column header="Product" class="column-align-center">
					<template #body="lead">
						{{ capitalize(lead.data.product) }}
					</template>
				</p-column>
				<p-column v-if="true" header="Add-Ons" class="column-align-center">
					<template #body="lead">
						<div v-if="lead.data.pl_price">
							<div class="sub-value">
								Premium Listing
								<icon
									style="display: inline-table"
									type="information-outline"
									v-tooltip.top="'Includes $5 add-on fee'"
									size="1em"
								/>
							</div>
							<div v-if="lead.data.call_duration && lead.data.call_duration > 0" class="sub-value call-duration">
								Duration: {{ callDuration(lead.data.call_duration) }}
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Price" class="column-align-right">
					<template #body="lead">
						{{ lead.data.price ? currency(lead.data.price + (lead.data.pl_price || 0)) : '--' }}
					</template>
				</p-column>
				<p-column v-if="appStore.appType !== 'admin'" header="Disposition Status" class="column-align-center">
					<template #body="lead">{{ lead.data.disposition }}</template>
				</p-column>
				<p-column v-if="appStore.appType !== 'admin'" header="Disposition" class="column-align-center">
					<template #body="lead">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Disposition'"
									@click="openDispositionModal(lead.data.purchase_id, lead.data.lead_id)"
									aria-label="Disposition"
								>
									<template #icon>
										<icon type="list-status" size="20px" />
									</template>
								</p-button>
							</div>
						</div>
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="row_count" @page="handlePageChange" />
		</div>
		<AuctionModal ref="auction_modal" />
		<DispositionModal ref="disposition_modal" @update="updatePurchaseDisposition($event)" />
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { startCase, map, find } from 'lodash-es';
import { endOfDay, startOfDay, parseISO } from 'date-fns';
import {
	capitalize,
	currency,
	formatDate,
	displaySelected,
	phoneFormat,
	sourceHash,
	subIdHash,
	verticalName,
	timeAgo,
} from '@/lib/Filters';
import AuctionModal from '@/views/Leads/panels/modals/AuctionModal.vue';
import DispositionModal from '@/views/Leads/panels/modals/DispositionModal.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import STATES from '@/lib/Data/states.json';
import { source_type_options } from '@/lib/Options';
import { reportAccountPurchases, shipPurchases } from '@GQL';
import type { FilterParams } from '@nextgenleads/db';

export default {
	name: 'CampaignPurchaseList',
	components: {
		AuctionModal,
		DispositionModal,
		dateRangePicker,
		queryForm,
		tableTools,
		typeIcon,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
					source_type: [],
					source_search: '',
					state: [],
				},
				pagination: {
					page_size: 100,
					page: 0,
				},
			},
			row_count: 0,
			revenue: 0,
			state_options: [...STATES],
			source_type_options,
			purchases: [],
			selected_purchases: [],
			refreshKey: 0,
			show_table_tools: false,
			breadcrumb_items: [{ label: 'Campaigns', route: `/campaigns` }],
		};
	},
	computed: {
		revenuePerLead() {
			if (this.row_count > 0) {
				return this.revenue / this.row_count;
			}
			return 0;
		},
		purchase_path() {
			if (this.marketplaceStore.mpid) {
				return this.$route.path;
			} else {
				return '/purchases';
			}
		},
		...mapStores(useAppStore, useMarketplaceStore, useSessionStore),
	},
	async created() {
		// Force refresh
		await this.getCampaignPurchaseReport(true);
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		displaySelected,
		timeAgo,
		map,
		parseISO,
		phoneFormat,
		sourceHash,
		startCase,
		subIdHash,
		verticalName,
		clearQuery() {
			this.loading = false;
		},
		callDuration(duration) {
			if (duration > 0) {
				const formatted: string[] = [];
				const hours = Math.floor(duration / 3600);
				const minutes = Math.floor((duration - 3600 * hours) / 60);
				const seconds = duration - 3600 * hours - 60 * minutes;
				if (hours > 0) formatted.push(hours.toString() + 'h');
				if (minutes > 0) formatted.push(minutes.toString() + 'm');
				formatted.push(seconds.toString() + 's');
				return formatted.join(' ');
			}
			return false;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getCampaignPurchaseReport();
		},
		async getCampaignPurchaseReport(refresh?: boolean) {
			this.loading = true;

			const query_params = {
				date_range: this.query.filters.created_at,
				filters: {},
				account_id: this.$route.params.account_id || this.$root.sessionStore.user.account_id,
				page: this.query.pagination.page,
				parent: this.$root.sessionStore.isParentAccount && !('account_id' in this.$route.params), // so that child view shows the purchases
			};

			const filters: Record<string, any> = {
				campaign_id: this.$route.params.campaign_id,
			};

			if (this.$root.sessionStore.isAccountUser) {
				filters.status = ['complete', 'return_rejected', 'return_pending'];
			} else {
				filters.status = ['complete', 'return_rejected', 'return_pending', 'return_accepted', 'canceled'];
			}

			if (this.query.filters.vertical_id.length > 0) {
				filters.vertical_id = this.query.filters.vertical_id;
			}

			if (this.query.filters.source_search) {
				filters.source_search = this.query.filters.source_search;
			}

			if (this.query.filters.state.length > 0) {
				filters.state = this.query.filters.state;
			}

			query_params.filters = filters;

			try {
				const report = await reportAccountPurchases(query_params, refresh);
				this.revenue = report.metrics.revenue;
				this.purchases = report.rows;
				this.row_count = report.row_count;
				if (refresh) {
					this.refreshKey += 1;
				}
				if (this.purchases.length > 0) {
					this.breadcrumb_items.push(
						{ label: this.purchases[0].campaign_name, route: `/campaigns/${this.$route.params.campaign_id}` },
						{ label: 'Campaign Purchases' }
					);
				} else {
					this.breadcrumb_items.push({ label: 'Campaign Purchases' });
				}
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Campaign Purchases',
					life: 3000,
				});
			}
			this.loading = false;
		},
		openAuctionLogModal(lead_id, auction_log_id) {
			this.$refs.auction_modal.open(lead_id, auction_log_id);
		},
		openDispositionModal(purchase_id, lead_id) {
			this.$refs.disposition_modal.open(purchase_id, lead_id);
		},
		toggleTableTools() {
			this.show_table_tools = !this.show_table_tools;
		},
		updatePurchaseDisposition(event) {
			const matching_lead = find(this.purchases, { purchase_id: event.purchase_id });

			if (matching_lead) {
				matching_lead.disposition = event.disposition;
			}
		},
		async reshipPurchases() {
			try {
				if (this.selected_purchases.length > 0) {
					const purchase_ids = this.selected_purchases.map((purchase) => {
						return purchase.purchase_id;
					});
					const ship_result = await shipPurchases(purchase_ids);
					if (ship_result) {
						this.$toast.add({
							severity: 'success',
							summary: 'Shipments Sent',
							detail: 'See shipment logs for details',
							life: 3000,
						});
					} else {
						throw new Error('Unknown error');
					}
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'An Error Occurred',
					detail: err.message,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.created-sub,
.purchased-sub {
	color: var(--gray-50);
	font-style: italic;
	margin-top: 0.5rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.click-target .mdi-icon {
	color: var(--color-b);
	cursor: pointer;
}

.call-duration {
	color: var(--green);
}
</style>
