<template>
	<div class="card padded signup-page-form">
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="signup_page.name" />
						<div v-if="v$.signup_page.name.$error" class="validation-error">
							{{ v$.signup_page.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Description:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="signup_page.description" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">URL Path:</label>
				<div class="controls">
					<div class="field">
						<div class="p-inputgroup">
							<div class="p-inputgroup-addon wide">https://{{ mpid }}.nextgenleads.app/sign-up/</div>
							<p-input-text v-model="signupPagePath" />
						</div>
						<div v-if="v$.signup_page.path.$error" class="validation-error">
							{{ v$.signup_page.path.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Marketing Channel:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="signup_page.channel"
							:options="channel_options"
							option-label="label"
							option-value="value"
							placeholder="Select One"
						/>
						<div v-if="v$.signup_page.channel.$error" class="validation-error">
							{{ v$.signup_page.channel.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Source:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="signup_page.source"
							:options="signup_source_options"
							option-label="label"
							option-value="value"
							placeholder="Select One"
						/>
						<div v-if="v$.signup_page.source.$error" class="validation-error">
							{{ v$.signup_page.source.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Auto-Assign Tags:</label>
				<div class="controls">
					<div class="field">
						<p-chips
							add-on-blur
							v-model="signup_page.tags"
							separator=","
							placeholder="Separate with a comma or by hitting enter"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Parent Account ID:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="signup_page.parent_account_id" />
						<div v-if="v$.signup_page.parent_account_id.$error" class="validation-error">
							{{ v$.signup_page.parent_account_id.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field">
						<label class="control-label" for="am_ids">Assign new sign-ups to account manager(s):</label>
						<p-multi-select
							filter
							id="am_ids"
							v-model="signup_page.settings.round_robin_ams"
							placeholder="Account Manager IDs"
							:options="account_managers"
							option-group-label="label"
							option-group-children="items"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>
		<p-fieldset legend="Partner Contact Info">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="signup_page.contact.name" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Email:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="signup_page.contact.email" />
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<p-fieldset legend="Page Content">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Company name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="signup_page.content.company_name" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Phone Number:</label>
					<div class="controls">
						<div class="field">
							<p-input-mask v-model="signup_page.content.phone" unmask mask="(999) 999-9999" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Body:</label>
					<div class="controls">
						<div class="field">
							<wysiwyg v-model="signup_page.content.body" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Logo:</label>
					<div class="controls">
						<div class="field">
							<image-upload
								v-model="signup_page.content.logo_url"
								:filename="signup_page.path"
								folder="signup-pages/assets"
								mode="advanced"
							/>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<gutter size="20px" />
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Attached Offer:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="signup_page.offer_id"
							:options="offer_options"
							option-label="label"
							option-value="value"
							placeholder="No Offer Selected"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="signup_page.status"
							:options="status_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="form-actions">
			<p-button label="Cancel" text @click="$router.back()" style="margin-right: auto" />
			<p-button label="Save Sign-Up Page" @click="saveSignUpPage">
				<template #icon>
					<div class="icon-wrapper button-icon">
						<icon type="check" size="24px" />
					</div>
				</template>
			</p-button>
		</div>
	</div>
</template>

<script>
import { cloneDeep, kebabCase, merge, sortBy } from 'lodash-es';
import imageUpload from '@/components/forms/ImageUpload.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import {
	getOffersAsOptions,
	getSettings,
	getSignUpPage,
	insertSignUpPage,
	updateSignUpPage,
	getUsersAsOptions,
} from '@/lib/GQL';

const default_signup_page = {
	name: '',
	description: null,
	path: null,
	channel: null,
	source: null,
	tags: [],
	parent_account_id: null,
	settings: {
		round_robin_ams: [],
	},
	contact: {
		name: null,
		email: null,
	},
	content: {
		company_name: null,
		phone: null,
		body: '',
		logo_url: null,
	},
	offer_id: null,
	status: 'active',
};

export default {
	name: 'SignUpPageForm',
	components: {
		imageUpload,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			signup_page: cloneDeep(default_signup_page),
			channel_options: [
				{ label: 'Ad Campaign', value: 'Ad Campaign' },
				{ label: 'Business Development', value: 'Business Development' },
				{ label: 'Cold Calling/List Dialing', value: 'Cold Calling/List Dialing' },
				{ label: 'Inbound', value: 'Inbound' },
				{ label: 'Organic', value: 'Organic' },
				{ label: 'Other', value: 'Other' },
				{ label: 'Referral', value: 'Referral' },
				{ label: 'Social Network', value: 'Social Network' },
				{ label: 'USHA', value: 'USHA' },
			],
			signup_source_options: [],
			account_managers: [],
			offer_options: [
				{
					label: 'None',
					value: null,
				},
			],
			status_options: [
				{ label: 'Active', value: 'active' },
				{ label: 'Disabled', value: 'disabled' },
				{ label: 'Archived', value: 'archived' },
			],
		};
	},
	async created() {
		const account_manager_params = {
			mpid: '$NG',
			team: 'sales',
			status: 'active',
		};
		this.account_managers = await getUsersAsOptions(account_manager_params);
	},
	computed: {
		mpid() {
			return (this.$route.params.mpid || this.$root.appStore.mpid).toLowerCase();
		},
		signupPagePath: {
			get() {
				return this.signup_page.path;
			},
			set(new_value) {
				this.signup_page.path = kebabCase(new_value);
			},
		},
	},
	validations: {
		signup_page: {
			name: {
				required: helpers.withMessage('A name for this sign-up page is required', required),
			},
			path: {
				required: helpers.withMessage('A path for this sign-up page is required', required),
			},
			channel: {
				required: helpers.withMessage('A marketing channel for this sign-up page is required', required),
			},
			source: {
				required: helpers.withMessage('A source for this sign-up page is required', required),
			},
			parent_account_id: {
				format: helpers.withMessage(
					'Invalid format',
					helpers.regex(/^([a-zA-Z0-9]{1,6})-([a-zA-Z0-9]{1,6})-([a-zA-Z0-9]{1,6})$/)
				),
			},
		},
	},
	methods: {
		async getSignUpPage(page_id) {
			const signup_page = await getSignUpPage(page_id);
			if (signup_page) {
				merge(this.signup_page, signup_page);
			}
		},
		async getOffers() {
			const offers_result = await getOffersAsOptions([[`mpid = '${this.mpid.toUpperCase()}'`]]);
			if (offers_result) {
				this.offer_options = this.offer_options.concat(sortBy(offers_result, ['label']));
			}
		},
		async saveSignUpPage() {
			const is_valid = await this.v$.$validate();

			if (is_valid) {
				const new_signup_page = cloneDeep(this.signup_page);
				delete new_signup_page.created_at;

				if (!new_signup_page.parent_account_id) {
					new_signup_page.parent_account_id = null;
				}

				try {
					let save_result;
					if (this.$route.meta.new) {
						new_signup_page.mpid = this.mpid.toUpperCase();
						save_result = await insertSignUpPage(new_signup_page);
					} else {
						save_result = await updateSignUpPage(this.signup_page.id, new_signup_page);
					}
					if (save_result.id) {
						this.$toast.add({
							severity: 'success',
							summary: 'Sign-Up Page Saved',
							detail: 'The sign-up page was successfully saved',
							life: 3000,
						});
						this.$router.back();
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save the Sign-Up Page',
						detail: err.message,
						life: 6000,
					});
				}
			}
		},
	},
	async mounted() {
		const signup_page_id = this.$route.query.duplicate || this.$route.params.signup_page_id || false;
		if (signup_page_id) {
			await this.getSignUpPage(signup_page_id);
			if (this.$route.meta.new) {
				delete this.signup_page.id;
				this.signup_page.name += ' (Copy)';
			}
		}

		await this.getOffers();
		const settings_result = await getSettings(['signup_source_id_options']);
		if (settings_result.settings) {
			Object.keys(settings_result.settings.signup_source_id_options)
				.sort()
				.forEach((signup_source) => {
					this.signup_source_options.push({
						label: signup_source,
						value: signup_source,
					});
				});
		}
	},
};
</script>

<style lang="less" scoped>
.signup-page-form {
	max-width: 960px;
}

.p-inputgroup-addon {
	min-width: auto;
	text-wrap: nowrap;
}
</style>
