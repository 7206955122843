<template>
	<div id="account-form" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Mass Migrate From V3</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card v-if="step === 1" class="account-form-content">
			<template #content>
				<div class="inner">
					<label class="control-label req">Companies:</label>
					<div class="controls">
						<div class="field">
							<div class="field">
								<p-chips
									add-on-blur
									id="tags"
									v-model="companies"
									separator=","
									placeholder="Separate by comma or by hitting enter"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<label class="control-label">Parent Account ID:</label>
						<div class="controls">
							<div class="field">
								<div class="field">
									<p-input-text
										id="company_id"
										v-model="parent_account_id"
										placeholder="Optional Parent Account ID in V4"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<p>Select the MPID for this new account:</p>
				<div class="flex flex-column">
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Marketplace:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="mpid"
										:options="mpid_options"
										:option-label="(option) => `${option.label} - ${option.value}`"
										option-value="value"
										placeholder="Select MPID"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template #footer>
				<div class="flex align-items-center justify-content-end">
					<p-button :loading="loading" label="Start Migration" @click="migrate(false)" />
				</div>
			</template>
		</p-card>
		<p-card v-if="step === 2" class="account-form-content">
			<template #title>Migration Result</template>
			<template #content>
				<ul class="migration-stats" style="columns: 3; font-size: var(--font-size-sm); color: var(--gray-50)">
					<li class="pb-1">
						Companies Count:
						<strong style="color: var(--black)">{{ final_response.companies }}</strong>
					</li>
					<li class="pb-1">
						Accounts Migrated:
						<strong
							style="color: var(--black)"
							:class="{
								'text-red': final_response.data_migrated < final_response.companies,
							}"
							>{{ final_response.data_migrated }} / {{ final_response.companies }}</strong
						>
					</li>
					<li class="pb-1">
						Balances Migrated:
						<strong
							style="color: var(--black)"
							:class="{
								'text-red': final_response.balance_migrated < final_response.companies,
							}"
							>{{ final_response.balance_migrated }} / {{ final_response.companies }}</strong
						>
					</li>
				</ul>
			</template>
		</p-card>
	</div>
</template>
<script lang="ts">
import { migrateAccountData, getMarketplacesAsOptions, massMigrateAccounts } from '@GQL';
import { get } from 'lodash-es';
import Loader from '@/App.vue';

import pChips from 'primevue/chips';

export default {
	name: 'MassMigrateAccountsPage',
	components: {
		Loader,
		pChips,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			breadcrumb_items: [{ label: 'Migrate V3 Account' }],
			companies: [],
			mpid: '',
			parent_account_id: null,
			mpid_options: [],
			step: 1,
			dry_run_response: {},
			final_response: {},
			loading: false,
			base_v4_url: '',
			base_v3_url: '',
			completed_todos: [],
		};
	},
	async mounted() {
		this.mpid_options = await getMarketplacesAsOptions();
		this.base_v4_url = `${window.location.origin}`;
		if (window.location.origin.indexOf('admin.nextgenleads.app') > -1) {
			// we are in production
			this.base_v3_url = `https://admin.nextgenleads.com`;
		} else {
			this.base_v3_url = `https://admin.stage.nextgenleads.com`;
		}
	},
	beforeRouteLeave(to, from, next) {
		if ('v4' in this.final_response) {
			this.$confirm.require({
				group: 'default',
				message: `Are you sure you want to leave this page? You may have unfinished to dos.`,
				header: 'Leaving Migration',
				acceptClass: 'p-button-danger',
				acceptLabel: 'Leave',
				rejectLabel: 'Stay',
				accept: () => {
					next();
				},
				reject: () => {
					next(false);
				},
			});
		} else {
			next();
		}
	},
	methods: {
		get,
		prepareLink(todo: { type: string; v3_id: string; v4_id?: string }, v4 = false) {
			// based on the type we create a different link
			try {
				if (
					todo.type.indexOf('campaign') > -1 ||
					todo.type.indexOf('channel_targeting') > -1 ||
					todo.type === 'premium_listing_type_mismatch'
				) {
					if (v4) {
						if ('v4_id' in todo) {
							if (get(this.final_response, 'data.v4_account.is_parent', false)) {
								return `${this.base_v4_url}/marketplaces/manage/${this.final_response.mpid}/parent-accounts/${this.final_response.v4_account_id}/campaign-templates/${todo.v4_id}`;
							} else {
								return `${this.base_v4_url}/marketplaces/manage/${this.final_response.mpid}/accounts/manage/${this.final_response.v4_account_id}/campaigns/${todo.v4_id}`;
							}
						}
					} else {
						return `${this.base_v3_url}/buyers/${this.dry_run_response.company_id}/campaigns/${todo.v3_id}/edit`;
					}
				} else if (todo.type.indexOf('shipper') > -1) {
					if (v4) {
						if ('v4_id' in todo) {
							return `${this.base_v4_url}/marketplaces/manage/${this.final_response.mpid}/accounts/manage/${this.final_response.v4_account_id}/shippers/${todo.v4_id}`;
						}
					} else {
						return `${this.base_v3_url}/buyers/${this.dry_run_response.company_id}/shippers/${todo.v3_id}/edit`;
					}
				} else {
					return '_no_link';
				}
			} catch (err) {
				return '_no_link';
			}
		},
		async migrate(dry = true) {
			this.loading = true;
			// this runs with dry = true
			try {
				const params = {
					companies: this.companies,
					mpid: this.mpid,
					dry_run: dry,
				};
				if (this.parent_account_id && this.parent_account_id !== '') {
					params.parent_account_id = this.parent_account_id;
				}

				const response = await massMigrateAccounts(params);
				this.final_response = { ...response };
				this.step = 2;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error when trying to migrate accounts',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		markCompleted(index) {
			if (this.completed_todos.includes(index)) {
				this.completed_todos = this.completed_todos.filter((todo) => todo !== index);
			} else {
				this.completed_todos.push(index);
			}
		},
	},
};
</script>

<style lang="less" scoped>
.account-form-content {
	max-width: 1000px;
}

h3 {
	color: var(--color-b);
}

.todo-container {
	padding: 0 1rem;
	border: 1px dashed var(--gray-30);
}

.todo {
	margin: 15px 0;
	padding-bottom: 15px;
	// border: 1px solid var(--gray-30);
	border-bottom: 1px dashed var(--gray-30);
	&:last-of-type {
		border-bottom: none;
	}
}

:deep(.account-stats) {
	columns: 3;
}
</style>
