import { $GQL } from '@GQL';
import dayjs from 'dayjs';

export async function searchLeads(value: string, date_range: [Date, Date], page: number) {
	const formatted_date_range = [dayjs(date_range[0]).format(), dayjs(date_range[1]).format()];

	const query = `
        query SearchLeads($value: String!, $date_range: [String]!, $page: Int) {
            searchLeads(value: $value, date_range: $date_range, page: $page) {
                lead_id
				vendor_lead_id
				source_id
				full_name
				dnc
				phone
				email
				state
				vertical_id
				purchases {
					account_id
					account_name
				}
				created_at
            }
        }
    `;

	const result = await $GQL.request(query, {
		value,
		date_range: formatted_date_range,
		page,
	});
	return result.searchLeads;
}
