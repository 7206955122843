<template>
	<div>
		<loader :show="template_loading" />
		<div v-if="!template_loading && requires_email">
			<div v-if="requires_email" class="control-group large">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<label class="control-label req">Email Template:</label>
							<p-dropdown
								:options="email_template_options"
								v-model="email_template_id"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group large">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<label class="control-label req">Recipient:</label>
							<p-dropdown
								v-model="recipient"
								name="recipient"
								:options="recipient_options"
								option-label="label"
								option-value="value"
							/>
							<div v-if="v$.recipient.$error" class="validation-error">
								{{ v$.recipient.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<gutter v-if="requires_email" size="10px" />

			<div v-if="template && recipient && requires_email">
				<div class="control-group large">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<label class="control-label req">Subject:</label>
								<p-input-text v-model="subject_html" />
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<label class="control-label req">Body:</label>
								<wysiwyg v-model="body_html" advanced />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group">
			<div class="inner">
				<label
					v-if="
						data.disposition === 'Email Response' ||
						data.disposition === 'Email Offer' ||
						data.disposition === 'Email Unsubscribe'
					"
					class="control-label req"
					>Template:</label
				>
				<label v-else class="control-label req">Note:</label>
				<div class="controls">
					<div class="field required">
						<p-text
							v-if="
								data.disposition === 'Email Response' ||
								data.disposition === 'Email Offer' ||
								data.disposition === 'Email Unsubscribe'
							"
							v-model="note"
						/>
						<p-text-area v-else v-model="note" />
						<div v-if="v$.note.$error" class="validation-error">
							{{ v$.note.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { getEmailTemplatesAsOptions, getAccountById, getEmailTemplateById, getUsersList, getSettings } from '@GQL';
import { mapper } from '@/lib/Utils/mapper';
import log from '@/lib/Log';
import pTextArea from 'primevue/textarea';
import pText from 'primevue/inputtext';
export default {
	name: 'EmailNoteComponent',
	components: {
		pText,
		pTextArea,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	props: {
		template_types: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: false,
		},
	},
	emits: ['submit'],
	data() {
		return {
			email_template_id: '',
			note: '',
			body_html: '',
			subject_html: '',
			recipient: '',
			account_id: '',
			users: [],
			requires_email: false,
			template_loading: false,
			recipient_options: [],
			template: false,
			account: {},
			template_dispo_map: {},
			all_email_template_options: [],
			email_template_options: [],
		};
	},
	watch: {
		data: {
			handler: function (new_value, old_value) {
				if ('account_id' in new_value && new_value.account_id !== '') {
					if (this.account_id === '') {
						this.loadRecipientOptions(new_value.account_id);
					}
				}

				if (
					'disposition' in new_value &&
					new_value.disposition !== '' &&
					new_value.disposition !== old_value.disposition
				) {
					this.setTemplateOptions(new_value.disposition);
				}
			},
			deep: true,
		},
		recipient: {
			handler: function (new_value) {
				// this triggers the mapping of the email if we need it
				if (new_value !== '') {
					this.mapTemplate();
				}
			},
			deep: true,
		},
		email_template_id: {
			handler: async function (new_value) {
				// this triggers the mapping of the email if we need it
				if (new_value !== '' || !new_value) {
					if (new_value === 'none') {
						this.recipient = '';
						this.requires_email = false;
						this.template = false;
						this.note = '';
						return;
					} else {
						await this.loadTemplate(new_value);
						if (this.recipient !== '') {
							// map it
							this.mapTemplate();
						}
					}
				}
			},
			deep: true,
		},
	},
	async mounted() {
		// here we are loading in the amw_email_template settings so that we can make the selection dynamic
		const template_map = await getSettings(['amw_email_templates']);
		this.template_dispo_map = template_map.settings.amw_email_templates[this.template_types] || {};
		this.all_email_template_options = await getEmailTemplatesAsOptions([[`status = 'active'`]], 'amw');
		this.all_email_template_options.push({ label: 'No Template (No Email Send)', value: 'none' });
		this.template_dispo_map['Emailed'].push('none');
	},
	methods: {
		mapTemplate() {
			try {
				const user = this.users.filter((row) => {
					return row.email === this.recipient.email;
				})[0];

				const params = {
					account_manager: { ...this.$root.sessionStore.user },
					user,
					account: { ...this.account },
				};
				this.body_html = mapper.compile(this.template.body, params); // add the mapping also...

				this.subject_html = mapper.compile(this.template.subject, params);
			} catch (err) {
				log.error('Failed Mapping', err);
				this.$toast.add({
					severity: 'error',
					summary: `Unable to map the email template. Contact manager for fix`,
					detail: `NAME: '${this.template.name}'  - (${this.template.id}) `,
				});
			}
		},
		async loadRecipientOptions(account_id) {
			this.account_id = account_id;
			// load in the users that we can send emails too!
			// get the users for the account, order with priority first...
			const user_params = {
				account_id: this.account_id,
				mpid: this.account_id.substring(0, 3),
				status: 'active',
			};
			const user_list = await getUsersList(user_params);
			this.users = user_list;
			this.recipient_options = user_list.map((row) => {
				return {
					value: {
						email: row.email,
						name: row.first_name + ' ' + row.last_name,
					},
					label: `${row.first_name} ${row.last_name} (${row.email})`,
				};
			});

			this.account = await getAccountById(this.account_id);
		},

		async setTemplateOptions(disposition) {
			if (disposition in this.template_dispo_map) {
				this.requires_email = true;
				// we need to clear out all sorts of things....
				this.template = false;
				this.recipient = '';
				this.note = '';
				this.email_template_id = '';

				// lets filter the options from the whole list into the
				this.email_template_options = this.all_email_template_options.filter((row) => {
					return this.template_dispo_map[disposition].indexOf(row.value) > -1;
				});
			} else {
				this.recipient = '';
				this.requires_email = false;
				this.template = false;
				this.note = '';
			}
		},

		async loadTemplate(template_id) {
			this.template_loading = true;
			try {
				const { emailTemplate } = await getEmailTemplateById(template_id);
				this.template = emailTemplate;
				this.note = '- Gmail Sent...';
			} catch (err) {
			} finally {
				this.template_loading = false;
			}
		},
		reset() {
			this.users = [];
			this.account = {};
			this.tempalte = false;
			this.account_id = '';
			this.note = '';
			this.requires_email = false;
			this.body_html = '';
			this.recipient = null;
			this.subject_html = '';
			this.recipient_options.slice(0, this.recipient_options.length);
		},
		async getData() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				let to_return;
				// return the note and body etc
				if (this.requires_email) {
					to_return = {
						template_id: this.template.id,
						subject: this.subject_html,
						body: this.body_html,
						to_email: this.recipient.email,
						to_name: this.recipient.name,
						note: this.note,
					};
				} else {
					to_return = {
						note: this.note,
					};
				}

				return to_return;
			}
		},
	},
	validations() {
		return {
			note: {
				required: helpers.withMessage('Text is needed for note', required),
			},
			recipient: {
				required: helpers.withMessage('Recipient is required', (value) => {
					return this.requires_email === false || value !== null;
				}),
			},
		};
	},
};
</script>
