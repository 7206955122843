<template>
	<p-dialog modal v-model:visible="showTermsModal" :close-on-escape="false" :closable="false" style="max-width: 750px">
		<template #header>
			<strong>Terms and Conditions</strong>
		</template>
		<p>Please agree to the following terms before continuing...</p>
		<div class="terms-text">
			<h3>TERMS AND CONDITIONS FOR LEADS MARKETING SERVICES</h3>
			<p>
				THESE TERMS AND CONDITIONS (“TERMS”) ARE A LEGAL, BINDING AGREEMENT BETWEEN NEXTGEN LEADS, LLC, 4110 MISSION
				BOULEVARD SUITE 200, SAN DIEGO, CALIFORNIA, 92109, A LEADS AND MARKETING SERVICES PROVIDER (“WE” OR “NEXTGEN”),
				AND YOU, THE PURCHASER OR RECIPIENT OF MARKETING LEADS OR SERVICES FROM US OR THROUGH THIS WEBSITE (“YOU” OR
				“PURCHASER”). ACCEPTANCE OF THESE TERMS IS A CONDITION TO YOUR USE OF THIS WEBSITE AND ANY LEADS, AND TO OUR
				PROVISION OF THE LEADS AND MARKETING SERVICES TO YOU. YOU ACCEPT THESE TERMS BY CLICKING “ACCEPT” OR SIMILAR
				BUTTON BELOW, WHICH WILL CONSTITUTE YOUR ELECTRONIC SIGNATURE ON THESE TERMS. YOU AGREE THAT EACH INTERACTION
				BETWEEN YOU AND THIS WEBSITE, AND EACH REQUEST FOR OR DELIVERY OF LEADS OR OTHER MARKETING SERVICES IS SUBJECT
				TO THESE TERMS. IF YOU DO NOT AGREE TO ANY OR ALL OF THESE TERMS YOU MAY NOT USE OUR SERVICES OR ACQUIRE LEADS
				OR SERVICES FROM US AND SHOULD NOT ACCESS THIS WEBSITE. THESE TERMS INCLUDE A MANDATORY ARBITRATION PROVISION.
			</p>
			<h3>PURCHASE OF LEADS</h3>
			<p>
				You agree to purchase from us data collected from consumers who complete an online form and submit contact
				information and details about their request for information or service quotes (“leads”) that conform to the
				following guidelines. A lead will include only information that has been input by the individual and the
				following lead contact information as available, unless otherwise agreed to by you and us in writing: (a) first
				and last name, (b) address, (c) email address, and (d) phone number. NextGen makes no representations about the
				quantity, sufficiency or quality of leads that it provides to you.
			</p>

			<p>
				You will be charged a cost per lead stated at the time of the purchase, and fees may vary from purchase to
				purchase. Fees accrue and are due upon your receipt of the leads. You agree to provide us current and valid
				credit card information (type, name, number, expiration date, etc.) and hereby authorize us (directly or through
				our agents) to charge the credit card you have provided in the amount of all or part of the fees payable for the
				leads purchased plus any taxes we may be required to collect. We may require that you fund in advance your
				account with us in an amount determined by us from time to time. You authorize us to make a charge against your
				account pre-funded balance in the amount of the fees for the leads that are delivered to you. Pre-funded
				balances will need to be replenished to maintain the balance at the required minimum amounts established by us
				from time to time. We reserve the right to not deliver leads if there is not a sufficient balance in your
				account to pay for the leads.
			</p>

			<p>
				Purchases of leads are not cancellable, and fees paid are not refundable. You are not authorized to use any
				leads that are not paid for.
			</p>

			<p>
				A lead may be rejected by you for a credit within ten (10) days of receipt if: (i) the lead includes a
				disconnected phone number; (ii) the lead individual is under 18 years of age; (iii) the lead is outside of the
				geography or not for the service expressly requested by you, where applicable, or (iv) the lead is a duplicate
				of another lead we have provided for the same requested services that is generated by the same individual (i.e.,
				the leads consist of the same name, phone number and email address).
			</p>

			<h3>AUTHORIZED USE AND PURPOSE OF LEADS</h3>
			<p>
				Leads and related marketing services are provided on a non-exclusive basis for a specific type of product or
				service for which the consumer submitted the online form (“the specific vertical”), such as for example,
				healthcare insurance. You are authorized to use the leads and our services provided by us solely for your
				internal marketing purposes of proposing only your products and services in the specific vertical to the
				consumer. You may not use the lead or services for purposes of marketing or offering products and services other
				than those that the consumer has specifically inquired about, or of third parties.
			</p>

			<p>
				Leads and services are confidential information of NextGen. YOU AGREE NOT TO SHARE WITH OTHERS, LICENSE, RESELL,
				DISTRIBUTE OR OTHERWISE DISCLOSE ANY OR ALL OF THE LEADS OR SERVICES OR THE INFORMATION CONTAINED IN THEM, ALONE
				OR AS PART OF A LIST OR ANY OTHER DATA OR LEAD AGGREGATION, TO ANY THIRD PARTY FOR ANY PURPOSE, EXCEPT ONLY TO
				AN ENTITY ENGAGED BY YOU AS A CONTRACTOR FOR THE SOLE PURPOSE OF ASSISTING YOU IN CONNECTION WITH THE AUTHORIZED
				USE OF THE LEADS BY YOU.
			</p>

			<h3>COMPLIANCE</h3>
			<p>
				It is your sole responsibility to familiarize yourself with all laws and regulations applicable to the receipt,
				use, storage and safeguarding of data that identifies consumers that we provide you, and to comply with them.
				You agree to maintain commercially reasonable physical, electronic and procedural controls and safeguards to
				protect any such consumer information received pursuant to these terms from unauthorized use or disclosure,
				including the maintenance of appropriate safeguards to restrict access to the consumer data solely to carry out
				the purposes for which the information was disclosed.
			</p>

			<h3>WARRANTIES & DISCLAIMER</h3>
			<p>With respect to each purchase or receipt of leads or services, you agree and warrant that:</p>
			<ul>
				<li>
					the use of the leads and services and any calls and other interactions with and information provided to
					consumers resulting from the leads will comply with all applicable federal, state and local laws, statutes,
					rules and regulations, including without limitation, the Communications Act of 1934, as amended, the Telephone
					Consumer Protection Act (“TCPA”) and implementing regulations issued by Federal Communications Commission, the
					Telemarketing and Consumer Fraud and Abuse Prevention Act, the Federal Trade Commission's Telemarketing Sales
					Rule, the Controlling the Assault of Non-Solicited Pornography and Marketing Act, and other federal and state
					laws and regulations governing the marketing, promotion, and/or sales of goods or services, including general
					consumer protection laws and regulations, or other consumer protection laws that prohibit unfair, deceptive,
					or misleading acts or practices;
				</li>
				<li>
					without limiting the generality of subsection (a) above, you will not make any calls to any individual listed
					on any federal or state national Do-Not-Call (DNC) registry unless an exemption applies;
				</li>
				<li>
					you will not use any lead information for purposes of determining a person's eligibility for insurance,
					credit, employment or otherwise in any manner that violates the Fair Credit Reporting Act;
				</li>
				<li>
					you have obtained any and all required licenses, permits, and other authorizations required by any law,
					regulation, or government or regulatory authority to conduct your business as presently conducted, including
					offering and selling the products and services that are the subject of the lead;
				</li>
				<li>
					you have disclosed the existence of any federal or state decrees, orders, or consent agreements, and any
					pending formal or informal government investigations, and you further represent and warrant that if you become
					involved or named in any action, investigation, complaint or other proceeding by or before any governmental or
					regulatory authority, or any private party, you will immediately provide notice to NextGen of such action,
					investigation, complaint or other proceeding; and
				</li>
				<li>you will ensure that your employees, contractors and agents comply with the foregoing.</li>
			</ul>
			<p>
				THE LEADS, MARKETING SERVICES AND THIS WEBSITE ARE PROVIDED “AS IS”, “WHERE IS”. EXCEPT AS EXPRESSLY SET FORTH
				IN THIS SECTION, NEITHER PARTY MAKES ANY WARRANTIES TO THE OTHER, AND ALL IMPLIED, STATUTORY OR OTHER WARRANTIES
				ARE DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR
				A PARTICULAR PURPOSE, COMPLIANCE WITH THE LAWS, NON-INFRINGEMENT, TITLE, OR BASED ON COURSE OF DEALING OR USAGE
				OF TRADE. WE DO NOT GUARANTEE THAT THE INFORMATION PROVIDED BY THE CONSUMER IS ACCURATE OR COMPLETE, OR THE
				RESULTS TO BE ACHIEVED FROM THE LEADS, INCLUDING ANY SPECIFIC CUSTOMER CONVERSION RATES.
			</p>

			<p>
				YOU UNDERSTAND AND ACCEPT THAT LEADS AND CONTACT INFORMATION COLLECTED HAVE NOT BEEN SCREENED OR VALIDATED BY US
				AND ARE NOT GUARANTEED TO BE ACCURATE OR ERROR-FREE AND ALL RISKS OF CONDITION, USE, QUALITY, DESIGN, OR FITNESS
				ARE YOURS.
			</p>

			<h3>INDEMNIFICATION</h3>
			<p>
				You agree to indemnify, defend and hold harmless NextGen and its members, directors, officers and affiliates,
				successors and assigns from and against any liabilities, damages, costs and expenses (including reasonable
				attorney's fees) resulting from a third party claim or investigation relating to or arising from your, your
				affiliates', or your or their employees', contractors' or agents' activities with respect to the leads or the
				possession, disclosure or use thereof, including without limitation, any consumer contact or information in
				connection therewith, or any breach of the warranties you made above, including your warrant of compliance with
				TCPA, or the restrictions on use of the leads. This obligation remains in effect for a period of five (5) years
				from the date of each lead purchased or received by you, and remains in effect thereafter for all claims made or
				investigations initiated during that period.
			</p>

			<h3>CONSENT TO CALL</h3>
			<p>
				We have made a reasonable effort, based on currently understood legal requirements, to obtain the consumer's
				online prior express written consent to be contacted by automatic telephone dialing systems on their mobile
				phones pursuant to the TCPA, and have provided to you a copy of the consent language and mechanism we use. You
				should determine whether the consents we have obtained are legally sufficient and comply with your internal
				marketing policies. NextGen makes no representations or warranties regarding such consent, including but not
				limited that such consent complies with the TCPA requirements or other applicable law, or that the leads can all
				be called.
			</p>

			<h3>DISCLAIMER AND LIMITATIONS OF LIABILITY</h3>
			<p>
				AS A CONDITION TO THE PROVISION OF THE LEADS AND OTHER MARKETING SERVICES, WE BOTH AGREE THAT, TO THE FULLEST
				EXTENT PERMITTED BY LAW, NEXTGEN WILL NOT BE LIABLE TO YOU, YOUR AFFILIATES OR RESPECTIVE CONTRACTORS OR AGENTS
				OR ANY THIRD PARTY, REGARDLESS OF THE CAUSE OR ACTION, FOR: (i) ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, SPECIAL
				OR RELIANCE DAMAGES RESULTING FROM OR ARISING IN CONNECTION WITH THE LEADS OR OTHER MARKETING SERVICES, ANY
				INFORMATION WE PROVIDE TO YOU IN CONNECTION THEREWITH, THESE TERMS OR ANY MATTERS RELATING THERETO, INCLUDING
				WITHOUT LIMITATION LOSS OF REVENUES, PROFITS, BUSINESS OPPORTUNITY OR DATA, EVEN IF ADVISED OF THE POSSIBILITY
				OF SUCH DAMAGES; OR (ii) ANY DAMAGES OF ANY TYPE, IN THE AGGREGATE AT ANY TIME, IN EXCESS OF THE AMOUNT PAID BY
				YOU TO US FOR LEADS UNDER THESE TERMS IN THE MOST RECENT THREE (3) MONTH PERIOD. THE LIMITATIONS AND EXCLUSIONS
				OF LIABILITY HEREIN SHALL APPLY EQUALLY FOR THE BENEFIT OF NEXTGEN'S SUPPLIERS, OFFICERS, AFFILIATES,
				REPRESENTATIVES, CONTRACTORS AND EMPLOYEES WHEN ACTING IN CONNECTION WITH THE SUBJECT MATTER OF THESE TERMS.
			</p>

			<p>
				YOU ACKNOWLEDGE THAT NEXTGEN WOULD NOT HAVE ENTERED INTO THIS AGREEMENT BUT FOR THE LIMITATIONS CONTAINED IN
				THIS SECTION.
			</p>

			<h3>TERMINATION</h3>
			<p>
				You or we may terminate our relationship at any time by discontinuing the provision or purchase of leads. These
				terms survive termination for any reason, and continue to apply indefinitely to any leads purchased or received
				hereunder and all matters relating to such leads or their use, including without limitation the use
				restrictions, warranties, indemnification, limitation of liability and mandatory arbitration provided herein.
			</p>

			<h3>MANDATORY ARBITRATION</h3>
			<p>
				These terms are governed by the laws of the State of Delaware. You and we agree to resolve any disputes relating
				to these terms, the leads or other marketing services exclusively by binding arbitration, before a single
				arbitrator, pursuant to the rules of the American Arbitration Association. The seat of the arbitration will be
				the jurisdiction where the party in the position of defendant is domiciled. The arbitrator shall be selected
				pursuant to the AAA rules, or otherwise as mutually agreeable to both parties. To begin the arbitration process,
				a party must make a written demand therefor. The decision of the arbitrator may be enforced in any court of
				competent jurisdiction.
			</p>

			<h3>MISCELLANEOUS</h3>
			<p>
				We do not endorse or recommend the products or services of any particular third party. NextGen is not a licensed
				insurance provider, does not offer any insurance products or services, and has no responsibility for any
				products or services you may purchase or obtain from the third parties who receive your information. That is a
				contract or transaction solely between you and them. The third party determines what products and services you
				may qualify for and the terms of any arrangements between you and them, and makes all decision relating to their
				products and services. However, NextGen does not market Medicare Advantage Plans or Medicare Part D
				(prescription drug plans) and prohibits its customers from using consumer information submitted on this site for
				the marketing or sale of Medicare Advantage Plans or Medicare Part D (prescription drug plans). Further, there
				is no guarantee that, by submitting your information, you will be contacted by one or more third parties
				regarding the subject of your inquiry.
			</p>

			<p>
				We are an independent contractor, and you and we are not by this agreement in a relationship of joint venturers,
				partners or agency, and neither party has the power to obligate or bind the other in any manner whatsoever. If
				any provision of this agreement is determined to be invalid, illegal or unenforceable, in whole or in part, the
				validity, legality and enforceability of any of the remaining provisions of this agreement shall not in any way
				be affected or impaired thereby and this agreement shall nevertheless be binding between the parties, and such
				provision shall be deemed to be restated to reflect as nearly as possible the original intensions of the parties
				in accordance with applicable law, and the remainder of the agreement shall remain in full force and effect.
			</p>

			<p>
				You may not assign or transfer the leads or these terms, without NextGen's prior written consent, and any
				attempted assignment or transfer without such consent shall be null and void.
			</p>
		</div>
		<template #footer>
			<div class="modal-footer">
				<div class="control-group no-margin">
					<div class="inner">
						<div class="controls gap-10">
							<div class="field fit">
								<p-checkbox input-id="terms-agree" v-model="terms_agree" binary />
							</div>
							<div class="field caption">
								<label for="terms-agree" style="margin-top: 3px"
									>I agree to the terms above<span class="req"></span
								></label>
							</div>
						</div>
						<div v-if="v$.terms_agree.$error" class="validation-error">
							{{ v$.terms_agree.$errors[0].$message }}
						</div>
					</div>
				</div>
				<div class="submit">
					<p-button label="Continue" icon="pi pi-check" @click="agreeToTerms()" />
				</div>
			</div>
		</template>
	</p-dialog>
</template>

<script>
import pDialog from 'primevue/dialog';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { updateUser } from '@GQL';

export default {
	name: 'TermModal',
	components: {
		pDialog,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			terms_agree: false,
		};
	},
	computed: {
		showTermsModal() {
			return (
				this.$root.sessionStore.isAccountUser &&
				!(this.$root.sessionStore.user.terms && this.$root.sessionStore.user.terms.version)
			);
		},
	},
	validations() {
		return {
			terms_agree: {
				required: helpers.withMessage('Please check the box above to agree to the terms before continuing', (v) => {
					return v === true;
				}),
			},
		};
	},
	methods: {
		async agreeToTerms() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					// Get the IP address
					const ip_result = await fetch('https://api.ipify.org');
					const ip_address = await ip_result.text();
					const terms = {
						ip_address,
						timestamp: new Date(),
						version: '20220405.4',
					};
					const user_result = await updateUser(this.$root.sessionStore.user.id, {
						terms,
					});
					this.$root.sessionStore.user.terms = terms;
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to Update Terms on User Account',
						detail: 'Please reach out to support@nextgenleads.com for help',
					});
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
.terms-text {
	background-color: var(--gray-10);
	border: 1px solid var(--gray-15);
	border-radius: 5px;
	max-height: 500px;
	overflow-y: auto;
	padding: 1rem;

	h3 {
		font-size: 1.25rem;
		font-weight: bold;
		margin-bottom: 0;
	}

	p {
		font-family: var(--font-mono);
		font-size: 1rem;
		line-height: 1.25em;
	}
}

.modal-footer {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
</style>
