<template>
	<div id="revenue-analyzer-wrapper" class="view-content">
		<div class="page-heading">
			<h1>Revenue Analyzer</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<div class="flex gap-5 table-container">
				<div class="card padded flex-shrink-0">
					<h5>Primary View</h5>
					<gutter size="20px" />
					<comparison-form
						ref="primary_form"
						@update:query="handlePrimaryQueryUpdate"
						@refresh="(q) => handlePrimaryQueryUpdate(q, true)"
					/>
					<gutter size="20px" />
					<MetricsTable :metrics="primary_metrics" />
					<gutter size="20px" />
					<RevenueTable :data="primary_data" :loading="loading" :grouping="primary_query.group_by" />
				</div>
				<template v-for="(query, index) in comparison_queries">
					<div class="card padded flex-shrink-0">
						<h5>Comparison View</h5>
						<gutter size="20px" />
						<comparison-form
							:ref="`comparison_form$_${index}`"
							@update:query="(q) => handleComparisonQueryUpdate(q, index)"
							@refresh="(q) => handleComparisonQueryUpdate(q, index, true)"
						/>
						<gutter size="20px" />
						<MetricsTable :metrics="comparison_metrics[index]" />
						<gutter size="20px" />
						<RevenueTable
							:data="comparison_data[index]"
							:grouping="comparison_queries[index].group_by"
							:loading="loading"
						/>
					</div>
				</template>
				<div class="card padded flex-shrink-0">
					<a @click="addComparison">
						<div class="flex flex-column justify-content-center align-items-center gap-2 h-full">
							<icon type="plus-circle" size="32px" />
							<strong>Add comparison view</strong>
						</div>
					</a>
				</div>
				<div class="w-3rem">&nbsp;</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, numberFormat } from '@/lib/Filters';
import ComparisonForm from './Form.vue';
import MetricsTable from './MetricsTable.vue';
import RevenueTable from './RevenueTable.vue';
import get from 'lodash/get';
import { getMAutRevenueAnalyzer } from '@GQL';

interface Query {
	search_term: string;
	match_type: string;
	range: Date[];
	vertical: string;
	biz_unit: string;
	type: string;
	group_by: string[];
}

export default {
	name: 'RevenueAnalyzer',
	components: {
		ComparisonForm,
		MetricsTable,
		RevenueTable,
	},
	data() {
		return {
			loading: false,
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Revenue Analyzer' }],
			primary_data: [],
			comparison_data: [],
			primary_metrics: [],
			comparison_metrics: [],
			primary_query: {
				group_by: [],
			} as Query,
			comparison_queries: [] as Query[],
		};
	},
	mounted() {
		this.$refs.primary_form.$refs.query_form.toggleDrawer();
	},
	methods: {
		currency,
		formatDate,
		numberFormat,
		checkQuery(query) {
			if ('group_by' in query) {
				if (query.group_by.length === 0) {
					this.$nextTick(() => {
						this.$refs.primary_form.$refs.query_form.toggleDrawer();
					});
					throw new Error('Invalid Query, missing Group By');
				}
			}
		},
		async handlePrimaryQueryUpdate(query: Partial<Query>, refresh = false) {
			try {
				this.checkQuery(query);
				this.primary_query = query;
				await this.getData(0, refresh);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Error: ${err.message}`,
					life: 9000,
				});
			}
		},
		async handleComparisonQueryUpdate(query: Partial<Query>, query_index, refresh = false) {
			try {
				this.checkQuery(query);
				this.comparison_queries.splice(query_index, 1, query);
				await this.getData(query_index + 1, refresh);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Error: ${err.message}`,
					life: 9000,
				});
			}
		},
		addComparison() {
			this.comparison_queries.push({
				group_by: [],
			});
			this.comparison_data.push([]);
			this.comparison_metrics.push([]);
			this.$nextTick(() => {
				this.$refs[`comparison_form$_${this.comparison_queries.length - 1}`][0].$refs.query_form.toggleDrawer();
			});
		},
		async getData(query_index, refresh = false) {
			this.loading = true;
			try {
				let query;
				if (query_index === 0) {
					// primary
					query = this.primary_query;
				} else {
					query = this.comparison_queries[query_index - 1];
				}
				const { rows, metrics } = await getMAutRevenueAnalyzer(query, refresh);
				if (query_index === 0) {
					this.primary_data = rows;
					if ('formatted' in metrics) {
						this.primary_metrics = [...metrics.formatted];
					}
				} else {
					// we splice the data and metrics
					this.comparison_data.splice(query_index - 1, 1, rows);
					this.comparison_metrics.splice(query_index - 1, 1, get(metrics, 'formatted', []));
				}
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style scoped lang="less">
.table-container {
	&:last-child {
		margin-right: 50px;
	}
}
</style>
