<template>
	<div class="action process-auction-logs">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Process Auction Logs</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeAction"
			/>
		</div>
		<div class="description">Create and ship purchases for winning bids in an auction.</div>
		<div class="action-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Max Winners:</label>
					<div class="controls">
						<div class="field s">
							<p-input-number v-model="modelValue.max_winners" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label"
						>Purchase Tags: <span class="sub-value">Add tags to the purchase records created by this queue</span></label
					>
					<div class="controls">
						<div class="field">
							<p-chips v-model="modelValue.tags" add-on-blur separator="," />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';
import pChips from 'primevue/chips';

export default {
	name: 'processAuctionLogs',
	emits: ['remove'],
	components: {
		deleteAction,
		pChips,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					max_winners: 4,
					tags: [],
				};
			},
		},
	},
	methods: {
		removeAction() {
			this.$emit('remove');
		},
	},
};
</script>
