import { $GQL } from '@GQL/index';
import type { MautRevenue } from '@nextgenleads/marketplace-driver';

export async function insertMautRevenue(data: Omit<MautRevenue, 'created_at' | 'id'>) {
	const mutation = `
        mutation InsertMautRevenue($data: JSONObject!) {
            insertMautRevenue(data: $data)
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertMautRevenue;
}
