<template>
	<div id="payment-methods" class="view-content">
		<loader :show="loading" />
		<div class="page-heading">
			<h1>Payment Methods</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-message v-if="hasExpired" severity="error" style="margin-top: 0">
			Some of your payment methods have expired. Please delete or update your expired payment methods to continue using
			them to make deposits.
		</p-message>
		<p-message v-if="!current_account.verified" severity="error" :closable="false">
			Since your account has not yet been verified, you will only be allowed to add up to two cards. To lift this
			restriction contact your Account Manager and have your account verified.
		</p-message>
		<div class="flex flex-row">
			<add-funds-modal ref="add_funds_modal" :show_button="true" />
			<gutter size="20px" />
			<AddPaymentMethod
				:disabled="!canAddPaymentMethod"
				ref="add_payment_method"
				@refresh="fetchPaymentMethods(true)"
			/>
		</div>
		<div class="card-list">
			<p-message v-if="credit_cards.length === 0" :closable="false">
				No payment methods found, please add a card to your account.
			</p-message>
			<template v-for="(card, index) in valid_cards" :key="index">
				<div class="card-wrapper">
					<div
						class="credit-card"
						:class="{
							verified: card.verified,
							pending: card.status === 'pending',
							expired: card.status === 'expired',
						}"
					>
						<div class="header">
							<div class="logo">
								<img :src="cardLogo(card.type)" :alt="card.type" />
							</div>
							<div class="icons">
								<EditPaymentMethod v-if="$root.sessionStore.isAdminUser" :card="card" />
								<icon
									v-if="
										card.status === 'active' ||
										card.status === 'pending' ||
										!card.verified ||
										$root.sessionStore.isAdminUser
									"
									v-tooltip.top="'Delete Payment Method'"
									class="action delete"
									type="trash-can"
									size="24px"
									@click="deleteCard(card.id)"
								/>

								<icon
									v-if="$root.sessionStore.isAdminUser && card.status !== 'pending'"
									v-tooltip.top="'Suspend Payment Method'"
									class="action delete"
									type="stop-circle"
									size="24px"
									@click="suspendCard(card.id)"
								/>
								<icon
									v-if="$root.sessionStore.isAdminUser"
									v-tooltip.top="
										card.status === 'pending' || !card.verified ? 'Verify the Payment Method' : 'See Verification Docs'
									"
									class="action view"
									type="license"
									size="24px"
									@click="openVerifyCardModal({ ...card })"
								/>
								<icon
									v-if="!$root.sessionStore.isAdminUser && (card.status === 'pending' || !card.verified)"
									v-tooltip.top="'Speed up the verify process and upload supporting documents'"
									class="action add"
									type="license"
									size="24px"
									@click="openVerificationUploadModal({ ...card })"
								/>
							</div>
						</div>
						<div class="body">
							<div class="card-number">**** **** **** {{ card.last_four }}</div>
							<div class="expiration">Exp {{ formatExpiration(card.expiration_date) }}</div>
						</div>
						<div class="name">
							<icon class="status" type="check-decagram" size="16px" />
							{{ card.name_on_card }}
						</div>
					</div>
					<div v-if="card.verified" class="added">Verified {{ formatDate(card.verified_at, 'lll') }}</div>
					<div v-if="card.status === 'pending'" class="added">Card must be reviewed by Sales Team.</div>
				</div>
			</template>
		</div>
		<p-confirm-dialog />
		<p-dialog />
		<verification-upload-modal ref="verification_upload_modal" />
		<VerifyModal ref="verify_card_modal" @refresh="fetchPaymentMethods(true)" />
	</div>
</template>

<script lang="ts">
import { formatDate } from '@/lib/Filters';
import pConfirmDialog from 'primevue/confirmdialog';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import AddPaymentMethod from '@/views/PaymentMethods/Record/NewModal.vue';
import EditPaymentMethod from '@/views/PaymentMethods/Record/ExistingModal.vue';
import VerificationUploadModal from '@/views/PaymentMethods/Record/VerificationUploadModal.vue';
import VerifyModal from '../Record/VerifyModal.vue';
import AddFundsModal from '@/views/AddFunds/Modal.vue';
import { getPaymentMethods, deletePaymentMethod, updatePaymentMethod, getAccountById } from '@GQL';
import dayjs from 'dayjs';
import log from '@/lib/Log';

export default {
	name: 'PaymentMethodsList',
	components: {
		pConfirmDialog,
		pDialog,
		pMessage,
		AddPaymentMethod,
		EditPaymentMethod,
		AddFundsModal,
		VerifyModal,
		VerificationUploadModal,
	},
	data() {
		return {
			loading: false,
			breadcrumb_items: [],
			credit_cards: [],
			valid_cards: [],
			current_account: {
				id: '',
				name: '',
				primary_user_id: '',
				verified: true,
				is_parent: false,
				balance: {
					actual: 0,
				},
				contact: {},
				settings: {
					timezone: 'America/Los_Angeles',
					budgets: [],
					allow_advanced_mode: false,
					enable_peer_competition: false,
				},
				support: {
					account_manager: {
						id: null,
						first_name: null,
						last_name: null,
						email: null,
						phone: null,
						avatar_url: null,
					},
					first_fund_date: null,
				},
				signup_data: {
					activity_score: 0,
					primary_vertical: null,
				},
			},
		};
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id ||
				this.$route.params.parent_account_id ||
				this.$root.sessionStore.user.account_id ||
				('mpid' in this.$route.params ? `${this.$route.params.mpid}-000-000` : `${this.$root.appStore.mpid}-000-000`)
			);
		},
		hasExpired() {
			return this.credit_cards.some((card) => {
				return dayjs(card.expiration_date).add(1, 'month').isBefore(new Date());
			});
		},
		canAddPaymentMethod() {
			return (
				this.$root.sessionStore.isAdminUser ||
				this.$root.sessionStore.hasVerifiedAccount ||
				(!this.$root.sessionStore.hasVerifiedAccount && this.credit_cards.length < 2)
			); // only allow up to two card when not verified...
		},
		hasPaymentMethods() {
			const valid_cards = this.credit_cards.filter((card) => {
				return dayjs(card.expiration_date).add(1, 'month').isAfter(new Date());
			});
			return valid_cards.length > 0;
		},
	},
	async mounted() {
		await this.fetchPaymentMethods(true);
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Payment Methods',
				},
			];
		} else {
			this.breadcrumb_items = [{ label: 'Payment Methods' }];
		}
		this.current_account = await getAccountById(this.accountId);
	},
	methods: {
		openVerificationUploadModal(card) {
			this.$refs.verification_upload_modal.openModal(card);
		},
		openVerifyCardModal(card) {
			this.$refs.verify_card_modal.openModal(card);
		},
		resetAll() {
			this.$refs.add_funds_modal.reset();
			this.$refs.add_payment_method.reset();
			// Update widget
		},
		async fetchPaymentMethods(refresh?: boolean) {
			this.loading = true;
			try {
				this.credit_cards = await getPaymentMethods(this.accountId, refresh);
				this.valid_cards = this.credit_cards.filter((row) => {
					return row.status !== 'archived';
				});
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get payment methods',
					life: 3000,
				});
			} finally {
				if (refresh) {
					this.resetAll();
				}
				this.loading = false;
			}
		},
		formatExpiration(exp_string) {
			return `${exp_string.substring(5, 7)}/${exp_string.substring(2, 4)}`;
		},
		formatDate,
		cardLogo(vendor) {
			switch (vendor) {
				case 'AX':
					return '/assets/img/credit-card-vendors/amex.svg';
				case 'DI':
					return '/assets/img/credit-card-vendors/discover.svg';
				case 'MC':
					return '/assets/img/credit-card-vendors/mastercard.svg';
				case 'VI':
					return '/assets/img/credit-card-vendors/visa.svg';
				default:
					return '/assets/img/credit-card-vendors/generic.svg';
			}
		},
		suspendCard(id) {
			this.$confirm.require({
				header: 'Suspend Payment Method',
				message: 'Are you sure you want to suspend this payment method?',
				icon: 'pi pi-exclamation-triangle',
				acceptClass: 'p-button-danger',
				accept: async () => {
					try {
						await updatePaymentMethod(id, { status: 'pending', verified: false });
						this.$toast.add({
							severity: 'info',
							summary: 'Suspended',
							detail: 'Suspended Payment Method',
							life: 3000,
						});
						await this.fetchPaymentMethods(true);
					} catch (err) {
						console.error(err, 'Suspend ');
						this.$toast.add({
							severity: 'error',
							summary: 'Could not suspend',
							detail: 'Suspended Payment Method',
							life: 3000,
						});
					}
				},
				reject: () => {
					this.$toast.add({
						severity: 'info',
						summary: 'Cancel',
						detail: 'Cancelled Suspencion',
						life: 3000,
					});
				},
			});
		},
		deleteCard(id) {
			this.$confirm.require({
				header: 'Delete Payment Method',
				message: 'Are you sure you want to delete this payment method?',
				icon: 'pi pi-exclamation-triangle',
				acceptClass: 'p-button-danger',
				accept: async () => {
					try {
						await deletePaymentMethod(id, this.$root.sessionStore.user.id);
						this.$toast.add({
							severity: 'info',
							summary: 'Deleted',
							detail: 'Deleted Payment Method',
							life: 3000,
						});
						await this.fetchPaymentMethods(true);
					} catch (err) {
						console.error(err, 'DELETE ');
						this.$toast.add({
							severity: 'error',
							summary: 'Could not delete',
							detail: 'Deleted Payment Method',
							life: 3000,
						});
					}
				},
				reject: () => {
					this.$toast.add({
						severity: 'info',
						summary: 'Cancel',
						detail: 'Cancelled Deletion',
						life: 3000,
					});
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.card-list {
	display: flex;
	flex-flow: row wrap;
	font-size: 1rem;
	gap: 2rem;
	padding: 2rem 0;

	.added {
		color: var(--gray-50);
		font-size: var(--font-size-sm);
		padding: 10px 0;
	}

	.credit-card {
		border: 1px solid var(--gray-50);
		border-radius: 8px;
		box-shadow: rgb(0 0 0 / 15%) 0 2px 5px;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
		max-width: 350px;
		min-height: 200px;
		padding: 1rem;
		transition: 0.3s;

		.header {
			align-items: flex-start;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.logo {
				flex: 1;

				img {
					max-width: 150px;
				}
			}

			.icons {
				display: flex;
				gap: 5px;
				justify-content: flex-end;

				.action {
					color: var(--gray-50);
					cursor: pointer;

					&:hover {
						color: var(--color-b);

						&.delete {
							color: var(--red);
						}

						&.add {
							color: var(--green);
						}

						&.view {
							color: var(--orange);
						}
					}
				}
			}
		}

		.body,
		.name {
			font-family: var(--font-mono);
			padding: 0.5rem 0;
		}

		.name {
			align-items: center;
			display: flex;
			gap: 0.5rem;
			justify-content: flex-start;
		}

		.status {
			color: var(--orange);
		}

		&.verified {
			.status {
				color: var(--green);
			}
		}

		&.pending {
			border: 2px solid var(--orange);

			.status {
				color: var(--orange);
			}
		}

		&.expired {
			border: 2px solid var(--red);

			.status {
				color: var(--red);
			}
		}
	}

	.mobile({
		.card-wrapper {
			width: 100%;
			max-width: 350px;
		}
	});

	.tablet-up({
	.credit-card {
		min-width: 340px;
	}
});
}
</style>
