// --------------------------------------------------
// FILTER: subIdHash
// Hash a sub_id
// --------------------------------------------------

// Import dependencies
import shortHash from 'shorthash2';

const sub_id_segment_map = {
	ad_group: 'adg',
	publisher: 'pub',
	channel: 'chn',
	campaign_id: 'cid',
	campaign_name: 'cmp',
	medium: 'med',
	keywords: 'key',
	url: 'url',
	sub_id: 'sub',
	s1: 's1',
	s2: 's2',
	s3: 's3',
	s4: 's4',
};

export function subIdHash(
	source_id: string = '',
	sub_id_map: string[],
	segments: Record<string, unknown> = {},
	default_value: string = 'unknown'
) {
	const hashed_segments: string[] = [];
	if (sub_id_map) {
		sub_id_map.forEach((sub_id_segment) => {
			let prefix = sub_id_segment_map[sub_id_segment];
			if (!prefix) {
				prefix = sub_id_segment.substring(0, 3);
			}
			const raw_value = prefix + ':' + segments[sub_id_segment];
			const hashed_segment = shortHash(raw_value);
			hashed_segments.push(hashed_segment);
		});
	} else {
		default_value.split('|').forEach((seg) => {
			hashed_segments.push(shortHash(seg));
		});
	}
	return shortHash(source_id) + '-' + hashed_segments.join('|');
}
