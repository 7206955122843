<template>
	<div id="conversions">
		<query-form v-model:query="query" :loading="loading" @apply="getReport">
			<template #display>
				<div class="display-value">
					<div class="label">Purchase Dates:</div>
					<div class="value">
						{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">State:</div>
					<div class="value">
						{{ displaySelected(query.filters.state, state_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Vertical:</div>
					<div class="value">
						{{ displaySelected(query.filters.vertical_id, appStore.verticalOptions) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Business Unit:</div>
					<div class="value">
						{{ displaySelected(query.filters.business_unit, business_unit_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Product:</div>
					<div class="value">
						{{ displaySelected(query.filters.product, appStore.options.products) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Bid Type:</div>
					<div class="value">
						{{ displaySelected(query.filters.bid_type, bid_type_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Campaigns:</div>
					<div class="value">
						{{ displaySelected(query.filters.campaign_id, campaign_options) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" />
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker :max-range="62" v-model="form.query.date_range" show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Filter By State:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											id="lead-state"
											v-model="form.query.filters.state"
											option-label="label"
											option-value="value"
											:options="state_options"
											placeholder="Select states"
										/>
									</div>
								</div>
							</div>
						</div>
						<gutter size="1em" />
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Filter By Vertical:</label>
								<div class="controls">
									<div class="field">
										<div
											v-for="vertical of appStore.verticalOptions"
											:key="vertical.value"
											class="p-checkbox-item flex align-items-center"
										>
											<p-checkbox
												v-model="form.query.filters.vertical_id"
												:input-id="vertical.value"
												name="category"
												:value="vertical.value"
											/>
											<label :for="vertical.value">{{ vertical.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="400px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Filter By Business Unit:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											id="business-unit"
											v-model="form.query.filters.business_unit"
											option-label="label"
											option-value="value"
											:options="business_unit_options"
											placeholder="Select business unit"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Filter By Product:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											id="product-type"
											v-model="form.query.filters.product"
											option-label="label"
											option-value="value"
											:options="appStore.options.products"
											placeholder="Select product type"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="400px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Filter By Bid Type:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											id="bid-type"
											v-model="form.query.filters.bid_type"
											option-label="label"
											option-value="value"
											:options="bid_type_options"
											placeholder="Select bid types"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Filter By Campaign:</label>
								<div class="controls">
									<div class="field" style="overflow: hidden">
										<p-multi-select
											id="bid-type"
											v-model="form.query.filters.campaign_id"
											option-label="label"
											option-value="value"
											:options="campaign_options"
											placeholder="Select campaigns"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>

		<gutter size="20px" />
		<div class="flex align-items-center justify-content-between">
			<div class="flex align-items-center gap-20">
				<ConversionCSVUploadModal />
				<ConvertByPhoneModal @success="getReport" />
			</div>
			<div class="flex align-items-center gap-10">
				<p-button @click="expandAll" v-tooltip.top="'Expand All Rows'">
					<template #icon>
						<icon type="arrow-expand-vertical" size="20px" />
					</template>
				</p-button>
				<p-button @click="collapseAll" v-tooltip.top="'Collapse All Rows'">
					<template #icon>
						<icon type="arrow-collapse-vertical" size="20px" />
					</template>
				</p-button>
				<p-button label="Copy Table Content" @click="copyTableContent()" />
			</div>
		</div>

		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				ref="dt"
				:loading="loading"
				scrollable
				:value="rows"
				v-model:expandedRows="expanded_rows"
				@row-expand="rowExpandHandler"
				@sort="setSortField"
				sortField="total_spend"
				:sort-order="-1"
				data-key="source_id"
				:frozenValue="frozen_rows"
				:row-class="rowClass"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column expander style="width: 1rem"></p-column>
				<p-column v-if="$root.sessionStore.isAdminUser" header="Source ID" class="width-m">
					<template #body="row">
						<span class="clickable" sortable sortField="source_id">
							<div class="overflow" v-tooltip.top="row.data.source_id">
								{{ row.data.source_id }}
							</div>
						</span>
					</template>
				</p-column>
				<p-column header="Source Hash">
					<template #body="row">
						<div class="overflow" v-tooltip.top="shortHash(row.data.source_id)">
							{{ shortHash(row.data.source_id) }}
						</div>
					</template>
				</p-column>
				<p-column header="Spend" class="column-align-right width-s" sortable sortField="total_spend">
					<template #body="row">
						{{ currency(row.data.total_spend) }}
					</template>
				</p-column>
				<p-column header="Count" class="column-align-right width-s" sortable sortField="total_purchase_count">
					<template #body="row">
						{{ row.data.total_purchase_count }}
					</template>
				</p-column>
				<p-column header="CPL" class="column-align-right width-s" sortable sortField="CPL">
					<template #body="row">
						{{ currency(row.data.CPL) }}
					</template>
				</p-column>
				<p-column header="CPA" class="column-align-right width-s" sortable sortField="CPA">
					<template #body="row">
						{{ currency(row.data.CPA) }}
					</template>
				</p-column>
				<p-column header="Sold" class="column-align-right width-s" sortable sortField="Sold">
					<template #body="row">
						{{ row.data.Sold }}
					</template>
				</p-column>
				<p-column header="Returned" class="column-align-right width-s" sortable sortField="Returned">
					<template #body="row">
						{{ row.data.Returned }}
					</template>
				</p-column>
				<p-column header="Contacted" class="column-align-right width-s" sortable sortField="Contacted">
					<template #body="row">
						{{ row.data.Contacted }}
					</template>
				</p-column>
				<p-column header="New" class="column-align-right width-s" sortable sortField="New">
					<template #body="row">
						{{ row.data.New }}
					</template>
				</p-column>
				<template #expansion="expanded_row">
					<p-data-table
						:loading="sub_id_loading[expanded_row.data.source_id]"
						:rowClass="
							() => {
								return 'conversion_sub_id_row';
							}
						"
						scrollable
						:value="expanded_row.data.sub_ids || []"
						class="sub-id-table"
						:sort-field="sort_field"
						:sort-order="sort_order"
					>
						<template #loading>
							<line-loader :show="true" />
						</template>
						<template #empty>
							<div class="dim">No results</div>
						</template>
						<p-column style="width: 64px">&nbsp;</p-column>
						<p-column header="Sub Id" sortable sortField="sub_id" style="padding-left: 2rem" class="width-m">
							<template #body="row">
								<div class="overflow" v-tooltip.top="row.data.sub_id">
									{{ row.data.sub_id || 'Unknown' }}
								</div>
							</template>
						</p-column>
						<p-column header="Sub Id Hash" sortable sortField="sub_id" class="sub-id-hash">
							<template #body="row">
								<div class="overflow" v-tooltip.top="subIdHash(expanded_row.data.source_id, row.data.sub_id)">
									{{ subIdHash(expanded_row.data.source_id, row.data.sub_id) || 'Unknown' }}
								</div>
							</template>
						</p-column>
						<p-column header="Spend" class="column-align-right width-s" sortable sortField="total_spend">
							<template #body="row">
								{{ currency(row.data.total_spend) }}
							</template>
						</p-column>
						<p-column header="Count" class="column-align-right width-s" sortable sortField="total_purchase_count">
							<template #body="row">
								{{ row.data.total_purchase_count }}
							</template>
						</p-column>
						<p-column header="CPL" class="column-align-right width-s" sortable sortField="CPL">
							<template #body="row">
								{{ currency(row.data.CPL) }}
							</template>
						</p-column>
						<p-column header="CPA" class="column-align-right width-s" sortable sortField="CPA">
							<template #body="row">
								{{ currency(row.data.CPA) }}
							</template>
						</p-column>
						<p-column header="Sold" class="column-align-right width-s" sortable sortField="Sold">
							<template #body="row">
								{{ row.data.Sold }}
							</template>
						</p-column>
						<p-column header="Returned" class="column-align-right width-s" sortable sortField="Returned">
							<template #body="row">
								{{ row.data.Returned }}
							</template>
						</p-column>
						<p-column header="Contacted" class="column-align-right width-s" sortable sortField="Contacted">
							<template #body="row">
								{{ row.data.Contacted }}
							</template>
						</p-column>
						<p-column header="New" class="column-align-right width-s" sortable sortField="new">
							<template #body="row">
								{{ row.data.New }}
							</template>
						</p-column>
					</p-data-table>
				</template>
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import { formatDate, displaySelected, currency } from '@/lib/Filters';
import { product_type_options } from '@/lib/Options';
import { getCampaignsAsOptions, reportSourceConversions, reportSubIdConversions } from '@GQL';
import { cloneDeep, round, sumBy } from 'lodash-es';
import ConversionCSVUploadModal from './Modals/CSVUploadModal.vue';
import ConvertByPhoneModal from './Modals/ConvertByPhoneModal.vue';
import state_options from '@/lib/Data/states.json';
import legacy_source_hashes from '@/lib/Data/legacy_source_hashes.json';
import legacy_sub_id_hashes from '@/lib/Data/legacy_sub_id_hashes.json';
import dayjs from 'dayjs';
import shortHash from 'shorthash2';

export default {
	name: 'AccountReportConversionsBySource',
	components: {
		dateRangePicker,
		queryForm,
		quickFilter,
		typeIcon,
		verticalIcon,
		ConversionCSVUploadModal,
		ConvertByPhoneModal,
	},
	data() {
		return {
			loading: false,
			breadcrumb_items: [],
			query: {
				date_range: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
				account_id: this.accountId,
				filters: {
					vertical_id: [],
					state: [],
					product: [],
					bid_type: [],
					business_unit: [],
					campaign_id: [],
				},
			},
			rows: [],
			frozen_rows: [],
			expanded_rows: {},
			product_type_options,
			state_options,
			bid_type_options: [
				{ label: 'Exclusive', value: 'exclusive' },
				{ label: 'Shared', value: 'shared' },
			],
			business_unit_options: [
				{ label: 'Owned and Operated', value: 'OO' },
				{ label: 'Partner Network', value: 'PN' },
			],
			campaign_options: [],
			sort_field: 'spend',
			sort_order: 1,
			sub_id_loading: {},
		};
	},
	computed: {
		accountId() {
			return this.$route.params.account_id || this.sessionStore.user.account_id;
		},
		...mapStores(useAppStore, useMarketplaceStore, useSessionStore),
	},
	async mounted() {
		this.query.account_id = this.accountId;
		await this.getReport();
		await this.getCampaigns();
	},
	methods: {
		formatDate,
		currency,
		displaySelected,
		shortHash,
		subIdHash(source_id, sub_id) {
			const source_id_hash = shortHash(source_id);
			let sub_id_hash = '';
			if (sub_id) {
				sub_id_hash = sub_id
					.split('|')
					.map((segment) => {
						return shortHash(segment);
					})
					.join('|');
			}
			return `${source_id_hash}-${sub_id_hash}`;
		},
		rowClass(row_data) {
			return row_data.source_id;
		},
		transformQuery(query) {
			const transformed_query = cloneDeep(query);
			transformed_query.filters = [[]];

			if (query.filters.state.length > 0) {
				transformed_query.filters[0].push({
					field: 'p.lead_state',
					op: 'in',
					value: query.filters.state,
				});
			}

			if (query.filters.vertical_id.length > 0) {
				transformed_query.filters[0].push({
					field: 'p.vertical_id',
					op: 'in',
					value: query.filters.vertical_id,
				});
			}

			if (query.filters.business_unit.length > 0) {
				transformed_query.filters[0].push({
					field: 'p.business_unit',
					op: 'in',
					value: query.filters.business_unit,
				});
			}

			if (query.filters.product.length > 0) {
				transformed_query.filters[0].push({
					field: 'p.product',
					op: 'in',
					value: query.filters.product,
				});
			}

			if (query.filters.bid_type.length > 0) {
				transformed_query.filters[0].push({
					field: 'p.bid_type',
					op: 'in',
					value: query.filters.bid_type,
				});
			}

			if (query.filters.campaign_id.length > 0) {
				transformed_query.filters[0].push({
					field: 'p.campaign_id',
					op: 'in',
					value: query.filters.campaign_id,
				});
			}

			if (transformed_query.filters[0].length === 0) {
				delete transformed_query.filters;
			}
			return transformed_query;
		},
		async getCampaigns() {
			this.campaign_options = await getCampaignsAsOptions([
				[
					{
						field: 'account_id',
						op: 'eq',
						value: this.accountId,
					},
				],
			]);
		},
		async getReport() {
			this.loading = true;
			// construct the query object to change filters
			try {
				const transformed_query = this.transformQuery(this.query);
				const results = await reportSourceConversions(transformed_query);
				this.rows = results.rows;

				if (this.rows.length > 0) {
					const totals_row = {
						source_id: 'TOTALS',
						total_spend: sumBy(results.rows, 'total_spend'),
						total_purchase_count: sumBy(results.rows, 'total_purchase_count'),
						CPL: sumBy(results.rows, 'total_spend') / sumBy(results.rows, 'total_purchase_count'),
						CPA: sumBy(results.rows, 'total_spend') / sumBy(results.rows, 'Sold'),
						Sold: sumBy(results.rows, 'Sold'),
						Returned: sumBy(results.rows, 'Returned'),
						Contacted: sumBy(results.rows, 'Contacted'),
						New: sumBy(results.rows, 'New'),
					};

					this.frozen_rows = [totals_row];
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get data',
				});
			} finally {
				this.loading = false;
			}
		},
		async rowExpandHandler(event) {
			if (!event.data.sub_ids || event.data.sub_ids.length === 0) {
				this.sub_id_loading[event.data.source_id] = true;
				event.originalEvent.stopPropagation();
				const transformed_query = this.transformQuery(this.query);

				const params = {
					account_id: this.accountId,
					source_id: event.data.source_id,
					filters: transformed_query.filters,
					date_range: transformed_query.date_range,
				};
				const results = await reportSubIdConversions(params);
				event.data.sub_ids = [...results.rows].sort((a, b) => b.total_spend - a.total_spend);
				this.sub_id_loading[event.data.source_id] = false;
			}
		},
		expandAll(event) {
			this.rows.forEach((row) => {
				this.expanded_rows[row.source_id] = true;
				this.rowExpandHandler({
					originalEvent: event,
					data: row,
				});
			});
		},
		collapseAll() {
			this.rows.forEach((row) => {
				delete this.expanded_rows[row.source_id];
			});
		},
		setSortField(event) {
			this.sort_field = event.sortField;
			this.sort_order = event.sortOrder;
		},
		copyTableContent() {
			let content = 'Hash\tSpend\tCount\tCPL\tCPA\tSold\tReturned\tContacted\tNew\r\n';

			// Sort content by spend but keep order of expanded rows
			const sorted_rows = [...this.rows].sort((a, b) => b.total_spend - a.total_spend);
			const sorted_expanded_rows = {};
			sorted_rows.forEach((row) => {
				if (this.expanded_rows[row.source_id]) {
					sorted_expanded_rows[row.source_id] = true;
				}
			});

			// Format all the data in each row
			sorted_rows.forEach((row) => {
				content +=
					[
						shortHash(row.source_id),
						round(row.total_spend, 2),
						row.total_purchase_count,
						round(row.CPL, 2),
						round(row.CPA, 2),
						row.Sold,
						row.Returned,
						row.Contacted,
						row.New,
					].join('\t') + '\r\n';
				if (this.expanded_rows[row.source_id] && row.sub_ids && row.sub_ids.length > 0) {
					row.sub_ids.forEach((sub_row) => {
						content +=
							[
								this.subIdHash(row.source_id, sub_row.sub_id),
								round(sub_row.total_spend, 2),
								sub_row.total_purchase_count,
								round(row.CPL, 2),
								round(sub_row.CPA, 2),
								sub_row.Sold,
								sub_row.Returned,
								sub_row.Contacted,
								sub_row.New,
							].join('\t') + '\r\n';
					});
				}
			});

			// Add totals row
			const totals_row = this.frozen_rows[0];
			content +=
				[
					'TOTALS',
					round(totals_row.total_spend, 2),
					totals_row.total_purchase_count,
					round(totals_row.CPL, 2),
					round(totals_row.CPA, 2),
					totals_row.Sold,
					totals_row.Returned,
					totals_row.Contacted,
					totals_row.New,
				].join('\t') + '\r\n';

			navigator.clipboard.writeText(content);

			this.$toast.add({
				severity: 'info',
				summary: 'Conversion data copied to the clipboard',
				life: 3000,
			});
		},
	},
};
</script>

<style lang="less" scoped>
.clickable {
	cursor: pointer;
	font-size: var(--font-size-base);
	margin-bottom: 0;
}

:deep(.p-datatable .p-datatable-tbody > tr.TOTALS > td) {
	background-color: #f0fcfa;
	font-weight: bold;
}

:deep(.p-datatable .p-datatable-tbody > tr.TOTALS > td .p-row-toggler) {
	display: none;
}

:deep(.sub-id-table) {
	margin: -0.75rem -1rem;

	thead tr {
		display: none;
	}
}

:deep(.width-s) {
	width: 130px;
}

:deep(.width-m) {
	width: 220px;
}

:deep(.overflow) {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 200px;
}
</style>
