import { $GQL } from '@GQL/index';

export async function getMergeEventsForNotes(account_id: string): Promise<string[]> {
	const query = `
        query GetMergeEventsForNotes($account_id: String!) {
            getMergeEventsForNotes(account_id: $account_id)
        }
    `;

	const result = await $GQL.request(query, { account_id });
	return result.getMergeEventsForNotes;
}
