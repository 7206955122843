<template>
	<div class="panel">
		<query-form v-model:query="query" :loading="loading" @apply="getAccounts" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM D, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Account Manager:</div>
					{{ displaySelected(query.filters.account_manager_id, displayUsers) }}
				</div>
				<div class="display-value">
					<div class="label">Account Status:</div>
					{{ displaySelected(query.filters.account_status, account_status_options) }}
				</div>
				<div class="display-value">
					<div class="label">Support Status:</div>
					{{ displaySelected(query.filters.support_status, support_status_options) }}
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getAccounts(true)"
				/>
				<p-button
					v-if="filteredAccounts.length > 0"
					v-tooltip.top="'Download'"
					icon="pi pi-download"
					aria-label="Download"
					class="mr-2"
					@click="exportCSV"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker v-model="form.query.filters.created_at" show-time />
									</div>
								</div>
							</div>
						</div>

						<div class="control-group">
							<div class="inner">
								<label class="control-label req" for="owner_id">Account Manager:</label>
								<div class="controls">
									<div class="field">
										<p-dropdown
											id="support_am_id"
											v-model="form.query.filters.account_manager_id"
											placeholder="Account Manager ID"
											:options="user_options"
											showClear
											filter
											option-group-label="label"
											option-group-children="items"
											option-label="label"
											option-value="value"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="20px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="control-label">Filter by Account Status:</div>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.filters.account_status"
											option-label="label"
											option-value="value"
											:options="account_status_options"
											placeholder="Filter by Status"
										/>
									</div>
								</div>
							</div>
						</div>

						<div class="control-group">
							<div class="inner">
								<div class="control-label">Filter by Support Status:</div>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.filters.account_support_status"
											option-label="label"
											option-value="value"
											:options="support_status_options"
											placeholder="Filter by Support Status"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter v-if="!mq.phone" size="40px" />
					<column v-if="!mq.phone"></column>
				</row>
			</template>
		</query-form>
		<div class="flex align-items-center justify-content-between gap-3 flex-wrap">
			<div class="flex">
				<p-button
					type="button"
					label="New Account"
					icon="pi pi-plus"
					@click="$router.push({ path: `${this.$route.path}/new` })"
				/>
				<gutter size="10px" />
				<p-input-text v-model="search_query" placeholder="Type to search accounts..." style="width: 250px" />
			</div>
			<div class="flex align-items-center gap-2">
				<p-button outlined rounded v-if="isAM" @click="handleShowAll">
					<template v-if="show_all">
						<icon v-tooltip.left="'Show my accounts'" type="briefcase-eye-outline" size="20px" />
					</template>
					<template v-else>
						<icon v-tooltip.left="'Show all accounts'" type="database-eye-outline" size="20px" />
					</template>
				</p-button>
				<show-archived v-model="show_archived" />
			</div>
		</div>
		<gutter size="20px" />
		<div class="card padded-table">
			<p-data-table
				ref="accounts_table"
				:loading="loading"
				:value="filteredAccounts"
				v-model:filters="table_filters"
				:global-filter-fields="['name', 'primary_user']"
				sortField="spend"
				:sortOrder="-1"
				:rows="50"
				data-key="id"
				@sort="sortAccounts"
			>
				<template #empty>
					<div class="dim">No Accounts returned</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column field="account_name" header="Name" sortable sortField="account_name">
					<template #body="{ data }">
						<div class="account-info">
							<div class="left">
								<div class="account-name row-id">
									<router-link
										class="account-name"
										:to="
											this.$root.sessionStore.isAdminUser
												? `${$route.path}/manage/${data.account_id}/reports/account-activity`
												: `${$route.path}/manage/${data.account_id}/reports/account-overview`
										"
									>
										{{ data.account_name }}
									</router-link>
								</div>
								<div class="account-user sub-value">
									<span v-if="data.primary_user"> {{ data.primary_user }} - </span>
									<span v-else> No User </span>
									{{ formatDate(data.signup_date, 'MM/DD/YYYY') }}
								</div>
							</div>
							<div class="right">
								<!--<div class="avatar am-avatar" :style="avatar(data)" v-tooltip="supportName(data)"></div>-->
							</div>
						</div>
					</template>
				</p-column>
				<p-column field="verticals" header="Verticals" class="column-align-center" sortable sortField="verticals">
					<template #body="{ data }">
						<div v-if="data.verticals" class="verticals">
							<div v-for="vertical in data.verticals" :key="vertical" class="vertical-icon">
								<vertical-icon :vertical-id="vertical" size="30px" />
							</div>
						</div>
						<span v-if="!data.verticals">NA</span>
					</template>
				</p-column>
				<p-column
					field="campaign_types"
					header="Product Types"
					class="column-align-center"
					sortable
					sortField="products"
				>
					<template #body="{ data }">
						<img
							v-for="prod_type in data.products"
							:key="data.account_id + prod_type"
							class="campaign-type"
							:src="product_icon_map[prod_type]"
							v-tooltip.right="capitalize(prod_type)"
						/>
						<span v-if="!data.products">NA</span>
					</template>
				</p-column>
				<p-column field="spend" header="Spend (Purchases)" class="column-align-center" sortable sortField="spend">
					<template #body="{ data }"> {{ currency(data.spend) }} ({{ data.purchase_count }}) </template>
				</p-column>
				<p-column field="balance" header="Balance" class="column-align-center" sortable sortField="balance">
					<template #body="{ data }">
						{{ currency(data.balance) }}
					</template>
				</p-column>
				<p-column
					field="last_activity"
					header="Last Activity"
					class="column-align-center"
					sortable
					sortField="last_activity"
				>
					<template #body="{ data }">
						{{ formatDate(data.last_activity, 'MM/DD/YYYY') || 'No activity' }}
					</template>
				</p-column>
				<p-column
					field="support_status"
					header="Support Status"
					class="column-align-center"
					sortable
					sortField="support_status"
				>
					<template #body="{ data }">
						<div v-if="data.support_status">
							{{ title(data.support_status) }}
						</div>
						<div v-else>None</div>
					</template>
				</p-column>
				<p-column class="align-center" header="Tools">
					<template #body="{ data }">
						<div class="toolset">
							<div class="tool">
								<p-button v-tooltip.top="'Edit'" @click="$router.push({ path: `${$route.path}/${data.account_id}` })">
									<template #icon>
										<icon type="pencil-box-outline" size="24px" />
									</template>
								</p-button>
							</div>
							<div class="tool">
								<delete-action
									message="Are you sure you want to delete/archive this account?"
									@delete="removeAccount(data.account_id)"
								/>
							</div>
						</div>
					</template>
				</p-column>
			</p-data-table>

			<p-paginator
				:rows="query.pagination.page_size"
				:total-records="total_records"
				:first="query.pagination.page_size * query.pagination.page"
				@page="changePage"
			/>

			<p-data-table
				ref="downloadtable"
				class="hidden"
				:value="filteredAccounts"
				:export-function="formatCSVData"
				:exportFilename="`accounts-${this.mpid}-${new Date().toISOString()}`"
			>
				<!-- Hidden Table for CSV Export -->
				<p-column field="signup_date" header="Created At" />
				<p-column field="account_name" header="Account Name" />
				<p-column field="primary_user" header="Primary User" />
				<p-column field="account_manager" header="Account Manager" />
				<p-column field="verticals" header="Active Campaigns - Verticals" />
				<p-column field="products" header="Active Campaigns - Products" />
				<p-column field="spend" header="Spend" />
				<p-column field="purchase_count" header="Purchase Count" />
				<p-column field="balance" header="Balance" />
				<p-column field="status" header="Status" />
				<p-column field="support_status" header="Support Status" />
			</p-data-table>
		</div>
	</div>
</template>
<script lang="ts">
import { get } from 'lodash-es';
import { endOfDay, startOfDay } from 'date-fns';
import { formatDate, title, currency, displaySelected } from '@/lib/Filters';
import { capitalize, debounce, orderBy } from 'lodash-es';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import pPaginator from 'primevue/paginator';
import { FilterMatchMode } from 'primevue/api';
import { reportAccountList, deleteAccount, getInternalUsersAsOptions } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import { account_status_options, support_status_options } from '@/lib/Options';

export default {
	name: 'AccountsList',
	components: {
		dateRangePicker,
		queryForm,
		showArchived,
		pPaginator,
		verticalIcon,
		deleteAction,
	},
	inject: ['mq'],
	data() {
		return {
			home: { label: 'Home', route: '/' },
			items: [{ label: 'Accounts', route: '/accounts' }],
			loading: false,
			user_options: [],
			account_status_options,
			support_status_options,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())], // used for spend...
					account_status: [],
					account_manager_id: null,
					account_support_status: [],
					parent_accounts: false,
				},
				pagination: {
					page_size: 50,
					page: 0,
				},
				sort: {
					field: 'spend',
					desc: true,
				},
			},
			search_query: null,
			total_records: 0,
			accounts: [],
			show_archived: false,
			show_all: false,
			product_icon_map: {
				data: '/assets/img/icons/icon-lead-data.svg',
				live_transfer: '/assets/img/icons/icon-lead-live-transfer.svg',
				call: '/assets/img/icons/icon-lead-call.svg',
			},
			table_filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
		};
	},
	computed: {
		mpid() {
			return this.$route.params.mpid || this.$root.appStore.mpid;
		},
		displayUsers() {
			if (this.user_options.length > 0) {
				return this.user_options[0].items;
			}
			return [];
		},
		isAM() {
			return (
				!this.$root.sessionStore.user.acccount_id &&
				['account_manager', 'sales_associate'].includes(this.$root.sessionStore.user.role_id['$NG'])
			);
		},
		filteredAccounts() {
			if (this.show_archived) {
				return this.accounts;
			}
			return this.accounts.filter((account) => {
				return account.status !== 'archived';
			});
		},
	},
	watch: {
		search_query: {
			handler: debounce(async function (new_value, old_value) {
				if (new_value.length === 0 || new_value.length > 2) {
					await this.getAccounts(true);
				}
			}, 250),
		},
	},
	async created() {
		this.user_options = await getInternalUsersAsOptions('sales');
	},
	async mounted() {
		if (this.isAM) {
			this.query.filters.account_manager_id = this.$root.sessionStore.user.id;
		}
		await this.getAccounts();
	},
	methods: {
		currency,
		capitalize,
		displaySelected,
		formatDate,
		title,
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getAccounts();
		},
		async getAccounts(force_new = false) {
			this.loading = true;
			// does GQL CALL

			// we convert query values into QueryParams & filters
			const account_filters = [[`a.mpid = '${this.mpid}'`, `a.is_parent != true`]];

			if (this.query.filters.account_status.length > 0) {
				account_filters[0].push(`a.status IN ('${this.query.filters.account_status.join(`','`)}')`);
			}

			if (this.query.filters.account_support_status.length > 0) {
				account_filters[0].push(
					`JSONExtractString(a.support, 'status') IN ('${this.query.filters.account_support_status.join(`','`)}')`
				);
			}

			if (this.query.filters.account_manager_id) {
				account_filters[0].push(
					`JSONExtractString(a.support, 'account_manager_id') = '${this.query.filters.account_manager_id}'`
				);
			}

			if (this.search_query) {
				const search_query = this.search_query.toLowerCase();
				account_filters[0].push(
					`LOWER(a.name) LIKE '%${search_query}%' OR LOWER(u.first_name) LIKE '%${search_query}%' OR LOWER(u.last_name) LIKE '%${search_query}%'`
				);
			}

			try {
				const result = await reportAccountList(
					{
						account_filters,
						date_range: this.query.filters.created_at,
						pagination: this.query.pagination,
						sort: this.query.sort,
					},
					force_new
				);
				this.accounts = result.rows;
				this.total_records = result.row_count;
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Accounts',
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
		changePage(pagination) {
			this.query.pagination.page = pagination.page;
			this.getAccounts(true);
		},
		async sortAccounts(sort) {
			this.query.sort.field = sort.sortField;
			this.query.sort.desc = false;
			if (sort.sortOrder === -1) {
				this.query.sort.desc = true;
			}
			await this.getAccounts(true);
		},
		// Generate styles for an account manager avatar
		avatar(buyer) {
			let support_user = {
				name: {
					first: 'N',
					last: 'A',
				},
			};

			if (buyer.account_owner) {
				support_user = buyer.account_owner;
			}

			return (
				'background-size: cover; background-image: url("//ui-avatars.com/api?size=40&font-size=0.5&background=' +
				get(support_user, 'color', '000000').replace('#', '') +
				'&color=FFFFFF&name=' +
				support_user.name.first +
				' ' +
				support_user.name.last +
				'")'
			);
		},
		supportName(buyer) {
			let support_user = {
				name: {
					first: 'N',
					last: 'A',
				},
			};
			if (buyer.account_owner) {
				support_user = buyer.account_owner;
			}
			return support_user.name.first + ' ' + support_user.name.last;
		},
		async removeAccount(account_id) {
			const delete_result = await deleteAccount(account_id);
			let message = 'The account was deleted';
			if (delete_result.archived > 0) {
				message = 'The account was archived';
			}
			this.$toast.add({
				severity: 'success',
				summary: message,
				life: 3000,
			});
			await this.getAccounts(true);
		},
		clearQuery() {
			this.query = {
				range: [startOfDay(new Date()), endOfDay(new Date())],
			};
		},
		formatCSVData(data) {
			switch (data.field) {
				case 'created_at':
					return this.formatDate(data.data, 'YYYY-MM-DD h:mm:ssa');
				case 'verticals':
				case 'products':
					return data.data.join(', ');
				case 'spend':
				case 'balance':
					return this.currency(data.data);
				default:
					return data.data;
			}
		},
		handleShowAll() {
			if (this.show_all === true) {
				if (this.isAM) {
					this.query.filters.account_manager_id = this.$root.sessionStore.user.id;
					this.getAccounts();
					this.show_all = false;
				}
			} else {
				if (this.isAM) {
					this.query.filters.account_manager_id = null;
					this.getAccounts();
					this.show_all = true;
				}
			}
		},
		exportCSV() {
			this.$refs.downloadtable.exportCSV();
		},
	},
};
</script>

<style lang="less" scoped>
.account-info {
	display: flex;
	justify-content: space-between;
}

.avatar {
	border: 1px solid var(--gray-25);
	border-radius: 20px;
	display: inline-block;
	height: 40px;
	margin: 5px 0 5px 10px;
	width: 40px;
}

.campaign-type {
	margin-right: 0.25rem;
}

.verticals {
	display: inline-flex;

	.vertical-icon {
		margin-right: 0.25rem;
	}

	.vertical-icon:last-child {
		margin-right: 0;
	}
}

.campaign-type {
	font-size: 1.5rem;
}
</style>
