import { $GQL } from '@GQL';
import type { Vendor } from '@nextgenleads/source-driver';

export async function getVendorById(id: string): Promise<Omit<Vendor, 'created_at' | 'modified_at'>> {
	const query = `
        query GetVendorById($id: ID!) {
            vendor(id: $id) {
                id
                name
                verticals {
                    auto_insurance {
                        data
                        call
                        live_transfer
                        ad
                    }
                    health_insurance {
                        data
                        call
                        live_transfer
                        ad
                    }
                    medicare {
                        data
                        call
                        live_transfer
                        ad
                    }
                }
                contact {
                    name
                    phone
                    email
                }
                settings {
                    max_return_rate
                    allow_cancellations
                    bid_margin
                }
                status
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.vendor;
}
