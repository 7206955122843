import { $GQL } from '@GQL/index';

export async function getRandomDummyLead(vertical: string, product: string) {
	const query = `
        query GetRandomDummyLead($vertical: String, $product: String) {
            getRandomDummyLead(vertical: $vertical, product: $product) {
                    id
                    mpid
                    ulid
                    vendor_id
                    vendor_lead_id
                    product
                    vertical_id
                    first_name
                    last_name
                    street_1
                    street_2
                    city
                    state
                    zip
                    county
                    lat
                    lng
                    email
                    phone
                    data
                    meta
                    dnc
                    leadid_token
                    trustedform_id
                    trustedform_cert_url
                    trustedform_share_url
                    elid
                    spoken_language
                    tcpa
                    permanent_id
                    session_id
                    source_id
                    sub_id
                    source_tags
                    business_unit
                    source_type
                    source_segments
                    site_id
                    click_network
                    click_token
                    tags
                    duplicate
                    spam
                    test
                    cost
                    created_at
                    modified_at
            }
        }
    `;

	const result = await $GQL.request(query, { vertical, product });
	return result.getRandomDummyLead;
}
