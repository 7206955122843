<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>{{ $route.meta.title }}</h1>
		</div>
		<div class="page-content">
			<row>
				<column>
					<div class="flex align-items-center justify-content-end w-full activity-quick-filters">
						<quick-filter v-model="timeframe" value="daily">Daily</quick-filter>
						<quick-filter v-model="timeframe" value="weekly">Weekly</quick-filter>
						<quick-filter v-model="timeframe" value="monthly">Monthly</quick-filter>
					</div>
				</column>
			</row>
			<gutter size="10px" />
			<div class="card padded max-h-screen">
				<p-data-table
					ref="bizunitpeformance"
					:value="performance_results"
					rowGroupMode="subheader"
					groupRowsBy="category"
					scrollable
					scrollHeight="800px"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No Data</div>
					</template>
					<p-column frozen class="max-w-12rem">
						<template #body>
							<!-- Profit -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('profit')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('profit') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Profit</strong>
							</div>
							<div v-if="!hide_categories.includes('profit')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- ROI -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('roi')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('roi') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>ROI</strong>
							</div>
							<div v-if="!hide_categories.includes('roi')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Revenue Per Lead -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('revenue_per_lead')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('revenue_per_lead') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Revenue Per Lead</strong>
							</div>
							<div v-if="!hide_categories.includes('revenue_per_lead')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Cost Per Lead -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('cost_per_lead')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('cost_per_lead') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Cost Per Lead</strong>
							</div>
							<div v-if="!hide_categories.includes('cost_per_lead')" class="cost_per_lead">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Customer Count -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('customer_count')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('customer_count') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Customer Count</strong>
							</div>
							<div v-if="!hide_categories.includes('customer_count')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Revenue -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('revenue')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('revenue') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Revenue</strong>
							</div>
							<div v-if="!hide_categories.includes('revenue')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Cost -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('cost')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('cost') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Cost</strong>
							</div>
							<div v-if="!hide_categories.includes('cost')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Counts -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('counts')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('counts') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Counts</strong>
							</div>
							<div v-if="!hide_categories.includes('counts')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Profit Percentage -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('profit_percentage')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('profit_percentage') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Profit Percentage</strong>
							</div>
							<div v-if="!hide_categories.includes('profit_percentage')">
								<div class="cell sub-value">OO</div>
								<div class="cell sub-value">PN</div>
								<div class="cell sub-value">CP</div>
								<div class="cell sub-value">Total</div>
							</div>
							<!-- Sanity Check -->
							<div class="cell bg-gray-100">
								<a @click="toggleHidden('sanity_check')"
									><icon
										:key="componentKey"
										:type="hide_categories.includes('sanity_check') ? 'plus' : 'minus'"
										class="mr-2" /></a
								><strong>Sanity Check</strong>
							</div>
							<div v-if="!hide_categories.includes('sanity_check')">
								<div class="cell sub-value">Campaigns Table Revenue</div>
								<div class="cell sub-value">% Difference</div>
							</div>
						</template>
					</p-column>
					<p-column v-for="date in dates" :header="date" :key="date" class="max-w-8rem">
						<template #body="row">
							<!-- profit÷ -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('profit')">
								<div class="cell" :class="colorCode(get(row.data, date + '.profit.oo', 0), 'profit', 'oo')">
									{{ currency(get(row.data, date + '.profit.oo', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.profit.pn', 0), 'profit', 'pn')">
									{{ currency(get(row.data, date + '.profit.pn', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.profit.cp', 0), 'profit', 'cp')">
									{{ currency(get(row.data, date + '.profit.cp', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.profit.total', 0), 'profit', 'total')">
									{{ currency(get(row.data, date + '.profit.total', 0), 0) }}
								</div>
							</div>
							<!-- roi -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('roi')">
								<div class="cell" :class="colorCode(get(row.data, date + '.roi.oo', 0), 'roi', 'oo')">
									{{ percentage(get(row.data, date + '.roi.oo', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.roi.pn', 0), 'roi', 'pn')">
									{{ get(row.data, date + '.roi.pn', 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.roi.cp', 0), 'roi', 'cp')">
									{{ get(row.data, date + '.roi.cp', 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.roi.total', 0), 'roi', 'total')">
									{{ get(row.data, date + '.roi.total', 0) }}
								</div>
							</div>
							<!-- revenue_per_lead -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('revenue_per_lead')">
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.revenue_per_lead.oo', 0), 'revenue_per_lead', 'oo')"
								>
									{{ currency(get(row.data, date + '.revenue_per_lead.oo', 0), 2) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.revenue_per_lead.pn', 0), 'revenue_per_lead', 'pn')"
								>
									{{ currency(get(row.data, date + '.revenue_per_lead.pn', 0), 2) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.revenue_per_lead.cp', 0), 'revenue_per_lead', 'cp')"
								>
									{{ currency(get(row.data, date + '.revenue_per_lead.cp', 0), 2) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.revenue_per_lead.total', 0), 'revenue_per_lead', 'total')"
								>
									{{ currency(get(row.data, date + '.revenue_per_lead.total', 0), 2) }}
								</div>
							</div>
							<!-- cost_per_lead -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('cost_per_lead')">
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.cost_per_lead.oo', 0), 'cost_per_lead', 'oo')"
								>
									{{ currency(get(row.data, date + '.cost_per_lead.oo', 0), 2) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.cost_per_lead.pn', 0), 'cost_per_lead', 'pn')"
								>
									{{ currency(get(row.data, date + '.cost_per_lead.pn', 0), 2) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.cost_per_lead.cp', 0), 'cost_per_lead', 'cp')"
								>
									{{ currency(get(row.data, date + '.cost_per_lead.cp', 0), 2) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.cost_per_lead.total', 0), 'cost_per_lead', 'total')"
								>
									{{ currency(get(row.data, date + '.cost_per_lead.total', 0), 2) }}
								</div>
							</div>
							<!-- customer_count -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('customer_count')">
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.customer_count.oo', 0), 'customer_count', 'oo')"
								>
									{{ get(row.data, date + '.customer_count.oo', 0) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.customer_count.pn', 0), 'customer_count', 'pn')"
								>
									{{ get(row.data, date + '.customer_count.pn', 0) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.customer_count.cp', 0), 'customer_count', 'cp')"
								>
									{{ get(row.data, date + '.customer_count.cp', 0) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.customer_count.total', 0), 'customer_count', 'total')"
								>
									{{ get(row.data, date + '.customer_count.total', 0) }}
								</div>
							</div>
							<!-- revenue -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('revenue')">
								<div class="cell" :class="colorCode(get(row.data, date + '.revenue.oo', 0), 'revenue', 'oo')">
									{{ currency(get(row.data, date + '.revenue.oo', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.revenue.pn', 0), 'revenue', 'pn')">
									{{ currency(get(row.data, date + '.revenue.pn', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.revenue.cp', 0), 'revenue', 'cp')">
									{{ currency(get(row.data, date + '.revenue.cp', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.revenue.total', 0), 'revenue', 'total')">
									{{ currency(get(row.data, date + '.revenue.total', 0), 0) }}
								</div>
							</div>
							<!-- cost -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('cost')">
								<div class="cell" :class="colorCode(get(row.data, date + '.cost.oo', 0), 'cost', 'oo')">
									{{ currency(get(row.data, date + '.cost.oo', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.cost.pn', 0), 'cost', 'pn')">
									{{ currency(get(row.data, date + '.cost.pn', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.cost.cp', 0), 'cost', 'cp')">
									{{ currency(get(row.data, date + '.cost.cp', 0), 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.cost.total', 0), 'cost', 'total')">
									{{ currency(get(row.data, date + '.cost.total', 0), 0) }}
								</div>
							</div>
							<!-- counts -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('counts')">
								<div class="cell" :class="colorCode(get(row.data, date + '.counts.oo', 0), 'counts', 'oo')">
									{{ get(row.data, date + '.counts.oo', 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.counts.pn', 0), 'counts', 'pn')">
									{{ get(row.data, date + '.counts.pn', 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.counts.cp', 0), 'counts', 'cp')">
									{{ get(row.data, date + '.counts.cp', 0) }}
								</div>
								<div class="cell" :class="colorCode(get(row.data, date + '.counts.total', 0), 'counts', 'total')">
									{{ get(row.data, date + '.counts.total', 0) }}
								</div>
							</div>
							<!-- profit_percentage -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('profit_percentage')">
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.profit_percentage.oo', 0), 'profit_percentage', 'oo')"
								>
									{{ percentage(get(row.data, date + '.profit_percentage.oo', 0), 0) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.profit_percentage.pn', 0), 'profit_percentage', 'pn')"
								>
									{{ percentage(get(row.data, date + '.profit_percentage.pn', 0), 0) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.profit_percentage.cp', 0), 'profit_percentage', 'cp')"
								>
									{{ percentage(get(row.data, date + '.profit_percentage.cp', 0), 0) }}
								</div>
								<div
									class="cell"
									:class="colorCode(get(row.data, date + '.profit_percentage.total', 0), 'profit_percentage', 'total')"
								>
									{{ percentage(get(row.data, date + '.profit_percentage.total', 0), 0) }}
								</div>
							</div>
							<!-- sanity_check -->
							<div class="cell bg-gray-100">&nbsp;</div>
							<div v-if="!hide_categories.includes('sanity_check')">
								<div class="cell">
									{{ currency(get(row.data, date + '.sanity_check.campaigns_table_revenue', 0), 0) }}
								</div>
								<div class="cell">
									{{ percentage(get(row.data, date + '.sanity_check.difference', 0), 0) }}
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import quickFilter from '@/components/widgets/QuickFilter.vue';
import { currency, percentage, colorCell } from '@/lib/Filters';
import { get } from 'lodash-es';

export default {
	name: 'BizUnitPerformance',
	components: {
		quickFilter,
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			timeframe: 'daily',
			componentKey: 0,
			hide_categories: [],
			group_headers: {
				profit: 'Profit',
				roi: 'ROI',
				revenue_per_lead: 'Revenue Per Lead',
				cost_per_lead: 'Cost Per Lead',
				customer_count: 'Customer Count',
				revenue: 'Revenue',
				cost: 'Cost',
				counts: 'Counts',
				profit_percentage: 'Profit Percentage',
				sanity_check: 'Sanity Check',
			},
			key_labels: [
				{ key: 'oo', label: 'Owned & Operated' },
				{ key: 'pn', label: 'Partner Networks' },
				{ key: 'cp', label: 'Call Partners' },
				{ key: 'total', label: 'Total' },
				{ key: 'campaigns_table_revenue', label: 'Campaigns Table Revenue' },
				{ key: 'difference', label: '% Difference' },
			],
			// Minmax values for color coding
			minmax: {
				profit: {
					oo: { min: 0, max: 1000 },
					pn: { min: 0, max: 1000 },
					cp: { min: 0, max: 1000 },
					total: { min: 0, max: 1000 },
				},
				roi: {
					oo: { min: 0, max: 1 },
					pn: { min: 0, max: 1 },
					cp: { min: 0, max: 1 },
					total: { min: 0, max: 1 },
				},
				revenue_per_lead: {
					oo: { min: 0, max: 10 },
					pn: { min: 0, max: 10 },
					cp: { min: 0, max: 10 },
					total: { min: 0, max: 10 },
				},
				cost_per_lead: {
					oo: { min: 0, max: 10 },
					pn: { min: 0, max: 10 },
					cp: { min: 0, max: 10 },
					total: { min: 0, max: 10 },
				},
				customer_count: {
					oo: { min: 0, max: 100 },
					pn: { min: 0, max: 100 },
					cp: { min: 0, max: 100 },
					total: { min: 0, max: 100 },
				},
				revenue: {
					oo: { min: 0, max: 1000 },
					pn: { min: 0, max: 1000 },
					cp: { min: 0, max: 1000 },
					total: { min: 0, max: 1000 },
				},
				cost: {
					oo: { min: 0, max: 1000 },
					pn: { min: 0, max: 1000 },
					cp: { min: 0, max: 1000 },
					total: { min: 0, max: 1000 },
				},
				counts: {
					oo: { min: 0, max: 100 },
					pn: { min: 0, max: 100 },
					cp: { min: 0, max: 100 },
					total: { min: 0, max: 100 },
				},
				profit_percentage: {
					oo: { min: 0, max: 1 },
					pn: { min: 0, max: 1 },
					cp: { min: 0, max: 1 },
					total: { min: 0, max: 1 },
				},
			},
			performance_results: [
				{
					'2024-01-01': {
						day: '2024-01-01',
						profit: { oo: 42.89, pn: 111.13, cp: 65.09, total: 231 },
						roi: { oo: 0.46, pn: 0.42, cp: 0.32, total: 0.65 },
						revenue_per_lead: { oo: 5.97, pn: 5.73, cp: 5.9, total: 6.7 },
						cost_per_lead: { oo: 4.28, pn: 5.49, cp: 6.99, total: 5.44 },
						customer_count: { oo: 20, pn: 24, cp: 35, total: 81 },
						revenue: { oo: 30.55, pn: 61.87, cp: 220.16, total: 361.64 },
						cost: { oo: 47.75, pn: 96.34, cp: 75.08, total: 130.64 },
						counts: { oo: 12, pn: 24, cp: 41, total: 81 },
						profit_percentage: { oo: 0.37, pn: 0.51, cp: 0.56, total: 0.6 },
						sanity_check: { campaigns_table_revenue: 1038342, difference: 0.07 },
					},
					'2024-01-02': {
						day: '2024-01-02',
						profit: { oo: 35.33, pn: 81.83, cp: 97.09, total: 205 },
						roi: { oo: 0.41, pn: 0.52, cp: 0.04, total: 0.77 },
						revenue_per_lead: { oo: 7.14, pn: 7.5, cp: 7.21, total: 7.01 },
						cost_per_lead: { oo: 6.7, pn: 4.27, cp: 7.41, total: 4.85 },
						customer_count: { oo: 9, pn: 13, cp: 19, total: 41 },
						revenue: { oo: 48.32, pn: 294.5, cp: 136.9, total: 491.38 },
						cost: { oo: 8.35, pn: 72.3, cp: 118.82, total: 286.38 },
						counts: { oo: 8, pn: 8, cp: 16, total: 41 },
						profit_percentage: { oo: 0.59, pn: 0.53, cp: 0.69, total: 0.51 },
						sanity_check: { campaigns_table_revenue: 990899, difference: 0.02 },
					},
					'2024-01-03': {
						day: '2024-01-03',
						profit: { oo: 39.77, pn: 71.58, cp: 10.57, total: 120 },
						roi: { oo: 0.37, pn: 0.35, cp: 0.07, total: 0.63 },
						revenue_per_lead: { oo: 6.83, pn: 5.07, cp: 6.2, total: 5.39 },
						cost_per_lead: { oo: 5.3, pn: 5.73, cp: 6.43, total: 4.82 },
						customer_count: { oo: 5, pn: 2, cp: 18, total: 25 },
						revenue: { oo: 33.35, pn: 76.85, cp: 143.42, total: 267.32 },
						cost: { oo: 43.92, pn: 21.7, cp: 93.56, total: 147.32 },
						counts: { oo: 2, pn: 2, cp: 8, total: 25 },
						profit_percentage: { oo: 0.63, pn: 0.4, cp: 0.61, total: 0.39 },
						sanity_check: { campaigns_table_revenue: 1092524, difference: 0.06 },
					},
					'2024-01-04': {
						day: '2024-01-04',
						profit: { oo: 23.89, pn: 54.1, cp: 19.97, total: 131 },
						roi: { oo: 0.68, pn: 0.39, cp: 0.08, total: 0.4 },
						revenue_per_lead: { oo: 4.54, pn: 4.76, cp: 4.37, total: 4.83 },
						cost_per_lead: { oo: 5.88, pn: 6.11, cp: 6.61, total: 5.69 },
						customer_count: { oo: 17, pn: 23, cp: 32, total: 80 },
						revenue: { oo: 35.48, pn: 191.06, cp: 92.31, total: 319.6 },
						cost: { oo: 9.76, pn: 84.12, cp: 40.69, total: 188.6 },
						counts: { oo: 17, pn: 25, cp: 28, total: 80 },
						profit_percentage: { oo: 0.41, pn: 0.63, cp: 0.34, total: 0.34 },
						sanity_check: { campaigns_table_revenue: 1089115, difference: 0.03 },
					},
					'2024-01-05': {
						day: '2024-01-05',
						profit: { oo: 64.44, pn: 132.33, cp: 98.96, total: 347 },
						roi: { oo: 0.39, pn: 0.41, cp: 0.31, total: 0.73 },
						revenue_per_lead: { oo: 5.12, pn: 5.21, cp: 4.9, total: 5.52 },
						cost_per_lead: { oo: 5.84, pn: 4.04, cp: 6.8, total: 5.56 },
						customer_count: { oo: 17, pn: 30, cp: 25, total: 77 },
						revenue: { oo: 195.66, pn: 317.49, cp: 260.7, total: 739.61 },
						cost: { oo: 40.14, pn: 215.55, cp: 251.99, total: 392.61 },
						counts: { oo: 19, pn: 32, cp: 35, total: 77 },
						profit_percentage: { oo: 0.66, pn: 0.44, cp: 0.54, total: 0.56 },
						sanity_check: { campaigns_table_revenue: 977607, difference: 0.02 },
					},
				},
			],
		};
	},
	computed: {
		vertical() {
			return this.$route.meta.vertical;
		},
		dates() {
			return Object.keys(this.performance_results[0]);
		},
	},
	methods: {
		get,
		currency,
		percentage,
		colorCell,
		colorCode(value, category, metric) {
			return colorCell(value, this.minmax[category][metric].min, this.minmax[category][metric].max);
		},
		toggleHidden(category) {
			if (this.hide_categories.includes(category)) {
				this.hide_categories = this.hide_categories.filter((c) => c !== category);
			} else {
				this.hide_categories.push(category);
			}
			this.componentKey += 1;
		},
	},
};
</script>

<style lang="less" scoped>
:deep(.p-datatable .p-datatable-tbody > tr > td) {
	padding: 0;
}

.cell {
	padding: 0.25rem 0.5rem;
	background: var(--white);
	&:hover {
		filter: brightness(0.9);
		color: black !important;
	}
}

.sub-value {
	margin: 0;
}
</style>
