<template>
	<p-card id="login-form">
		<template #header>
			<div class="flex w-full p-2 justify-content-center align-items-center text-center">
				<img class="logo" :src="logoUrl" alt="NextGen Leads Logo" />
			</div>
		</template>
		<template #title>
			<div class="login-title">
				<h2>Sign In</h2>
				<div class="signup">Don't have an account? <router-link :to="'/sign-up'">Sign Up</router-link></div>
			</div>
		</template>
		<template #content>
			<flash-message />
			<form>
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<div class="p-inputgroup">
											<span class="p-inputgroup-addon">
												<alt-icon type="email" size="1.25rem" />
											</span>
											<p-input-text
												v-model.trim="email"
												name="email"
												class="p-inputtext-sm flex-1"
												aria-labelledby="email"
												placeholder="Email"
												v-focus
											/>
										</div>
										<div v-if="v$.email.$error" class="validation-error">
											{{ v$.email.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
				<gutter size="20px" />
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<div class="p-inputgroup">
											<span class="p-inputgroup-addon">
												<alt-icon type="lock" size="1.25rem" />
											</span>
											<p-password
												v-model="password"
												name="password"
												class="flex-1"
												:feedback="false"
												toggle-mask
												aria-labelledby="password"
												placeholder="Password"
												@keypress.enter="login()"
											/>
										</div>
										<div v-if="v$.password.$error" class="validation-error">
											{{ v$.password.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</form>
		</template>
		<template #footer>
			<div class="flex flex-column justify-content-between gap-4 w-full">
				<div class="flex justify-content-between align-items-center w-full">
					<a @click.prevent="$emit('changeForm', 'RequestPasswordForm', email)">I forgot my password</a>
					<p-button :loading="logging_in" label="SIGN IN" @click="login()" />
				</div>
				<div class="legal">
					&copy;{{ year }} by NextGen Leads<br />
					<router-link :to="{ path: '/terms' }">Terms and Conditions</router-link> |
					<router-link :to="{ path: '/privacy-policy' }">Privacy Policy</router-link>
				</div>
			</div>
		</template>
	</p-card>
</template>

<script lang="ts">
import { get } from 'lodash-es';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { format as formatDate } from 'date-fns';
import flashMessage from '@/components/widgets/FlashMessage.vue';
import subdomains from '@/lib/Data/subdomains.json';

export default {
	name: 'LoginForm',
	emits: ['changeForm'],
	components: {
		flashMessage,
	},
	setup() {
		return { v$: useVuelidate() };
	},
	props: {
		loginEmail: {
			type: String,
		},
	},
	data() {
		return {
			logging_in: false,
			email: this.loginEmail || '',
			password: '',
		};
	},
	computed: {
		logoUrl() {
			return get(this.$root.appStore, 'marketplace.settings.logo_url', '/assets/img/logos/ngl-logo.svg');
		},
		year() {
			return formatDate(new Date(), 'yyyy');
		},
	},
	mounted() {
		this.$root.sessionStore.$reset();
	},
	methods: {
		async login() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) return;

			try {
				this.logging_in = true;
				const login_result = await this.$root.sessionStore.login({
					email: this.email,
					password: this.password,
					mpid: this.$root.appStore.mpid,
				});

				if (login_result.mpid) {
					const origin = window.location.host.split('.');
					const current_subdomain = origin[0];
					let new_subdomain = null;

					if (subdomains[current_subdomain] !== login_result.mpid.toUpperCase()) {
						Object.keys(subdomains).forEach((subdomain) => {
							if (subdomains[subdomain] === login_result.mpid.toUpperCase()) {
								new_subdomain = subdomain;
							}
						});
					}

					if (new_subdomain) {
						origin[0] = new_subdomain;
						window.location.href = `https://${origin.join('.')}${this.$route.query.path || '/'}?token=${login_result.token}`;
					}
				} else {
					this.$router.replace({ path: this.$route.query.path || '/' });
				}
			} finally {
				this.logging_in = false;
			}
		},
	},
	validations() {
		return {
			email: {
				required: helpers.withMessage('Please enter your email address', required),
				email: helpers.withMessage('Please enter a valid email address', email),
			},
			password: {
				required: helpers.withMessage('Please enter your password', required),
			},
		};
	},
};
</script>

<style scoped lang="less">
.login-title {
	margin: 1rem 0 3rem;
	text-align: center;

	h2 {
		font-size: 1.5rem;
		margin-bottom: 0.25em;
	}

	.signup {
		color: var(--gray-50);
		font-size: 0.875rem;
		font-weight: normal;
	}
}

#login-form {
	border-radius: 20px;
	box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
	padding: 30px 30px 10px;
	width: 450px;

	.logo {
		max-height: 200px;
		max-width: 100%;
	}

	.p-card-footer {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
}

.easy-access {
	background-color: #fff1cc;
	border: 1px dashed #ffd566;
	border-radius: 5px;
	padding: 20px;
}

.legal {
	color: var(--gray-45);
	font-size: 0.75rem;
	line-height: 1.5em;
	margin-top: 20px;
	text-align: center;
}
</style>
