<template>
	<div class="Disqualifications">
		<row>
			<div class="w-full flex justify-content-end align-items-center">
				<p-button
					v-tooltip.top="'Refresh'"
					label="Refresh Results"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="refreshReports"
				/>
			</div>
			<gutter size="20px" />
		</row>
		<div class="flex align-items-center justify-content-center w-full">
			<p-progress-spinner
				v-if="loading"
				class="spinner"
				style="height: 64px; width: 64px; margin: 0 auto"
				:pt="{ circle: { style: { stroke: 'var(--color-a)', strokeWidth: 2 } } }"
			/>
		</div>
		<row v-if="!loading && loaded" class="flex-column-reverse md:flex-row">
			<column :span="2">
				<div class="card">
					<div class="card-heading padded">
						<div class="flex align-items-center">
							<icon type="gavel" size="20px" />
							<h2>Reasons</h2>
						</div>
					</div>
					<div class="dq-reasons padded">
						<p-message v-if="dq_reasons.length === 0 && loaded" :closable="false"
							>This campaign has not been disqualified from any auctions during the selected date range.</p-message
						>
						<div v-else>
							<p-data-table :value="dq_reasons" sort-field="state">
								<template #empty>
									<div class="dim">No data</div>
								</template>
								<p-column field="reason" sortable header="Disqualification Reason">
									<template #body="row">
										<div v-if="$root.sessionStore.isAdminUser">
											<a @click.prevent="handleShowLeadByDQReason(row.data.dq_reason)">
												{{ row.data.dq_reason }}
											</a>
											<icon
												class="ml-1"
												v-if="row.data.dq_reason === 'Available balance is less than bid'"
												type="information-outline"
												v-tooltip.top="'Add more funds or set up auto-funding'"
											/>
										</div>
										<div v-else>
											{{ row.data.dq_reason }}
										</div>
									</template>
								</p-column>
								<p-column field="count" sortable header="Count">
									<template #body="row">
										{{ row.data.count }}
									</template>
								</p-column>
								<p-column field="percentage" sortable header="Overall Percentage of DQs">
									<template #body="row"> {{ percentage(row.data.percentage, 2) }} </template>
								</p-column>
							</p-data-table>
						</div>
					</div>
				</div>
				<p-dialog
					v-if="$root.sessionStore.isAdminUser"
					v-model:visible="show_lead_by_dq_reason"
					:modal="true"
					:dismissable-mask="true"
					:pt="{
						root: { class: 'w-12 sm:w-12 md:w-6' },
					}"
				>
					<template #header>
						<div class="flex gap-2 align-content-center align-items-center">
							<icon type="account" size="24px" style="color: var(--color-b)" />
							<strong>Disqualified Leads By DQ Reason</strong>
						</div>
					</template>
					<div class="flex-flex-column">
						<p-data-table :loading="leads_loading" :value="leads_by_dq_reason" sort-field="state">
							<template #loading>
								<line-loader :show="leads_loading" />
							</template>
							<template #empty>
								<div class="dim">No data</div>
							</template>
							<p-column field="created_at" sortable header="Date">
								<template #body="row">
									{{ formatDate(row.data.created_at, 'ddd, MMM D, YYYY h:mm:ssA') || '' }}<br />
									<div class="sub-value">{{ timeAgo(row.data.created_at) }}</div>
								</template>
							</p-column>
							<p-column field="lead_id" sortable header="Lead ID">
								<template #body="row">
									<LeadModal :lead_id="row.data.lead_id" />
								</template>
							</p-column>
						</p-data-table>
						<p-paginator
							:rows="pagination.page_size"
							:total-records="total_leads_by_dq_reason"
							@page="handlePageChange"
						/>
					</div>
				</p-dialog>
			</column>
			<gutter size="20px" />
			<column>
				<div class="card">
					<div class="card-heading padded">
						<div class="flex align-items-center">
							<icon type="gavel" size="20px" />
							<h2>Chart</h2>
						</div>
					</div>
					<div class="dq-reasons padded">
						<p-message v-if="dq_reasons.length === 0 && loaded" :closable="false"
							>This campaign has not been disqualified from any auctions during the selected date range.</p-message
						>
						<div v-for="(row, index) in dq_reasons" class="dq-reason" :key="`reason-${index}`">
							<div class="reason flex space-between gap-2">
								<div class="text flex-1">
									<strong>{{ row.dq_reason }}</strong>
								</div>
								<div class="num">
									<span class="percentage"
										>{{ numberFormat(row.count) }}
										<span style="color: var(--gray-90)">({{ percentage(row.percentage, 2) }})</span></span
									>
								</div>
							</div>
							<div class="visualization">
								<div class="bar">
									<div class="progress" :style="{ width: percentage(row.percentage, 2) }"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</column>
		</row>
	</div>
</template>

<script lang="ts">
import { useSessionStore } from '@/stores/session';
import { currency, formatDate, numberFormat, percentage, timeAgo } from '@/lib/Filters';
import { getDQReasons, getLeadsByDQReason } from '@/lib/GQL';
import pProgressSpinner from 'primevue/progressspinner';
import LeadModal from './Modals/LeadModal.vue';
import dayjs from 'dayjs';

export default {
	name: 'CampaignStatsDQReasons',
	setup() {
		const sessionStore = useSessionStore();
		return { sessionStore };
	},
	props: {
		query: {
			type: Object,
			required: true,
		},
	},
	components: {
		LeadModal,
		pProgressSpinner,
	},
	data() {
		return {
			dq_reasons: [],
			loading: false,
			loaded: false,
			show_lead_by_dq_reason: false,
			leads_loading: false,
			selected_reason: '',
			selected_lead: '',
			total_leads_by_dq_reason: 0,
			leads_by_dq_reason: [],
			pagination: {
				page_size: 10,
				page: 0,
			},
		};
	},
	methods: {
		currency,
		formatDate,
		numberFormat,
		percentage,
		timeAgo,
		async getDQReasons(refresh = false) {
			this.loading = true;
			this.loaded = false;
			const query_starttime = dayjs().format('YYYY-MM-DD HH:mm:ss');
			try {
				let no_cache = [];
				if (refresh) {
					no_cache = ['reportDQReasons'];
				}

				const dq_reasons = await getDQReasons(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
						show_internal_reasons: this.sessionStore.isAdminUser,
					},
					no_cache
				);

				this.dq_reasons = dq_reasons;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get DQ Reasons',
					detail: 'Try again or contact dev support',
				});
			} finally {
				this.loading = false;
				this.loaded = true;
			}
		},
		clearSelection() {
			this.selected_reason = '';
			this.leads_by_dq_reason = [];
			this.total_leads_by_dq_reason = 0;
			this.pagination.page = 0;
		},
		async refreshReports() {
			await this.getDQReasons(true);
		},
		async handleShowLeadByDQReason(reason) {
			this.show_lead_by_dq_reason = true;
			this.clearSelection();
			this.selected_reason = reason;
			await this.fetchLeadsByDQReason();
		},
		handlePageChange(data) {
			this.pagination.page = data.page;
			return this.fetchLeadsByDQReason();
		},
		async fetchLeadsByDQReason() {
			try {
				this.leads_loading = true;
				const { rows, row_count } = await getLeadsByDQReason({
					date_range: this.query.date_range,
					campaign_id: this.$route.params.campaign_id,
					dq_reason: this.selected_reason,
					pagination: this.pagination,
				});
				this.leads_by_dq_reason = rows;
				this.total_leads_by_dq_reason = row_count;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get leads by DQ reason',
					detail: 'Try again or contact dev support',
				});
			} finally {
				this.leads_loading = false;
			}
		},
	},
	mounted() {
		this.getDQReasons().catch((err) => {});
	},
};
</script>

<style lang="less" scoped>
.dq-reasons {
	:deep(.p-message) {
		margin: 0 0;
	}
}

.dq-reason {
	margin-bottom: 1em;

	.reason {
		font-size: 0.875rem;
		margin-bottom: 5px;
		line-height: 1.5;
	}

	.percentage {
		color: var(--gray-60);
	}

	.visualization {
		align-items: center;
		display: flex;
	}

	.bar {
		flex: 1 0;

		.progress {
			background-color: var(--color-b);
			height: 20px;
		}
	}

	.count {
		flex: 0 0 30px;
	}
}
</style>
