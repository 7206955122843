import { get, pick, startsWith } from 'lodash-es';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { initAdminSession, initMarketplaceSession, login as loginReq, verifyAuth, type LoginParams } from '@GQL';
import { io } from 'socket.io-client';
import log from '@/lib/Log';
import useToast from '@/lib/Toast';
import subdomains from '@/lib/Data/subdomains.json';

export const useSessionStore = defineStore('session', {
	state() {
		return {
			token: '',
			user: {
				id: '',
				account_id: '',
				first_name: '',
				last_name: '',
				role_id: '',
				email: '',
				phone: '',
				title: '',
				avatar_url: '',
				last_login: '',
				settings: {
					calendly_url: '',
					team: '',
				},
			},
			role: {
				id: '',
				type: '',
				granted: [],
				revoked: [],
				status: '',
			},
			account: {
				id: '',
				name: '',
				primary_user_id: '',
				verified: false,
				is_parent: false,
				balance: {
					actual: 0,
				},
				contact: {},
				settings: {
					timezone: 'America/Los_Angeles',
					budgets: [],
					allow_advanced_mode: false,
					enable_peer_competition: false,
				},
				support: {
					account_manager: {
						id: null,
						first_name: null,
						last_name: null,
						email: null,
						phone: null,
						avatar_url: null,
					},
					first_fund_date: null,
					kpi_targets: {
						data_cpa: 0,
						call_cpa: 0,
						live_transfer_cpa: 0,
						products_sold: '',
					},
				},
				signup_data: {
					activity_score: 0,
					primary_vertical: null,
				},
			},
			navigation: {},
			socket: null,
		};
	},
	getters: {
		fullName(): string {
			return this.user.first_name + ' ' + this.user.last_name;
		},
		isTokenSet(): boolean {
			return !!this.token;
		},
		isInitialized(): boolean {
			return !!this.user.id;
		},
		isAdminUser(): boolean {
			return this.role && this.role.type === 'admin' && !this.user.account_id;
		},
		isSuperAdmin(): boolean {
			return this.role.id === 'super_admin' && this.role.type === 'admin' && !this.user.account_id;
		},
		isMarketplaceUser(): boolean {
			return this.role && this.role.type === 'marketplace';
		},
		isAccountUser(): boolean {
			return this.role && this.role.type === 'account';
		},
		isParentAccountAdmin(): boolean {
			return this.role.id === 'parent_account_admin';
		},
		isParentAccount(): boolean {
			return this.account.is_parent;
		},
		hasValidAccountPhone(): boolean {
			return get(this.account, 'signup_data.phone_validation.activity_score', 0) > 60;
		},
		hasVerifiedAccount(): boolean {
			return this.account.verified;
		},
		wasMigratedFromV3(): boolean {
			return get(this.account, 'settings.ngl_v3_id', false);
		},
	},
	actions: {
		// Reset the session values
		$reset() {
			Cookies.remove('token');

			this.token = '';
			this.user = {
				id: '',
				account_id: '',
				first_name: '',
				last_name: '',
				role_id: '',
				email: '',
				phone: '',
				title: '',
				avatar_url: '',
				last_login: '',
				settings: {
					calendly_url: '',
					team: '',
				},
				terms: {
					version: null,
				},
			};
			this.role = {
				id: '',
				type: '',
				mpid: '',
				granted: [],
				revoked: [],
				status: '',
			};
			this.account = {
				id: '',
				name: '',
				verified: false,
				is_parent: false,
				balance: {
					actual: 0,
				},
				settings: {
					timezone: 'America/Los_Angeles',
					budgets: [],
					allow_advanced_mode: false,
					bulk_returns: {
						enabled: false,
					},
				},
				support: {
					account_manager: {
						id: null,
						first_name: null,
						last_name: null,
						email: null,
						phone: null,
						avatar_url: null,
					},
					first_fund_date: null,
				},
				signup_data: {
					activity_score: 0,
				},
			};
			this.navigation = {};
		},

		// Log into the app
		async login(params: LoginParams) {
			// Delete the mimic token for direct logins
			const origin = window.location.host.split(':')[0].split('.');
			let mpid = subdomains[origin[0]]?.toLowerCase();
			if (!mpid) {
				mpid = origin[0];
			}
			Cookies.remove(`${mpid}_token`);

			const login_result = await loginReq(params);
			this.token = login_result.token;

			Cookies.set('token', this.token);

			// Initialize the session
			await this.initialize();

			return login_result;
		},

		// Logout of the app
		async logout() {
			const toast = useToast();

			this.$reset();
			this.router.push({ path: '/login' });
			setTimeout(() => {
				toast.add({
					severity: 'info',
					summary: 'You have been logged out',
					life: 3000,
				});
			}, 1000);
		},

		// Initialize a new session
		async initialize() {
			const appStore = useAppStore();

			if (!this.token) {
				const url_params = new URLSearchParams(window.location.search);
				const temp_token = url_params.get('token');

				let token = Cookies.get('token');
				if (temp_token) {
					token = await verifyAuth(temp_token);
				} else {
					// Get the subdomain
					let subdomain = window.location.host.split('.')[0];

					// For localhost
					if (subdomain.indexOf(':') > -1) {
						subdomain = subdomain.split(':')[0];
					}

					// Check if a mimic token exists
					if (subdomain !== 'admin' && import.meta.env.VITE_UI !== 'admin') {
						if (subdomains[subdomain]) {
							subdomain = subdomains[subdomain].toLowerCase();
						}

						const mimic_token = Cookies.get(`${subdomain}_token`);
						if (mimic_token) {
							token = mimic_token;
						}
					} else {
						token = Cookies.get('token');
					}
				}

				if (token) {
					this.token = token;
					Cookies.set('token', token);
					url_params.delete('token');
					url_params.delete('temp_token'); // Added this just in case
				} else {
					window.location.href = `${window.location.origin}/login`;
				}
			}

			let init_result;
			if (appStore.isAdminApp) {
				init_result = await initAdminSession();
			} else {
				init_result = await initMarketplaceSession();
			}
			log.trace('Initialize Result:', init_result);

			this.user = init_result.initialize.user;
			this.role = init_result.initialize.role;
			this.navigation = init_result.settings.navigation;

			if (init_result.initialize.user.account) {
				this.account = init_result.initialize.user.account;
			}

			// TODO - Find a better way to add legacy permission....
			if (this.wasMigratedFromV3) {
				this.role.granted.push('SideBarMenu.purchases.legacy');
			} else {
				this.role.revoked.push('legacy');
			}

			this.connectSocket();
		},

		reauthenticate() {
			this.router.push({ path: '/login', query: { path: this.router.currentRoute.value.path } });
		},

		connectSocket() {
			// Connect the socket
			this.socket = io(import.meta.env.VITE_SOCKET_ENDPOINT, {
				auth: {
					token: this.token,
				},
			});

			this.socket.on('connect', () => {
				log.trace('Socket connected:', this.socket.connected);

				// SETTINGS LISTENERS
				this.addSocketListener('updateSettings:navigation', (event) => {
					this.navigation = { ...event.data };
				});

				// // always listen to the role updates
				this.addSocketListener(`updateRole`, (data) => {
					if (data.mpid === this.role.mpid && data.id === this.role.id) {
						this.role = pick(data, ['id', 'type', 'granted', 'mpid', 'revoked', 'status']);
					}
				});
			});

			this.socket.on('disconnect', () => {
				log.warn('Socket disconnected');
			});
		},

		joinRoom(room) {
			log.trace('Joining Room', room);
			this.socket.emit('joinRoom', room);
		},

		leaveRoom(room) {
			log.trace('Leaving Room', room);
			this.socket.emit('leaveRoom', room);
		},

		addSocketListener(event_name, callback) {
			log.trace('Socket Listening for: ', event_name);
			if (this.socket.listeners(event_name).length === 0) {
				this.socket.on(event_name, callback);
			}
		},

		removeSocketListener(event_name) {
			this.socket.off(event_name);
		},

		// Get the correct navigation for the user
		getNavigation(mpid?: string) {
			const appStore = useAppStore();
			const marketplaceStore = useMarketplaceStore();

			let main_navigation = [];
			if (this.navigation) {
				if (appStore.isAdminApp) {
					if (mpid && mpid === marketplaceStore.mpid && marketplaceStore.role) {
						main_navigation = this.navigation[marketplaceStore.role.id] || this.navigation.default;
					} else {
						main_navigation = this.navigation[this.role.id] || this.navigation.admin_default;
					}
				} else {
					main_navigation = this.navigation[this.role.id] || this.navigation.default;
				}
			}

			return main_navigation;
		},

		// Check user permissions
		hasPermission(required_permissions: string | string[]) {
			const marketplaceStore = useMarketplaceStore();
			const permissions = [required_permissions].flat();

			// Select the role for the marketplace or admin
			const current_role = marketplaceStore.mpid ? marketplaceStore.role : this.role;

			return permissions.every((permission) => {
				// Check if the permission is revoked
				const is_revoked = current_role.revoked.some((pattern_string: string) => {
					const pattern = new RegExp(pattern_string);
					return pattern.test(permission);
				});

				if (!is_revoked) {
					// Check if the permission matches at least one grant
					return current_role.granted.some((pattern_string: string) => {
						const pattern = new RegExp(pattern_string);
						return pattern.test(permission);
					});
				}
			});
		},
	},
});
