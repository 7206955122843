<template>
	<div class="flex-transition-wrapper">
		<div id="phone-number-form" class="view-content">
			<page-loader :show="loading" />
			<div class="page-heading">
				<h1>{{ $route.params.sub_account_id ? 'Edit' : 'Create' }} Sub-Account</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
			<div class="form-content">
				<row>
					<column max-width="600px">
						<p-card>
							<template #content>
								<p class="req">Required Fields</p>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">Sub-Account Name:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="sub_account.name" />
												<div v-if="v$.sub_account.name.$error" class="validation-error">
													{{ v$.sub_account.name.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Description:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="sub_account.description" />
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Verticals:</label>
										<div class="controls" style="margin-bottom: 0">
											<div class="field fit">
												<p-checkbox
													input-id="vertical_ids_health_insurance"
													v-model="sub_account.vertical_ids"
													value="health_insurance"
												/>
											</div>
											<div class="field caption">
												<label for="vertical_ids_health_insurance">Health Insurance</label>
											</div>
										</div>
										<div class="controls" style="margin-bottom: 0">
											<div class="field fit">
												<p-checkbox
													input-id="vertical_ids_medicare"
													v-model="sub_account.vertical_ids"
													value="medicare"
												/>
											</div>
											<div class="field caption"><label for="vertical_ids_medicare">Medicare</label></div>
										</div>
										<div class="controls">
											<div class="field fit">
												<p-checkbox
													input-id="vertical_ids_auto_insurance"
													v-model="sub_account.vertical_ids"
													value="auto_insurance"
												/>
											</div>
											<div class="field caption"><label for="vertical_ids_auto_insurance">Auto Insurance</label></div>
										</div>
									</div>
								</div>
								<p-fieldset legend="Provider Details">
									<div class="control-group">
										<div class="inner">
											<label class="control-label req">Provider:</label>
											<div class="controls">
												<div class="field">
													<p-dropdown
														v-model="sub_account.provider_id"
														:options="provider_options"
														option-label="label"
														option-value="value"
														placeholder="Select Provider"
													/>
													<div v-if="v$.sub_account.provider_id.$error" class="validation-error">
														{{ v$.sub_account.provider_id.$errors[0].$message }}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="control-group">
										<div class="inner">
											<label class="control-label">Provider Account ID:</label>
											<div class="controls">
												<div class="field">
													<p-input-text v-model="sub_account.provider_account_id" disabled />
												</div>
											</div>
										</div>
									</div>
								</p-fieldset>
							</template>
						</p-card>
					</column>
				</row>
			</div>
		</div>
		<div class="view-actions">
			<div class="flex justify-content-between align-items-center">
				<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
				<p-button icon="pi pi-check" label="Save Sub-Account" @click.prevent="submit" />
			</div>
		</div>
	</div>
</template>
<script>
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { cloneDeep, omit } from 'lodash-es';
import { getSubAccountById, getProvidersAsOptions, insertSubAccount, updateSubAccount } from '@GQL';
import codeEditor from '@/components/forms/CodeEditor.vue';
import voiceRouteBuilder from '@/views/Calls/RouteTemplates/VoiceRoute/VoiceRouteBuilder.vue';
import pageLoader from '@/components/elements/Loader.vue';

const default_sub_account = {
	name: null,
	description: null,
	vertical_ids: [],
	provider_id: 'twilio',
	provider_account_id: null,
};

export default {
	name: 'PhoneNumberFormPage',
	components: {
		codeEditor,
		voiceRouteBuilder,
		pageLoader,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			sub_account: cloneDeep(default_sub_account),
			provider_options: [],
			breadcrumb_items: [{ label: 'Sub-Accounts', route: '/sub-accounts' }, { label: 'Edit Sub-Account' }],
		};
	},
	validations() {
		return {
			sub_account: {
				name: {
					required: helpers.withMessage('A name is required.', required),
				},
				provider_id: {
					required: helpers.withMessage('Please select a provider.', required),
				},
			},
		};
	},
	methods: {
		async submit() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				try {
					let result;
					if (this.$route.meta.new) {
						result = await insertSubAccount(this.sub_account);
					} else {
						result = await updateSubAccount(this.sub_account.id, omit(this.sub_account, ['id', 'created_at']));
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully updated the sub-account`,
							detail: `${this.sub_account.name}`,
							life: 5000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to update the sub-account`,
							detail: `${this.sub_account.name}`,
						});
					}
				} catch (err) {
					const msg_snippet = err.message.split(': {')[0];
					this.$toast.add({
						severity: 'error',
						summary: `Unable to update sub-account`,
						detail: `${msg_snippet}`,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
	async mounted() {
		try {
			this.loading = true;
			this.provider_options = await getProvidersAsOptions();
			if (!this.$route.meta.new) {
				const sub_account = await getSubAccountById(this.$route.params.sub_account_id);
				if (sub_account) {
					this.sub_account = cloneDeep(sub_account);
				}
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
				life: 6000,
			});
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="less" scoped>
.type-option-box {
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 1em;
	cursor: default;

	strong {
		display: block;
		font-size: 1.125em;
		margin-bottom: 5px;
	}

	&.selected {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-light);
		color: var(--color-b-light);

		strong {
			color: var(--color-b);
		}
	}
}

.detail-row {
	align-items: center;
	color: var(--gray-70);
	display: flex;
	flex-direction: row;
	font-size: 0.875rem;
	height: 2.5em;
}

.detail-label {
	flex: 0 0 200px;
}

.detail-value {
	flex: 1 0;
}

.detail-icon {
	display: inline-block;
	margin-right: 15px;
}

.field.caption {
	margin-top: 3px;
}
</style>
