<template>
	<div id="live-transfer-start" class="view-content">
		<div class="page-heading">
			<h1>Live Transfer Workstation</h1>
		</div>
		<gutter size="20px" />
		<div id="live-transfer-leads-list">
			<query-form v-model:query="query" :loading="loading" @apply="getLiveTransferReturns">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Vertical:</div>
						<div class="value">
							{{ displaySelected(query.filters.vertical_id, $root.appStore.verticalOptions) }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="getLiveTransferReturns(true)"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range
												:rangeLimit="{
													days: 62,
												}"
												v-model="form.query.filters.created_at"
												hide-labels
												show-time
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group collapse">
								<div class="inner">
									<label class="control-label">Filter By Vertical:</label>
									<div class="controls">
										<div class="field">
											<div
												v-for="vertical of $root.appStore.verticalOptions"
												:key="vertical.value"
												class="p-checkbox-item flex align-items-center"
											>
												<p-checkbox
													v-model="query.filters.vertical_id"
													:input-id="vertical.value"
													name="category"
													:value="vertical.value"
												/>
												<label :for="vertical.value">{{ vertical.label }}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<!--      <div class="metrics">-->
			<!--        <row>-->
			<!--          <div class="column">-->
			<!--            <metric label="Transfers Today" :value="completed_transfers.today || 0" />-->
			<!--          </div>-->
			<!--          <gutter size="20px" />-->
			<!--          <div class="column">-->
			<!--            <metric label="Transfers This Week" :value="completed_transfers.week || 0" />-->
			<!--          </div>-->
			<!--          <gutter size="20px" />-->
			<!--          <div class="column">-->
			<!--            <metric label="Transfers This Month" :value="completed_transfers.month || 0" />-->
			<!--          </div>-->
			<!--        </row>-->
			<!--      </div>-->
			<gutter size="20px" />
			<div class="card padded">
				<p-data-table
					ref="dt"
					:loading="loading"
					:rows="100"
					scrollable
					:value="rows"
					key="refreshKey"
					data-key="agent_id"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column header="Agent Name">
						<template #body="row">
							{{ row.data.agent_name }}
						</template>
					</p-column>
					<p-column header="Transfers">
						<template #body="row">
							{{ row.data.total_transfers }}
						</template>
					</p-column>
					<p-column header="Returns Pending">
						<template #body="row">
							{{ row.data.return_pending }}
						</template>
					</p-column>
					<p-column header="Returns Accepted">
						<template #body="row">
							{{ row.data.return_accepted }}
						</template>
					</p-column>
					<p-column header="Returns Rejected">
						<template #body="row">
							{{ row.data.return_rejected }}
						</template>
					</p-column>
					<p-column header="Return Percentage">
						<template #body="row">
							{{ percentage(row.data.total_returned / row.data.total_transfers, 2) }}
						</template>
					</p-column>
					<p-column header="Tools">
						<template #body="row">
							<div @click="openReturnsModal(row.data)" v-tooltip="'Review Returns'" v-if="row.data.returns.length > 0">
								<icon type="format_list_bulleted" size="24px" />
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
		<LTReturnModal ref="return_modal" />
	</div>
</template>

<script lang="ts">
import { endOfDay, startOfDay } from 'date-fns';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import {
	formatDate,
	displaySelected,
	currency,
	verticalName,
	phoneFormat,
	percentage,
	capitalize,
	timeAgo,
} from '@/lib/Filters';
import type { FilterParams, GetListParams } from '@nextgenleads/db';
import { reportLiveTransferReturns } from '@GQL';
import LTReturnModal from '@/views/LTWorkstation/Returns/List/LTReturnModal.vue';

export default {
	name: 'LiveTransferReturns',
	components: {
		dateRange,
		queryForm,
		LTReturnModal,
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
				},
				pagination: {
					page_size: 50,
					page: 0,
				},
			},

			rows: [],
			total_records: 0,
		};
	},
	async mounted() {
		await this.getLiveTransferReturns();
	},
	methods: {
		percentage,
		capitalize,
		currency,
		formatDate,
		displaySelected,
		phoneFormat,
		timeAgo,
		verticalName,
		openReturnsModal(row) {
			this.$refs.return_modal.open(row);
		},
		createReasonToolTip(data) {
			if (data.status === 'complete') {
				return '';
			} else if ('reason' in data) {
				return 'Reason: ' + data.reason + '\n Notes: ' + data.notes;
			}
		},
		async getLiveTransferReturns(refresh = false) {
			this.loading = true;
			try {
				// if (this.query.filters.vertical_id.length > 0) {
				//   // do the in
				//   filters.push({
				//     field: 'vertical_id',
				//     op: 'in',
				//     value: this.query.filters.vertical_id,
				//   });
				// }
				//
				// if (this.query.filters.state.length > 0) {
				//   // do the in
				//   filters.push({
				//     field: 'lead_state',
				//     op: 'in',
				//     value: this.query.filters.state,
				//   });
				// }
				//
				// const query_params: GetListParams = {
				//   filters: [filters],
				//   pagination: this.query.pagination,
				//   order: {
				//     field: 'created_at',
				//     desc: true,
				//   },
				// };

				const query_params = {
					date_range: [this.query.filters.created_at[0].toISOString(), this.query.filters.created_at[1].toISOString()],
				};

				const result = await reportLiveTransferReturns(query_params, refresh);
				this.rows = result.rows;
				this.total_records = result.row_count;
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.red {
	color: red;
}
</style>
