<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Campaign Analyzer</h1>
		</div>
		<div class="page-content">
			<div class="flex gap-5 table-container">
				<div class="card padded flex-shrink-0">
					<h5>Primary View</h5>
					<gutter size="20px" />
					<analyzer-form
						ref="primary_form"
						@update:query="handlePrimaryQueryUpdate"
						@refresh="(q) => handlePrimaryQueryUpdate(q, true)"
					/>
					<analyzer-summary :metrics="primary_metrics" :loading="loading" />
					<gutter size="20px" />
					<analyzer-table
						:data="primary_data"
						:loading="loading"
						:grouping="primary_query.group_by"
						:minmax="primary_minmax"
					/>
				</div>
				<template v-for="(query, index) in comparison_queries">
					<div class="card padded flex-shrink-0">
						<h5>Comparison View</h5>
						<gutter size="20px" />
						<analyzer-form
							:ref="`comparison_form$_${index}`"
							@update:query="(q) => handleComparisonQueryUpdate(q, index)"
							@refresh="(q) => handleComparisonQueryUpdate(q, index, true)"
						/>
						<gutter size="20px" />
						<analyzer-summary :metrics="comparison_metrics[index]" />
						<gutter size="20px" />
						<analyzer-table
							:data="comparison_data[index]"
							:grouping="comparison_queries[index].group_by"
							:loading="loading"
							:minmax="comparison_minmax[index]"
						/>
					</div>
				</template>
				<div class="card padded flex-shrink-0">
					<a @click="addComparison">
						<div class="flex flex-column justify-content-center align-items-center gap-2 h-full">
							<icon type="plus-circle" size="32px" />
							<strong>Add comparison view</strong>
						</div>
					</a>
				</div>
				<div class="w-3rem">&nbsp;</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import get from 'lodash/get';
import { currency, formatDate, numberFormat, colorCell } from '@/lib/Filters';
import AnalyzerForm from '../AnalyzerForm.vue';
import AnalyzerSummary from '../AnalyzerSummary.vue';
import AnalyzerTable from '../AnalyzerTable.vue';
import { getMAutCampaignAnalyzer } from '@GQL/queries/getMAutCampaignAnalyzer';

interface Query {
	source_code: string;
	match_type: string;
	day_of_the_week: string;
	range: Date[];
	vertical_id: string;
	business_unit: string;
	media_buyer: string;
	group_by: string[];
}

export default {
	name: 'CampaignAnalyzer',
	components: {
		AnalyzerForm,
		AnalyzerSummary,
		AnalyzerTable,
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			primary_query: {
				group_by: ['date'],
			} as Query,
			primary_metrics: [],
			primary_data: [],
			primary_minmax: {},
			comparison_data: [],
			comparison_metrics: [],
			comparison_queries: [] as Query[],
			comparison_minmax: [],
		};
	},
	mounted() {
		this.$refs.primary_form.$refs.query_form.toggleDrawer();
	},
	methods: {
		colorCell,
		currency,
		formatDate,
		numberFormat,
		async handlePrimaryQueryUpdate(query: Partial<Query>, refresh = false) {
			try {
				this.checkQuery(query);
				this.primary_query = query;
				await this.getData(0, refresh);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Error: ${err.message}`,
					life: 9000,
				});
			}
		},
		async handleComparisonQueryUpdate(query: Partial<Query>, query_index, refresh = false) {
			try {
				this.checkQuery(query);
				this.comparison_queries.splice(query_index, 1, query);
				await this.getData(query_index + 1, refresh);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Error: ${err.message}`,
					life: 9000,
				});
			}
		},
		async getData(query_index, refresh = false) {
			this.loading = true;
			try {
				let query;
				if (query_index === 0) {
					// primary
					query = this.primary_query;
				} else {
					query = this.comparison_queries[query_index - 1];
				}
				const { rows, metrics } = await getMAutCampaignAnalyzer(query, refresh);
				if (query_index === 0) {
					this.primary_data = rows;
					if ('formatted' in metrics) {
						this.primary_metrics = [...metrics.formatted];
					}
					if ('min_max' in metrics) {
						this.primary_minmax = { ...metrics.min_max };
					}
				} else {
					// we splice the data and metrics
					this.comparison_data.splice(query_index - 1, 1, rows);
					this.comparison_metrics.splice(query_index - 1, 1, get(metrics, 'formatted', []));
					this.comparison_minmax.splice(query_index - 1, 1, get(metrics, 'min_max', {}));
				}
			} finally {
				this.loading = false;
			}
		},
		checkQuery(query) {
			if ('group_by' in query) {
				if (query.group_by.length === 0) {
					this.$nextTick(() => {
						this.$refs.primary_form.$refs.query_form.toggleDrawer();
					});
					throw new Error('Invalid Query, missing Group By');
				}
			}
		},
		async handlePrimaryQueryUpdate(query: Query, refresh = false) {
			try {
				this.checkQuery(query);
				this.primary_query = query;
				await this.getData(0, refresh);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Error: ${err.message}`,
					life: 9000,
				});
			}
		},
		async handleComparisonQueryUpdate(query: Partial<Query>, query_index, refresh = false) {
			try {
				this.checkQuery(query);
				this.comparison_queries.splice(query_index, 1, query);
				await this.getData(query_index + 1, refresh);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Error: ${err.message}`,
					life: 9000,
				});
			}
		},
		addComparison() {
			this.comparison_queries.push({
				group_by: [],
			});
			this.comparison_data.push([]);
			this.comparison_metrics.push([]);
			this.comparison_minmax.push({});
			this.$nextTick(() => {
				this.$refs[`comparison_form$_${this.comparison_queries.length - 1}`][0].$refs.query_form.toggleDrawer();
			});
		},
	},
};
</script>

<style scoped>
:deep(.p-datatable .p-datatable-tbody > tr > td:has(.color-cell)) {
	padding: 0;
	.color-cell {
		padding: 0.75rem 1rem;
	}
}
:deep(.p-datatable .p-datatable-tbody > tr > td:has(.color-row)) {
	padding: 0;
	.color-row {
		padding: 0.75rem 1rem;
	}
}
</style>
