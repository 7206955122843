<template>
	<p-data-table :value="metrics">
		<p-column field="metric" header="">
			<template #body="row">
				<strong>
					{{ row.data.metric }}
				</strong>
			</template>
		</p-column>
		<p-column field="revenue" header="Revenue">
			<template #body="row">
				{{ currency(row.data.revenue, 0) }}
			</template>
		</p-column>
		<p-column field="count" header="Count">
			<template #body="row">
				{{ numberFormat(row.data.count) }}
			</template>
		</p-column>
		<p-column field="rpc" header="Rev / Count">
			<template #body="row">
				{{ currency(row.data.rpc) }}
			</template>
		</p-column>
	</p-data-table>
</template>

<script lang="ts">
import { currency, numberFormat } from '@/lib/Filters';
export default {
	name: 'RevenueAnalyzerMetrics',
	data() {
		return {};
	},
	props: {
		metrics: {
			type: Array,
		},
	},
	methods: {
		currency,
		numberFormat,
	},
};
</script>

<style scoped lang="less"></style>
