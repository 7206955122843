import { $GQL } from '@GQL';

interface search_result {
	id: string;
	type: string;
	data: any;
}

export async function searchUsers(value: string, page: number) {
	const query = `
        query SearchUsers($value: String!, $page: Int) {
            searchUsers(value: $value, page: $page) {
                user_id
				mpid
				full_name
				account_id
				account_name
				is_parent_user
				email
				phone
				status
            }
        }
    `;

	const result = await $GQL.request(query, { value, page });
	return result.searchUsers;
}
