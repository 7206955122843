// ----------------------------------------------------------------------------------------------------
// FILTER: currency
// Format a number as a currency
// ----------------------------------------------------------------------------------------------------

export function currency(value: number, decimal?: number) {
	const fractional = decimal ?? 2;
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: fractional,
		maximumFractionDigits: fractional,
	}).format(value);
}
