import { $GQL } from '@GQL/index';

export async function getMAutCustomerAcquisitionAnalyzer(
	params: {
		range: string[];
		filters: {};
		group_by: string[]; // selectable
	},
	refresh?: boolean
) {
	const query = `
        query GetMAutCustomerAcquisitionAnalyzer($params: JSONObject!) {
            getMAutCustomerAcquisitionAnalyzer(params: $params) {
				rows
				metrics
			}
        }
    `;

	const no_cache = refresh ? ['getMAutCustomerAcquisitionAnalyzer'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.getMAutCustomerAcquisitionAnalyzer;
}
