<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Revenue</h1>
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getData">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.range, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getData(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker :max-range="62" v-model="form.query.range" />
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="flex justify-content-end">
			<p-button icon="pi pi-plus" @click="show_modal = true" label="Add Revenue" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				ref="revenue"
				:loading="loading"
				:value="revenue"
				@update:sortField="updateSortField"
				@update:sortOrder="updateSortOrder"
				@sort="getData(true)"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No campaigns</div>
				</template>
				<p-column header="Date" sortable field="date">
					<template #body="row">
						{{ row.data.date }}
					</template>
				</p-column>
				<p-column header="Revenue Source" sortable field="source_name">
					<template #body="row">
						{{ row.data.source_name }}
					</template>
				</p-column>
				<p-column header="Vertical" sortable field="vertical_id">
					<template #body="row">
						{{ title(row.data.vertical_id) }}
					</template>
				</p-column>
				<p-column header="Biz Unit" sortable field="business_unit">
					<template #body="row">
						{{ row.data.business_unit }}
					</template>
				</p-column>
				<p-column header="Type" sortable field="type">
					<template #body="row">
						{{ title(row.data.type) }}
					</template>
				</p-column>
				<p-column header="Revenue" sortable field="revenue">
					<template #body="row">
						{{ currency(row.data.revenue, 0) }}
					</template>
				</p-column>
				<!--				<p-column header="Original Revenue" sortable field="original_revenue">-->
				<!--					<template #body="row">-->
				<!--						{{ currency(row.data.original_revenue, 0) }}-->
				<!--					</template>-->
				<!--				</p-column>-->
				<p-column header="Count" sortable field="count">
					<template #body="row">
						{{ row.data.count }}
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="total_rows" @page="handlePageChange" />

			<p-dialog v-model:visible="show_modal" modal style="max-width: 400px">
				<template #header>
					<strong>Create a new Revenue Row</strong>
				</template>
				<div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Date:</label>
							<div class="controls">
								<div class="field">
									<p-input-mask v-model="revenue_row.date" mask="9999-99-99" placeholder="YYYY-MM-DD format" />
									<div v-if="v$.revenue_row.date.$error" class="validation-error">
										{{ v$.revenue_row.date.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Revenue Source:</label>
							<div class="controls">
								<div class="field">
									<p-input-text v-model="revenue_row.source_name" />
									<div v-if="v$.revenue_row.source_name.$error" class="validation-error">
										{{ v$.revenue_row.source_name.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Revenue:</label>
							<div class="controls">
								<div class="field">
									<p-input-currency v-model="revenue_row.revenue" />

									<div v-if="v$.revenue_row.revenue.$error" class="validation-error">
										{{ v$.revenue_row.revenue.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Count:</label>
							<div class="controls">
								<div class="field">
									<p-input-number v-model="revenue_row.count" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Vertical:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="revenue_row.vertical_id"
										:options="vertical_options"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.revenue_row.vertical_id.$error" class="validation-error">
										{{ v$.revenue_row.vertical_id.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner required">
							<label class="control-label req">Business Unit:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="revenue_row.business_unit"
										:options="biz_unit_options"
										option-label="label"
										option-value="value"
									/>

									<div v-if="v$.revenue_row.business_unit.$error" class="validation-error">
										{{ v$.revenue_row.business_unit.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Revenue Type:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="revenue_row.type"
										:options="revenue_type_options"
										option-label="label"
										option-value="value"
									/>

									<div v-if="v$.revenue_row.type.$error" class="validation-error">
										{{ v$.revenue_row.type.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<template #footer>
					<p-button label="Insert" :loading="loading" @click="createRevenueRow" />
				</template>
			</p-dialog>
		</div>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, title } from '@/lib/Filters';
import pInputMask from 'primevue/inputmask';
import { getMAutRevenue, insertMautRevenue } from '@GQL';
import cloneDeep from 'lodash/cloneDeep';
import queryForm from '@/components/widgets/QueryForm.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import dayjs from 'dayjs';
import { vertical_options, biz_unit_options, revenue_type_options } from '@/lib/Options';

const default_revenue_row = {
	source_name: '',
	date: '',
	revenue: 0,
	type: '',
	business_unit: '',
	count: 0,
	revenue_updates: [],
};

export default {
	name: 'Revenue',
	components: {
		dateRangePicker,
		queryForm,
		tableTools,
		pInputMask,
	},
	async setup() {
		return {
			v$: useVuelidate(),
		};
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			show_modal: false,
			revenue_type_options,
			biz_unit_options,
			vertical_options,
			query: {
				range: [dayjs().startOf('day').subtract(7, 'days').toDate(), dayjs().endOf('day').toDate()],
				order: {
					field: 'date',
					desc: true,
				},
				pagination: {
					page: 0,
					page_size: 200,
				},
			},
			total_rows: 0,
			revenue_row: {},
			revenue: [],
		};
	},
	async mounted() {
		await this.getData(true);
	},
	methods: {
		title,
		currency,
		formatDate,
		async updateSortField(value) {
			this.query.order.field = value;
		},
		async updateSortOrder(value) {
			this.query.order.desc = value === -1;
		},
		async handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getData();
		},
		async createRevenueRow() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				// we need to create an update_revenue object
				this.loading = true;
				try {
					// const res = await
					const res = await insertMautRevenue({
						...this.revenue_row,
						revenue_updates: [
							{
								user_id: this.$root.sessionStore.user.id,
								new_value: this.revenue_row.revenue,
								old_value: 0,
								updated_at: new Date(),
							},
						],
					});
					// reset the row data
					this.revenue_row = cloneDeep(default_revenue_row);
					this.show_modal = false;
					await this.getData(true);
					this.$toast.add({
						severity: 'info',
						summary: 'New Revenue Created',
						life: 6000,
					});
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save the create',
						life: 6000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Issue with validation',
					life: 7000,
				});
			}
		},
		async getData(refresh = false) {
			this.loading = true;
			try {
				const date_range = this.query.range.map((d) => {
					return d.toISOString().slice(0, 10);
				});
				const { row_count, rows } = await getMAutRevenue(
					{
						date_range,
						pagination: this.query.pagination,
						order: this.query.order,
					},
					refresh
				);
				this.revenue = rows;
				this.total_rows = row_count;
			} catch (err) {
				// TODO
				// console.error(err, 'Unable to get data');j
			} finally {
				this.loading = false;
			}
		},
	},
	validations() {
		return {
			revenue_row: {
				date: {
					required: helpers.withMessage('Date is required.', required),
				},
				source_name: {
					required: helpers.withMessage('Source Name is required.', required),
				},
				vertical_id: {
					required: helpers.withMessage('Vertical is required.', required),
				},
				business_unit: {
					required: helpers.withMessage('Business Unit is required.', required),
				},
				revenue: {
					required: helpers.withMessage('Revenue is required.', required),
				},
				type: {
					required: helpers.withMessage('Revenue type is required.', required),
				},
			},
		};
	},
};
</script>

<style scoped></style>
