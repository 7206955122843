<template>
	<div class="view-content">
		<div class="page-heading">
			<h2 v-if="$route.params.account_manager_id">Dormant Accounts</h2>
			<h1 v-else>Dormant Accounts</h1>
		</div>
		<div>
			<div class="card padded">
				<query-form v-model:query="query" :loading="loading" @apply="getDormantAccounts" @abort="clearQuery">
					<template #display>
						<div v-if="!$route.params.account_manager_id" class="display-value">
							<div class="label">Account Manager:</div>
							<div class="value">
								{{ displaySelected(query.filters.account_manager_id, displayUsers) }}
							</div>
						</div>
						<div class="display-value">
							<div class="label">Primary Vertical:</div>
							<div class="value">
								{{ displaySelected(query.filters.primary_vertical, verticalOptions) }}
							</div>
						</div>
					</template>

					<template #form="form">
						<row>
							<column v-if="!$route.params.account_manager_id">
								<div class="control-group collapse">
									<div class="inner">
										<label class="control-label" for="owner_id">Account Manager:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													class="w-full md:w-20rem"
													id="support_am_id"
													v-model="query.filters.account_manager_id"
													placeholder="Account Manager ID"
													:options="user_options"
													showClear
													filter
													option-group-label="label"
													option-group-children="items"
													option-label="label"
													option-value="value"
												/>
											</div>
										</div>
									</div>
								</div>
							</column>
							<gutter size="20px" />
							<column class="">
								<div class="control-group collapse">
									<div class="inner">
										<label class="control-label">Verticals:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="query.filters.primary_vertical"
													id="primary_vertical"
													:options="verticalOptions"
													showClear
													option-label="label"
													option-value="value"
													placeholder="Select Verticals"
													class="w-full md:w-20rem"
												/>
											</div>
										</div>
									</div>
								</div>
							</column>
							<column></column>
							<column></column>
							<column></column>
						</row>
					</template>
					<template #utils>
						<p-button
							@click="getDormantAccounts(true)"
							v-tooltip.top="'Refresh'"
							icon="pi pi-refresh"
							aria-label="Refresh"
							class="mr-2"
						/>
					</template>
				</query-form>
				<p-data-table ref="dt" scrollable data-key="id" :loading="loading" :value="rows">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>

					<p-column field="account_name" header="Account">
						<template #body="row">
							<router-link
								:to="`/marketplaces/manage/${row.data.id.slice(0, 3)}/accounts/manage/${row.data.id}/reports/account-activity`"
							>
								{{ row.data.account_name }}
							</router-link>
							<div class="sub-value">{{ row.data.id }}</div>
						</template>
					</p-column>

					<p-column field="account_manager" header="Account Manager">
						<template #body="row">
							{{ row.data.account_manager }}
						</template>
					</p-column>

					<p-column field="primary_vertical" header="Primary Vertical" class="column-align-center">
						<template #body="row">
							<div v-if="row.data.primary_vertical" class="verticals">
								<div :key="row.data.primary_vertical" class="vertical-icon">
									<vertical-icon :vertical-id="row.data.primary_vertical" size="30px" />
								</div>
							</div>
							<span v-if="!row.data.primary_vertical">Not Set</span>
						</template>
					</p-column>

					<p-column field="spend" header="Spend" sortable sort-field="spend">
						<template #body="row">
							{{ currency(row.data.spend || 0) }}
						</template>
					</p-column>

					<p-column field="balance" header="Balance" sortable sort-field="balance">
						<template #body="row">
							{{ currency(row.data.balance || 0) }}
						</template>
					</p-column>

					<p-column field="signup_date" header="Signup Date" sortable sort-field="signup_date">
						<template #body="row">
							{{ formatDate(row.data.signup_date, 'ddd, MMM D, YYYY') }}
							<div class="sub-value">{{ timeAgo(row.data.signup_date) }}</div>
						</template>
					</p-column>

					<p-column field="last_purchase" header="Last Purchase" sortable sort-field="last_purchase">
						<template #body="row">
							{{ formatDate(row.data.last_purchase, 'ddd, MMM D, YYYY') }}
							<div class="sub-value">{{ timeAgo(row.data.last_purchase) }}</div>
						</template>
					</p-column>
				</p-data-table>
				<p-paginator
					v-if="!$route.params.account_manager_id"
					:rows="query.pagination.page_size"
					:total-records="total_rows"
					@page="handlePageChange"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, timeAgo, displaySelected } from '@/lib/Filters';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { getInternalUsersAsOptions, reportDormantAccounts } from '@GQL';
import queryForm from '@/components/widgets/QueryForm.vue';

export default {
	name: 'DormantAccounts',
	components: {
		queryForm,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			user_options: [],
			total_rows: 0,
			rows: [],
			query: {
				filters: {
					account_manager_id: null,
					primary_vertical: null,
				},
				pagination: {
					page_size: 50,
					page: 0,
				},
				refresh: false,
			},
		};
	},
	async created() {
		this.user_options = await getInternalUsersAsOptions('sales');
	},
	async mounted() {
		await this.getDormantAccounts();
	},
	computed: {
		displayUsers() {
			if (this.user_options.length > 0) {
				return this.user_options[0].items;
			}
			return [];
		},
		verticalOptions() {
			return this.$root.appStore.verticalOptions;
		},
	},
	methods: {
		currency,
		formatDate,
		timeAgo,
		displaySelected,
		async handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getDormantAccounts(true);
		},
		clearQuery() {
			this.query.filters.account_manager_id = null;
			this.query.filters.primary_vertical = null;
		},
		async getDormantAccounts(refresh?: boolean) {
			this.loading = true;
			if (refresh) {
				this.query.refresh = true;
			}
			try {
				const filters = [];

				if (this.$route.params.account_manager_id) {
					this.query.filters.account_manager_id = this.$route.params.account_manager_id;
					// delete the pagination, not needed
				}

				if (this.query.filters.account_manager_id) {
					filters.push(`am.id = '${this.query.filters.account_manager_id}'`);
				}
				if (this.query.filters.primary_vertical) {
					filters.push(`a.signup_data->>'primary_vertical' = '${this.query.filters.primary_vertical}'`);
				}

				const query_params: Record<string, unknown> = {
					filters: [filters],
				};

				if (!this.$route.params.account_manager_id) {
					query_params.pagination = this.query.pagination;
				}
				const result = await reportDormantAccounts(query_params, refresh);
				this.total_rows = +result.row_count;
				this.rows = result.rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Dormant Accounts',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
