<template>
	<div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req target-type-label">Target Type:</label>
				<div class="controls">
					<div class="field columns-2">
						<div v-for="option in target_type_options" :key="option.value" class="radio-option">
							<div class="flex gap-10">
								<p-radio-button
									:input-id="`target_type_${option.value}`"
									:value="option.value"
									name="notification_filter"
									v-model="modelValue.target_type"
								/>
								<label class="radio-label" :for="`target_type_${option.value}`">{{ option.label }}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="types_with_text_input.indexOf(modelValue.target_type) > -1">
			<div class="inner">
				<label class="control-label req">Value:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="modelValue.value" :placeholder="textFieldPlaceHolder" />
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="modelValue.target_type === 'account_users_by_mpid'">
			<div class="inner">
				<label class="control-label req">MPID:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="modelValue.value"
							placeholder="Select MPID"
							:options="mpid_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="modelValue.target_type === 'user'">
			<div class="inner">
				<label class="control-label req">User:</label>
				<div class="controls">
					<div class="field">
						<!-- <p-auto-complete
							v-model="user_value"
							option-label="label"
							:suggestions="user_options"
							@complete="searchUser"
							@item-select="selectUser"
						/> -->
						<p-input-text v-model="user_value" placeholder="User ID" />
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="modelValue.target_type === 'account_users_by_account_manager'">
			<div class="inner">
				<label class="control-label req">Account Manager:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="modelValue.value"
							placeholder="Select Account Manager"
							:options="account_manager_options"
							option-group-label="label"
							option-group-children="items"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="modelValue.target_type === 'users_by_marketplace_role'">
			<div class="inner">
				<label class="control-label req">MPID:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="modelValue.mpid"
							placeholder="Select MPID"
							:options="mpid_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import pAutoComplete from 'primevue/autocomplete';
import { getUsersAsOptions, getMarketplacesAsOptions } from '@GQL';

const target_type_options = [
	{ label: 'Single User', value: 'user' }, // value to be user ulid
	{ label: 'All Account Users', value: 'account_users' }, // value to be account_id
	{ label: 'All Account Users for Account Manager', value: 'account_users_by_account_manager' }, // value to be Account Manager
	{ label: 'All Account Users for Marketplace', value: 'account_users_by_mpid' }, // value to be only mpids
	{ label: 'All Internal Users', value: 'internal_users' }, // No VALUE
	{ label: 'All External Users', value: 'external_users' }, // No VALUE
	{ label: 'All Users w/ Role', value: 'users_by_role' },
	{ label: 'All Users w/ specific Marketplace Role', value: 'users_by_marketplace_role' },
	{ label: 'All Account Users w/ Account Tag', value: 'users_by_account_tag' },
	{ label: 'All Child & Grandchild Account Users', value: 'account_users_by_parent' },
];

export default {
	name: 'NotificationFilterComponent',
	components: {
		pAutoComplete,
	},
	props: {
		modelValue: {
			type: Object,
			required: true,
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			mpid_options: [],
			user_options: [],
			user_value: null,
			account_manager_options: [],
			target_type_options,
			types_without_value: ['internal_users', 'external_users'],
			types_with_text_input: ['account_users', 'users_by_role', 'users_by_marketplace_role', 'account_users_by_parent'],
		};
	},
	computed: {
		textFieldPlaceHolder() {
			switch (this.modelValue.target_type) {
				case 'account_users_by_parent':
					return 'Parent Account ID: e.g. GRF-000-000';
				case 'account_users':
					return 'Account ID e.g. NGL-000-002';
				case 'users_by_marketplace_role':
				case 'users_by_role':
					return 'Role ID e.g. account_admin';
			}
		},
	},
	watch: {
		modelValue: {
			handler(new_val) {
				// if the new_val has a target type that doesn't need value, remove it for simplicity
				if (this.types_without_value.indexOf(new_val.target_type) > -1) {
					delete new_val.mpid;
					delete new_val.value;
				}
				if (new_val.target_type !== 'users_by_marketplace_role') {
					delete new_val.mpid;
				}

				this.$emit('update:modelValue', new_val);
			},
			deep: true,
		},
	},
	methods: {
		// Note: Removing the ability to search users by name since it isn't used much, instead just input the user's ID into text field instead of autocomplete
		// searchUser(event) {
		// setTimeout(async () => {
		// 	if (event.query.trim().length > 3) {
		// 		const options = [];
		// 		const result = await getUsersAsOptions([
		// 			[`lower(first_name || ' ' || last_name) LIKE '%${event.query.trim().toLowerCase()}%'`],
		// 		]);
		// 		result.forEach((group) => {
		// 			const items = group.items.map((row) => {
		// 				row.label += ` ${group.label}`;
		// 				return row;
		// 			});
		// 			options.push(...items);
		// 		});
		// 		this.user_options = options;
		// 	}
		// }, 250);
		// },
		// selectUser(event) {
		// 	this.$emit('update:modelValue', event.value);
		// },
	},
	async mounted() {
		this.mpid_options = await getMarketplacesAsOptions();

		const account_manager_params = {
			mpid: '$NG',
			team: 'sales',
			status: 'active',
		};

		this.account_manager_options = await getUsersAsOptions(account_manager_params);
	},
};
</script>

<style lang="less" scoped>
.columns-2 {
	columns: 2;
}

.radio-option {
	padding: 0.25em 0;
}

.radio-label {
	cursor: pointer;
	font-weight: normal;
}

.target-type-label {
	padding-bottom: 0.5rem;
}
</style>
