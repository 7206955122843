import { $GQL } from '@GQL/index';
import { sortBy, groupBy } from 'lodash-es';
import type { UsersParams } from '@GQL/index';

/**
 * This function is to return users as options based on the mpid of the user or just all NGL users
 */
export async function getUsersAsOptions(params: UsersParams) {
	const query = `
        query GetUsersAsOptions($params: UsersParams) {
            users(params: $params) {
                id
                first_name
                last_name
                account_id
                account {
                  name
                }
            }
        }
    `;

	const results = await $GQL.request(query, { params });

	const users = results.users;
	const grouped = groupBy(users, 'account_id');
	const final_options = [];
	Object.keys(grouped).forEach((key) => {
		let group = key; //?
		if (group === 'null') {
			group = 'Internal';
		} else {
			if (grouped[key][0].account && grouped[key][0].account.name) {
				group = grouped[key][0].account.name + ` (${key})`;
			} else {
				group = 'Unknown';
			}
		}
		const unsorteditems = grouped[key].map((row) => {
			return {
				value: row.id,
				label: row.first_name + ' ' + row.last_name,
			};
		});
		final_options.push({
			label: group,
			items: sortBy(unsorteditems, ['label']),
		});
	});

	return sortBy(final_options, ['label']);
}
