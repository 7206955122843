import { getUsersAsOptions } from './getUsersAsOptions';
/**
 * Easily returns all the NGL account managers
 */
export async function getAccountManagersAsOptions() {
	const account_manager_params = {
		mpid: '$NG',
		team: 'sales',
		status: 'active',
	};
	return getUsersAsOptions(account_manager_params);
}
