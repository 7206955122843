import isNumber from 'lodash/isNumber';

// ----------------------------------------------------------------------------------------------------
// FILTER: numberFormat
// Format a number as a number
// ----------------------------------------------------------------------------------------------------

export function numberFormat(value: number, significant_digits?: number) {
	if (isNumber(significant_digits)) {
		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: significant_digits,
			maximumFractionDigits: significant_digits,
		}).format(value);
	}
	return new Intl.NumberFormat('en-US').format(value);
}
