import { $GQL } from '@GQL/index';

export async function unhashString(value: string): Promise<string | null> {
	const query = `
		query UnhashString($value: String!) {
			unhashString(value: $value)
		}
	`;

	const result = await $GQL.request(query, { value });
	return result.unhashString;
}
