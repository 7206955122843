<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Lead Campaigns</h1>
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getData" @abort="">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.range, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getData(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker :max-range="62" v-model="form.query.range" />
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div>
			<div class="card padded" id="table_holder">
				<p-data-table
					ref="leadcampaigns"
					:loading="loading"
					:value="lead_campaigns"
					:sortOrder="1"
					v-model:filters="table_filters"
					:global-filter-fields="['source_id']"
					@update:sortField="updateSortField"
					@update:sortOrder="updateSortOrder"
					@sort="getData(true)"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No campaigns</div>
					</template>
					<template #header>
						<div class="flex justify-content-between flex-wrap gap-2">
							<p-input-text
								v-model="table_filters['global'].value"
								placeholder="Search Sources on this page"
								autocomplete="off"
								style="min-width: 300px"
							/>
							<div class="flex justify-content-end">
								<p-button text @click="show_zero_cost_modal = true" label="$0 Cost Campaigns" />
								<p-button
									v-if="!show_expanded_revenue"
									icon="pi pi-plus"
									label="Expand Revenue"
									@click.prevent="show_expanded_revenue = !show_expanded_revenue"
								/>
								<p-button
									v-else
									icon="pi pi-minus"
									label="Collapse Revenue"
									@click.prevent="show_expanded_revenue = !show_expanded_revenue"
								/>
							</div>
						</div>
					</template>
					<p-column header="Date" sortable field="date">
						<template #body="row">
							{{ row.data.date }}
						</template>
					</p-column>
					<p-column header="Day" sortable field="day">
						<template #body="row">
							<div>
								{{ row.data.day }}
							</div>
						</template>
					</p-column>
					<p-column header="Source Code" sortable field="source_id">
						<template #body="row">
							<div>
								{{ row.data.source_id }}
							</div>
						</template>
					</p-column>
					<p-column header="Profit" sortable field="profit">
						<template #body="row">
							<div :class="cellClass(row.data)">
								{{ currency(row.data.profit, 0) }}
							</div>
						</template>
					</p-column>
					<p-column header="Revenue" sortable field="total_revenue">
						<template #body="row">
							{{ currency(row.data.total_revenue, 0) }}
						</template>
					</p-column>
					<p-column
						sortable
						field="data_revenue"
						v-if="show_expanded_revenue"
						header="Data"
						:pt="{
							headerCell: { class: 'bg-teal-400' },
						}"
					>
						<template #body="row">
							{{ currency(row.data.data_revenue, 0) }}
						</template>
					</p-column>
					<p-column
						sortable
						field="lt_revenue"
						v-if="show_expanded_revenue"
						header="LT"
						:pt="{
							headerCell: { class: 'bg-teal-400' },
						}"
					>
						<template #body="row">
							{{ currency(row.data.live_transfer_revenue, 0) }}
						</template>
					</p-column>
					<p-column
						sortable
						field="call_revenue"
						v-if="show_expanded_revenue"
						header="Call"
						:pt="{
							headerCell: { class: 'bg-teal-400' },
						}"
					>
						<template #body="row">
							{{ currency(row.data.call_revenue, 0) }}
						</template>
					</p-column>
					<p-column
						sortable
						field="ads3_revenue"
						v-if="show_expanded_revenue"
						header="ADS3"
						:pt="{
							headerCell: { class: 'bg-teal-400' },
						}"
					>
						<template #body="row">
							{{ currency(row.data.ads3_revenue, 0) }}
						</template>
					</p-column>
					<p-column
						sortable
						field="ads1_revenue"
						v-if="show_expanded_revenue"
						header="ADS1"
						:pt="{
							headerCell: { class: 'bg-teal-400' },
						}"
					>
						<template #body="row">
							{{ currency(row.data.ads1_revenue, 0) }}
						</template>
					</p-column>
					<!--					<p-column header="Call" sortable field="call">-->
					<!--						<template #body="row">-->
					<!--							{{ currency(row.data.call, 0) }}-->
					<!--						</template>-->
					<!--					</p-column>-->
					<p-column header="Cost" sortable field="cost">
						<template #body="row">
							<div :class="needsCostCheck(row.data)">
								{{ currency(row.data.cost, 0) }}
								<a @click.prevent="openEditCostModal(row.data)">
									<icon v-tooltip.top="'Edit'" type="square-edit-outline" size="16px" />
								</a>
							</div>
						</template>
					</p-column>
					<p-column header="Count" sortable field="lead_count">
						<template #body="row">
							{{ row.data.lead_count }}
						</template>
					</p-column>
					<p-column header="RPL" sortable field="revenue_per_lead">
						<template #body="row">
							{{ currency(row.data.revenue_per_lead) }}
						</template>
					</p-column>
					<p-column header="CPL" sortable field="cost_per_lead">
						<template #body="row">
							{{ currency(row.data.cost_per_lead) }}
						</template>
					</p-column>
					<p-column header="Vertical" sortable field="vertical_id">
						<template #body="row">
							{{ title(row.data.vertical_id) }}
						</template>
					</p-column>
					<p-column header="Biz Unit" sortable field="business_unit">
						<template #body="row">
							{{ row.data.business_unit }}
						</template>
					</p-column>
				</p-data-table>
				<p-paginator :rows="query.pagination.page_size" :total-records="total_rows" @page="handlePageChange" />
			</div>
		</div>
		<p-dialog v-model:visible="show_modal" modal style="max-width: 400px">
			<template #header>
				<strong>Update Cost for {{ edit_data.source_id }}</strong>
			</template>
			<p>
				You are editing the cost data for {{ edit_data.source_id }} for the date of
				<strong>{{ edit_data.date }}</strong>
			</p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Cost:</label>
					<div class="controls">
						<div class="field">
							<p-input-currency v-model="edit_data.new_cost" />
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<p-button label="Update" :loading="loading" @click="updateCost" />
			</template>
		</p-dialog>

		<p-dialog v-model:visible="show_zero_cost_modal" modal style="max-width: 400px">
			<template #header>
				<strong>List of Campaigns that should be $0 cost</strong>
			</template>
			<div>
				<p>Based on this list of zero cost campaigns, any row that doesn't have a cost will be highlighted in yellow</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Zero Cost Campaigns:</label>
						<div class="controls">
							<div class="field">
								<p-chips
									add-on-blur
									id="tags"
									v-model="zero_cost_campaigns"
									separator=","
									placeholder="Separate by hitting enter"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<p-button label="Save" :loading="loading" @click="saveZeroCostCampaigns" />
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, title } from '@/lib/Filters';
import { getMAutLeadCampaigns, getSettings, updateLeadCampaignCost, updateSetting } from '@GQL';
import queryForm from '@/components/widgets/QueryForm.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { FilterMatchMode } from 'primevue/api';

export default {
	name: 'LeadCampaigns',
	components: {
		dateRangePicker,
		queryForm,
		tableTools,
	},
	inject: ['mq'],
	data() {
		return {
			table_filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			loading: false,
			show_modal: false,
			show_zero_cost_modal: false,
			zero_cost_campaigns: [],
			total_rows: 0,
			query: {
				range: [dayjs().subtract(5, 'day').toDate(), dayjs().subtract(1, 'day').toDate()],
				order: {
					field: 'date',
					desc: true,
				},
				pagination: {
					page: 0,
					page_size: 200,
				},
			},
			show_expanded_revenue: false,
			lead_campaigns: [],
			edit_data: {
				id: '',
				source_id: '',
				date: '',
				new_cost: 0,
			},
		};
	},
	methods: {
		currency,
		formatDate,
		title,
		needsCostCheck(data) {
			// check the zero list
			// if cost is zero and the source_id is not in the list
			return [
				{
					'color-cell bg-yellow-200': data.cost === 0 && this.zero_cost_campaigns.indexOf(data.source_id) === -1,
				},
			];
		},
		async updateSortField(value) {
			this.query.order.field = value;
		},
		async updateSortOrder(value) {
			this.query.order.desc = value === -1;
		},
		cellClass(data) {
			return [
				{
					'color-cell bg-teal-200 text-green-500': data.profit > 900,
					'color-cell bg-red-200': data.profit < 0,
				},
			];
		},
		openEditCostModal(data) {
			this.edit_data = {
				source_id: data.source_id,
				id: data.id,
				new_cost: data.cost,
				date: data.date,
			};
			this.show_modal = true;
		},
		async handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getData();
		},
		async getData(refresh = false) {
			this.loading = true;
			try {
				const date_range = this.query.range.map((d) => {
					return d.toISOString().slice(0, 10);
				});
				const { row_count, rows } = await getMAutLeadCampaigns(
					{
						date_range,
						pagination: this.query.pagination,
						order: this.query.order,
					},
					refresh
				);
				this.lead_campaigns = rows;
				this.total_rows = row_count;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get data',
					life: 6600,
				});
			} finally {
				this.loading = false;
			}
		},
		async updateCost() {
			this.loading = true;
			try {
				const res = await updateLeadCampaignCost(this.edit_data.id, this.edit_data.new_cost);
				this.$toast.add({
					severity: 'success',
					summary: `Cost for ${this.edit_data.source_id} has been updated`,
					life: 5000,
				});
				await this.getData(true);
				this.show_modal = false;
			} finally {
				this.loading = false;
			}
			// do the update with the id
		},
		async saveZeroCostCampaigns() {
			// get the array and set it in the settings
			this.loading = true;
			try {
				const res = await updateSetting(
					'maut_zero_cost_campaigns',
					{
						data: {
							campaigns: this.zero_cost_campaigns,
						},
					},
					'$NG',
					true
				);
				this.$toast.add({
					severity: 'info',
					summary: 'Zero cost campaigns are saved',
					life: 5600,
				});
				this.show_zero_cost_modal = false;
			} catch (err) {
				// TODO
				// console.error(err, 'Unable to save zero cost campaigns');
			} finally {
				this.loading = false;
			}
		},
		async getZeroCostCampaigns() {
			try {
				const zero_cost_campaigns = await getSettings('maut_zero_cost_campaigns');
				this.zero_cost_campaigns = get(zero_cost_campaigns, 'settings.maut_zero_cost_campaigns.campaigns', []);
			} catch (err) {
				// TODO
				// console.error(err, 'Zero Cost Campaign setting issue');
			}
		},
	},
	async mounted() {
		await this.getData();
		await this.getZeroCostCampaigns();
	},
};
</script>

<style lang="less" scoped>
.needs-cost-update {
	background: var(--yellow-600);
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
	font-size: 0.85rem;
	padding: 0.5rem;
	&:has(.color-cell) {
		padding: 0;
		.color-cell {
			padding: 0.75rem 1rem;
		}
	}
}
</style>
