<template>
	<div id="campaign-tracker-wrapper">
		<row>
			<div class="page-heading">
				<h1>Campaign Tracker</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
		</row>
		<row class="tab-row">
			<p-tab-menu v-model:activeIndex="active" :model="tabs">
				<template #item="{ label, item }">
					<template v-if="item.route">
						<div class="account-tab">
							<router-link :to="`${item.route}`">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
							</router-link>
						</div>
					</template>
				</template>
			</p-tab-menu>
		</row>
		<row class="maut-content">
			<div class="top-fade" />
			<router-view>
				<template #default="{ Component, route }">
					<transition name="drop-fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</template>
			</router-view>
		</row>
	</div>
</template>

<script lang="ts">
import pTabMenu from 'primevue/tabmenu';

export default {
	name: 'CampaignTracker',
	components: {
		pTabMenu,
	},
	data() {
		return {
			active_tab: 0,
			active: 0,
			tabs: [
				{
					label: 'Analyzer',
					icon: 'analytics',
					route: '/maut/campaign-tracker/analyzer',
					ref: 'analyzer',
				},
				{
					label: 'Key Campaigns',
					icon: 'ads_click',
					route: '/maut/campaign-tracker/key-campaigns',
					ref: 'key-campaigns',
				},
			],
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Campaign Tracker' }],
		};
	},
	beforeMount() {
		const paths = this.$router.currentRoute.value.fullPath.split('/').filter((p) => {
			if (p) return p;
		});

		if (paths.length === 2) {
			this.$router.push('/maut/campaign-tracker/analyzer');
		}
	},
	computed: {},
	methods: {
		transitionType(route) {
			return route.matched[route.matched.length - 1].meta.transition || 'drop-fade';
		},
	},
};
</script>

<style scoped lang="less">
#campaign-tracker-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;

	.row {
		flex: 0 0 auto;
	}

	.maut-content {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		overflow-y: hidden;

		.view-content {
			min-height: auto;
		}
	}

	.top-fade {
		background: linear-gradient(
			180deg,
			rgb(255 255 255 / 100%) 0%,
			rgb(255 255 255 / 100%) 30%,
			rgb(255 255 255 / 0%) 100%
		);
		height: 40px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
}

.page-heading {
	margin-bottom: -20px;
	padding: 40px;
}

.maut-content {
	background: linear-gradient(
		180deg,
		rgb(255 255 255 / 100%) 0%,
		rgb(255 255 255 / 0%) 15%,
		rgb(255 255 255 / 0%) 100%
	);

	:deep(h1) {
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}

	:deep(.page-heading) {
		margin-bottom: 1em;
	}

	:deep(.p-breadcrumb) {
		display: none;
	}
}
</style>
