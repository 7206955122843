<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Key Campaigns</h1>
		</div>
		<div class="page-content">
			<row>
				<column>
					<div class="flex align-items-center justify-content-end w-full activity-quick-filters">
						<quick-filter v-model="timeframe" value="daily">Daily</quick-filter>
						<quick-filter v-model="timeframe" value="weekly">Weekly</quick-filter>
						<quick-filter v-model="timeframe" value="monthly">Monthly</quick-filter>
					</div>
				</column>
			</row>
			<gutter size="10px" />
			<div class="card padded max-h-screen">
				<p-data-table ref="keycampaigns" :value="key_campaign_results" scrollable scrollHeight="800px">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No Data</div>
					</template>
					<p-column field="category" />
					<p-column frozen header="Campaign Name" field="name">
						<template #body>
							<template v-for="metric in metrics">
								<div class="cell bg-gray-100">
									<a @click="toggleHidden(metric)"
										><icon
											:key="componentKey"
											:type="hide_categories.includes(metric) ? 'plus' : 'minus'"
											class="mr-2" /></a
									><strong>{{ title(metric) }}</strong>
								</div>
								<div v-if="!hide_categories.includes(metric)">
									<div v-for="name in campaign_names" class="cell">
										{{ name }}
									</div>
								</div>
							</template>
						</template>
					</p-column>
					<p-column frozen header="Source Code">
						<template #body>
							<template v-for="metric in metrics">
								<div class="cell bg-gray-100">&nbsp;</div>
								<div v-if="!hide_categories.includes(metric)">
									<div v-for="name in campaign_names" class="cell">
										<strong>{{ abbrev(name) }}</strong>
									</div>
								</div>
							</template>
						</template>
					</p-column>
					<p-column v-for="date in dates" :header="date" :key="date">
						<template #body="row">
							<template v-for="metric in metrics">
								<div class="cell bg-gray-100">&nbsp;</div>
								<div v-if="!hide_categories.includes(metric)">
									<template v-for="key in keys">
										<div class="cell" :class="colorCode(get(row.data, `${date}.${key}.${metric}`), key, metric)">
											{{ get(row.data, `${date}.${key}.${metric}`) }}
										</div>
									</template>
								</div>
							</template>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import quickFilter from '@/components/widgets/QuickFilter.vue';
import { currency, percentage, colorCell, title } from '@/lib/Filters';
import { get } from 'lodash-es';

export default {
	name: 'KeyCampaigns',
	components: {
		quickFilter,
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			timeframe: 'daily',
			componentKey: 0,
			hide_categories: [],
			metrics: ['profit', 'roi', 'revenue', 'count', 'cost', 'revenue_per_lead', 'cost_per_lead'],
			campaign_names: [
				'All Google - Health',
				'All MediaAlpha - Health',
				'QuinStreet - Health',
				'Healthcare.com - Health',
				'All Bing - Health',
				'Delty - Health',
				'All SmartFinancial - Health',
				'All Datalot - Health',
				'All Facebook - Health',
				'All Email - Health',
				'All Partner Network - Health',
				'Medicare O&O',
				'MediaAlpha HE OX Flagship',
				'Excel Impact',
			],
			keys: [
				'google_health',
				'media_alpha_health',
				'quinstreet_health',
				'healthcare_com_health',
				'bing_health',
				'delty_health',
				'smart_financial_health',
				'datalot_health',
				'facebook_health',
				'email_health',
				'partner_network_health',
				'medicare_oo',
				'mediaalpha_he_ox',
				'excel_impact',
			],
			minmax: {
				google_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				media_alpha_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				quinstreet_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				healthcare_com_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				bing_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				delty_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				smart_financial_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				datalot_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				facebook_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				email_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				partner_network_health: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				medicare_oo: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				mediaalpha_he_ox: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
				excel_impact: {
					profit: { min: 100, max: 300 },
					roi: { min: 100, max: 500 },
					revenue: { min: 0, max: 1000 },
					count: { min: 0, max: 100 },
					cost: { min: 0, max: 150 },
					revenue_per_lead: { min: 0, max: 100 },
					cost_per_lead: { min: 0, max: 100 },
				},
			},
			key_campaign_results: [
				{
					'2021-01-01': {
						date: '2021-01-01',
						google_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						media_alpha_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						quinstreet_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						healthcare_com_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						bing_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						delty_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						smart_financial_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						datalot_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						facebook_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						email_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						partner_network_health: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						medicare_oo: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						mediaalpha_he_ox: {
							profit: 250,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						excel_impact: {
							profit: 100,
							roi: 200,
							revenue: 450,
							count: 40,
							cost: 70,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
					},
					'2021-01-02': {
						date: '2021-01-02',
						google_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						media_alpha_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						quinstreet_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						healthcare_com_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						bing_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						delty_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						smart_financial_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						datalot_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						facebook_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						email_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						partner_network_health: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						medicare_oo: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						mediaalpha_he_ox: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
						excel_impact: {
							profit: 100,
							roi: 200,
							revenue: 300,
							count: 70,
							cost: 50,
							revenue_per_lead: 60,
							cost_per_lead: 50,
						},
					},
				},
			],
		};
	},
	methods: {
		get,
		currency,
		percentage,
		colorCell,
		title,
		abbrev(campaign_name: string) {
			switch (campaign_name) {
				case 'All Google - Health':
					return 'HE_GS';
				case 'All MediaAlpha - Health':
					return 'HE_MA|HE_XCL1|HE_SC|HE_DMS';
				case 'QuinStreet - Health':
					return 'NG_HE_QUIN';
				case 'Healthcare.com - Health':
					return 'NG_HE_HC';
				case 'All Bing - Health':
					return 'HE_BS';
				case 'Delty - Health':
					return 'NG_HE_DLT';
				case 'All SmartFinancial - Health':
					return 'HE_SF';
				case 'All Datalot - Health':
					return 'HE_LOT';
				case 'All Facebook - Health':
					return 'HE_FB';
				case 'All Email - Health':
					return 'HE_EM';
				case 'All Partner Network - Health':
					return 'pn_he';
				case 'Medicare O&O':
					return 'NG_ME';
				case 'MediaAlpha HE OX Flagship':
					return 'NG_HE_MA';
				case 'Excel Impact':
					return 'NG_HE_XCL1';
				default:
					return '';
			}
		},
		colorCode(value, key, metric) {
			return colorCell(value, this.minmax[key][metric].min, this.minmax[key][metric].max);
		},
		toggleHidden(category) {
			if (this.hide_categories.includes(category)) {
				this.hide_categories = this.hide_categories.filter((c) => c !== category);
			} else {
				this.hide_categories.push(category);
			}
			this.componentKey += 1;
		},
	},
	computed: {
		dates() {
			return Object.keys(this.key_campaign_results[0]);
		},
	},
};
</script>

<style lang="less" scoped>
:deep(.p-datatable .p-datatable-tbody > tr > td) {
	padding: 0;
}

.cell {
	padding: 0.25rem 0.5rem;
	background: var(--white);
	&:hover {
		filter: brightness(0.9);
		color: black !important;
	}
}

.sub-value {
	margin: 0;
}
</style>
