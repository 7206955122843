<template>
	<div id="campaigns-and-revenue-wrapper">
		<row>
			<div class="page-heading">
				<h1>Campaigns and Revenue</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
		</row>
		<row class="tab-row">
			<p-tab-menu v-model:activeIndex="active" :model="tabs">
				<template #item="{ label, item }">
					<template v-if="item.route">
						<div class="account-tab">
							<router-link :to="`${item.route}`">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
							</router-link>
						</div>
					</template>
				</template>
			</p-tab-menu>
		</row>
		<row class="maut-content">
			<div class="top-fade" />
			<router-view>
				<template #default="{ Component, route }">
					<transition name="drop-fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</template>
			</router-view>
		</row>
	</div>
</template>

<script lang="ts">
import pTabMenu from 'primevue/tabmenu';

export default {
	name: 'CampaignsAndRevenue',
	components: {
		pTabMenu,
	},
	data() {
		return {
			active_tab: 0,
			active: 0,
			tabs: [
				{
					label: 'Lead Campaigns',
					icon: 'flag_circle',
					route: '/maut/campaigns-and-revenue/lead-campaigns',
					ref: 'lead-campaigns',
				},
				{
					label: 'Customer Acquisition Campaigns',
					icon: 'person_add',
					route: '/maut/campaigns-and-revenue/customer-acquisition-campaigns',
					ref: 'customer-acquisition-campaigns',
				},
				{
					label: 'Revenue',
					icon: 'paid',
					route: '/maut/campaigns-and-revenue/revenue',
					ref: 'revenue',
				},
			],
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Campaigns and Revenue' }],
		};
	},
	beforeMount() {
		const paths = this.$router.currentRoute.value.fullPath.split('/').filter((p) => {
			if (p) return p;
		});

		let last_path = paths[paths.length - 1];
		if (paths.length === 2) {
			this.$router.push('/maut/campaigns-and-revenue/lead-campaigns');
			last_path = 'lead-campaigns';
		}

		// get last path and make sure the active index is the correct one
		const tab_index = this.tabs.findIndex((row) => {
			return row.ref === last_path;
		});
		this.active = tab_index;
	},
	computed: {},
	methods: {
		transitionType(route) {
			return route.matched[route.matched.length - 1].meta.transition || 'drop-fade';
		},
	},
};
</script>

<style scoped lang="less">
#campaigns-and-revenue-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;

	.row {
		flex: 0 0 auto;
	}

	.maut-content {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		overflow-y: hidden;

		.view-content {
			min-height: auto;
		}
	}

	.top-fade {
		background: linear-gradient(
			180deg,
			rgb(255 255 255 / 100%) 0%,
			rgb(255 255 255 / 100%) 30%,
			rgb(255 255 255 / 0%) 100%
		);
		height: 40px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
}

.page-heading {
	margin-bottom: -20px;
	padding: 40px;
}

.maut-content {
	background: linear-gradient(
		180deg,
		rgb(255 255 255 / 100%) 0%,
		rgb(255 255 255 / 0%) 15%,
		rgb(255 255 255 / 0%) 100%
	);

	:deep(h1) {
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}

	:deep(.page-heading) {
		margin-bottom: 1em;
	}

	:deep(.p-breadcrumb) {
		display: none;
	}
}
</style>
