<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>{{ $route.meta.title }}</h1>
		</div>
		<div class="page-content">
			<row>
				<column>
					<div class="flex align-items-center justify-content-end w-full activity-quick-filters">
						<quick-filter v-model="timeframe" value="daily">Daily</quick-filter>
						<quick-filter v-model="timeframe" value="weekly">Weekly</quick-filter>
						<quick-filter v-model="timeframe" value="monthly">Monthly</quick-filter>
					</div>
				</column>
			</row>
			<gutter size="10px" />
			<div class="card padded max-h-screen">
				<p-data-table
					ref="bizunitpeformance"
					:value="tableData"
					rowGroupMode="subheader"
					groupRowsBy="category"
					scrollable
					scrollHeight="800px"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No Data</div>
					</template>
					<p-column field="category" />
					<p-column frozen field="name" :colspan="1">
						<template #body="row">
							<div class="sub-value">
								{{ row.data.name }}
							</div>
						</template>
					</p-column>
					<p-column frozen :colspan="1">
						<template #body="row">
							<strong class="sub-value">
								{{ abbrev(row.data.name) }}
							</strong>
						</template>
					</p-column>
					<p-column frozen header="Total" :colspan="1">
						<template #body>
							<div class="sub-value">100</div>
						</template>
					</p-column>
					<p-column v-for="date in dates" :header="date" :key="date">
						<template #body="row">
							{{ row.data.value }}
						</template>
					</p-column>
					<template #groupheader="row">
						<div class="flex align-items-center gap-2 color-row bg-gray-100 relative w-full">
							<strong class="inline sticky left-1rem">{{ row.data.category }}</strong>
						</div>
					</template>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import quickFilter from '@/components/widgets/QuickFilter.vue';
import { currency, percentage, colorCell } from '@/lib/Filters';

export default {
	name: 'OOPerformance',
	components: {
		quickFilter,
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			timeframe: 'daily',
			group_headers: {
				summary_metrics: 'Summary Metrics',
				monetization_metrics: 'Monetization Metrics',
				revenue_per_purchase: 'Revenue Per Purchase (Price)',
				purchase_counts: 'Purchase Counts',
				call_metrics: 'Call Metrics',
				revenue_percentage: 'Revenue Percentage',
				rates: 'Rates',
			},
			key_labels: [
				{ key: 'profit', label: 'Profit' },
				{ key: 'roi', label: 'ROI' },
				{ key: 'total_lead_count', label: 'Total Lead Count' },
				{ key: 'total_rpl', label: 'Total RPL' },
				{ key: 'cpl', label: 'CPL' },
				{ key: 'ppl', label: 'PPL' },
				{ key: 'total_lead_cost', label: 'Total Lead Cost' },
				{ key: 'data_lead_rpl', label: 'Data Lead RPL' },
				{ key: 'live_transfer_rpl', label: 'Live Transfer RPL' },
				{ key: 'non_typ_call_rpl', label: 'Non-TYP Call RPL' },
				{ key: 'total_lead_working_rpl', label: 'Total Lead Working RPL' },
				{ key: 'typ_call_rpl', label: 'TYP Call RPL' },
				{ key: 'ads1_rpl', label: 'ADS1 RPL' },
				{ key: 'ads3_rpl', label: 'ADS3 RPL' },
				{ key: 'total_typ_rpl', label: 'Total TYP RPL' },
				{ key: 'non_typ_call_rate', label: 'Non-TYP Call Accept Rate' },
				{ key: 'typ_call_rate', label: 'TYP Call Accept Rate' },
				{ key: 'ads1_rate', label: 'ADS1 Accept Rate' },
				{ key: 'ads3_rate', label: 'ADS3 Accept Rate' },
				{ key: 'total_typ_rate', label: 'Total TYP Call Accept Rate' },
				{ key: 'exclusive_leads', label: 'Exclusive Leads' },
				{ key: 'shared_leads', label: 'Shared Leads' },
				{ key: 'live_transfers', label: 'Live Transfers' },
				{ key: 'non_typ_first_party_calls', label: '1st Party Calls (Non-TYP)' },
				{ key: 'typ_first_party_calls', label: '1st Party Calls (TYP)' },
				{ key: 'total_call_revenue', label: 'Total Call Revenue' },
				{ key: 'call_revenue_per_lead', label: 'Call Revenue per Lead' },
				{ key: 'call_revenue_per_purchase', label: 'Call Revenue per Purchase' },
				{ key: 'first_party_ads', label: '1st Party Ads' },
				{ key: 'third_party_ads', label: '3rd Party Ads' },
				{ key: 'data_monetization', label: 'Data Monetization' },
			],
			performance_results: [
				{
					date: '2024-01-01',
					summary_metrics: {
						profit: 100,
						roi: 300,
						total_lead_count: 500,
						total_rpl: 900,
						cpl: 0,
						ppl: 0,
						total_lead_cost: 0,
					},
					monetization_metrics: {
						data_lead_rpl: 100,
						live_transfer_rpl: 300,
						non_typ_call_rpl: 500,
						total_lead_working_rpl: 900,
						typ_call_rpl: 0,
						ads1_rpl: 0,
						ads3_rpl: 0,
						total_typ_rpl: 0,
						non_typ_call_rate: 0,
						typ_call_rate: 0,
					},
					revenue_per_purchase: {
						exclusive_leads: 100,
						shared_leads: 300,
						live_transfers: 500,
						non_typ_first_party_calls: 0,
						typ_first_party_calls: 0,
						first_party_ads: 0,
						third_party_ads: 0,
					},
					purchase_counts: {
						exclusive_leads: 100,
						shared_leads: 300,
						live_transfers: 500,
						non_typ_first_party_calls: 0,
						typ_first_party_calls: 0,
						first_party_ads: 0,
						third_party_ads: 0,
					},
					call_metrics: {
						total_call_revenue: 100,
						call_revenue_per_lead: 300,
						call_revenue_per_purchase: 500,
					},
					revenue_percentage: {
						exclusive_leads: 100,
						shared_leads: 300,
						live_transfers: 500,
						non_typ_first_party_calls: 0,
						typ_first_party_calls: 0,
						first_party_ads: 0,
						third_party_ads: 0,
						data_monetization: 100,
					},
				},
			],
		};
	},
	computed: {
		vertical() {
			return this.$route.meta.vertical;
		},
		dates() {
			return this.performance_results.map((entry) => entry.date);
		},
		tableData() {
			let formattedData = [];
			this.performance_results.forEach((entry) => {
				Object.keys(this.group_headers).forEach((metricKey) => {
					if (entry[metricKey]) {
						Object.keys(entry[metricKey]).forEach((key) => {
							const keyLabel = this.key_labels.find((label) => label.key === key);
							if (keyLabel) {
								const formattedEntry = {
									category: this.group_headers[metricKey],
									name: keyLabel.label,
									date: entry.date,
									value: entry[metricKey][key],
								};
								formattedData.push(formattedEntry);
							}
						});
					}
				});
			});
			return formattedData;
		},
	},
	methods: {
		currency,
		percentage,
		colorCell,
		abbrev(metric: string) {
			switch (metric) {
				case 'Exclusive Leads':
					return 'EXC';
				case 'Shared Leads':
					return 'SHR';
				case 'Live Transfers':
					return 'LT';
				case '1st Party Calls (Non-TYP)':
					return 'CALL';
				case '1st Party Calls (TYP)':
					return 'TYPCALL';
				case '1st Party Ads':
					return 'ADS1';
				case '3rd Party Ads':
					return 'ADS3';
				case 'Data Monetization':
					return 'DM';
				default:
					return null;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.left-1rem {
	left: 1rem;
}

:deep(.p-datatable .p-datatable-tbody > tr > td:has(.color-cell)) {
	padding: 0;
	.color-cell {
		padding: 0.75rem 1rem;
	}
}
:deep(.p-datatable .p-datatable-tbody > tr > td:has(.color-row)) {
	padding: 0;
	.color-row {
		padding: 0.75rem 1rem;
	}
}
</style>
