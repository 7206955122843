<template>
	<div
		id="sidebar"
		class="sidebar"
		:class="{
			collapsed: menuCollapsed,
			minimized: !isMarketplaceSidebar && (mq.tabletPlus ? false : menuHidden),
			'marketplace-sidebar': isMarketplaceSidebar,
		}"
	>
		<!-- <div v-if="mq.phone && !menuHidden" class="mobile-close" @click="mobileToggle()">
			<icon type="backburger" size=" 28px" />
		</div> -->
		<div v-if="!menuHidden" class="mobile-close" @click="mobileToggle()">
			<icon type="backburger" size=" 28px" />
		</div>
		<div class="branding">
			<div v-if="menuCollapsed && isMarketplaceSidebar" class="toggle-arrow" @click="toggleSidebar">
				<icon type="chevron-right" size="24px" />
			</div>
			<router-link :to="{ name: 'Home' }">
				<img class="logo-icon" src="/assets/img/logos/ngl-icon-white.svg" :alt="`NextGen Leads`" />
				<img class="logo-large" :src="logoUrl" :alt="marketplaceName" />
			</router-link>
		</div>
		<NavMenu :menu-collapsed="menuCollapsed" :mpid="mpid" />
		<Support :menu-collapsed="menuCollapsed" />
	</div>
</template>

<script lang="ts">
import { get } from 'lodash-es';
import NavMenu from '@/components/navigation/Menu.vue';
import Support from '@/components/navigation/Support.vue';
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Sidebar',
	components: {
		NavMenu,
		Support,
	},
	inject: {
		mq: {
			from: 'mq',
		},
	},
	props: {
		menuCollapsed: Boolean,
		menuHidden: Boolean,
		mpid: String,
		nested: Boolean,
		isMarketplaceSidebar: Boolean,
	},
	emits: ['mobile-close'],
	data() {
		return {};
	},
	computed: {
		...mapStores(useAppStore, useMarketplaceStore),
		marketplaceName() {
			return get(this.appStore, 'marketplace.name', get(this.appStore, 'marketplace.name', 'NextGen Leads'));
		},
		logoUrl() {
			if (this.appStore.mpid !== '$NG' || (this.appStore.mpid === '$NG' && this.$route.meta.nested_marketplace)) {
				let store_to_use = 'appStore';
				if (this.appStore.mpid === '$NG') {
					store_to_use = 'marketplaceStore';
				}

				return get(
					this[store_to_use],
					'marketplace.settings.logo_url',
					'https://ngl4-uploads.s3.us-west-2.amazonaws.com/marketplaces/NGL/marketplace-logo.png'
				);
			}
			return '/assets/img/logos/ngl-logo-white.svg';
		},
	},
	methods: {
		mobileToggle() {
			this.$emit('mobile-toggle');
		},
		toggleSidebar() {
			// event.preventDefault();
			this.$emit('toggle-menu');
		},
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';

#sidebar {
	background: var(--gray-75);
	box-shadow: var(--shadow-default);
	display: flex;
	flex: 0 0 260px;
	flex-direction: column;
	justify-content: flex-start;
	overflow: visible;
	position: relative;
	transition: 0.3s;
	z-index: 1000;

	&.marketplace-sidebar {
		background-color: var(--white);
		// box-shadow: var(--shadow-light);
	}

	.logo-icon {
		display: none;
	}

	.toggle-arrow {
		display: none;
	}

	.mobile-close {
		color: var(--gray-30);
		position: absolute;
		right: 0.5rem;
		top: 0.5rem;
	}

	.branding {
		border-radius: 5px;
		margin: 20px 0 0;

		a {
			align-items: center;
			display: flex;
			height: 100px;
			justify-content: center;
		}

		img {
			max-height: 100px;
			max-width: 180px;
		}
	}

	&.collapsed {
		flex: 0 0 60px;

		.logo-icon {
			display: block;
			margin: 10px auto;
			padding: 0;
		}

		.toggle-arrow {
			display: block;
			cursor: pointer;
			margin: 10px auto;
			padding: 0;
		}

		.logo-large {
			display: none;
		}

		.branding {
			margin-top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 60px; //

			a {
				height: 60px;
				margin: 0;
				padding: 5px;
			}

			img {
				height: 40px;
				width: 28px;
			}
		}
	}

	&.minimized {
		flex: 0;
		transform: translateX(-250px);
		width: 0;
	}

	.desktop-up({
		flex: 0 0 280px;
	});

	.mobile({
		position: fixed;
		height: 100%;
		z-index: 1000;
		width: 280px;
	});
}

#marketplace-wrapper #sidebar,
body.marketplace #sidebar {
	background-color: var(--white);
	width: 70px;
	position: relative;

	.logo-icon {
		display: none;
	}
}
</style>
