<template>
	<div id="create-automatic-optimization-form" class="view-content">
		<div class="page-heading">
			<h1>Add Automatic Optimizer</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<AutoOptimizerForm />
	</div>
</template>

<script>
import AutoOptimizerForm from './Form.vue';
export default {
	name: 'AutoOptimizerPage',
	components: {
		AutoOptimizerForm,
	},
	data() {
		return {
			breadcrumb_items: [
				{
					label: 'MAUT',
					route: '/automatic-optimization',
				},
				{
					label: 'New Automatic Optimizer',
				},
			],
		};
	},
};
</script>
