<template>
	<div id="vendors-form" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.vendor_id ? 'Edit' : 'Create' }} Vendor</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card class="vendor-card">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Vendor Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text id="vendor" v-model="vendor.name" class="key-input flex-1" placeholder="Name" />
								<div v-if="v$.vendor.name.$error" class="validation-errors">
									<div v-for="error in v$.vendor.name.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Vendor ID:</label>
						<div class="controls">
							<div class="field">
								<div class="p-inputgroup">
									<p-input-text
										v-if="existing_record"
										id="vendor-id"
										v-model="formattedVendorId"
										class="key-input flex-1"
										:disabled="existing_record"
										:pt="{
											root: { class: 'border-gray-500 surface-100 text-black-alpha-90 opacity-90' },
										}"
									/>
									<p-input-text
										v-else
										id="vendor-id"
										v-model="formattedVendorId"
										class="key-input flex-1"
										placeholder="Only lowercase letters, numbers, and underscores are allowed"
									/>
									<div class="p-inputgroup-addon">
										<icon type="lock" />
									</div>
								</div>
								<div v-if="v$.vendor.id.$error">
									<p v-for="error in v$.vendor.id.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p-fieldset legend="Contact Info">
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Name:</label>
							<div class="controls">
								<div class="field">
									<p-input-text
										id="full-name"
										v-model="vendor.contact.name"
										class="p-inputtext"
										placeholder="Full Name"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Phone:</label>
							<div class="controls">
								<div class="field">
									<p-input-mask
										id="phone"
										v-model="vendor.contact.phone"
										mask="(999) 999-9999"
										placeholder="(999) 999-9999"
										:unmask="true"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Email:</label>
							<div class="controls">
								<div class="field">
									<p-input-text id="email" v-model="vendor.contact.email" class="p-inputtext" placeholder="Email" />
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>
				<gutter size="20px" />
				<p class="mb-0 font-bold">
					What type of leads are being purchased from this Vendor?<span
						style="color: var(--red-600); font-size: 0.8rem; margin-left: 0.25rem; vertical-align: super"
						>*</span
					>
				</p>
				<p-fieldset legend="Auto Insurance" class="toggle-set">
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.auto_insurance.data" />
						</div>
						Data
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.auto_insurance.call" />
						</div>
						Calls
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.auto_insurance.live_transfer" />
						</div>
						Live Transfers
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.auto_insurance.ad" />
						</div>
						Ads
					</div>
				</p-fieldset>
				<p-fieldset legend="Health Insurance" class="toggle-set">
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.health_insurance.data" />
						</div>
						Data
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.health_insurance.call" />
						</div>
						Calls
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.health_insurance.live_transfer" />
						</div>
						Live Transfers
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch mr-1">
							<p-input-switch v-model="vendor.verticals.health_insurance.ad" />
						</div>
						Ads
					</div>
				</p-fieldset>
				<p-fieldset legend="Medicare" class="toggle-set">
					<div class="flex align-items-center m-1">
						<div class="switch">
							<p-input-switch v-model="vendor.verticals.medicare.data" />
						</div>
						Data
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch">
							<p-input-switch v-model="vendor.verticals.medicare.call" />
						</div>
						Calls
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch">
							<p-input-switch v-model="vendor.verticals.medicare.live_transfer" />
						</div>
						Live Transfers
					</div>
					<div class="flex align-items-center m-1">
						<div class="switch">
							<p-input-switch v-model="vendor.verticals.medicare.ad" />
						</div>
						Ads
					</div>
				</p-fieldset>
				<p-fieldset legend="Settings">
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<label class="req" for="return-rate">Max Return Rate</label>
									<div class="faded">The maximum return rate allowed when selling this vendor's leads</div>
								</div>
								<div class="field max-w-6rem">
									<p-input-percentage class="input-align-center" v-model="vendor.settings.max_return_rate" />
								</div>
							</div>
						</div>
					</div>
					<gutter size="20px" />
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<label class="req" for="allow-cancellations">Allow Cancellations</label>
									<div class="faded">Whether or not we can cancel on these vendor's leads</div>
								</div>
								<div class="field fit">
									<p-input-switch id="allow-cancellations" v-model="vendor.settings.allow_cancellations" />
								</div>
							</div>
						</div>
					</div>
					<gutter size="20px" />
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<label class="req" for="bid-margin">Bid Margin</label>
									<div class="faded">The percentage we will add for selling these vendor's leads</div>
								</div>
								<div class="field max-w-6rem">
									<p-input-percentage class="input-align-center" id="bid-margin" v-model="vendor.settings.bid_margin" />
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="vendor.status"
									:options="status_options"
									placeholder="Select Status"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
						<div v-if="v$.vendor.status.$error">
							<p v-for="error in v$.vendor.status.$errors" :key="error.$uid" class="validation-error">
								{{ error.$message }}
							</p>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-between align-items-center">
					<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
					<p-button icon="pi pi-check" label="Save Vendor" @click.prevent="submit" />
				</div>
			</template>
		</p-card>
	</div>
</template>
<script>
import { status_options } from '@/lib/Options';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import pInputPercentage from '@/components/forms/InputPercentage.vue';
import { merge, omit, snakeCase } from 'lodash-es';
import { getVendorById, insertVendor, updateVendor } from '@GQL';

function lowerCamelCase(value) {
	const regex = /^[a-z0-9_]+$/g;
	return value.indexOf(' ') === -1 && regex.test(value);
}

function mustBeEmail(value) {
	const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
	return !helpers.req(value) || regex.test(value);
}

function mustBePhoneNumber(value) {
	const regex = /^[0-9]{10}$/;
	return !helpers.req(value) || (regex.test(value) && value.length === 10);
}

const default_vendor = {
	id: '',
	name: '',
	verticals: {
		auto_insurance: {
			data: false,
			call: false,
			live_transfer: false,
			ad: false,
		},
		health_insurance: {
			data: false,
			call: false,
			live_transfer: false,
			ad: false,
		},
		medicare: {
			data: false,
			call: false,
			live_transfer: false,
			ad: false,
		},
	},
	contact: {
		phone: '',
		name: '',
		email: '',
	},
	settings: {
		max_return_rate: 0,
		allow_cancellations: false,
		bid_margin: 0,
	},
	status: '',
};

export default {
	name: 'VendorsForm',
	components: {
		pInputPercentage,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			vendor: { ...default_vendor },
			existing_record: false,
			status_options,
			breadcrumb_items: [{ label: 'Vendors' }],
			test: 0.1,
		};
	},
	computed: {
		formattedVendorId: {
			get() {
				return snakeCase(this.vendor.id);
			},
			set(new_value) {
				this.vendor.id = snakeCase(new_value);
			},
		},
	},
	async mounted() {
		try {
			this.loading = true;
			this.existing_record = !!this.$route.params.vendor_id;
			if (this.existing_record) {
				const vendor = await getVendorById(this.$route.params.vendor_id);
				this.vendor = merge(default_vendor, vendor);
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async submit() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				try {
					let result;
					if (this.existing_record) {
						result = await updateVendor(this.vendor.id, omit(this.vendor, 'id'));
					} else {
						result = await insertVendor(this.vendor);
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully ${this.existing_record ? 'updated' : 'inserted new'} vendor`,
							detail: `${this.vendor.name}`,
							life: 5000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to ${this.existing_record ? 'update' : 'insert new'} vendor`,
							detail: `${this.vendor.name}`,
						});
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: `Unable to ${this.existing_record ? 'update' : 'insert new'} vendor`,
						detail: `${this.vendor.name}`,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
	validations() {
		return {
			vendor: {
				name: { required },
				id: {
					required,
					custom: helpers.withMessage('Must match lower camel case', lowerCamelCase),
				},
				contact: {
					phone: { mustBePhoneNumber },
					email: { mustBeEmail },
				},
				verticals: {
					health_insurance: {
						data: { required },
						call: { required },
						live_transfer: { required },
						ad: { required },
					},
					medicare: {
						data: { required },
						call: { required },
						live_transfer: { required },
						ad: { required },
					},
				},
				settings: {
					max_return_rate: { required },
					allow_cancellations: { required },
					bid_margin: { required },
				},
				status: { required },
			},
		};
	},
};
</script>

<style lang="less">
.vendor-card {
	max-width: 800px;
}

.description p {
	color: var(--gray-50);
}

.toggle-set {
	font-size: var(--font-size-sm);
}
</style>
