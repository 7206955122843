<template>
	<div id="marketplace-wrapper">
		<sidebar
			:mpid="marketplaceStore.mpid"
			:menu-collapsed="menuCollapsed"
			:menu-hidden="menuHidden"
			:collapsed-active="collapsedActive"
			:is-marketplace-sidebar="true"
			@mobile-toggle="handleMobileToggle"
			@toggle-menu="handleMenuToggle"
			nested
		/>
		<main :class="{ 'opacity-50': !menuHidden && mq.phone }">
			<div id="marketplace-view" ref="nestedview" @click="handleBodyClick">
				<router-view>
					<template #default="{ Component, route }">
						<transition :name="transitionType(route)" mode="out-in">
							<component :is="Component" />
						</transition>
					</template>
				</router-view>
			</div>
		</main>
	</div>
</template>

<script lang="ts">
import { provide } from 'vue';
import sidebar from '@/components/ui/Sidebar.vue';
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';
import { $GQL } from '@GQL/index';

export default {
	name: 'NestedMarketplaceWrapper',
	components: {
		sidebar,
	},
	inject: ['mq'],
	provide: {
		isNested: true,
	},
	setup() {
		const marketplaceStore = useMarketplaceStore();
		provide(marketplaceStore);

		return {
			marketplaceStore,
		};
	},
	data() {
		return {
			menuCollapsed: false,
			menuHidden: false,
			collapsedActive: false,
			show: false,
		};
	},
	computed: {
		...mapStores(useAppStore, useMarketplaceStore),
	},
	methods: {
		handleBodyClick() {
			if (this.mq.phone === true && this.menuHidden === false) {
				// this.menuCollapsed = true;
				this.menuHidden = !this.menuHidden;
			}
		},
		handleMenuToggle() {
			this.menuCollapsed = !this.menuCollapsed;
			this.collapsedActive = false;
			this.menuHidden = false;
		},
		handleMobileToggle() {
			this.menuCollapsed = true;
			this.collapsedActive = false;
			this.menuHidden = !this.menuHidden;
		},
		transitionType(route) {
			return route.matched[route.matched.length - 1].meta.transition || 'drop-fade';
		},
	},
	async beforeRouteEnter(to, from) {
		const marketplaceStore = useMarketplaceStore();
		await marketplaceStore.initialize(to.params.mpid);

		try {
			await $GQL.setMPID(to.params.mpid);
		} catch (err) {
			$root.toast.add({
				severity: 'error',
				summary: 'Access Denied',
				detail: 'You do not have permission to manage the selected marketplace',
				life: 3000,
			});
			return false;
		}

		return true;
	},
	async beforeRouteUpdate(to, from) {
		if (to.params.mpid !== from.params.mpid) {
			await this.marketplaceStore.initialize(to.params.mpid);

			try {
				await $GQL.setMPID(to.params.mpid);
			} catch (err) {
				$root.toast.add({
					severity: 'error',
					summary: 'Access Denied',
					detail: 'You do not have permission to manage the selected marketplace',
					life: 3000,
				});
				return false;
			}
		}

		return true;
	},
	async beforeRouteLeave(to, from) {
		// Unassign the marketplace
		this.marketplaceStore.mpid = null;
	},
	mounted() {
		this.show = true;
	},
};
</script>

<style lang="less">
#marketplace-view {
	height: calc(100vh - 62px);
	overflow: auto;
	position: relative;
}

#marketplace-wrapper {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.account-content > .view-content {
	height: auto;
	margin-bottom: 0;
	overflow: visible;
}
</style>
