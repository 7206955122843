<template>
	<div id="dncs-list" class="view-content">
		<DNCRecordModal id="dnc_record_modal" ref="dnc_modal" @refresh="getList" />
		<h1>DNCs</h1>
		<breadcrumb :items="breadcrumb_items" />
		<div>
			<gutter size="20px" />
			<div class="flex">
				<p-button icon="pi pi-plus" label="Add DNC" @click.prevent="$refs.dnc_modal.openModal(accountId)" />
				<gutter size="20px" />
				<DNCCSVUploadModal />
			</div>
			<gutter size="20px" />
			<query-form v-model:query="query" :loading="loading" @apply="getList">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>

					<div class="display-value">
						<div class="label">Types:</div>
						<div class="value">
							{{ displaySelected(query.filters.type, dnc_type_options) }}
						</div>
					</div>
				</template>

				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range-picker v-model="form.query.filters.created_at" hide-labels show-time />
										</div>
									</div>
								</div>
							</div>

							<div class="control-group">
								<div class="inner">
									<label class="control-label">Filter By Type:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="form.query.filters.type"
												:options="dnc_type_options"
												option-label="label"
												option-value="value"
												placeholder="Select DNC Types"
												class="w-full"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>

			<div class="card padded">
				<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="dncs">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="created_at" header="Created">
						<template #body="dnc">
							{{ formatDate(dnc.data.created_at, 'MM/DD/YYYY') }}
						</template>
					</p-column>
					<p-column class="align-center" field="phone" header="Phone">
						<template #body="dnc">
							{{ phoneFormat(dnc.data.phone) }}
						</template>
					</p-column>
					<p-column field="source" header="Source">
						<template #body="dnc">
							{{ dnc.data.source }}
						</template>
					</p-column>
					<p-column class="align-center" field="user" header="User">
						<template #body="dnc">
							<div v-if="dnc.data.type === 'account'">
								{{ dnc.data.account.name }}
								<div class="sub-value">By: {{ dnc.data.user.first_name + ' ' + dnc.data.user.last_name }}</div>
							</div>
							<div v-else>
								{{ dnc.data.user.first_name + ' ' + dnc.data.user.last_name }}
							</div>
						</template>
					</p-column>
					<p-column field="dnc_type" header="Type">
						<template #body="dnc">
							{{ capitalize(dnc.data.type) }}
						</template>
					</p-column>
					<p-column header="Tools">
						<template #body="dnc">
							<icon v-tooltip.top="'Edit DNC Info' + dnc.data.id" class="tool" type="square-edit-outline" size="24px" />
						</template>
					</p-column>
				</p-data-table>
				<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
			</div>
		</div>
	</div>
</template>

<script>
/**
 * TODO:UI add filters, maybe phone search, maybe account id filter
 * // make a note that list refreshes after 5 minutes
 */
import queryForm from '@/components/widgets/QueryForm.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import { getDNCList } from '@GQL';
import DNCRecordModal from '../Record/RecordModal.vue';
import DNCCSVUploadModal from '@/views/DNCs/Record/CSVUploadModal.vue';
import { formatDate, phoneFormat, capitalize, displaySelected } from '@/lib/Filters';
import { dnc_type_options } from '@/lib/Options';
import dayjs from 'dayjs';

export default {
	name: 'DNCList',
	components: {
		dateRangePicker,
		queryForm,
		DNCRecordModal,
		DNCCSVUploadModal,
	},
	props: {},
	data() {
		return {
			breadcrumb_items: [{ label: 'DNCs' }],
			query: {
				filters: {
					created_at: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
					type: [],
				},
				pagination: {
					page_size: 50,
					page: 0,
				},
			},
			dnc_type_options,
			dncs: [],
			total_records: 0,
			loading: false,
		};
	},
	computed: {
		accountId() {
			// used for nested account id dnc lists...
			if ('account_id' in this.$route.params) {
				return this.$route.params.account_id;
			} else {
				return null;
			}
		},
	},
	async mounted() {
		// so get List
		await this.getList();
	},
	methods: {
		displaySelected,
		formatDate,
		phoneFormat,
		capitalize,
		handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.getList();
		},
		async getList() {
			this.loading = true;
			const filters = [
				`(created_at BETWEEN '${this.query.filters.created_at[0].toISOString()}' AND '${this.query.filters.created_at[1].toISOString()}')`,
			];

			if (this.accountId) {
				filters.push(`account_id = '${accountId}'`);
				filters.push(`type = 'account'`);
			}

			if (this.query.filters.type.length !== 0) {
				const types_str = this.query.filters.type
					.map((row) => {
						return `type = '${row}'`;
					})
					.join(' OR ');
				filters.push('(' + types_str + ')');
			}

			try {
				const { rows, row_count } = await getDNCList({ ...this.query, filters: [filters] });
				this.total_records = row_count;
				this.dncs = rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to load data',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less"></style>
