import { getUsersAsOptions } from './getUsersAsOptions';

/**
 * Easily returns all the NGL employes
 * TODO - add a filter to get specific roles (for when admins only should be in the drop down for example
 */
export async function getInternalUsersAsOptions(team?: string) {
	const internal_users_params = {
		mpid: '$NG',
		status: 'active',
	};

	if (team) {
		internal_users_params.team = team;
	}

	return getUsersAsOptions(internal_users_params);
}
