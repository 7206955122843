<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Customer Acquisition Campaigns</h1>
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getData(true)">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getData(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker :max-date_range="62" v-model="form.query.date_range" />
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="card padded">
			<p-data-table
				ref="custaqcampaigns"
				:loading="loading"
				:value="cust_aq_campaigns"
				@update:sortField="updateSortField"
				@update:sortOrder="updateSortOrder"
				@sort="getData(true)"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No campaigns</div>
				</template>
				<p-column header="Date" sortable field="date">
					<template #body="row">
						{{ row.data.date }}
					</template>
				</p-column>
				<p-column header="Source ID" sortable field="source_id">
					<template #body="row">
						{{ row.data.source_id }}
					</template>
				</p-column>
				<p-column header="Cost" sortable field="cost">
					<template #body="row">
						{{ currency(row.data.cost, 0) }}
					</template>
				</p-column>
				<p-column header="Count" sortable field="count">
					<template #body="row">
						{{ row.data.count }}
					</template>
				</p-column>
				<p-column header="CPA">
					<template #body="row">
						{{ currency(row.data.count > 0 ? row.data.cost / row.data.count : 0, 0) }}
					</template>
				</p-column>
				<p-column header="Vertical" sortable field="vertical_id">
					<template #body="row">
						{{ title(row.data.vertical_id) }}
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="total_rows" @page="handlePageChange" />
		</div>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, title } from '@/lib/Filters';
import queryForm from '@/components/widgets/QueryForm.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import dayjs from 'dayjs';
import { getMAutCustomerAcquisitionCosts } from '@GQL';

export default {
	name: 'CustAcqCampaigns',
	components: {
		dateRangePicker,
		queryForm,
		tableTools,
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			query: {
				date_range: [dayjs().subtract(1, 'week').toDate(), dayjs().endOf('day').toDate()],
				pagination: {
					page: 0,
					size: 50,
				},
				order: {
					field: 'date',
					desc: true,
				},
			},
			total_rows: 0,
			cust_aq_campaigns: [],
		};
	},
	methods: {
		currency,
		formatDate,
		title,
		async updateSortField(value) {
			this.query.order.field = value;
		},
		async updateSortOrder(value) {
			this.query.order.desc = value === -1;
		},
		async handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getData();
		},
		async getData(refresh = false) {
			this.loading = true;
			try {
				const { rows, row_count } = await getMAutCustomerAcquisitionCosts(
					{
						...this.query,
						date_range: this.query.date_range.map((date) => {
							return this.formatDate(date, 'YYYY-MM-DD');
						}),
					},
					refresh
				);
				this.total_rows = row_count;
				this.cust_aq_campaigns = rows;
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		await this.getData();
	},
};
</script>

<style scoped></style>
