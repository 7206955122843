<template>
	<p-data-table :value="data" :loading="loading">
		<template #loading>
			<line-loader :show="loading" />
		</template>
		<template #empty>
			<div class="dim">No Results</div>
		</template>
		<p-column
			v-for="col of columns"
			:key="col.field"
			:field="col.field"
			:header="col.header"
			sortable
			:sort-field="col.field"
		>
			<template #body="row">
				<div v-if="['profit'].includes(col.field)">
					<div :class="colorCellWithNoNegatives(row.data[col.field], minmax[col.field].min, minmax[col.field].max)">
						{{ currency(row.data[col.field], 0) }}
					</div>
				</div>
				<div v-else-if="['revenue', 'cost'].includes(col.field)">
					<div>
						{{ currency(row.data[col.field], 0) }}
					</div>
				</div>
				<div v-else-if="col.field === 'lead_count'">
					<div :class="colorCell(row.data[col.field], minmax[col.field].min, minmax[col.field].max)">
						{{ numberFormat(row.data[col.field]) }}
					</div>
				</div>
				<div v-else-if="['rpl', 'cpl'].includes(col.field)">
					<div :class="colorCell(row.data[col.field], minmax[col.field].min, minmax[col.field].max)">
						{{ currency(row.data[col.field]) }}
					</div>
				</div>
				<div v-else-if="col.field === 'ppl'">
					{{ currency(row.data[col.field]) }}
				</div>
				<div v-else-if="col.field === 'roi'">
					<div :class="colorCellWithNoNegatives(row.data[col.field], minmax[col.field].min, minmax[col.field].max)">
						{{ percentage(row.data[col.field], 0) }}
					</div>
				</div>
				<div v-else>
					{{ row.data[col.field] }}
				</div>
			</template>
		</p-column>
	</p-data-table>
</template>
21nb
<script lang="ts">
import { colorCell, currency, numberFormat, title, percentage } from '@/lib/Filters';

export default {
	name: 'CampaignAnalyzerTable',
	data() {
		return {
			base_columns: [
				{ field: 'profit', header: 'Profit' },
				{ field: 'revenue', header: 'Revenue' },
				{ field: 'cost', header: 'Cost' },
				{ field: 'lead_count', header: 'Count' },
				{ field: 'rpl', header: 'RPL' },
				{ field: 'cpl', header: 'CPL' },
				{ field: 'ppl', header: 'PPL' },
				{ field: 'roi', header: 'ROI' },
			],
		};
	},
	props: {
		data: {
			type: Array,
		},
		loading: {
			type: Boolean,
		},
		grouping: {
			type: Array,
			default() {
				return [];
			},
		},
		minmax: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	computed: {
		columns() {
			const calculated_columns = this.grouping.map((group) => {
				return { field: group, header: title(group) };
			});
			return [...calculated_columns, ...this.base_columns];
		},
	},
	methods: {
		title,
		currency,
		numberFormat,
		percentage,
		colorCell,
		colorCellWithNoNegatives(value, min, max) {
			if (value < 0) {
				return 'color-cell bg-gray-800 text-red-400';
			} else {
				return this.colorCell(value, min, max);
			}
		},
	},
};
</script>

<style scoped lang="less">
.p-datatable .p-datatable-tbody > tr > td:has(.color-cell) {
	padding: 0;
	.color-cell {
		padding: 0.75rem 1rem;
	}
}
.p-datatable .p-datatable-tbody > tr > td:has(.color-row) {
	padding: 0;
	.color-row {
		padding: 0.75rem 1rem;
	}
}
</style>
