import { $GQL } from '@GQL/index';

export async function reportChildAccountsList(
	params: {
		parent_account_id: string;
		date_range: [string, string];
		sort: {
			field: string;
			order: string;
		};
		pagination: {
			page: number;
			page_size: number;
		};
		name_search?: string;
	},
	refresh?: boolean
) {
	const query = `
        query ReportChildAccountsList($params: JSONObject!) {
            reportChildAccountsList(params: $params) {
                rows
                row_count
                metrics
            }
        }
    `;

	const results = await $GQL.request(query, {
		params,
		no_cache: refresh ? ['reportChildAccountsList'] : undefined,
	});
	return results.reportChildAccountsList;
}
