<template>
	<div class="ad-form">
		<p class="req">Required Fields</p>
		<p-message v-if="ad.approval_status === 'pending_approval' && !$route.meta.new" :closable="false"
			>This ad is currently pending approval.</p-message
		>
		<p-message v-if="ad.approval_status === 'approved' && !$route.meta.new" severity="success" :closable="false"
			>This ad has already been approved. Any changes to this ad will require additional approval.</p-message
		>
		<p-message v-if="ad.approval_status === 'rejected' && !$route.meta.new" severity="warn" :closable="false"
			>This ad has been rejected for the following reason: {{ ad.rejected_reason }}</p-message
		>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Premium Listing Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="ad.name" />
						<div v-if="v$.ad.name.$error" class="validation-error">
							{{ v$.ad.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Vertical:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="ad.vertical_id"
							:options="vertical_options"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.ad.vertical_id.$error" class="validation-error">
							{{ v$.ad.vertical_id.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="$root.sessionStore.isAdminUser" class="control-group">
			<div class="inner">
				<label class="control-label">Template ID (Admin Only):</label>
				<div class="controls">
					<div class="field">
						<div class="p-inputgroup">
							<div class="p-inputgroup-addon"><icon type="eye-lock" /></div>
							<p-dropdown
								v-model="ad.template_id"
								:options="templateOptions"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<p-fieldset legend="Ad Data">
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Agent Name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="ad.data.agent_name" />
							<div v-if="v$.ad.data.agent_name.$error" class="validation-error">
								{{ v$.ad.data.agent_name.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Pronouns:</label>
					<div class="controls">
						<div class="field">
							<p-select-button
								v-model="ad.data.pronouns"
								:options="pronoun_options"
								option-label="label"
								option-value="value"
							/>
							<div v-if="v$.ad.data.pronouns.$error" class="validation-error">
								{{ v$.ad.data.pronouns.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Company Name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="ad.data.company_name" />
							<div v-if="v$.ad.data.company_name.$error" class="validation-error">
								{{ v$.ad.data.company_name.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Phone Number:</label>
					<div class="controls">
						<div class="field">
							<p-input-mask v-model="ad.data.phone" mask="(999) 999-9999" unmask />
							<div v-if="v$.ad.data.phone.$error" class="validation-error">
								{{ v$.ad.data.phone.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Email Address:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="ad.data.email" />
							<div v-if="v$.ad.data.email.$error" class="validation-error">
								{{ v$.ad.data.email.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="control-group">
				<div class="inner">
					<label class="control-label">Bio:</label>
					<div class="controls">
						<div class="field">
							<p-textarea v-model="ad.data.bio" />
						</div>
					</div>
				</div>
			</div> -->
			<row>
				<column>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Photo:</label>
							<div class="controls">
								<div class="field">
									<image-upload
										accept="image/*"
										v-model="ad.data.photo_url"
										:filename="`ad-photo-${ad.id}`"
										folder="ads"
									/>
								</div>
							</div>
						</div>
					</div>
				</column>
				<gutter size="40px" />
				<column>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Logo:</label>
							<div class="controls">
								<div class="field">
									<image-upload
										accept="image/*"
										v-model="ad.data.logo_url"
										:filename="`ad-logo-${ad.id}`"
										folder="ads"
									/>
								</div>
							</div>
						</div>
					</div>
				</column>
			</row>
		</p-fieldset>
		<gutter size="1em" />
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown v-model="ad.status" :options="status_options" option-label="label" option-value="value" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="$root.sessionStore.isAdminUser" class="control-group">
			<div class="inner">
				<label class="control-label">Approval Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="ad.approval_status"
							:options="approval_status_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="preview">
			<p-button label="Preview Premium Listing" @click="showPreview()">
				<template #icon>
					<div class="icon-wrapper button-icon">
						<icon type="eye" size="24px" />
					</div>
				</template>
			</p-button>
		</div>
		<div class="form-actions">
			<p-button label="Cancel" text @click="this.$router.go(-1)" />
			<p-button label="Save Premium Listing" @click="save()">
				<template #icon>
					<div class="icon-wrapper button-icon">
						<icon type="check" size="24px" />
					</div>
				</template>
			</p-button>
		</div>
		<p-dialog modal header="Premium Listing Preview" :style="{ width: '90vw' }" v-model:visible="show_preview_modal">
			<div class="text-align-center">
				<p-select-button
					v-model="preview_width"
					:options="responsive_options"
					option-label="label"
					option-value="value"
				/>
			</div>
			<gutter size="20px" />
			<div class="responsive-wrapper">
				<div :class="['ad-preview', preview_width]" v-html="parsed_ad_content"></div>
			</div>
		</p-dialog>
	</div>
</template>

<script>
import { cloneDeep, defaultsDeep, omit, sortBy } from 'lodash-es';
import {
	getAd,
	getAdTemplates,
	insertAd,
	previewAdContent,
	updateAd,
	insertNotification,
	sendTemplatedEmail,
} from '@GQL';
import { email, helpers, required } from '@vuelidate/validators';
import { ulid } from 'ulid';
import { useVuelidate } from '@vuelidate/core';
import { vertical_options } from '@/lib/Options';
import imageUpload from '@/components/forms/ImageUpload.vue';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';

const default_ad = {
	id: ulid(),
	account_id: null,
	name: '',
	vertical_id: 'health_insurance',
	template_id: null,
	type: 'premium_listing',
	data: {
		agent_name: '',
		pronouns: ['they', 'them', 'their'],
		company_name: '',
		phone: '',
		email: '',
		bio: '',
		photo_url: null,
		logo_url: null,
	},
	approval_status: 'pending_approval',
	approved_by: null,
	rejected_reason: null,
	status: 'active',
};

export default {
	name: 'AdForm',
	components: {
		imageUpload,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			ad: cloneDeep(default_ad),
			ad_templates: [],
			approval_status_options: [
				{ label: 'Pending Approval', value: 'pending_approval' },
				{ label: 'Approved', value: 'approved' },
				{ label: 'Rejected', value: 'rejected' },
			],
			parsed_ad_content: null,
			preview_width: 'desktop',
			pronoun_options: [
				{ label: 'he/him/his', value: ['he', 'him', 'his'] },
				{ label: 'she/her/her', value: ['she', 'her', 'her'] },
				{ label: 'they/them/their', value: ['they', 'them', 'their'] },
			],
			responsive_options: [
				{ label: 'Desktop', value: 'desktop' },
				{ label: 'Tablet', value: 'tablet' },
				{ label: 'Mobile', value: 'mobile' },
			],
			show_preview_modal: false,
			status_options: [
				{ label: 'Active', value: 'active' },
				{ label: 'Disabled', value: 'disabled' },
				{ label: 'Archived', value: 'archived' },
			],
			vertical_options,
		};
	},
	computed: {
		accountId() {
			if (this.$route.params.account_id) {
				return this.$route.params.account_id;
			}
			return this.$root.sessionStore.account.id;
		},
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		templateOptions() {
			let options = [];
			this.ad_templates.forEach((ad_template) => {
				if (ad_template.vertical_id === this.ad.vertical_id) {
					options.push({
						label: ad_template.name,
						value: ad_template.id,
					});
				}
			});

			options = sortBy(options, 'label');
			options.unshift({ label: 'Use Best Performing', value: 'BEST_PERFORMING' });
			return options;
		},
		adVertical() {
			return this.ad.vertical_id;
		},
		...mapStores(useMarketplaceStore),
	},
	watch: {
		adVertical(new_value) {
			if (!this.ad.name) {
				this.ad.name = vertical_options.find((option) => option.value === new_value).label + ` Premium Listing`;
			}
		},
	},
	validations() {
		return {
			ad: {
				name: {
					required: helpers.withMessage('A name for this ad is required', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical selection is required', required),
				},
				data: {
					agent_name: {
						required: helpers.withMessage('An agent name is required', required),
					},
					pronouns: {
						required: helpers.withMessage('A pronoun selection is required', required),
					},
					company_name: {
						required: helpers.withMessage('A company name is required', required),
					},
					phone: {
						required: helpers.withMessage('A valid phone number is required', required),
					},
					email: {
						email: helpers.withMessage('The email address is invalid', email),
					},
				},
			},
		};
	},
	methods: {
		async getAd() {
			if (this.$route.meta.new && this.$route.query.id) {
				try {
					const ad = await getAd(this.$route.query.id);
					delete ad.id;
					ad.name += ' (Copy)';
					ad.approval_status = 'pending_approval';
					ad.approved_by = null;
					this.ad = defaultsDeep(cloneDeep(ad), cloneDeep(default_ad));
					// Generate a new ID for duplicates
					this.ad.id = ulid();
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to copy the premium listing',
						detail: err.message,
						life: 3000,
					});
				}
			}

			if (this.$route.params.ad_id) {
				try {
					const ad = await getAd(this.$route.params.ad_id);
					this.ad = defaultsDeep(cloneDeep(ad), cloneDeep(default_ad));
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to load the premium listing',
						detail: err.message,
						life: 3000,
					});
				}
			}

			if (this.ad.template_id === null) {
				this.ad.template_id = 'BEST_PERFORMING';
			}
		},
		async showPreview() {
			try {
				if (this.ad.template_id === 'BEST_PERFORMING') {
					this.parsed_ad_content = await previewAdContent(null, this.ad.data, this.ad.vertical_id, this.mpid);
				} else {
					this.parsed_ad_content = await previewAdContent(this.ad.template_id, this.ad.data);
				}
				this.show_preview_modal = true;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to preview the premium listing',
					detail: err.response.errors[0].message,
					life: 6000,
				});
			}
		},
		async save() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) {
				setTimeout(() => {
					document.querySelector('.validation-error').closest('.control-group').scrollIntoView({ behavior: 'smooth' });
				}, 100);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save the premium listing',
					detail: 'Invalid field',
					life: 5000,
				});
			} else {
				try {
					const ad_to_save = cloneDeep(
						omit(this.ad, ['template', 'approver', 'assigned_campaigns', 'created_at', 'modified_at'])
					);
					if (ad_to_save.template_id === 'BEST_PERFORMING') {
						ad_to_save.template_id = null;
					}

					let save_result;
					if (!this.$route.meta.new) {
						save_result = await updateAd(this.ad.id, ad_to_save);
					} else {
						// Reset the approval status for any changes made by a non-admin
						if (!this.$root.sessionStore.isAdminUser) {
							ad_to_save.approval_status = 'pending_approval';
						} else {
							if (ad_to_save.approval_status !== 'pending_approval') {
								ad_to_save.approved_by = this.$root.sessionStore.user.id;
							}
						}

						// Make sure the account_id is set
						ad_to_save.account_id = this.accountId;
						save_result = await insertAd(ad_to_save);
					}

					// If this is an account user notify AM of changes
					if (this.$root.sessionStore.isAccountUser) {
						const notification = await insertNotification({
							type: ['drawer', 'push'],
							tags: ['ad'],
							message: `<p>Premium Listing Needs Approval: <a href="https://admin.nextgenleads.app/marketplaces/manage/${this.mpid}/accounts/manage/${this.accountId}/ads/${save_result.id}">${ad_to_save.name}</a>.</p>`,
							filters: {
								target_type: 'user',
								value: this.$root.sessionStore.account.support.account_manager.id,
							},
							expires: null,
							status: 'active',
							created_by: this.$root.sessionStore.user.id,
						});

						const email = await sendTemplatedEmail({
							recipients: {
								name: `${this.$root.sessionStore.account.support.account_manager.first_name} ${this.$root.sessionStore.account.support.account_manager.last_name}`,
								email: this.$root.sessionStore.account.support.account_manager.email,
							},
							template: {
								id: '01HZFV5XEA54AFMHC0CETF77FK',
								data: {
									modification_type: this.$route.meta.new ? 'created' : 'updated',
									base_url: 'https://admin.nextgenleads.app',
									mpid: this.mpid,
									account_id: this.accountId,
									premium_listing_id: save_result.id,
								},
							},
						});
					}

					this.$toast.add({
						severity: 'success',
						summary: 'The premium listing was saved successfully',
						life: 3000,
					});
					this.$router.go(-1);
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save the premium listing',
						detail: err.message,
						life: 6000,
					});
				}
			}
		},
	},
	async mounted() {
		await this.getAd();
		try {
			this.ad_templates = await getAdTemplates([[`type = 'premium_listing' AND status = 'active'`]], ['adTemplates']);
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to load ad templates',
				detail: err.message,
				life: 3000,
			});
		}
	},
};
</script>

<style lang="less">
.preview {
	background-color: var(--gray-05);
	border: 2px solid var(--gray-10);
	border-radius: 5px;
	padding: 20px;
	text-align: center;
}

.responsive-wrapper {
	background-color: var(--gray-10);
	padding: 20px;
	width: 100%;

	.ad-preview {
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
		padding: 20px;
		margin: 0 auto;
	}

	.desktop {
		width: 100%;
	}

	.tablet {
		width: 768px;
	}

	.mobile {
		width: 480px;
	}
}

.form-actions {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
</style>
