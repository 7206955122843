<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>{{ isCreate ? 'Create' : 'Edit' }} Notification</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card class="notification-form">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<NotificationFilterComponent v-model="notification.filters" />
								<div v-if="v$.notification.filters.$error" class="validation-error">
									<!--									{{ v$.notification.filters.$errors[0].$message }}-->
									{{
										v$.notification.filters.$errors
											.map((it) => {
												return it.$message;
											})
											.join(', ')
									}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="message">Message:</label>
						<div class="controls">
							<div class="field">
								<wysiwyg v-model="notification.message" class="wysiwyg-textbox" />
								<div v-if="v$.notification.message.$error" class="validation-error">
									{{ v$.notification.message.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<!-- <div class="field m">
									<label class="control-label req" for="type">Type:</label>
									<div class="faded"></div>
									<p-chips add-on-blur id="type" v-model="notification.type" separator="," />
									<div class="sub-value">Where is this notification displayed: drawer, push, etc.</div>
									<div v-if="v$.notification.type.$error" class="validation-error">
										{{ v$.notification.type.$errors[0].$message }}
									</div>
								</div> -->
								<div class="field">
									<label class="control-label req" for="tags"> Tags:</label>
									<p-chips add-on-blur id="tags" v-model="notification.tags" separator="," />
									<div class="sub-value">Categorize the type of notification</div>
									<div v-if="v$.notification.tags.$error" class="validation-error">
										{{ v$.notification.tags.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<div class="label-checkbox-container">
									<label class="control-label" for="push-notification">Send Push Notification</label>
									<p-checkbox v-model="notification.type" input-id="push-notification-option" value="push" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="expires">Notification Expiration:</label>
						<div class="controls gap-20">
							<div class="field">
								<p-dropdown
									v-model="expires_type"
									:options="expires_type_options"
									option-label="label"
									option-value="value"
									placeholder="No expiration"
								/>
							</div>
							<div class="field">
								<template v-if="expires_type === 'date'">
									<p-calendar v-model="expires_date_value" date-format="D, M d, yy" :manual-input="false" />
								</template>
								<template v-if="expires_type === 'offset'">
									<div class="p-inputgroup">
										<p-input-number v-model="expires_offset_value" class="input-align-right" />
										<div class="p-inputgroup-addon">hr(s)</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="status">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="notification.status"
									:options="status_options"
									placeholder="Select Status"
									option-label="label"
									option-value="value"
								/>
								<div v-if="v$.notification.status.$error" class="validation-error">
									{{ v$.notification.status.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-between mt-4">
					<p-button severity="secondary" text label="Cancel" @click="$router.back()" />
					<p-button v-if="isCreate" label="Send Notification" @click="submit" />
					<p-button v-else label="Update Notification" @click="submit" />
				</div>
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import pChips from 'primevue/chips';
import pDialog from 'primevue/dialog';
import pTextArea from 'primevue/textarea';
import pCalendar from 'primevue/calendar';
import { requiredIf, required, helpers } from '@vuelidate/validators';
import NotificationFilterComponent from './NotificationFilterComponent.vue';
import { useVuelidate } from '@vuelidate/core';
import { useSessionStore } from '@/stores/session';
import { insertNotification, updateNotification, getNotification } from '@GQL';
import { cloneDeep } from 'lodash-es';
import log from '@/lib/Log';

const status_options = [
	{ label: 'Active', value: 'active' },
	{ label: 'Archived', value: 'archived' },
];

export default {
	name: 'CreateNotification',
	components: {
		pDialog,
		pChips,
		pTextArea,
		pCalendar,
		NotificationFilterComponent,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	async mounted() {
		this.breadcrumb_items.push({ label: this.isCreate ? 'New Notification' : 'Edit Notification' });
		if (this.$route.params.notification_id) {
			const notification = await getNotification(this.$route.params.notification_id);
			this.notification = cloneDeep(notification);
		}
	},
	data() {
		return {
			status_options,
			breadcrumb_items: [
				{
					label: 'Notifications',
					route: '/notifications',
				},
			],
			expires_type_options: [
				{ label: 'No expiration', value: null },
				{ label: 'Expire on a specific date', value: 'date' },
				{ label: 'Expire after a specific number of hours', value: 'offset' },
			],
			expires_type: null,
			expires_date_value: new Date(),
			expires_offset_value: 24,
			expires_obj: {},
			expires_modal: false,
			notification: {
				message: '',
				filters: {
					target_type: '',
					value: null,
					mpid: null,
				},
				type: ['drawer'],
				tags: ['new'],
				status: 'active',
				created_by: this.sessionStore.user.id,
			},
			expires: `expires: z.object({
				type: z.enum(['date', 'offset']),
				value: z.date().or(z.string())
			}).nullish(),`,
		};
	},
	computed: {
		isCreate() {
			return this.$route.meta.new === true;
		},
	},
	methods: {
		async submit() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				if (this.expires_type && this.expires_type === 'date') {
					this.expires_obj = { type: 'date', value: this.expires_date_value };
				} else if (this.expires_type && this.expires_type === 'offset') {
					this.expires_obj = { type: 'offset', value: this.expires_offset_value };
				}
				try {
					const params =
						Object.keys(this.expires_obj).length > 0
							? { expires: this.expires_obj, ...cloneDeep(this.notification) }
							: { ...cloneDeep(this.notification) };
					if (this.$route.meta.new) {
						await insertNotification(params);
					} else {
						await updateNotification(this.$route.params.notification_id, params);
					}

					this.$toast.add({
						severity: 'success',
						summary: this.$route.meta.new ? 'Notification created' : 'Notification updated',
						life: 6000,
					});
					this.$router.back();
				} catch (err) {
					log.trace('Error submitting notification', err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to submit notification',
						detail: err.message,
						life: 6000,
					});
				}
			}
		},
	},
	validations() {
		return {
			notification: {
				message: {
					required: helpers.withMessage('Enter the notification message', required),
				},
				filters: {
					target_type: {
						required: helpers.withMessage('Target type is required', required),
					},
					value: {
						requiredif: helpers.withMessage(
							'A value is required',
							requiredIf(['internal_users', 'external_users'].indexOf(this.notification.filters.target_type) === -1)
						),
					},
					mpid: {
						requiredif: helpers.withMessage(
							'A marketplace is required',
							requiredIf(this.notification.filters.target_type === 'users_by_marketplace_role')
						),
					},
				},
				type: {
					required: helpers.withMessage('Enter the notification type', required),
				},
				tags: {
					required: helpers.withMessage('Enter tags for this notification', required),
				},
				status: {
					required: helpers.withMessage('Select a status for this notification', required),
				},
			},
		};
	},
};
</script>

<style lang="less" scoped>
.notification-form {
	max-width: 800px;
}

::v-deep(.wysiwyg-textbox .tiptap) {
	height: 200px !important;
}

.radio-options {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 5px 10px;
}

.radio-label {
	cursor: pointer;
	font-weight: normal;
}

.radio-option {
	margin-right: 20px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-items: start;
}

.label-checkbox-container {
	display: flex;
	align-items: center;
}

.label-checkbox-container .control-label {
	margin-right: 10px;
}
</style>
