<template>
	<div v-once v-tooltip.top="tooltip" :class="['vertical-icon', vertical]" :style="style" />
</template>

<script lang="ts">
import { startCase } from 'lodash-es';

export default {
	props: {
		verticalId: {
			type: String,
		},
		size: {
			type: String,
			default: '40px',
		},
		hideTooltip: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		style() {
			return {
				height: this.size,
				width: this.size,
			};
		},
		vertical() {
			switch (this.verticalId) {
				case 'auto_insurance':
					return 'auto-insurance';
				case 'health_insurance':
					return 'health-insurance';
				case 'medicare':
					return 'medicare';
				default:
					return 'unknown';
			}
		},
		tooltip() {
			if (!this.hideTooltip) {
				return startCase(this.vertical);
			}
			return null;
		},
	},
	methods: {
		startCase,
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.vertical-icon {
	background-size: contain;
	border-radius: 50%;
	display: inline-block;
	overflow: hidden;

	&.auto-insurance {
		background-image: ~'url(/assets/img/icons/icon-auto-insurance.svg)';
	}

	&.health-insurance {
		background-image: ~'url(/assets/img/icons/icon-health-insurance.svg)';
	}

	&.medicare {
		background-image: ~'url(/assets/img/icons/icon-medicare.svg)';
	}

	&.unknown {
		background-image: ~'url(/assets/img/icons/icon-unknown.svg)';
	}
}
</style>
