<template>
	<div id="parent-account-overview-report" class="view-content">
		<div class="page-heading">
			<h1>Parent Account Overview</h1>
			<breadcrumb />
		</div>
		<div class="flex align-items-center justify-content-between">
			<div class="flex align-items-center">
				<div class="quick-filters vertical flex align-items-center">
					<div class="label">Vertical:</div>
					<gutter size="10px" />
					<template v-for="vertical in $root.appStore.verticalOptions" :key="vertical.value">
						<quick-filter v-model="filters.vertical_id" :value="vertical.value" allow-multiple @toggle="getReportData">
							{{ vertical.label }}
						</quick-filter>
					</template>
				</div>
				<gutter size="40px" />
				<div class="quick-filters product flex align-items-center">
					<div class="label">Product:</div>
					<gutter size="10px" />
					<quick-filter v-model="filters.product" value="data" @toggle="getReportData()" allow-multiple
						>Data Leads</quick-filter
					>
					<quick-filter v-model="filters.product" value="call" @toggle="getReportData()" allow-multiple
						>Calls</quick-filter
					>
					<quick-filter v-model="filters.product" value="live_transfer" @toggle="getReportData()" allow-multiple
						>Live Transfers</quick-filter
					>
					<quick-filter v-model="filters.product" value="ad" @toggle="getReportData()" allow-multiple>Ads</quick-filter>
				</div>
			</div>
			<div class="flex align-items-center">
				<strong>Compare:</strong>
				<quick-filter v-model="compare" value="dod">Day</quick-filter>
				<quick-filter v-model="compare" value="wow">Week</quick-filter>
				<quick-filter v-model="compare" value="mom">Month</quick-filter>
			</div>
		</div>
		<gutter size="10px" />
		<div class="card padded">
			<h3>Buyer Velocity</h3>
			<p-data-table :value="buyers">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No purchases for this time period</div>
				</template>
				<p-column header="Account">
					<template #body="row">
						<router-link
							v-if="$route.params.mpid"
							:to="{
								path: `../../../accounts/manage/${row.data.account_id}/reports/account-activity`,
							}"
						>
							{{ row.data.account_name }}
						</router-link>
						<router-link
							v-else
							:to="{
								path: `/accounts/manage/${row.data.account_id}/reports/account-overview`,
							}"
						>
							{{ row.data.account_name }}
						</router-link>
						<div class="sub-value">{{ row.data.account_id }}</div>
					</template>
				</p-column>
				<p-column header="Current Balance" :field="sortField('balance')" sortable class="column-align-right">
					<template #body="row">
						{{ currency(row.data.balance) }}
					</template>
				</p-column>
				<p-column header="Purchases" :field="sortField('purchases')" sortable class="column-align-right">
					<template #body="row">
						{{ currentPeriodData(row.data, 'purchases') || 0 }}
						<div
							:class="[
								'sub-value',
								trendClass(
									currentPeriodData(row.data, 'purchases') || 0,
									comparePeriodData(row.data, 'purchases') || 0
								),
							]"
						>
							<icon type="calendar-arrow-left" style="margin-top: -4px" />
							{{ comparePeriodData(row.data, 'purchases') || 0 }}
						</div>
					</template>
				</p-column>
				<p-column header="Spend" :field="sortField('spend')" sortable class="column-align-right">
					<template #body="row">
						{{ currency(currentPeriodData(row.data, 'spend') || 0) }}
						<div
							:class="[
								'sub-value',
								trendClass(currentPeriodData(row.data, 'spend') || 0, comparePeriodData(row.data, 'spend') || 0),
							]"
						>
							<icon type="calendar-arrow-left" style="margin-top: -4px" />
							{{ currency(comparePeriodData(row.data, 'spend') || 0) }}
						</div>
					</template>
				</p-column>
				<p-column header="CPL" :field="sortField('cpl')" sortable class="column-align-right">
					<template #body="row">
						{{ currency(currentPeriodData(row.data, 'cpl') || 0) }}
						<div
							:class="[
								'sub-value',
								trendClass(currentPeriodData(row.data, 'cpl') || 0, comparePeriodData(row.data, 'cpl') || 0),
							]"
						>
							<icon type="calendar-arrow-left" style="margin-top: -4px" />
							{{ currency(comparePeriodData(row.data, 'cpl') || 0) }}
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script>
import trendClass from '@/lib/Utils/trendClass';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import lineLoader from '@/components/elements/LineLoader.vue';
import { currency } from '@/lib/Filters';
import { reportParentAccountOverview } from '@GQL';

export default {
	name: 'ParentAccountOverview',
	components: {
		quickFilter,
		lineLoader,
	},
	data() {
		return {
			loading: false,
			filters: {
				vertical_id: [],
				product: [],
			},
			compare: 'dod',
			buyers: [],
		};
	},
	computed: {},
	methods: {
		currency,
		trendClass,
		async getReportData(refresh = false) {
			this.loading = true;
			this.buyers = await reportParentAccountOverview(
				{
					parent_account_id: this.$route.params.parent_account_id || this.$root.sessionStore.account.id,
					vertical_id: this.filters.vertical_id,
					product: this.filters.product,
				},
				refresh
			);
			this.loading = false;
		},
		currentPeriodData(row, value) {
			if (this.compare === 'dod') {
				return +row['today_' + value];
			}
			if (this.compare === 'wow') {
				return +row['this_week_' + value];
			}
			if (this.compare === 'mom') {
				return +row['this_month_' + value];
			}
		},
		comparePeriodData(row, value) {
			if (this.compare === 'dod') {
				return +row['yesterday_' + value];
			}
			if (this.compare === 'wow') {
				return +row['last_week_' + value];
			}
			if (this.compare === 'mom') {
				return +row['last_month_' + value];
			}
		},
		sortField(value) {
			if (value === 'balance') {
				return value;
			}
			if (this.compare === 'dod') {
				return 'today_' + value;
			}
			if (this.compare === 'wow') {
				return 'this_week_' + value;
			}
			if (this.compare === 'mom') {
				return 'this_month_' + value;
			}
		},
	},
	async mounted() {
		await this.getReportData();
	},
};
</script>

<style scoped lang="less">
h3 {
	font-size: 1.125rem;
	font-weight: bold;
}
</style>
