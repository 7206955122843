<template>
	<p-card class="auto-optimizer-form">
		<template #content>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Source Code:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="source_code" placeholder="Source Code" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Traffic Source:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="traffic_source" placeholder="Traffic Source" />
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<div class="w-full flex justify-content-between align-items-center">
				<p-button
					severity="secondary"
					label="Cancel"
					text
					@click.prevent="$router.push({ path: '/maut/automatic-optimization' })"
				/>
				<p-button label="Save" />
			</div>
		</template>
	</p-card>
</template>

<script lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
	name: 'AutoOptimizerForm',
	inject: ['mq'],
	async setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			source_code: '',
			traffic_source: '',
		};
	},
	async mounted() {},
	methods: {
		async submit() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				const params = {
					source_code: this.source_code,
					traffic_source: this.traffic_source,
				};
			}
		},
	},
	validations() {
		return {
			source_code: {
				required: helpers.withMessage('Source Code required.', required),
			},
			traffic_source: {
				required: helpers.withMessage('Traffic Source required.', required),
			},
		};
	},
};
</script>

<style lang="less">
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
