import { $GQL } from '@GQL';
import { type SignUpPage } from '@nextgenleads/marketplace-driver';
import type { QueryFilters } from '@nextgenleads/db';

export async function getSignUpOffer(filters: QueryFilters) {
	const request = `
        query GetSignUpOffer($filters: [[Any]]) {
			signUpPages(filters: $filters) {
				id
				mpid
				channel
				source
				tags
				parent_account_id
				content {
					company_name
					phone
					body
					logo_url
				}
				settings {
					round_robin_ams
				}
				offer_id
				offer {
				    credit_type
				    credit_amount
				    expiration
				    trigger
				    required_funding
				    name
				    status
				}
				status
			}
        }
    `;

	const result = await $GQL.request(request, { filters, no_cache: ['signUpPages'] });
	return result.signUpPages.filter((row) => {
		return row.status === 'active';
	});
}
