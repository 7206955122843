import { $GQL } from '@GQL/index';

export async function redeemAccountOfferCode(code: string, account_id: string) {
	const query = `
        mutation RedeemAccountOfferCode($code: String!, $account_id: String) {
            redeemAccountOfferCode(code: $code, account_id: $account_id) {
              status
              error
            }
        }
  `;

	const result = await $GQL.request(query, { code, account_id });
	return result.redeemAccountOfferCode;
}
