import { $GQL } from '@GQL';

interface search_result {
	id: string;
	type: string;
	data: any;
}

export async function searchAccounts(value: string, page: number) {
	const query = `
        query SearchAccounts($value: String!, $page: Int) {
            searchAccounts(value: $value, page: $page) {
                account_id
				account_name
				phone
				email
				primary_user
				account_owner
				created_at
				mpid
				is_parent
				parent_account_id
				status
            }
        }
    `;

	const result = await $GQL.request(query, { value, page });
	return result.searchAccounts;
}
