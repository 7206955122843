import CampaignsAndRevenueWrapper from '@/views/MAUT/CampaignsAndRevenue/Page.vue';
import LeadCampaigns from '@/views/MAUT/CampaignsAndRevenue/Tabs/LeadCampaigns.vue';
import CustomerAcquisitionCampaigns from '@/views/MAUT/CampaignsAndRevenue/Tabs/CustomerAcquisitionCampaigns.vue';
import Revenue from '@/views/MAUT/CampaignsAndRevenue/Tabs/Revenue.vue';
import VerticalPerformance from '@/views/MAUT/VerticalPerformance/Page.vue';
import BizUnitPerformance from '@/views/MAUT/VerticalPerformance/Tabs/BizUnit.vue';
import OOPerformance from '@/views/MAUT/VerticalPerformance/Tabs/OO.vue';
import CampaignTracker from '@/views/MAUT/CampaignTracker/Page.vue';
import Analyzer from '@/views/MAUT/CampaignTracker/Tabs/Analyzer.vue';
import KeyCampaigns from '@/views/MAUT/CampaignTracker/Tabs/KeyCampaigns.vue';
import CreateAutomaticOptimization from '@/views/MAUT/AutomaticOptimization/Page.vue';
import AutomaticOptimization from '@/views/MAUT/AutomaticOptimization/List.vue';
import RevenueAnalyzer from '@/views/MAUT/RevenueAnalyzer/Page.vue';
import CustAcqAnalyzer from '@/views/MAUT/CustomerAcquisitionAnalyzer/Page.vue';
import CallCenterBidCalculator from '@/views/MAUT/CallCenterBidCalculator/Page.vue';
import StateReport from '@/views/MAUT/StateReport/Page.vue';
import mapNestedRoutes from '@/lib/Utils/mapNestedRoutes';

export default [
	{
		path: '/maut/campaigns-and-revenue',
		name: 'CampaignsAndRevenue',
		component: CampaignsAndRevenueWrapper,
		children: mapNestedRoutes(
			[
				{
					path: 'lead-campaigns',
					name: 'LeadCampaigns',
					component: LeadCampaigns,
					meta: {
						title: 'Lead Campaigns',
						permissions: 'maut',
					},
				},
				{
					path: 'customer-acquisition-campaigns',
					name: 'CustomerAcquisitionCampaigns',
					component: CustomerAcquisitionCampaigns,
					meta: {
						title: 'Customer Acquisition Campaigns',
						permissions: 'maut',
					},
				},
				{
					path: 'revenue',
					name: 'Revenue',
					component: Revenue,
					meta: {
						title: 'Revenue',
						permissions: 'maut',
					},
				},
			],
			'CampaignsAndRevenue:',
			{}
		),
	},
	{
		path: '/maut/vertical-performance',
		name: 'VerticalPerformance',
		component: VerticalPerformance,
		children: mapNestedRoutes(
			[
				{
					path: 'OO/health',
					name: 'OOHealth',
					component: OOPerformance,
					meta: {
						title: 'Health Performance - Owned & Operated',
						permissions: 'maut',
						type: 'oo',
						vertical: 'health',
					},
				},
				{
					path: 'OO/medicare',
					name: 'Medicare',
					component: OOPerformance,
					meta: {
						title: 'Medicare Performance - Owned & Operated',
						permissions: 'maut',
						type: 'oo',
						vertical: 'medicare',
					},
				},
				{
					path: 'OO/auto',
					name: 'Auto',
					component: OOPerformance,
					meta: {
						title: 'Auto Performance - Owned & Operated',
						permissions: 'maut',
						type: 'oo',
						vertical: 'auto',
					},
				},
				{
					path: 'biz-units/health',
					name: 'BUHealth',
					component: BizUnitPerformance,
					meta: {
						title: 'Health Performance - Biz Units',
						permissions: 'maut',
						type: 'biz_units',
						vertical: 'health',
					},
				},
				{
					path: 'biz-units/medicare',
					name: 'BUMedicare',
					component: BizUnitPerformance,
					meta: {
						title: 'Medicare Performance - Biz Units',
						permissions: 'maut',
						type: 'biz_units',
						vertical: 'medicare',
					},
				},
				{
					path: 'biz-units/auto',
					name: 'BUAuto',
					component: BizUnitPerformance,
					meta: {
						title: 'Auto Performance - Biz Units',
						permissions: 'maut',
						type: 'biz_units',
						vertical: 'auto',
					},
				},
			],
			'VerticalPerformance:',
			{}
		),
	},
	{
		path: '/maut/campaign-tracker',
		name: 'CampaignTracker',
		component: CampaignTracker,
		children: mapNestedRoutes(
			[
				{
					path: 'analyzer',
					name: 'Analyzer',
					component: Analyzer,
					meta: {
						title: 'Analyzer',
						permissions: 'maut',
					},
				},
				{
					path: 'key-campaigns',
					name: 'KeyCampaigns',
					component: KeyCampaigns,
					meta: {
						title: 'Key Campaigns',
						permissions: 'maut',
					},
				},
			],
			'CampaignTracker:',
			{}
		),
	},
	{
		name: 'AutomaticOptimization',
		path: '/maut/automatic-optimization',
		component: AutomaticOptimization,
		meta: {
			title: 'Automatic Optimization',
			permissions: 'maut',
		},
	},
	{
		path: '/maut/automatic-optimization/new',
		name: 'CreateAutomaticOptimization',
		component: CreateAutomaticOptimization,
		meta: {
			title: 'Create Automatic Optimization',
			permissions: 'maut',
		},
	},
	{
		name: 'RevenueAnalyzer',
		path: '/maut/revenue-analyzer',
		component: RevenueAnalyzer,
		meta: {
			title: 'Revenue Analyzer',
			permissions: 'maut',
		},
	},
	{
		name: 'CustomerAcquisitionAnalyzer',
		path: '/maut/customer-acquisition-analyzer',
		component: CustAcqAnalyzer,
		meta: {
			title: 'Customer Acquisition Analyzer',
			permissions: 'maut',
		},
	},
	{
		name: 'CallCenterBidCalculator',
		path: '/maut/call-center-bid-calculator',
		component: CallCenterBidCalculator,
		meta: {
			title: 'Call Center Bid Calculator',
			permissions: 'maut',
		},
	},
	{
		name: 'StateReport',
		path: '/maut/state-report',
		component: StateReport,
		meta: {
			title: 'Velocity Metrics by State',
			permissions: 'maut',
		},
	},
];
