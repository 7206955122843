<template>
	<p-data-table :value="metrics">
		<p-column field="stat" header="Stat">
			<template #body="row">
				<strong>
					{{ title(row.data.stat) }}
				</strong>
			</template>
		</p-column>
		<p-column field="profit" header="Profit">
			<template #body="row">
				{{ currency(row.data.profit, 0) }}
			</template>
		</p-column>
		<p-column field="revenue" header="Revenue">
			<template #body="row">
				{{ currency(row.data.revenue, 0) }}
			</template>
		</p-column>
		<p-column field="cost" header="Cost">
			<template #body="row">
				{{ currency(row.data.cost, 0) }}
			</template>
		</p-column>
		<p-column field="count" header="Count">
			<template #body="row">
				{{ numberFormat(row.data.lead_count) }}
			</template>
		</p-column>
		<p-column field="rpl" header="RPL">
			<template #body="row">
				{{ currency(row.data.rpl) }}
			</template>
		</p-column>
		<p-column field="cpl" header="CPL">
			<template #body="row">
				{{ currency(row.data.cpl) }}
			</template>
		</p-column>
		<p-column field="ppl" header="PPL">
			<template #body="row">
				{{ currency(row.data.ppl) }}
			</template>
		</p-column>
		<p-column field="roi" header="ROI">
			<template #body="row">
				{{ percentage(row.data.roi, 0) }}
			</template>
		</p-column>
	</p-data-table>
</template>

<script lang="ts">
import { currency, numberFormat, percentage, title } from '@/lib/Filters';
export default {
	name: 'CampaignAnalyzerSummary',
	data() {
		return {};
	},
	props: {
		metrics: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		currency,
		numberFormat,
		percentage,
		title,
	},
};
</script>

<style scoped lang="less"></style>
