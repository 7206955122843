<template>
	<div id="call-center-bid-calculator-wrapper" class="view-content">
		<div class="page-heading">
			<h1>Call Center Bid Calculator</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<query-form v-model:query="query" :loading="loading">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.range, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Include Low Quality Campaigns:</div>
					<div class="value">
						<div class="value">{{ query.show_low_quality ? 'Yes' : 'No' }}</div>
					</div>
				</div>
			</template>
			<template #utils>
				<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" />
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker :max-range="62" v-model="form.query.range" />
									</div>
								</div>
							</div>
						</div>
					</column>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">&nbsp;</label>
								<div class="controls">
									<div class="field fit">
										<p-checkbox input-id="show_low_quality" v-model="query.show_low_quality" binary />
									</div>
									<div class="field"><label for="show_low_quality">Include Low Quality</label></div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<gutter size="20px" />
		<h2>Campaign Bids</h2>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table :value="campaign_bids" scrollable>
				<p-column-group type="header">
					<p-row>
						<p-column header="Campaign Name" field="campaign_name" sortable />
						<p-column header="Leads" field="leads" sortable />
						<p-column header="Live Transfers" field="live_transfers" sortable />
						<p-column header="Calls" field="calls" sortable />
						<p-column header="Revenue" field="revenue" sortable />
						<p-column header="LT Conversion" field="lt_conversion" sortable />
						<p-column header="Call Conversion" field="call_conversion" sortable />
						<p-column header="RPL" field="rpl" sortable />
					</p-row>
					<p-row>
						<p-column :colspan="1" header="Totals" class="bg-gray-100 border-bottom-1 border-gray-200 pb-2" />
						<p-column :header="campaign_bid_totals.leads" class="bg-gray-100 border-bottom-1 border-gray-200 pb-2" />
						<p-column
							:header="campaign_bid_totals.live_transfers"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column :header="campaign_bid_totals.calls" class="bg-gray-100 border-bottom-1 border-gray-200 pb-2" />
						<p-column
							:header="currency(campaign_bid_totals.revenue, 0)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column
							:header="percentage(campaign_bid_totals.lt_conversion, 2)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column
							:header="percentage(campaign_bid_totals.call_conversion, 2)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column
							:header="currency(campaign_bid_totals.rpl, 2)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
					</p-row>
				</p-column-group>
				<p-column field="campaign_name" header="Campaign Name" />
				<p-column field="leads" header="Leads" />
				<p-column field="live_transfers" header="Live Transfers" />
				<p-column field="calls" header="Calls" />
				<p-column field="revenue" header="Revenue">
					<template #body="row">
						{{ currency(row.data.revenue, 0) }}
					</template>
				</p-column>
				<p-column field="lt_conversion" header="LT Conversion">
					<template #body="row">
						{{ percentage(row.data.lt_conversion, 2) }}
					</template>
				</p-column>
				<p-column field="call_conversion" header="Call Conversion">
					<template #body="row">
						{{ percentage(row.data.call_conversion, 2) }}
					</template>
				</p-column>
				<p-column field="rpl" header="RPL">
					<template #body="row">
						{{ currency(row.data.rpl, 2) }}
					</template>
				</p-column>
			</p-data-table>
		</div>
		<gutter size="20px" />
		<h2>Source Code Bids</h2>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table :value="source_code_bids" scrollable>
				<p-column-group type="header">
					<p-row>
						<p-column header="Campaign Name" field="campaign_name" sortable />
						<p-column header="Source Code" field="source_code" sortable />
						<p-column header="Leads" field="leads" sortable />
						<p-column header="Live Transfers" field="live_transfers" sortable />
						<p-column header="Calls" field="calls" sortable />
						<p-column header="Revenue" field="revenue" sortable />
						<p-column header="LT Conversion" field="lt_conversion" sortable />
						<p-column header="Call Conversion" field="call_conversion" sortable />
						<p-column header="RPL" field="rpl" sortable />
					</p-row>
					<p-row>
						<p-column :colspan="2" header="Totals" class="bg-gray-100 border-bottom-1 border-gray-200 pb-2" />
						<p-column :header="source_code_bid_totals.leads" class="bg-gray-100 border-bottom-1 border-gray-200 pb-2" />
						<p-column
							:header="source_code_bid_totals.live_transfers"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column :header="source_code_bid_totals.calls" class="bg-gray-100 border-bottom-1 border-gray-200 pb-2" />
						<p-column
							:header="currency(source_code_bid_totals.revenue, 0)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column
							:header="percentage(source_code_bid_totals.lt_conversion, 2)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column
							:header="percentage(source_code_bid_totals.call_conversion, 2)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
						<p-column
							:header="currency(source_code_bid_totals.rpl, 2)"
							class="bg-gray-100 border-bottom-1 border-gray-200 pb-2"
						/>
					</p-row>
				</p-column-group>
				<p-column field="campaign_name" header="Campaign Name" />
				<p-column field="source_code" header="Source Code" />
				<p-column field="leads" header="Leads" />
				<p-column field="live_transfers" header="Live Transfers" />
				<p-column field="calls" header="Calls" />
				<p-column field="revenue" header="Revenue">
					<template #body="row">
						{{ currency(row.data.revenue, 0) }}
					</template>
				</p-column>
				<p-column field="lt_conversion" header="LT Conversion">
					<template #body="row">
						{{ percentage(row.data.lt_conversion, 2) }}
					</template>
				</p-column>
				<p-column field="call_conversion" header="Call Conversion">
					<template #body="row">
						{{ percentage(row.data.call_conversion, 2) }}
					</template>
				</p-column>
				<p-column field="rpl" header="RPL">
					<template #body="row">
						{{ currency(row.data.rpl, 2) }}
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, title, percentage, displaySelected } from '@/lib/Filters';
import pColumnGroup from 'primevue/columngroup';
import queryForm from '@/components/widgets/QueryForm.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import dayjs from 'dayjs';

export default {
	name: 'CallCenterBidCalculator',
	components: {
		dateRangePicker,
		pColumnGroup,
		queryForm,
		tableTools,
	},
	data() {
		return {
			loading: false,
			breadcrumb_items: [{ label: 'MAUT', route: '/maut' }, { label: 'Call Center Bid Calculator' }],
			query: {
				range: [dayjs().startOf('day').subtract(7, 'days').toDate(), dayjs().endOf('day').toDate()],
				show_low_quality: false,
			},
			campaign_bids: [
				{
					campaign_name: 'Health: Exclusive Low Quality Leads (24/7) - Meera',
					leads: 4241,
					live_transfers: 58,
					calls: 42,
					revenue: 6966,
					lt_conversion: 0.0137,
					call_conversion: 0.0356,
					rpl: 1.64,
				},
				{
					campaign_name: 'Medicare: Exclusive 24/7 - Meera.ai',
					leads: 3219,
					live_transfers: 122,
					calls: 209,
					revenue: 13272,
					lt_conversion: 0.0379,
					call_conversion: 0.0649,
					rpl: 4.12,
				},
			],
			source_code_bids: [
				{
					campaign_name: 'Health: Exclusive Low Quality Leads (24/7) - Meera',
					source_code: 'NG_HE_MA',
					leads: 465,
					live_transfers: 5,
					calls: 2,
					revenue: 331,
					lt_conversion: 0.0108,
					call_conversion: 0.0043,
					rpl: 0.71,
				},
				{
					campaign_name: 'Health: Exclusive Low Quality Leads (24/7) - Meera',
					source_code: 'NG_HE_DLT',
					leads: 366,
					live_transfers: 0,
					calls: 1,
					revenue: 95,
					lt_conversion: 0.0,
					call_conversion: 0.0027,
					rpl: 0.26,
				},
			],
		};
	},
	methods: {
		currency,
		formatDate,
		title,
		percentage,
		displaySelected,
	},
	computed: {
		campaign_bid_totals() {
			return {
				leads: this.campaign_bids.reduce((acc, bid) => acc + bid.leads, 0),
				live_transfers: this.campaign_bids.reduce((acc, bid) => acc + bid.live_transfers, 0),
				calls: this.campaign_bids.reduce((acc, bid) => acc + bid.calls, 0),
				revenue: this.campaign_bids.reduce((acc, bid) => acc + bid.revenue, 0),
				lt_conversion: this.campaign_bids.reduce((acc, bid) => acc + bid.lt_conversion, 0) / this.campaign_bids.length,
				call_conversion:
					this.campaign_bids.reduce((acc, bid) => acc + bid.call_conversion, 0) / this.campaign_bids.length,
				rpl: this.campaign_bids.reduce((acc, bid) => acc + bid.rpl, 0) / this.campaign_bids.length,
			};
		},
		source_code_bid_totals() {
			return {
				leads: this.source_code_bids.reduce((acc, bid) => acc + bid.leads, 0),
				live_transfers: this.source_code_bids.reduce((acc, bid) => acc + bid.live_transfers, 0),
				calls: this.source_code_bids.reduce((acc, bid) => acc + bid.calls, 0),
				revenue: this.source_code_bids.reduce((acc, bid) => acc + bid.revenue, 0),
				lt_conversion:
					this.source_code_bids.reduce((acc, bid) => acc + bid.lt_conversion, 0) / this.source_code_bids.length,
				call_conversion:
					this.source_code_bids.reduce((acc, bid) => acc + bid.call_conversion, 0) / this.source_code_bids.length,
				rpl: this.source_code_bids.reduce((acc, bid) => acc + bid.rpl, 0) / this.source_code_bids.length,
			};
		},
	},
};
</script>

<style scoped lang="less"></style>
